//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClienteEdit from './ClienteEdit';
import ClienteView from './ClienteView';

export default class Cliente extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/cliente/novo" component={ClienteEdit} />
          <Route path="/cliente/view/:id" exact component={ClienteView} />
          <Route path="/cliente/:id" component={ClienteEdit} />
        </Switch>
      </React.Fragment>
    );
  }
}
