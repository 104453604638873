//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../../components/templates/ListagemPage';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';
import { tableHelper } from '../../../helpers/tableHelper';
import { compraPecaService } from '../../../services/compraPeca.service';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import CompraPecaFilterAdmin from './CompraPecaFilterAdmin';
import { objectsConstants } from '../../../constants/objects.constants';
import { userService } from '../../../services/user.service';
import NovaCompraModal from '../../../components/Modals/NovaCompraModal';

const columns = [
  {
    path: 'numOs',
    title: 'Nº CP',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  { path: 'endereco', title: 'Cidade', isKey: false, dataSort: true },
  {
    path: 'postoAutorizado.nomeFantasia',
    title: 'Posto autorizado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'creationDateTime',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  { path: 'quantidadePecas', title: 'Peças', isKey: false, dataSort: false },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase'
  },
  {
    isKey: false,
    isFarol: true,
    path: 'diasCorridos'
  }
];

type State = {
  modal: boolean
};

class CompraPecaListAdmin extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      fields
    };
  }

  handleRowClick = (row: e) => {
    this.props.loading(true);
    compraPecaService.getById(row.id).then(response => {
      let ordemServico = response.data.data;
      this.props.setExtension(ordemServico.postoAutorizado.id);
      this.props.setOrdemServico(ordemServico);
      this.props.loading(false);
      this.props.history.push('/compraPeca/view');
    });
  };

  renderCustom(row: any, ordemServico: any, column: any) {
    if (column.isFarol) {
      let classFarol = ordemServicoHelper.getFarol(ordemServico);
      return <div className={`farol ${classFarol}`} />;
    } else {
      return tableHelper.renderCustom(row, ordemServico, column);
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Compra de Peças"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`COMPRA DE PECAS(${this.props.subTitle})`}
          newButtonActionClick={() => {
            if (userService.isAdmin()) {
              this.toggle();
            } else {
              this.props.history.push('/compraPeca/novo');
            }
          }}
          labelNewButton="Nova Compra"
          roleNewButton={objectsConstants.ABILITY_SOLICITAR_COMPRA_PECA}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={compraPecaService}
          defaultOrder={'creationDateTime'}
          defaultDirection={'desc'}
          defaultFilter={{ endereco: {} }}
          onRowClick={this.handleRowClick}
          classSignal="temSinal"
          FormFilter={CompraPecaFilterAdmin}
          showNewButton={false}
          controller="compraPeca"
          fields={this.state.fields}
        >
          {tableColumn}
        </ListagemPage>
        <NovaCompraModal
          isModalVisible={this.state.modal}
          toggleModal={this.toggle}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

const mapDispatch = ({
  load: { loading },
  postoAutorizado: { setExtension },
  ordemServico: { setOrdemServico }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load }),
  setExtension: (extension: number) => setExtension(extension)
});

export default connect(
  mapStateToProps,
  mapDispatch
)(CompraPecaListAdmin);
