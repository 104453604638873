//@flow
import * as React from 'react';
import { marcaService } from '../../../services/marca.service';
import SelectInput from '../../../components/Inputs/SelectInput';
import FormInput from '../../../components/Inputs/FormInput';
import DatePickerInput from '../../../components/Inputs/DatePickerInput';
import moment from 'moment/moment';
import { Can } from '@casl/react';

import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
export default class RelatorioDeOSExpiradaFormFilter extends React.PureComponent<
  Props,
  State
> {
  render() {
    const { entity, permissions } = this.props;
    return (
      <React.Fragment>
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataInicio"
          label="Data de "
          value={entity.dataInicio ? moment(entity.dataInicio) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={['col-6 col-sm-3']}
          id={'dataInicio'}
        />
        <DatePickerInput
          name="dataFim"
          label="Data até "
          placeholder={'--/--/--'}
          value={entity.dataFim ? moment(entity.dataFim) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={['col-6 col-sm-3']}
          id={'dataFim'}
        />
        <FormInput
          type="text"
          name="codRelogio"
          label="Cód. Relógio"
          placeholder="Cód. Relógio"
          style={['col-12 col-sm-3']}
          id={'codRelogio'}
          onChange={this.props.onChangeUpperCase}
          value={entity.codRelogio}
        />
      </React.Fragment>
    );
  }
}
