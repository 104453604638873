//@flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { UserAvatar } from '../../components/menu/UserAvatar';
import { colaboradorService } from '../../services/colaborador.service';
type Props = {
  id: number
};

class CardHistorico extends React.Component<> {
  constructor(props: Props) {
    super(props);
    this.state = { listHistorico: [] };
  }

  componentDidMount() {
    colaboradorService.getHistoricoForUser(this.props.id).then(response => {
      let listHistorico = response.data.data;
      this.setState({ listHistorico });
    });
  }
  render() {
    return (
      <div className="card cardSeculus d-flex flex-column">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">Histórico</h3>
        </div>
        <div className="conteudoCard mt-2">
          <div className="row ">
            <div className="col-12 pt-3">
              <div className="historico">
                <ul>
                  {this.state.listHistorico.map(function(historico, index) {
                    return (
                      <li className={historico.className} key={index}>
                        <div className="bullet">{index + 1}</div>
                        <div className="pb-1 pb-sm-4 pl-2 pl-xl-0">
                          <div className="titulo">{historico.tipo}</div>
                          <div className="data">{historico.data}</div>
                          <div className="autor d-flex align-items-center">
                            <UserAvatar foto={historico.usuario.foto} />
                            <div className="por ml-1">
                              Por: {historico.usuario.name}
                            </div>
                          </div>
                          {historico.diffs.map(function(diffItem, index) {
                            return (
                              <div className="descricao align-items-center pt-2">
                                <label className="descricao d-block">
                                  Alterou {diffItem.fielName}
                                </label>
                                <label className="descricao d-block">
                                  <strong className="miniLabel"> De:</strong>
                                  {diffItem.from}
                                </label>
                                <label className="descricao d-block">
                                  <strong className="miniLabel"> Para:</strong>
                                  {diffItem.to}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {this.state.listHistorico.length === 0 && (
                <div className="justify-content-center pl-3">
                  <p className="p-msg">Nenhum registro encontrado</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  null
)(withRouter(CardHistorico));
