//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const tipoTransporteService = {
  findAll
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.FIND_TIPO_TRANSPORTE}`);
}
