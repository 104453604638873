//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import FinanceiroList from './FinanceiroList';

export default class Financeiro extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/financeiro" component={FinanceiroList} />
        </Switch>
      </React.Fragment>
    );
  }
}
