//@flow
import * as React from 'react';
import '../Components/table.css';
import TablePecasViewItem from './TablePecasViewItem';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class TablePecasView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <div className="react-bs-table pb-5">
          <div className="react-bs-container-header table-header-wrapper table-header">
            <table className="table table-hover tablePecas">
              <thead>
                <tr className="headerGrid text-left">
                  <th colSpan="2">Peça</th>
                  <th>Código peça</th>
                  <th>Valor unit.</th>
                  <th>Qtd.</th>
                  <th>Qtd. atendida</th>
                  <th />
                </tr>
              </thead>
            </table>
          </div>
          <div className="react-bs-container-body">
            <table className="table tablePecas">
              <tbody>
                {this.props.listPecas.map((peca, index) => (
                  <TablePecasViewItem
                    key={peca.id}
                    peca={peca}
                    lasItem={this.props.listPecas.length - 1 == index}
                    index={index}
                    onRejectPeca={this.props.onRejectPeca}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TablePecasView;
