import ListagemConversa from './ListagemConversas';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Chat from './Chat';

export default class Conversa extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/chat" exact component={ListagemConversa} />
          <Route path="/chat/private/:receiver" component={Chat} />
        </Switch>
      </React.Fragment>
    );
  }
}
