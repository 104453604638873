//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import Datatable from '../../../components/table/Datatable';
import { CardItemFull } from '../../../components/templates/CardItemFull';
import HeaderList from '../../../components/templates/HeaderList';
import { tableHelper } from '../../../helpers/tableHelper';
import { pesquisaSatisfacaoClientesService } from '../../../services/pesquisaSatisfacaoClientesService';

const columns = [
  { path: 'cliente.nome', title: 'Nome', isKey: false, dataSort: false },
  {
    path: 'cliente.email',
    title: 'E-mail',
    isKey: false,
    dataSort: true
  },
  {
    path: 'cliente.celular',
    title: 'Telefone',
    isKey: false,
    dataSort: true
  },
  {
    path: 'velocidade',
    title: 'Velocidade do serviço',
    isKey: false,
    dataSort: true
  },
  {
    path: 'solucao',
    title: 'Solução do problema',
    isKey: false,
    dataSort: true
  },
  {
    path: 'relacionamento',
    title: 'Relacionamento',
    isKey: false,
    dataSort: true
  },
  {
    path: 'mediaAvaliacao',
    title: 'Avaliação',
    isKey: true,
    dataSort: true,
    isDecimal: true
  }
];

type State = {
  modal: boolean
};

let timoutVar;
class PostoAutorizadoClientList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let modal = false;

    // if (this.props.location.state && this.props.location.state.showModal) {
    //   modal = this.props.location.state.showModal;
    // }

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      modal,
      fields,
      selected: [],
      allowSearch: false,
      filter: props.filter
    };
  }

  renderCustom = (row: any, cliente: any, column: any) => {
    return tableHelper.renderCustom(row, cliente, column);
  };

  updateTable = () => {
    this._datatable.getWrappedInstance().updateTable();
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <CardItemFull>
          <HeaderList
            title={`CLIENTES(${this.props.subTitle})`}
            labelNewButton={''}
            showNewButton={false}
            filter={this.props.filter}
            controller={'relatorio/postoAutorizadoClientes'}
            fields={this.state.fields}
          />

          <Datatable
            ref={datatable => (this._datatable = datatable)}
            defaultOrder={'creationDateTime'}
            defaultDirection={'desc'}
            service={pesquisaSatisfacaoClientesService}
            classSignal={this.props.classSignal}
            filter={this.state.filter}
            onRowClick={this.props.onRowClick}
            submitFilter={this.state.submitFilter}
          >
            {tableColumn}
          </Datatable>
        </CardItemFull>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle, mainTitle } = state.title;

  return {
    subTitle,
    mainTitle
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(PostoAutorizadoClientList);
