//@flow
import * as React from 'react';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import { relatorioService } from '../../../services/relatorio.service';
import RelatorioRepresamentoPreReparoFormFilter from './RepresamentoPreReparoFormFilter';
import RelatorioRepresamentoPreReparoChart from './RepresamentoPreReparoChart';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import _ from 'lodash';

export default class RelatorioRepresamentoPreReparo extends React.PureComponent<{}> {
  constructor(props) {
    super(props);
    this.state = { showTable: false };
  }

  render() {
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle=" Represamento pré-reparo "
          canBack={true}
        />{' '}
        <CardRelatorio
          controller={'relatorio/represamentoPreReparo'}
          FormFilter={RelatorioRepresamentoPreReparoFormFilter}
          renderChartOnConstruct={true}
          showTable={this.state.showTable}
          Chart={RelatorioRepresamentoPreReparoChart}
          hideExport={true}
        />
      </React.Fragment>
    );
  }
}
