//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import CardCompraPecaList from '../../components/Cards/CardCompraPecaList';
import { compraPecaService } from '../../services/compraPeca.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import Toast from '../../components/Toasts/Toast';
import { withRouter } from 'react-router-dom';
import ChatOs from '../chat/ChatOs';
import OrdemServicoHistorico from '../ordemServico/OrdemServicoHistorico';
import RelogioContainerItemView from './RelogioContainerItemView';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import _ from 'lodash';
import { dateHelper } from '../../helpers/date.helper';
import ConfirmarPecasBtn from './Components/ConfirmarPecasBtn';
import { propertyConstantes } from '../../constants/property.constantes';
import { objectsConstants } from '../../constants/objects.constants';
import { Redirect } from 'react-router-dom';

class CompraPecaView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  getValorCompra = () => {
    const entity = this.props.ordemServico;
    let valorTotal = 0;
    _.map(entity.relogios, function(item) {
      _.map(item.pecas, function(peca) {
        valorTotal += parseInt(peca.quantidade) * peca.valorCompra;
      });
    });

    return ordemServicoHelper.formatMoney(parseFloat(valorTotal), 2, ',', '.');
  };

  confirmarRecebimento = () => {
    this.props.loading(true);
    const { ordemServico } = this.props;

    compraPecaService.confirmarRecebimento(ordemServico.id).then(
      r => {
        this.props.setOrdemServico(r.data.data);
        this.props.success({
          message: 'Recebimento confirmado com sucesso!'
        });
        this.props.loading(false);
      },
      error => {
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível aprovar orçamento.'
        });
      }
    );
  };

  getStateOrButton = ordemServico => {
    const { user } = this.props;
    if (
      user.role === objectsConstants.ROLE_ADMIN ||
      user.role === objectsConstants.ROLE_MANAUS
    ) {
      return ordemServico.statusOS.description;
    }

    if (
      ordemServico.statusOS.name === propertyConstantes.AGUARDANDO_RECEBIMENTO
    ) {
      return (
        <ConfirmarPecasBtn
          onConfirm={this.confirmarRecebimento}
          numOs={ordemServico.numOs}
        />
      );
    }

    return ordemServico.statusOS.description;
  };

  render() {
    const entity = this.props.ordemServico;

    let posto = '';
    if (!this.props.match.params.id) {
      // posto = this.props.location.state.entity.posto;
      posto = entity.postoAutorizado;
    }
    console.log(entity);
    if (!entity) {
      return (
        <Redirect
          to={{
            pathname: `/compraPeca`
          }}
        />
      );
    }

    let dias =
      dateHelper.diff(entity.creationDateTime, entity.receiverDateTime) +
      ' dias';

    return (
      <React.Fragment>
        {entity.id && (
          <TopoTitleComponente
            mainTitle="Compra DE PEÇAS"
            subTitle={entity.numOs}
            canBack={true}
          />
        )}
        <Toast />
        <div className="flex-column">
          <CardCompraPecaList showNewOS={false} posto={posto} />
        </div>

        {entity.id && (
          <div className="flex-column">
            <div className="card cardSeculus cardTab d-flex flex-column w2">
              <h4 className=" text-center d-inline d-lg-none">
                {entity.numOs}
              </h4>
              <div>
                <ul className="nav" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="relogio-tab"
                      data-toggle="tab"
                      data-target="#relogio"
                      role="tab"
                      aria-controls="relogio"
                      aria-selected="true"
                    >
                      <i className="icon-relogio" />{' '}
                      <span className="d-none d-md-inline">Peças</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="mensagens-tab"
                      data-toggle="tab"
                      data-target="#mensagens"
                      role="tab"
                      aria-controls="mensagens"
                      aria-selected="false"
                    >
                      <i className="icon-mensagem_x" />{' '}
                      <span className="d-none d-md-inline">Mensagens</span>
                    </a>
                    <span className="badge badge-success">
                      {this.props.totalMessages}
                    </span>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="historico-tab"
                      data-toggle="tab"
                      data-target="#historico"
                      role="tab"
                      aria-controls="historico"
                      aria-selected="false"
                    >
                      <i className="icon-historico" />{' '}
                      <span className="d-none d-md-inline">histórico</span>
                    </a>
                  </li>
                  <li className="nav-item" />
                  <li className="nOs float-right d-none d-lg-inline">
                    {entity.numOs}
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="relogio"
                    role="tabpanel"
                    aria-labelledby="relogio-tab"
                  >
                    <div className="row topoTab">
                      <div className="col-3 px-1 px-sm-2 px-md-3">
                        <h4>Solicitação aberta</h4>
                        <h1>
                          <b>{dias}</b>
                        </h1>
                      </div>
                      <div className="col-4 px-1 px-sm-2 px-md-3 text-center">
                        <h4>valor Total da solicitação</h4>
                        <h1>
                          <b>{this.getValorCompra()}</b>
                        </h1>
                      </div>
                      <div className="col-5 text-right px-1 px-sm-2 px-md-3">
                        <h4 className="txtBlue">
                          {entity.codigoRastreio ? entity.codigoRastreio : '--'}
                        </h4>
                        <h4
                          className={`sts ${
                            entity.statusOS.name ===
                            propertyConstantes.APROVAR_ORCAMENTO
                              ? 'txtBlue bold'
                              : ''
                          }`}
                        >
                          {this.getStateOrButton(entity)}
                        </h4>
                        <p>
                          {entity.dataRecebimento && 'Recebido '}
                          {entity.dataRecebimento &&
                            dateHelper.format(entity.dataRecebimento, {
                              pattern: 'DD/MM/YYYY'
                            })}
                        </p>
                      </div>
                    </div>
                    <hr />

                    {entity.relogios.map((relogio, index) => (
                      <RelogioContainerItemView
                        key={relogio.relogio.id}
                        relogio={relogio}
                        compraPeca={entity}
                        codigoRastreio={entity.codigoRastreio}
                      />
                    ))}
                  </div>
                  <div
                    className="tab-pane fade "
                    id="mensagens"
                    role="tabpanel "
                    aria-labelledby="mensagens-tab "
                  >
                    <ChatOs os={entity.id} />
                  </div>
                  <div
                    className="tab-pane fade "
                    id="historico"
                    role="tabpanel "
                    aria-labelledby="historico-tab "
                  >
                    <OrdemServicoHistorico id={entity.id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { ordemServico } = state.ordemServico;
  const { totalMessages } = state.chat;
  const { user, permissions } = state.authentication;
  return {
    ordemServico,
    user,
    permissions,
    totalMessages
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico },
  alert: { success, error },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(withRouter(CompraPecaView));
