//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const importacaoValoresPecasService = {
  findAll,
  findByFiltro,
  importarValoresPecas,
};
function findAll() {
  return defaultService.doGet(`${urlsConstants.IMPORTACOES_VALORES_FINDALL}`);
}
function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.IMPORTACOES_VALORES_PECAS}list`,
    options,
    filter
  );
}
function importarValoresPecas(archive: any) {
  return defaultService.doPost(
    urlsConstants.IMPORTACOES_VALORES_PECAS_IMPORTAR,
    archive
  );
}
