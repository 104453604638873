import React, { PureComponent } from "react";
import SockJsClient from "react-stomp";
import { oauthHeaderJson } from "../../helpers/oauth-header";
import { mensagemService } from "../../services/messagem.service";
type Props = {
  id: number,
  urlConect: String
};
type State = {
  entity: any
};

export default class SocketConect extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }  

  render() {
    const { urlConect } = this.props;
    const wsSourceUrl = mensagemService.getWsUrl();
    let headers = oauthHeaderJson();
    return (
      <SockJsClient
        url={wsSourceUrl}
        topics={[urlConect, "/app" + urlConect]}
        onMessage={msg => {
          this.props.handleMessage(msg);
        }}
        subscribeHeaders={headers}
        headers={headers}
        ref={client => {
          this.clientRef = client;
        }}
      />
    );
  }
}