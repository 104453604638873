//@flow
import * as React from 'react';

import { Route, Switch } from 'react-router-dom';
import RelogioCrudList from './RelogioCrudList';
import RelogioEdit from './RelogioEdit';

export default class RelogioCrud extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/relogio" exact component={RelogioCrudList} />
          <Route path="/relogio/novo/" component={RelogioEdit} />
          <Route path="/relogio/edit/:id" component={RelogioEdit} />
        </Switch>
      </React.Fragment>
    );
  }
}
