//@flow
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import { tableHelper } from '../../../helpers/tableHelper';
import { consumoBaterias } from '../../../services/consumoBaterias.service';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import ConsumoBateriasChart from './ConsumoBateriasChart';
import ConsumoBateriasFormFilter from './ConsumoBateriasChartFormFilter';

//Nº
const columnsSintetic = [
  {
    path: 'calibre',
    title: 'Calibre',
    isKey: true,
    dataSort: true
  },
  {
    path: 'quantidade',
    title: 'Quantidade',
    isKey: false,
    dataSort: true
  }
];
const columns = [
  {
    path: 'calibre',
    title: 'Calibre',
    isKey: true,
    dataSort: true
  },
  {
    path: 'codigoRelogio',
    title: 'Código do relógio',
    isKey: false,
    dataSort: true
  },
  {
    path: 'nomeMarca',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'garantia',
    title: 'Garantia',
    isKey: false,
    dataSort: true,
    yesNo: true
  },
  {
    path: 'quantidade',
    title: 'Quantidade',
    isKey: false,
    dataSort: true
  }
];

class ConsumoBaterias extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let month = moment().month();
    let year = moment().year();

    let dataInicio = moment()
      .month(month)
      .year(year)
      .date(1)
      .hour(0)
      .minute(0);

    let dataFim = moment()
      .month(month)
      .year(year)
      .hour(23)
      .minute(59);

    dataFim = dataFim.date(dataFim.endOf('month').format('DD'));

    this.state = {
      fields,
      defaultFilter: {
        dataInicio: dataInicio.format('YYYY-MM-DDTHH:mm:ss'),
        dataFim: dataFim.format('YYYY-MM-DDTHH:mm:ss'),
        sintetico: false
      },
      filter: {}
    };
  }
  _handleFilterChange = filter => {
    this.setState({ filter });
  };
  render() {
    let handleColumn = columns;
    if (this.state.filter.sintetico) {
      handleColumn = columnsSintetic;
    }
    const tableColumn = _.map(handleColumn, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="CONSUMO DE BATERIAS "
          canBack={false}
        />
        <CardRelatorio
          title={`CONSUMO DE BATERIAS (${this.props.subTitle})`}
          FormFilter={ConsumoBateriasFormFilter}
          Chart={ConsumoBateriasChart}
          showTable={true}
          tableService={consumoBaterias}
          controller={'relatorio/consumoBaterias'}
          fields={this.state.fields}
          defaultFilter={this.state.defaultFilter}
          onHandleFilter={this._handleFilterChange}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(ConsumoBaterias);
