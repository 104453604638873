import React, {PureComponent} from 'react';
import ImageInput from '../../components/Inputs/ImageInput';
import CropperView from '../../components/Imagen/CropperView';
import {connect} from 'react-redux';

type Props = {
    onChange: any,
    name: string,
    dataUrl: any,
    title: string,
    subititle: string
};
type State = {
    entity: any
};

export default class PecaImageLoad extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            entity: props.entity,
            image: null
        };
    }
    // componentWillReceiveProps(nexProps: any) {
    //   this.setState({ dataUrl: nexProps.dataUrl });
    // }
    changeImage = image => {
        this.setState({ image });
    };
    onCropper = dataUrl => {
        let { image } = this.state;
        image.data = dataUrl;
        this.setState({ image: null });
        if (this.props.onChange) {
            this.props.onChange(this.props.name,image);
        }
    };

    render() {
        let { image } = this.state;
        let dataURL = this.props.dataUrl();
        return (
            <React.Fragment>
                <CropperView
                    modal={image ? true : false}
        onCropper={this.onCropper}
        titleBtn={'Cortar e enviar imagem'}
        title={this.props.title}
        subititle={this.props.subititle}
        maxHeight={150}
        maxWidth={300}
        dataUrl={image ? image.data : null}
        onChange={this.props.onChange}
        />
        <div className="buttonImage action">
        <ImageInput
            maxHeight={300}
        maxWidth={150}
        // height={150}
        onChange={this.changeImage}
        dataUrl={dataURL}
        alt={this.props.title}
        className={"fotosLoja "+this.props.className}
        readOnly={this.props.readOnly}
        ref={input => {
            this._imageInputRef = input;
        }}
        />
        </div>
        </React.Fragment>
    );
    }
}
