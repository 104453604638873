//@flow
import * as React from 'react';
import type { ClienteType } from '../../types/cliente.type';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { objectsConstants } from '../../constants/objects.constants';
import { Can } from '@casl/react';
import { propertyConstantes } from '../../constants/property.constantes';

type Props = {
  cliente: ClienteType
};

class CardCliente extends React.Component<Props> {
  render() {
    const { cliente, permissions } = this.props;
    return (
      <div className="card cardSeculus miniCard d-flex flex-column ">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">cliente</h3>
          <div className="boxButon float-right d-sm-flex">
            <Can
              I={objectsConstants.VIEW}
              a={objectsConstants.ABILITY_EDITAR_CLIENTE}
              ability={permissions}
            >
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-toggle="modal"
                data-target="#editarDados"
                onClick={() => {
                  this.props.history.push(`/cliente/${cliente.id}`);
                }}
              >
                editar dados
              </button>
            </Can>
          </div>
        </div>

        <div className="conteudoCard mt-2">
          <div className="row ">
            <div className="col-12">
              <label className="miniLabel">Nome:</label>
              <div className="mb-2">
                <b>{cliente.nome}</b>
              </div>
            </div>

            <div className="col-12">
              <label className="miniLabel">CPF ou CNPJ:</label>
              <div className="mb-2">{cliente.documento}</div>
            </div>

            <div className="col-12">
              <label className="miniLabel">E-mail:</label>
              <div className="mb-2">
                {cliente.email ? cliente.email : 'Não informado'}
              </div>
            </div>
            <div className="col-12">
              <label className="miniLabel">Endereço:</label>
              <div className="mb-2">
                {cliente.endereco.rua}, {cliente.endereco.numero} -{' '}{cliente.endereco.complemento?cliente.endereco.complemento+" - ":""}
                {cliente.endereco.bairro}
                <br /> {cliente.endereco.cidade.nome}/
                {cliente.endereco.cidade.estado?cliente.endereco.cidade.estado.nome:''} - {cliente.endereco.cep}
              </div>
            </div>
            {cliente.telefones.map((telefone, index) => {
              return (
                <div className="col-6" key={telefone.id}>
                  <label className="miniLabel">
                    {propertyConstantes[telefone.tipo]}:
                  </label>
                  <div className="mb-2">{telefone.numero}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;
  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(CardCliente));
