//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import OsAguardandoImportacaoList from './OsAguardandoImportacaoList';
import OsAguardandoImportacaoView from "./OsAguardandoImportacaoView";

export default class OsAguardandoImportacao extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
   {/*       <Route path="/doc/view/:id" exact component={DocView} />
          <Route path="/doc" exact component={DocList} />*/}
            <Route path="/aguardandoImportacao" exact component={OsAguardandoImportacaoList} />
            <Route path="/aguardandoImportacao/view/:marca/:status" exact component={OsAguardandoImportacaoView} />
        </Switch>
      </React.Fragment>
    );
  }
}
