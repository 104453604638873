//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { FormRow } from '../../components/FormRow';
import FileInput from '../../components/Inputs/FileInput';
import FormInput from '../../components/Inputs/FormInput';
import PhoneInput from '../../components/Inputs/PhoneInput';
import SelectInput from '../../components/Inputs/SelectInput';
import ZipCodeInput from '../../components/Inputs/ZipCodeInput';
import { objectsConstants } from '../../constants/objects.constants';
import { urlsConstants } from '../../constants/url.constant';
import { validateInput } from '../../helpers/validateInputs.helper';
import { enderecoService } from '../../services/endereco.service';
import { formsServices } from '../../services/forms.service';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import PostoImageLoad from './PostoImageLoad';

type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  country: Array<any>,
  states: Array<any>,
  cities: Array<any>,
  validateObject: any,
  onBack: any,
  step: number,
  children?: any
};

const objectValidateBranchVO = [
  objectsConstants.nomeFantasiaValidate,
  objectsConstants.telefoneComercialValidate
];

const objectValidateAddressVO = [
  objectsConstants.paisValidate,
  objectsConstants.estadoValidate,
  objectsConstants.cidadeValidate,
  objectsConstants.cepValidate,
  objectsConstants.ruaValidate,
  objectsConstants.numeroValidate,
  objectsConstants.bairroValidate
];

export default class PostoAutorizadoForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      entity: _.cloneDeep(this.props.entity),
      country: formsServices.getCountry(),
      states: formsServices.getStates(),
      cities: formsServices.getCities(),
      validateObject: {}
    };
  }

  handleChangebranchVO = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    let entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, type === 'checkbox' ? checked : value);
    this.setState({ entity });
  };

  handleSelectChange = (name: string, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);

    newEntity[name] = option !== null ? option.value : option;
    this.setState({ entity: newEntity });
  };

  handleZipCode = (location, zipCode) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);

    if (location.erro) {
      _.set(newEntity, 'cep', zipCode);
      this.setState({ entity: newEntity });
    } else {
      _.set(newEntity, 'cep', zipCode);

      enderecoService
        .findEstadoExact(location.uf, objectsConstants.PAIS_DEFAULT)
        .then(estado => {
          _.set(newEntity, 'estado', estado.data);
          enderecoService
            .findCidadeExact(location.localidade, estado.data)
            .then(cidade => {
              _.set(newEntity, 'cidade', cidade.data);
              _.set(newEntity, 'bairro', location.bairro);
              _.set(newEntity, 'rua', location.logradouro);
              this.setState({ entity: newEntity });
            });
        });
    }
  };

  getEntity = () => {
    return this.state.entity;
  };
  validateForms = () => {
    const { entity } = this.state;
    let isValid;
    let validate = {};

    objectValidateBranchVO.forEach(o => {
      const value = entity[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });
    objectValidateAddressVO.forEach(o => {
      const value = entity[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });

    this.setState({ validateObject: validate });

    if (_.isEmpty(validate)) {
      isValid = true;
    }

    return isValid;
  };

  handleChangeImage = (name, value) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, value);
    this.setState({ entity: newEntity });
  };

  getFaixadaURL = () => {
    const { entity } = this.state;
    if (entity.faixada) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.faixada.id}`;
    } else {
      return require('../../img/foto_faixada.svg');
    }
  };

  getFotoInternaURL = () => {
    const { entity } = this.state;
    if (entity.fotoInterna) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.fotoInterna.id}`;
    } else {
      return require('../../img/foto_internaLoja.svg');
    }
  };

  render() {
    const { entity, validateObject } = this.state;

    return (
      <React.Fragment>
        <div className="conteudoCard flex-fill flex-row d-sm-flex">
          <FormRow>
            <div className="col-12 col-md-6 mx-0">
              <div className="row m-0">
                <div className="col-6 col-md-12 pr-1 pl-0 p-md-1">
                  <PostoImageLoad
                    name={'faixada'}
                    title={'FOTO DA FACHADA'}
                    subititle={
                      'A foto precisa mostrar toda faixada da loja, com placa e nome legível.'
                    }
                    dataUrl={this.getFaixadaURL}
                    onChange={this.handleChangeImage}
                    saveData={postoAutorizadoService.saveFaixada}
                    entity={entity}
                  />
                </div>
                <div className="col-6 col-md-12 pl-1 pr-0 p-md-1">
                  <PostoImageLoad
                    name={'fotoInterna'}
                    title={'FOTO ITERNA DA LOJA'}
                    subititle={'A foto precisa mostrar interior da loja.'}
                    dataUrl={this.getFotoInternaURL}
                    onChange={this.handleChangeImage}
                    saveData={postoAutorizadoService.saveFotoInterna}
                    entity={entity}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="form-row">
                <FormInput
                  type="text"
                  name="nomeFantasia"
                  label="Nome fantasia"
                  placeholder="Nome fantasia"
                  required={true}
                  messageValidate={validateObject.nomeFantasia}
                  style={['col-12 col-sm-12']}
                  id={'nomeFantasia'}
                  ref={input => {
                    this._nomeFantasia = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.nomeFantasia}
                />

                <FormInput
                  type="text"
                  name="horaFuncionamento"
                  label="Horário de Funcionamento"
                  placeholder="Horário de Funcionamento"
                  //erroMensagem={validateObject.horaFuncionamento}
                  required={false}
                  style={['col-12 col-lg-12']}
                  id={'horaFuncionamento'}
                  ref={input => {
                    this._horaFuncionamento = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.horaFuncionamento}
                />
                <FormInput
                    type="text"
                    name="emailComercial"
                    label="E-mail Comercial"
                    placeholder="E-mail Comercial"
                    required={true}
                    style={['col-12 col-lg-12']}
                    id={'emailComercial'}
                    ref={input => {
                      this._emailComercial = input;
                    }}
                    onChange={this.handleChangebranchVO}
                    value={entity.emailComercial}
                />

                <SelectInput
                  type="text"
                  name="pais"
                  label="País"
                  placeholder="País"
                  onFetchData={enderecoService.findPais}
                  valueKey="id"
                  labelKey="nome"
                  required={true}
                  style={['col-6 col-sm-6']}
                  size={6}
                  returnFullObject={true}
                  searchable={true}
                  //messageValidate={validate.portadorDeficiencia}
                  id={'pais'}
                  onChange={this.handleSelectChange}
                  value={entity.pais}
                />

                <SelectInput
                  type="text"
                  name="estado"
                  label="Estado"
                  placeholder="Estado"
                  messageValidate={validateObject.estado}
                  required={true}
                  style={['col-6 col-sm-6']}
                  size={6}
                  onFetchData={enderecoService.findEstado}
                  returnFullObject={true}
                  searchable={true}
                  //messageValidate={validate.portadorDeficiencia}
                  id={'estado'}
                  valueKey="id"
                  labelKey="nome"
                  onChange={this.handleSelectChange}
                  value={entity.estado}
                />

                <SelectInput
                  type="text"
                  name="cidade"
                  label="Cidade"
                  placeholder="Cidade"
                  messageValidate={validateObject.cidade}
                  required={true}
                  style={['col-12 col-sm-12']}
                  onFetchData={enderecoService.findCidade}
                  returnFullObject={true}
                  searchable={true}
                  //messageValidate={validate.portadorDeficiencia}
                  id={'cidade'}
                  ref={input => {
                    this._cidade = input;
                  }}
                  valueKey="id"
                  labelKey="nome"
                  onChange={this.handleSelectChange}
                  value={entity.cidade}
                />

                <ZipCodeInput
                  type="number"
                  name="cep"
                  label="CEP"
                  placeholder="CEP"
                  messageValidate={validateObject.cep}
                  required={true}
                  style={['col-6 col-sm-6']}
                  size={6}
                  id={'cep'}
                  ref={input => {
                    this._cep = input;
                  }}
                  onChange={(location, zipCode) => {
                    this.handleZipCode(location, zipCode);
                  }}
                  value={entity.cep}
                />

                <FormInput
                  type="text"
                  name="rua"
                  label="Logradouro"
                  placeholder="Av, Rua, Alameda"
                  messageValidate={validateObject.rua}
                  required={true}
                  style={['col-12 col-sm-12']}
                  id={'rua'}
                  ref={input => {
                    this._logradouro = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.rua}
                />
                <FormInput
                  type="text"
                  name="numero"
                  label="Nº"
                  placeholder="Nº"
                  messageValidate={validateObject.numero}
                  required={true}
                  style={['col-4 col-sm-4']}
                  size={4}
                  id={'numero'}
                  ref={input => {
                    this._numero = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.numero}
                />
                <FormInput
                  type="text"
                  name="complemento"
                  label="Complemento"
                  placeholder="Complemento"
                  messageValidate={validateObject.complemento}
                  required={false}
                  style={['col-8 col-sm-8']}
                  size={8}
                  id={'complemento'}
                  ref={input => {
                    this._complemento = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.complemento}
                />
                <FormInput
                  type="text"
                  name="bairro"
                  label="Bairro"
                  placeholder="Bairro"
                  messageValidate={validateObject.bairro}
                  required={true}
                  style={['col-12 col-sm-12']}
                  id={'bairro'}
                  ref={input => {
                    this._bairro = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.bairro}
                />

                <PhoneInput
                  type="number"
                  name="telefoneComercial"
                  label="Telefone comercial"
                  placeholder="Somente números"
                  messageValidate={validateObject.telefoneComercial}
                  required={true}
                  style={['col-12 col-sm-8']}
                  size={2}
                  id={'telefoneComercial'}
                  ref={input => {
                    this._telefoneComercial = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.telefoneComercial}
                />

                <div className="form-group col-12 col-lg-12 mt-4">
                  {entity.id && (
                    <FileInput
                      emptyLabel="Anexar contrato"
                      name="contratoSocial"
                      saveData={postoAutorizadoService.saveContrato}
                      entity={entity}
                      fileItem={entity.contratoSocial}
                      onChange={this.handleChangeImage}
                    />
                  )}
                </div>
              </div>
            </div>
          </FormRow>
        </div>
      </React.Fragment>
    );
  }
}
