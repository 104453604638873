import $ from 'jquery';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import FormInput from '../../components/Inputs/FormInput';
import { passwordRecoverService } from '../../services/passwordRecoverService';
import '../index/customIndex.css';
import ResetPasswordSucess from './ResetPasswordSucess';
import SectionLoginBase from './SectionLoginBase';

type Props = {
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  alert: any,
  fieldMessage: any
};

class RecoverReset extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      key: this.props.match.params.id,
      match: false,
      msgError: '',
      errors: '',
      strongMsg: '',
      sended: false
    };
  }

  handlePassStrong = () => {
    const { password } = this.state;

    let lower = /[A-Z]/;
    let numeric = /[0-9]/;

    return lower.test(password) && numeric.test(password);
  };

  handleRecoverPassword = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    const { password, confirmPassword } = this.state;

    this.props.clear();

    if (this.handlePassStrong()) {
      this.props.loading(true);

      let entity = {
        key: this.props.match.params.id,
        password: password,
        confirmPassword: confirmPassword
      };

      return passwordRecoverService.reset(entity).then(
        response => {
          let json = response.data;
          this.setState({
            sended: true
          });
          this.props.loading(false);
        },
        error => {
          this.setState({ errors: error.response.data.messages, saved: false });
          this.props.loading(false);
        }
      );
    } else {
      this.props.clear();
      this.props.error(
        'Certifique-se que a senha digitada tenha pelomenos uma letra maiúscula e um número.'
      );
    }
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  componentWillMount() {
    localStorage.removeItem('token');

    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px'
    });
  }

  clickEvent(event) {
    if (event.target.hash !== '') {
      event.preventDefault();
      let hash = event.target.hash;
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top
        },
        500,
        function() {
          window.location.hash = hash;
        }
      );
    }
  }

  render() {
    const { sended, password, confirmPassword, match } = this.state;
    let inputPassAndConfirm = password && confirmPassword;

    if (sended) {
      return <ResetPasswordSucess />;
    }

    return (
      <SectionLoginBase>
        <div className="index_btCadastro" />
        <h1 className="mb-2 text-center">Redefinir Senha</h1>
        <p className="mb-4 text-center">
          Informe uma nova senha e a confirmação
        </p>

        <form className="index_form" onSubmit={this.handleRecoverPassword}>
          {/* <!--toast error--> */}

          <FormInput
            label={'Nova senha'}
            type={'password'}
            name={'password'}
            onChange={this.handleChange}
            value={password}
            placeholder="Digite sua nova senha"
            noSize={true}
            id={'password'}
            className="index_input"
          />

          <FormInput
            label={'Confirmação de nova senha'}
            type={'password'}
            name={'confirmPassword'}
            onChange={this.handleChange}
            value={confirmPassword}
            placeholder="Confirme a nova senha"
            noSize={true}
            id={'confirmPassword'}
            className="index_input"
          />

          <div className="mb-5 mt-5">
            <NavLink to="/index">Voltar</NavLink>
          </div>
          <button
            type="submit"
            className="btn btn-primary mb-4"
            disabled={!inputPassAndConfirm}
          >
            ALTERAR
          </button>
        </form>
      </SectionLoginBase>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    loggedIn,
    alert,
    messages
  };
}

const mapDispatch = ({
  alert: { error, clear },
  authentication: { login },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  loading: (load: boolean) => loading({ load }),
  login: (user, password) => login({ user, password })
});
export default connect(
  mapStateToProps,
  mapDispatch
)(RecoverReset);
