//@flow
import * as React from 'react';
import FormInput from '../../components/Inputs/FormInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import { colaboradoresHelper } from '../../helpers/colaboradores.helper';
import RoleSelectInput from '../../components/Inputs/RoleSelectInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class ColaboradorCrudListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <RoleSelectInput
          label="Perfil"
          placeholder={'Perfil'}
          id={'role'}
          name={'role'}
          returnFullObject={false}
          onChange={this.props.onChangeSelect}
          value={entity.role}
          style={'col-sm-2'}
        />
        <FormInput
          label="Nome"
          placeholder={'Nome'}
          id={'name'}
          name="name"
          value={entity.name}
          onChange={this.props.onChangeText}
          type={'text'}
          size={12}
          style={'col-sm-4'}
        />

        <FormInput
          label="E-mail"
          placeholder={'E-mail'}
          id={'email'}
          name="email"
          onChange={this.props.onChangeText}
          type={'text'}
          value={entity.email}
          size={12}
          style={'col-sm-4'}
        />
        {/* TODO*/}
        {/* Inseri um status que é de OS não sei qual o status do colaborador */}
        <StatusSelectInput
          label="Status"
          placeholder={'Status'}
          id={'status'}
          name="status"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-2'}
          value={entity.status}
          service={colaboradoresHelper.getListStatus}
        />
      </React.Fragment>
    );
  }
}
