import React, { PureComponent } from 'react';
import Rating from 'react-rating';
var startEmpty = require('../../img/icon_star.svg');
var startChequed = require('../../img/icon_star_yellow.svg');

export default class RatingItem extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      rate: 0
    };
  }
  changeRating = rate => {
    this.setState({ rate }, () => {
      if (!this.props.readonly && this.props.onChangeRating) {
        this.props.onChangeRating(this.props.item, rate);
      }
    });
  };
  render() {
    // rating = 2;
    return (
      <div className="row ratingItem">
        <div className="col-sm-6 d-flex flex-row align-items-left pr-3">
          <p className={this.props.classLabel}> {this.props.label}:</p>
        </div>
        <div className="col-sm-6 d-flex flex-row align-items-left pr-3">
          <Rating
            emptySymbol={<img src={startEmpty} className="iconRating" />}
            fullSymbol={<img src={startChequed} className="iconRating" />}
            readonly={this.props.readonly}
            initialRating={
              this.props.rating
                ? Number(this.props.rating)
                : Number(this.state.rate)
            }
            onChange={this.changeRating}
          />
          <span className="totalRating">
            {this.props.rating
              ? Number(this.props.rating)
              : Number(this.state.rate)}
          </span>
        </div>
      </div>
    );
  }
}
