import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CardSelectPosto from '../../components/Cards/CardSelectPosto';
import BoxOrdemServico from '../boxOrdemServico/BoxOrdemServico';
import TaskView from '../task/TaskView';

type Props = {};
class HomeAdministratorConsulta extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = { entity: {}, postoAutorizado: {} };
  }

  onChangeSelect = (name: string, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity });
  };

  handleSelectPosto = postoAutorizado => {
    this.setState({ postoAutorizado });
  };

  render() {
    return (
      <React.Fragment>
        <div className="flex-column">
          <TaskView />
        </div>
        <div className="flex-column">
          <CardSelectPosto
            onChangeSelect={this.onChangeSelect}
            entity={this.state.entity}
            onSelectPosto={this.handleSelectPosto}
          />
          <BoxOrdemServico postoAutorizado={this.state.postoAutorizado} />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    user,
    alert,
    messages
  };
}

export default connect(mapStateToProps)(HomeAdministratorConsulta);
