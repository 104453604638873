//@flow
import * as React from 'react';

import { Route, Switch } from 'react-router-dom';
import GrupoMarcaList from './GrupoMarcaList';
import GrupoMarcaView from './GrupoMarcaView';
import GrupoMarcaEdit from './GrupoMarcaEdit';

export default class GrupoMarca extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/GrupoMarca" exact component={GrupoMarcaList} />
          <Route path="/GrupoMarca/view/:id" component={GrupoMarcaView} />
          <Route path="/GrupoMarca/edit" component={GrupoMarcaEdit} />
        </Switch>
      </React.Fragment>
    );
  }
}
