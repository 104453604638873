//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const docService = {
  findByFiltro,
  getOs,
  savePedido,
  getTotalDocAtendido,
  find
};

function find(input) {
  return defaultService.doGet(`${urlsConstants.DOC}find/${input}`);
}

function getTotalDocAtendido() {
  return defaultService.doGet(`${urlsConstants.DOC}getTotalDocAtendido`);
}

function savePedido(idDoc, entity: any) {
  return defaultService.doPost(
    `${urlsConstants.DOC}concluirDoc/${idDoc}`,
    entity
  );
}

function getOs(idDoc: number) {
  let url = `${urlsConstants.DOC}getOs/${idDoc}`;
  return defaultService.doGet(url);
}
function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.DOC}list`,
    options,
    filter
  );
}
