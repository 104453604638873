import React, { PureComponent } from 'react';
import TaskComponente from './TaskComponente';
import MessageComponente from './MessageComponente';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import Toast from '../../components/Toasts/Toast';

type Props = {};

class Topo extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      activeitem: {}
    };
    document.addEventListener('mousedown', this.hideMenu);
  }
  hideMenu = () => {
    this.setState({ activeitem: -1 });
  };
  closeMenus = e => {
    e.preventDefault();
  };
  setActive = (id: number) => {
    this.setState({ activeitem: id });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { activeitem } = this.state;
    const { user, mainTitle, subTitle, canBack } = this.props;
    return (
      <React.Fragment>
        <Toast />

        <div className="header" onMouseOut={this.closeMenus}>
          <div className="container-fluid">
            <div className="row linhaTopo">
              <div className="navTopo col-lg-12 d-flex align-items-center">
                <div className="hamburger sidebar-toggle hideDesktop">
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </div>
                {canBack && (
                  <div
                    className="action bt-voltar d-flex align-items-center"
                    onClick={this.handleBack}
                  >
                    <i className="icon-seta_02" />
                  </div>
                )}
                <div className="flex-grow-1 flex-column">
                  <h2 className="txtBoasVindas ml-4 mb-0">
                    {mainTitle ? mainTitle : 'Olá bem vindo(a)'}
                  </h2>
                  <h2 className="boxTeste txtBoasVindas ml-4 mb-0">
                    <b>{subTitle ? subTitle : user.name}</b>
                  </h2>
                </div>
                <ul className="d-inline-flex mb-0">
                  <TaskComponente
                    id={0}
                    onActive={this.setActive}
                    activeComponente={activeitem}
                    closeNav={() => {
                      this.setActive(-1);
                    }}
                  />
                  <MessageComponente
                    id={1}
                    onActive={this.setActive}
                    activeComponente={activeitem}
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { mainTitle, subTitle, canBack } = state.pageTitle;

  return {
    user,
    mainTitle,
    subTitle,
    canBack
  };
}

export default connect(mapStateToProps)(withRouter(Topo));
