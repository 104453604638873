import { Can } from '@casl/react';
import * as React from 'react';
import { objectsConstants } from '../../constants/objects.constants';
import { propertyConstantes } from '../../constants/property.constantes';
import { dateHelper } from '../../helpers/date.helper';
import RelogioEntregueBtn from '../../views/cliente/Componentes/RelogioEntregueBtn';
import AprovarOrcamentoBtn from '../../views/cliente/Componentes/AprovarOrcamentoBtn';
import connect from 'react-redux/es/connect/connect';
import { ordemServicoService } from '../../services/ordemServico.service';
type Props = {};
type State = {
  tasks: any,
  entity: any
};

class CardReincidencia extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  getStateOrButton = ordemServico => {
    const { user } = this.props;
    if (user.role.extension === undefined) {
      return ordemServico.statusOS.description;
    }
    let orcamentoReprovado =
      ordemServico.statusOS.name === propertyConstantes.ORCAMENTO_REPROVADO
        ? true
        : false;
    let reparada =
      ordemServico.statusOS.name === propertyConstantes.REPARADA ? true : false;

    let aguardandoRelogio =
      ordemServico.statusOS.name === propertyConstantes.AGUARDANDO_RELOGIO
        ? true
        : false;

    if (orcamentoReprovado || reparada || aguardandoRelogio) {
      return <RelogioEntregueBtn onConfirm={this.entregaRelogio} />;
    }

    return ordemServico.statusOS.description;
  };

  reproveOrcamento = itensOrcamentoVO => {
    //vai nos servidor salva reprovação de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService
      .reproveOrcamento(ordemServico.id, itensOrcamentoVO)
      .then(
        r => {
          this.props.setOrdemServico(r.data.data);
          this.setState({ showReproveModal: false, refreshOsCliente: true });
          this.props.success({
            message: 'Orçamento salvo com sucesso.'
          });
          this.props.loading(false);
        },
        error => {
          this.props.loading(false);
          this.props.error('Não foi possível salvar requisição.');
          console.log(error);
        }
      );
  };

  aproveOrcamento = () => {
    let { ordemServico } = this.props;
    this.props.aproveOrcamento(ordemServico);
  };

  render() {
    let { ordemServico, permissions } = this.props;
    let dias =
      dateHelper.diff(ordemServico.creationDateTime, ordemServico.dataReparo) +
      ' dias';
    let orcamentoReprovado =
      ordemServico.statusOS.name === propertyConstantes.ORCAMENTO_REPROVADO
        ? true
        : false;
    return (
      <React.Fragment>
        <hr className="sep-large" />
        <h3 className="flex-grow-1">{this.props.index} - Reincidência</h3>
        <div className="row topoTab">
          <div className="col-4 px-1 px-sm-2 px-md-3">
            <h4>Prazo de reparo</h4>
            <h1>
              <b>{dias}</b>
            </h1>
            <div className="miniLabel">Data de abertura</div>
            <p>{dateHelper.format(ordemServico.creationDateTime)}</p>
          </div>
          <div className="col-4 px-1 px-sm-2 px-md-3 text-center">
            <h4 className={`${orcamentoReprovado ? 'txtRed' : ''}`}>
              Orçamento {`${orcamentoReprovado ? 'Reprovado' : ''}`}
            </h4>
            <h1 className={`${orcamentoReprovado ? 'txtRed' : ''}`}>
              {<b>{this.props.getValorTotal(this.props.ordemServico)}</b>}
            </h1>
            {ordemServico.statusOS.name ===
              propertyConstantes.APROVAR_ORCAMENTO && (
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_APROVAR_ORCAMENTO_OS}
                ability={permissions}
              >
                {
                  <AprovarOrcamentoBtn
                    getValorOrcamento={() => {
                      this.props.getValorTotal(this.props.ordemServico);
                    }}
                    ordemServico={ordemServico}
                    onAprove={this.aproveOrcamento}
                    onReprove={this.props.handleModalReprove}
                  />
                }
              </Can>
            )}
          </div>
          <div className="col-4 text-right px-1 px-sm-2 px-md-3">
            <h4>Status</h4>
            <h4 className={`sts ${ordemServico.statusOS.className}`}>
              {this.getStateOrButton(ordemServico)}
            </h4>

            <div className="miniLabel">Data do Status</div>
            <p>
              {dateHelper.format(ordemServico.dataStatusAtual, {
                pattern: 'DD/MM/YYYY'
              })}
            </p>
          </div>
        </div>
        <div className="flex-column pl-1">
          <div className="miniTxt pt-3">Questionamento do cliente</div>
          <p>{ordemServico.questionamentoCliente}</p>
        </div>
        <div className="col-6 text-right">
          <button type="button" className="btn btn-primary white btn-sm">
            <i className="icon-imprimir mr-2" />
            Imprimir
          </button>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;
  const { totalMessages } = state.chat;
  const { user, permissions } = state.authentication;
  return {
    cliente,
    user,
    permissions,
    totalMessages
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico },
  alert: { error, clear, success },
  load: { loading }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load })
});

export default connect(mapStateToProps, mapDispatch)(CardReincidencia);
