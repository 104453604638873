import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import { translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
import ApiCep from '../../services/zipCode.service';

type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange: any,
  onBlur: any,
  children?: any,
  required: boolean,
  messageValidate?: string,
  placeholder: string,
  disabled: boolean,
  maxLength: number,
  style: any
};

class ZipCodeInput extends PureComponent<Props> {
  static defaultProps = {
    size: 12
  };

  handleBorderColor = messageValidate => {
    let borderColor, borderWidth;
    if (messageValidate) {
      borderColor = '#ea3a45';
      borderWidth = 2;
    }
    return {
      borderColor,
      borderWidth
    };
  };

  findEndereco = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    let match = value.match(/\d+/g);
    const { props } = this;
    if (match != null && match.join('').length === 8) {
      ApiCep.SearchCep(match.join(''))
        .then(response => {
          console.log(response);
          props.onChange(response.data, value);
        })
        .catch(function(error) {
          props.onChange({ erro: true, timeout: true }, value);
        });
    } else {
      props.onChange({ erro: true, notComplete: true }, value);
    }
  };

  render() {
    const { messages, name } = this.props;
    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
      //TODO Corrigir gambiarra pra deixar campo sem col, e assim sem padding X
      if (this.props.size == 100) {
        colSize = '';
      }
    }

    const value = this.props.value;
    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    return (
      <FormGroup className={`${colSize} ${this.props.style}`}>
        {this.props.label && (
          <Label for={this.props.name}>
            {this.props.label}
            {this.props.required && '*'}
          </Label>
        )}
        <InputMask
          mask="99.999-999"
          maskChar=" "
          id={this.props.id}
          name={this.props.name}
          value={value}
          style={this.handleBorderColor(messageValidate)}
          onChange={this.findEndereco}
          required={this.props.required}
          placeholder={this.props.placeholder}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled ? this.props.disabled : false}
          className={`form-control ${this.props.className}`}
        >
          {this.props.children}
        </InputMask>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(ZipCodeInput);
