//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import ClienteView from '../cliente/ClienteView';

class OrdemServicoEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return <ClienteView showCardNovaOS={this.props.match.params.showCardNovaOS}/>;
  }
}

export default connect(
  null,
  null
)(OrdemServicoEdit);
