//@flow
import * as React from 'react';
import { marcaService } from '../../../services/marca.service';
import SelectInput from '../../../components/Inputs/SelectInput';
import MonthPickerInput from '../../../components/Inputs/MonthPickerInput';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';
import moment from 'moment/moment';
import { objectsConstants } from '../../../constants/objects.constants';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Can } from '@casl/react';
class RelatorioPrazoMedioEntregaPosReparoFormFilter extends React.PureComponent<{}> {
  constructor(props) {
    super(props);
    let dataAtual = moment();

    let year = dataAtual.year();
    let month = dataAtual.month() + 1;
    this.state = { month };
    this.props.onChangeMonthYear(1, year, 'inicio');
  }

  handleMesAno = (name, month, year) => {
    this.props.onChangeMonthYear(month, year, name);
  };
  render() {
    const { entity, permissions } = this.props;
    return (
      <React.Fragment>
        <div className="form-group col-4 col-sm-4">
          <SelectInput
            type="text"
            name="marca"
            label="Marca"
            placeholder="-Selecione-"
            parent={entity.marca}
            style={['col-12 col-sm-12']}
            onFetchData={marcaService.find}
            valueKey="id"
            labelKey="nome"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'marca'}
            onChange={this.props.onChangeSelect}
            value={entity.marca}
          />
        </div>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <SelectInput
            type="text"
            name="postoAutorizado"
            label="Autorizada"
            placeholder="-Selecione-"
            style={['col-12 col-sm-4']}
            onFetchData={postoAutorizadoService.find}
            valueKey="id"
            labelKey="nomeFantasia"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'postoAutorizado'}
            onChange={this.props.onChangeSelect}
            value={entity.postoAutorizado}
          />
        </Can>
        <MonthPickerInput
          placeholder={'Inicio Mês/Ano'}
          name="inicio"
          label="Mês/Ano"
          month={1}
          onChange={this.handleMesAno}
          size={6}
          style={['col-6 col-sm-2']}
          id={'inicioAoMes'}
        />
        <MonthPickerInput
          placeholder={'Fim Mês/Ano'}
          name="fim"
          label="Mês/Ano"
          month={this.state.month}
          onChange={this.handleMesAno}
          size={6}
          style={['col-6 col-sm-2']}
          id={'fimAnoMes'}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps)(
  RelatorioPrazoMedioEntregaPosReparoFormFilter
);
