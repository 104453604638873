//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import { Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import { urlsConstants } from '../../constants/url.constant';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { colaboradorService } from '../../services/colaborador.service';
import RelogioCrudListFilter from './RelogioCrudListFilter';
import {
  objectsConstants,
  constHelper,
} from '../../constants/objects.constants';
import { marcaService } from '../../services/marca.service';
import { relogioService } from '../../services/relogio.service';
import FotoRelogio from '../../components/FotoRelogio';

const columns = [
  {
    path: 'foto',
    title: '',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'codigoRelogio',
    title: 'Código do Relógio',
    isKey: true,
    dataSort: false,
  },
  {
    path: 'marca.nome',
    title: 'Marca',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'mesesGarantia',
    title: 'Meses de garantia do relógio',
    isKey: false,
    dataSort: false,
  },
];

const endPoint = '/relogio/novo/';
const endPointView = '/relogio/edit/';
class RelogioCrudList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Novo Relógio',
      },
    });
  };

  renderCustom(row: any, entity: any, column: any) {
    if (column.path == 'foto') {
      return (
        <FotoRelogio relogio={entity} height={'32px'} width={'32px'} noMargin />
      );
    } else {
      return tableHelper.renderCustom(row, entity, column);
    }
  }
  render() {
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    const tableColumn = columns.map((column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Relógio" subTitle=" " canBack={false} />
        <ListagemPage
          title={`RELÓGIO`}
          newButtonActionClick={this.handleNewButton}
          delete={() => {}}
          service={relogioService}
          labelNewButton="Novo Relógio"
          defaultOrder={'id'}
          defaultDirection={'desc'}
          defaultFilter={{}}
          onRowClick={this.handleRowClick}
          FormFilter={RelogioCrudListFilter}
          controller="relogio"
          fields={fields}
          roleNewButton={objectsConstants.ABILITY_CADASTRO_DE_RELOGIO}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(RelogioCrudList);
