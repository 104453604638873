//@flow
import { FormGroup, InputType, Label } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../helpers/message.helper';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  maxLength?: number,
  style?: any,
  messageValidate?: string
};

class EditableTextField extends PureComponent<Props> {
  _input: any;

  constructor(props: Props) {
    super(props);
    this.state = { editable: false, value: this.props.value };
  }

  componentWillReceiveProps(nexProps) {
    if (nexProps.value != this.props.value) {
      this.setState({ value: nexProps.value });
    }
  }

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false
  };

  getInput = () => {
    return this._input;
  };

  handleBorderColor = messageValidate => {
    let borderColor, borderWidth;
    if (messageValidate) {
      borderColor = '#ea3a45';
      borderWidth = 2;
    }
    return {
      borderColor,
      borderWidth
    };
  };
  setEditable = (editable, value) => {
    if (!value) {
      this.setState({ editable });
    } else {
      this.setState({ editable, value });
    }
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.setState({ value });
  };

  render() {
    const { messages, name } = this.props;
    let colSize = 'form-group col';

    //FIXME NAO SE DEVE ALTERAR O VALOR DE UMA VARIAVEL DO TIPO PROPS. O PROPS É IMUTAVEL.
    let { messageValidate } = this.props;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.size && this.props.noSize !== true) {
      colSize += '-' + this.props.size;
    } else {
      colSize = '';
    }
    const value = this.state.value;
    const { emailValidation, passwordValidation } = this.props;
    return (
      <React.Fragment>
        {!this.state.editable && (
          <React.Fragment>
            <span
              className={'ml-2 h4 text-primary'}
              onClick={() => {
                this.setEditable(true);
              }}
            >
              <i className={'icon-edit_icon'} />
            </span>
            <p>{this.state.value}</p>
          </React.Fragment>
        )}

        {this.state.editable && (
          <React.Fragment>
            <span
              className={'ml-2 h5 text-danger'}
              onClick={() => {
                this.setEditable(false, this.props.value);
              }}
            >
              <i className={'icon-icon_x'}> </i>
            </span>
            <span
              className={'ml-2 h4 text-primary'}
              onClick={() => {
                console.log(this.props);
                this.props.onSave(this.state.value);
                this.setEditable(false);
              }}
            >
              <span className="icon-btn_confirm">
                <span className="path1" />
                <span className="path2" />
              </span>
            </span>

            <textarea
              id={this.props.id}
              name={this.props.name}
              value={value}
              onBlur={this.props.onBlur}
              style={this.handleBorderColor(messageValidate)}
              onChange={this.handleChange}
              required={this.props.required}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled ? this.props.disabled : false}
              maxLength={this.props.maxLength}
              innerRef={input => {
                this._input = input;
              }}
              rows={this.props.rows}
              className={'form-control ' + this.props.className}
            >
              {this.props.children}
            </textarea>
            {messageValidate && (
              <ToolTipFeedBack
                target={this.props.id}
                messageValidate={messageValidate}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(EditableTextField);
