//@flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import CardColaborador from '../../components/Cards/CardColaborador';
import CardHistorico from '../../components/Cards/CardHistorico';
import CardConfigGerais from '../../components/Cards/CardConfigGerais';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';
import {
  constHelper,
  objectsConstants
} from '../../constants/objects.constants';
import { Can } from '@casl/react';

type State = { colaborador: any };

class ConfiguracoesSintomasCausas extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { colaborador: {} };
  }

  render() {
    const { permissions } = this.props;
    const { colaborador } = this.state;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Configurações"
          subTitle="Sintomas e causas "
          canBack={true}
        />
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ROLE_MANAUS}
          ability={permissions}
        >
          <div className="flex-column">
            <CardColaborador colaborador={colaborador} />
          </div>
          <div className="flex-column">
            <CardHistorico colaborador={colaborador} />
          </div>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}
export default connect(
  mapStateToProps,
  null
)(ConfiguracoesSintomasCausas);
