//@flow
import moment from 'moment/moment';
import * as React from 'react';
import CidadeInput from '../../../components/Inputs/CidadeInput';
import DatePickerInput from '../../../components/Inputs/DatePickerInput';
import EstadoInput from '../../../components/Inputs/EstadoInput';
import FormInput from '../../../components/Inputs/FormInput';
import SelectInput from '../../../components/Inputs/SelectInput';
import StatusSelectInput from '../../../components/Inputs/StatusSelectInput';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class CompraPecaFilterAdmin extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Número OS"
          placeholder={'Número OS'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.id}
        />
        <EstadoInput
          name="estado"
          value={entity.estado}
          style={'col-2 col-sm-1'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          label="UF"
        />
        <CidadeInput
          name="cidade"
          value={entity.cidade}
          style={'col-4 col-sm-2'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          parentValue={entity.estado}
        />

        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataInicio"
          label="Data de"
          value={entity.dataInicio ? moment(entity.dataInicio) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-2'}
          id={'dataInicio'}
        />
        <DatePickerInput
          name="dataFim"
          label="Data até"
          placeholder={'--/--/--'}
          value={entity.dataFim ? moment(entity.dataFim) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-2'}
          id={'dataFim'}
        />

        <StatusSelectInput
          label="Status"
          placeholder={'Status'}
          id={'statusOS'}
          name="statusOS"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-3'}
          value={entity.statusOS}
          service={ordemServicoHelper.getListStatus}
        />
      </React.Fragment>
    );
  }
}
