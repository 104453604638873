import React, {PureComponent} from 'react';
import {mensagemService} from '../../services/messagem.service'
import {oauthHeaderJson} from "../../helpers/oauth-header";
import SockJsClient from 'react-stomp';
import _ from 'lodash';
import {midiaService} from "../../services/midiaService";
import {connect} from "react-redux";
import {withRouter,NavLink} from "react-router-dom";
import {dateHelper} from '../../helpers/date.helper'
import avatar from '../../img/avatarDefault.jpg'
import { urlsConstants } from "../../constants/url.constant";
import { ordemServicoService } from "../../services/ordemServico.service";
type Props = {
    id: number,
    onActive: any,
    activeComponente: number
};
type State = {
    entity: any
};
// { id: 1, message: "Olá, tudo bemcom você?", count: 1, dateTime: "Hoje", user: { name: "Cibelle Lima de Souza", midiaVO: { data: require("../../img/imagemTeste.png") } } }
const arrayMessage = [];

class MessageComponente extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            active: false,
            messages: [],
            numberMessages: 0,
            message: null
        };
    }

    componentWillReceiveProps(nexProps: any) {
        let active = nexProps.activeComponente === this.props.id;
        this.setState({active});
    }
    
    

    toggleClass() {
        const currentState = this.state.active;
        this.setState({active: !currentState});
        this.props.onActive(this.props.id);
    }

    hideMeneuItem = () => {
        if (this.state.active) {
            this.setState({active: false});
            this.props.onActive(-1);
        }
    };


    handleMessage(data) {
        let numberMessage = 0
        for (let i = 0; i < data.data.length; i++) {
            numberMessage = numberMessage + data.data[i].countMessageNotView;
        }
        this.setState({messages: data.data, numberTasks: numberMessage});
    }

    render() {
        const {active, messages} = this.state;
        const {user, location} = this.props;
        const wsSourceUrl = mensagemService.getWsUrl();
        let headers = oauthHeaderJson();
        const topic = mensagemService.getWsTopicMyMessage(user.id);
        return (
            <li
                className={`header-icon d-flex flex-row align-items-center ${
                    active ? 'active' : ''
                    }`}
                onClick={this.toggleClass}
            >
                <SockJsClient
                    url={wsSourceUrl}
                    topics={[topic, '/app' + topic]}
                    onMessage={msg => {
                        this.handleMessage(msg);
                    }}
                    subscribeHeaders={headers}
                    headers={headers}
                    ref={client => {
                        this.clientRef = client;
                    }}
                />
                <i className="icon-mensagem_x">
                    {messages.length > 0 && (
                        <span className="badge badge-success">{messages.length} </span>
                    )}
                </i>{' '}
                <span className="hideMobile">
          Você possui <br/>
                    {messages.length} mensagens.

        </span>
                <div className="drop-down">
                    <div className="dropdown-content-body">
                        <ul className="listaMensagens">
                            {messages.map((item, index) => (

                                <li key={item.sender.id}>
                                    <a onClick={()=>{

                                        if(item.lastMessage.ordemServico){
                                          this.props.loading(true);
                                          let urlOS = urlsConstants.ORDEM_SERVICO + item.lastMessage.ordemServico.id;
                                          ordemServicoService.doGet(urlOS).then(response => {
                                            let os = response.data.data;
                                            this.props.setOrdemServico(os);
                                            this.props.setCliente(os.cliente);
                                            this.props.history.push(`/ordemServico/view/${item.lastMessage.ordemServico.id}`)
                                            this.props.loading(false);
                                          });

                                        }else{
                                          this.props.history.push({pathname:`/chat/private/${item.sender.id}`,state:{receiverName:item.sender.name}})
                                        }

                                       }} className="d-flex flex-row align-items-center">
                                        {item.sender.foto && item.sender.foto.id && (<img
                                            src={midiaService.get(item.sender.foto.id)}
                                            className="rounded-circle avatar-img"
                                        />)}
                                        {!item.sender.foto && (<img
                                            src={avatar}
                                            className="rounded-circle avatar-img"
                                        />)}

                                        <div className="resumoMsg flex-grow-1">
                                            <div className="nomeMsg">{item.sender.name}</div>
                                            <div className="msg">{item.lastMessage.message}</div>
                                        </div>
                                        <div className="dataMsg">
                                            <div className="data">{dateHelper.format(item.lastMessage.dateTime)}</div>
                                            <div className="badge badge-success">{item.countMessageNotView}</div>
                                        </div>
                                    </a>
                                </li>
                            ))}

                            {messages.length > 0 && (
                                <li>
                                    <NavLink to="/chat"> Ver todas
                                        <i className="icon-acessar"/>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </li>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.authentication;
    const {alert} = state;
    const {messages} = state.fieldMessage;

    return {
        user,
        alert,
        messages
    };
}

const mapDispatch = ({
                       ordemServico: { setCliente, clearOS, setOrdemServico },
                       load: { loading }
                     }) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({})
});
const connectedMessageComponente = withRouter(MessageComponente);

export default connect(
    mapStateToProps,mapDispatch
)(connectedMessageComponente);