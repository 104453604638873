//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import './taskInput.css';
import icon_alert from '../../img/icon_alert_line.svg';
type Props = {
  title: string,
  task: any
};

class taskButton extends PureComponent<Props> {
  handleTaskAction = () => {
    const { task } = this.props;
    //if(task.type==="upload")
    this.props.onClick(this.props.task);
  };
  render() {
    return (
      <div className="form-row align-items-center lineTarefa">
        <Col xs="1">
          <img src={icon_alert} alt="logo" />
        </Col>
        <Col xs="4" style={{ textAlign: 'left' }}>
          {this.props.title}
        </Col>
        <Col xs="7" style={{ textAlign: 'left' }}>
          {this.props.description}
        </Col>
      </div>
    );
  }
}

export default connect()(taskButton);
