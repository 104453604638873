//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import FormInput from '../../components/Inputs/FormInput';
import FormTextArea from '../../components/Inputs/FormTextArea';
import ImageInput from '../../components/Inputs/ImageInput';
import MoneyInput from '../../components/Inputs/MoneyInput';
import SelectInput from '../../components/Inputs/SelectInput';
import Toast from '../../components/Toasts/Toast';
import { objectsConstants } from '../../constants/objects.constants';
import relogioBG from '../../img/relogioBG.png';
import { marcaService } from '../../services/marca.service';
import { midiaService } from '../../services/midiaService';
import { relogioService } from '../../services/relogio.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
// type Props = { titlePage: any };
type State = { initialValues: any };

const NewRelogio = Yup.object().shape({
  codigoRelogio: Yup.string().required('Código do Relógio é obrigatório'),
  marca: Yup.string().required('Marca é obrigatório'),
});

class RelogioEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        codigoRelogio: '',
      },
    };
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      relogioService.getById(this.props.match.params.id).then((result) => {
        let initialValues = result.data.data;
        this.setState({ initialValues });
      });
    }
  }

  showError = (messageError) => {
    this.props.error({ message: messageError });
    return '';
  };

  clearError = () => {
    this.props.clear();
    return '';
  };

  dataUrl = (values) => {
    if (values.foto && values.foto.id) {
      return midiaService.get(values.foto.id);
    } else {
      if (values.foto) {
        console.log(values.foto);
        return values.foto.data;
      } else {
        return relogioBG;
      }
    }
  };

  render() {
    return (
      <Formik
        validationSchema={NewRelogio}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={this.state.initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.props.loading(true);
          relogioService
            .doSave(values)
            .then((response) => {
              actions.setSubmitting(false);
              this.props.success({ message: 'Relógio salva com sucesso.' });
              this.props.loading(false);
              this.props.history.push('/relogio');
            })
            .catch(() => {
              actions.setSubmitting(false);
              this.props.loading(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="flex-column">
              <Toast />
              <TopoTitleComponente
                mainTitle={'Relógio'}
                subTitle={
                  values.id != undefined ? ' Editar dados' : 'Novo relógio'
                }
                canBack={true}
              />
              {!_.isEmpty(errors) &&
                this.showError(objectsConstants.messageCampoObrigatorio)}
              {values.id && this.clearError()}
              <div className="cardSeculus d-flex flex-column mb-0">
                <div className="topoCard d-flex flex-column flex-sm-row">
                  <h3 className="flex-grow-1">Relógio</h3>
                </div>
                <div className="form-row">
                  <div className={'col-12 col-sm-6'}>
                    <ImageInput
                      maxWidth={200}
                      maxHeight={300}
                      onChange={(imageVO) => setFieldValue('foto', imageVO)}
                      dataUrl={this.dataUrl(values)}
                    />
                  </div>
                  <div className={'col-12 col-sm-6'}>
                    <FormInput
                      type="text"
                      id="codigoRelogio"
                      name="codigoRelogio"
                      label={'Código do Relógio'}
                      placeholder="Código do Relógio"
                      style={['col-12']}
                      value={values.codigoRelogio}
                      onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                        const { name, value } = e.currentTarget;
                        let newValue = value.trim();
                        setFieldValue(name, newValue);
                      }}
                      noSize={true}
                      erroMensagem={errors.codigoRelogio}
                    />
                    <SelectInput
                      type="text"
                      name="marca"
                      label="Marca"
                      placeholder="Marca"
                      style={['col-12']}
                      onFetchData={marcaService.findCadastroManual}
                      valueKey="id"
                      labelKey="nome"
                      returnFullObject={true}
                      searchable={true}
                      //messageValidate={validate.portadorDeficiencia}
                      id={'marca'}
                      onChange={(name, value) => setFieldValue(name, value)}
                      value={values.marca}
                      erroMensagem={errors.marca}
                    />
                    <MoneyInput
                      type="text"
                      name="valor"
                      label="Valor"
                      placeholder="Valor"
                      size={3}
                      className={['col-12']}
                      id={'valor'}
                      ref={(input) => {
                        this._valor = input;
                      }}
                      value={values.valor}
                      onChange={(name, value) => setFieldValue(name, value)}
                      erroMensagem={errors.valor}
                    />
                  </div>
                  <div className="form-group col-6 col-lg-6">
                    <FormInput
                      type="number"
                      id="mesesGarantia"
                      name="mesesGarantia"
                      label={'Meses de garantia do Relógio'}
                      placeholder="Meses de garantia do Relógio"
                      style={['col-12']}
                      value={values.mesesGarantia}
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.mesesGarantia}
                    />
                  </div>

                  <div className="form-group col-12">
                    <label>Descrição</label>
                    <FormTextArea
                      id="descricao"
                      name="descricao"
                      placeholder="Descrição"
                      value={values.descricao}
                      onChange={handleChange}
                      rows="4"
                      noSize={true}
                      required={true}
                      erroMensagem={errors.descricao}
                    />
                  </div>
                </div>

                <div className="d-flex flex-row flex-sm-row ">
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="button"
                      className="btn btn-secondary btn30 white"
                      disabled={isSubmitting}
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="submit"
                      className="btn btn-secondary btn30 green"
                      disabled={isSubmitting}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: (msg) => success(msg),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(
  null,
  mapDispatch
)(RelogioEdit);
