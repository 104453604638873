//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const compraPecaService = {
  findByFiltro,
  doSave,
  getById,
  confirmarRecebimento,
  rejeitarPeca,
  getTotal,
  find
};

function find(input) {
  return defaultService.doGet(`${urlsConstants.COMPRA_PECA}find/${input}`);
}

function getTotal() {
  return defaultService.doGet(`${urlsConstants.COMPRA_PECA}getTotal`);
}

function getById(id: number) {
  return defaultService.doGet(`${urlsConstants.COMPRA_PECA}${id}`);
}

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.COMPRA_PECA}list`,
    options,
    filter
  );
}

function doSave(compraPecaVO) {
  return defaultService.doSave(urlsConstants.COMPRA_PECA, compraPecaVO);
}

function confirmarRecebimento(OSid) {
  return defaultService.doPost(
    `${urlsConstants.COMPRA_PECA}confirmarRecebimento/${OSid}`,
    {}
  );
}

function rejeitarPeca(OSid, pecaRegistroTrabalhoDocVO) {
  return defaultService.doPost(
    `${urlsConstants.COMPRA_PECA}rejeitarPeca/${OSid}`,
    pecaRegistroTrabalhoDocVO
  );
}
