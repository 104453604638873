//@flow
import { Can } from '@casl/react';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import BtnSecondary from '../../components/Buttons/BtnSecondary';
import CardListaPosto from '../../components/Cards/CardListaPosto';
import { CardItem } from '../../components/templates/CardItem';
import { objectsConstants } from '../../constants/objects.constants';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import PostoAutorizadoFormViewView from '../postoAutorizado/PostoAutorizadoFormViewView';

type State = { colaborador: any, entity: any };

class ConfiguracoesValoresGarantiaPosto extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = { posto: {}, entity: {} };
  }

  componentDidMount() {
    postoAutorizadoService.doGet(this.props.match.params.id).then(response => {
      let { entity } = this.state;
      let posto = response.data.data;
      entity.postoAutorizado = posto;
      this.setState({ posto, entity });
    });
  }

  changePosto = () => {
    this.props.history.push('/configuracoes/valoresGarantia');
  };

  getBtns = () => {
    return <BtnSecondary title={'Alterar posto'} onSubmit={this.changePosto} />;
  };

  render() {
    const { permissions } = this.props;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <div className="flex-column">
            {this.state.posto.id && (
              <CardItem title="POSTO AUTORIZADO" getBtns={this.getBtns}>
                <PostoAutorizadoFormViewView
                  entity={this.state.posto}
                  readOnly={true}
                />
              </CardItem>
            )}
          </div>
          <div className="flex-column">
            {entity.postoAutorizado && <CardListaPosto filter={entity} />}
          </div>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}
export default connect(
  mapStateToProps,
  null
)(withRouter(ConfiguracoesValoresGarantiaPosto));
