//@flow
import { FormGroup, Label, Input, InputType } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../helpers/message.helper';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  disabled: boolean,
  style?: any,
  messageValidate?: string
};

class CheckBoxInput extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }
  _input: any;

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false
  };

  getInput = () => {
    return this._input;
  };

  toggleLine = e => {
    e.preventDefault();
    this.setState({ checked: !this.state.checked }, () => {
      this.props.onChange(this.state.checked, this.props.name);
    });
  };

  render() {
    return (
      <FormGroup className={`${this.props.style}`}>
        <Label>{''}</Label>
        <div className="d-flex bd-highlight action" onClick={this.toggleLine}>
          <div className="p-2 bd-highlight">
            <div className="bottom">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={this.state.checked}
                  onChange={() => {}}
                  ref={input => {
                    this._input = input;
                  }}
                  id={this.props.id}
                  name={this.props.name}
                />
                <i className="icon-checkbox" />
              </label>
            </div>
          </div>
          <div className="pt-2 bd-highlight">
            <div className="pt-2 serv_txt">
              <div className="miniTxt">{this.props.label}</div>
            </div>
          </div>
          <div className="bd-highlight" />
        </div>
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(CheckBoxInput);
