//@flow
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { FormRow } from '../../components/FormRow';
import PhoneInput from '../../components/Inputs/PhoneInput';
import CpfInput from '../../components/Inputs/CpfInput';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import FormInput from '../../components/Inputs/FormInput';
import UploadPictureRegister from '../../components/UploadPictureRegister';
import { responsibleConstant } from '../../constants/objects.constants';
import { validateInput } from '../../helpers/validateInputs.helper';
import PasswordInput from '../../components/Inputs/PasswordInput';

type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  emailValidation: any,
  passwordValidation: any,
  validateObject: any,
  hidePassWord: boolean
};

const objectValidateResponsibleVO = [
  responsibleConstant.nomeValidate,
  responsibleConstant.dataNascimentoValidate,
  responsibleConstant.cpfValidate,
  responsibleConstant.emailValidate,
  responsibleConstant.confirmarEmailValidate,
  responsibleConstant.celularValidate
];

export default class RegisterSecondStepForm extends PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      entity: _.cloneDeep(this.props.entity),
      emailValidation: {},
      passwordValidation: {},
      validateObject: {}
    };
  }

  handleChangeResponsibleVO = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    let entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, type === 'checkbox' ? checked : value);
    this.setState({ entity });
  };

  handleChange = (name: string, value: any) => {
    let entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  checkEmail = () => {
    const { entity } = this.state;
    let error;
    if (entity.email && entity.confirmarEmail) {
      if (entity.email !== entity.confirmarEmail) {
        error = { error: 'E-mails não correspondem' };
      } else {
        error = {};
      }
    }
    this.setState({
      emailValidation: error
    });
  };

  checkPassword = () => {
    const { entity } = this.state;
    let error;
    if (entity.senha && entity.confirmarSenha) {
      if (entity.senha !== entity.confirmarSenha) {
        error = { error: 'Senhas não correspondem' };
      } else {
        error = {};
      }
    }
    this.setState({
      passwordValidation: error
    });
  };

  validateForms = () => {
    const { entity } = this.state;
    let isValid;
    let validate = {};

    if (entity.id === undefined) {
      objectValidateResponsibleVO.push(responsibleConstant.senhaValidate);
      objectValidateResponsibleVO.push(
        responsibleConstant.confirmarSenhaValidate
      );
    }

    objectValidateResponsibleVO.forEach(o => {
      const value = entity[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });

    this.setState({ validateObject: validate });
    if (_.isEmpty(validate)) {
      isValid = true;
    }

    return isValid;
  };

  changeImage = imageVO => {
    let entity = _.cloneDeep(this.state.entity);
    _.set(entity, 'foto', imageVO.image);
    this.setState({ entity });
  };

  getEntity = () => {
    return this.state.entity;
  };

  getDataUrl = () => {
    const { entity } = this.state;
    if (entity.foto && entity.foto.id == undefined && entity.foto.data) {
      return entity.foto.data;
    }
  };

  render() {
    const { entity, validateObject } = this.state;
    return (
      <React.Fragment>
        <FormRow>
          <UploadPictureRegister
            name="foto"
            onChange={this.changeImage}
            idImage={entity.foto ? entity.foto.id : null}
            dataUrl={this.getDataUrl()}
            id="foto"
          />
          <div className="col-12 col-lg-9">
            <FormRow>
              <FormInput
                type="text"
                name="nome"
                label="Nome completo"
                placeholder="Nome completo"
                required={true}
                style={['col-12 col-lg-12']}
                messageValidate={validateObject.nome}
                id={'nome'}
                ref={input => {
                  this._nome = input;
                }}
                onChange={this.handleChangeResponsibleVO}
                value={entity.nome}
              />
              <DatePickerInput
                placeholder="-- / -- / ----"
                name="dataNascimento"
                label="Data de nascimento:"
                value={
                  entity.dataNascimento ? moment(entity.dataNascimento) : null
                }
                onChange={this.handleChange}
                messageValidate={validateObject.dataNascimento}
                required={true}
                size={6}
                style={['col-6 col-lg-4']}
                id={'dataNascimento'}
                ref={input => {
                  this._dataNascimento = input;
                }}
              />
              <CpfInput
                type="number"
                name="cpf"
                label="CPF"
                placeholder="CPF"
                required={true}
                style={['col-6 col-lg-4']}
                messageValidate={validateObject.cpf}
                size={6}
                id={'cpf'}
                ref={input => {
                  this._cpf = input;
                }}
                onChange={this.handleChange}
                value={entity.cpf}
              />
              <PhoneInput
                type="number"
                name="celular"
                label="Telefone Celular"
                placeholder="Somente números"
                messageValidate={validateObject.celular}
                required={true}
                style={['col-6 col-lg-4']}
                size={6}
                id={'celular'}
                ref={input => {
                  this._celular = input;
                }}
                onChange={this.handleChangeResponsibleVO}
                value={entity.celular}
              />
            </FormRow>
            <FormRow>
              <FormInput
                type="text"
                name="email"
                label="E-mail"
                placeholder="E-mail"
                messageValidate={validateObject.email}
                required={true}
                style={['col-12 col-lg-6']}
                id={'email'}
                ref={input => {
                  this._email = input;
                }}
                onChange={this.handleChangeResponsibleVO}
                value={entity.email}
              />
              <FormInput
                type="text"
                name="confirmarEmail"
                label="Confirmar e-mail"
                placeholder="Confirmar e-mail"
                messageValidate={validateObject.confirmarEmail}
                required={true}
                style={['col-12 col-lg-6']}
                id={'confirmarEmail'}
                onBlur={this.checkEmail}
                ref={input => {
                  this._confirmarEmail = input;
                }}
                onChange={this.handleChangeResponsibleVO}
                value={entity.confirmarEmail}
              />
            </FormRow>
            {!this.props.hidePassWord && (
              <PasswordInput
                type="password"
                name="senha"
                label="Senha"
                placeholder="Senha"
                messageValidate={validateObject.senha}
                required={true}
                style={['col-lg']}
                size={6}
                id={'senha'}
                onChange={this.handleChangeResponsibleVO}
                value={entity.senha}
                confirmPasswordName="confirmarSenha"
                confirmPasswordLabel="Confirmar senha"
                confirmPasswordPlaceholder="Confirmar senha"
                confirmPasswordId={'confirmarSenha'}
                confirmPasswordValue={entity.confirmarSenha}
              />
            )}
          </div>
        </FormRow>
        <div className="row rodapeFixed">
          <div className="col-12">
            <h5 className="legenda">(*) Campos obrigatórios</h5>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
