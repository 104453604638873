//@flow
import _ from 'lodash';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { propertyConstantes } from '../../constants/property.constantes';
import { urlsConstants } from '../../constants/url.constant';
import '../../css/boxOS_Selecionada.css';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { ordemServicoService } from '../../services/ordemServico.service';
import type { ClienteType } from '../../types/cliente.type';
import { objectsConstants } from '../../constants/objects.constants';
import { Can } from '@casl/react';
import OrdemServicoItem from './OrdemServicoItem';
import { FooterTablePagination } from '../table/FooterTablePagination';

type Props = { cliente: ClienteType, showNewOS: boolean };

const defautOptions = {
  pageSize: 10,
  page: 0,
  order: 'farol',
  direction: 'desc'
};
let timoutVar;
class CardClienteOrdemServico extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    let options = defautOptions;
    options.filter = { idCliente: this.props.cliente.id };
    this.state = { ordens: [], allowSearch: false, options, modal: false };
    if (props.showCardNovaOS) {
      this.toggle();
    }
  }
  handleConfirm = () => {};

  componentDidMount() {
    this.requestData(this.state.options);
    if (this.props.showCardNovaOS) {
      this.toggle();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshOs) {
      this.requestData(this.state.options);
    }
    if(nextProps.cliente.id!==this.state.options.filter.idCliente){
      let options = defautOptions;
      options.filter = { idCliente: this.props.cliente.id };
      this.setState({ ordens: [], allowSearch: false, options, modal: false },()=>{
        this.requestData(this.state.options, this.state.filter);
      })
    }
  }

  clearThisTimout = () => {
    clearTimeout(timoutVar);
  };
  updateTable = () => {
    timoutVar = setTimeout(() => this.requestData(this.state.options), 1000);
  };

  handlePageChange = (page: number) => {
    const newOption = _.cloneDeep(this.state.options);
    newOption.page = page - 1;
    this.setState(
        {
          options: newOption
        },
        () => {
          this.requestData(this.state.options, this.state.filter);
        }
    );
  };

  requestData = (options: any) => {
    this.props.loading(true);
    ordemServicoService
        .findByCliente({id:this.state.options.filter.idCliente}, options)
        .then(response => {
          let data = response.data;
          this.setState({
            ordens: data.data,
            dataTotalSize: data.dataTotalSize,
            currentPage: options.page,
            order: options.order,
            direction: options.direction
          });
          this.props.loading(false);
        })
        .catch(error => {
          this.props.loading(false);
          console.log(error);
        });
  };

  handleSelectOS = (id: number) => {
    if (this.props.showNewOS) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
              <div className="confirm-ui success">
                <h3>
                  <span className="txtRed">Cancelar OS?</span>
                </h3>
                <p className="p-msg">
                  Ao clicar em outra os, os dados digitados serão perdidos.
                </p>
                <p>
                  <b>Deseja realmente selecionar outra OS?</b>
                </p>
                <button
                    className="btn btn-primary my-4 mx-lg-3"
                    onClick={() => {
                      let urlOS = urlsConstants.ORDEM_SERVICO + id;
                      ordemServicoService.doGet(urlOS).then(response => {
                        this.props.setOrdemServico(response.data.data);
                      });
                      onClose();
                    }}
                >
                  Selecionar os
                </button>
                <button
                    className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
                    onClick={() => {
                      onClose();
                    }}
                >
                  Não, obrigado
                </button>
              </div>
          );
        }
      });
    } else {
      let urlOS = urlsConstants.ORDEM_SERVICO + id;
      ordemServicoService.doGet(urlOS).then(response => {
        this.props.setOrdemServico(response.data.data);
      });
    }
  };
  openSearchBar = () => {
    this.setState({ allowSearch: true });
  };
  closeSearchBar = () => {
    this.setState({ allowSearch: false });
  };

  filterOS = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newFilter = _.cloneDeep(this.state.options);
    let valueUpper = _.isNil(value) ? null : _.toUpper(value);
    _.set(newFilter.filter, name, valueUpper);
    this.setState({ options: newFilter }, () => {
      this.clearThisTimout();
      this.updateTable();
    });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  getHeader = () => {
    const { permissions } = this.props;
    let _self = this;
    if (!this.props.showNewOS) {
      return (
          <div className="boxButon float-right flex-shrink-1 d-sm-flex">
            {!this.state.allowSearch && (
                <Can
                    I={objectsConstants.VIEW}
                    a={objectsConstants.ABILITY_CADASTRAR_OS}
                    ability={permissions}
                >
                  <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={this.toggle}
                  >
                    nova os mesmo cliente
                  </button>
                </Can>
            )}
            {this.state.allowSearch && (
                <input
                    type="text"
                    className={`form-control boxInputSearch`}
                    name="marcaOS"
                    placeholder="Buscar Nº OS, relógio"
                    onChange={this.filterOS}
                    value={this.state.options.filter.marcaOS}
                />
            )}
            {!this.state.allowSearch && (
                <i className="icon-search boxIcon" onClick={this.openSearchBar} />
            )}
            {this.state.allowSearch && (
                <i className="icon-x boxIcon" onClick={this.closeSearchBar} />
            )}
          </div>
      );
    }
  };

  getFarol = (ordemServico: any) => {
    let classFarol = ordemServicoHelper.getFarol(ordemServico);
    return <div className={`farol ${classFarol}`} />;
  };

  render() {
    let _self = this;
    return (
        <div className="card cardSeculus d-flex flex-column w1">
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalBody>
              <div className="justify-content-center mt-1">
                <h3>
                  {' '}
                  <span className="txtGreen"> nova os </span>- Tipo de OS
                </h3>
              </div>
              <div className="justify-content-center mt-2">
                <p className="text-center">
                  Clique em alguma opção abaixo para criar uma nova ordem
                </p>
              </div>
              <div className="text-center mt-2">
                <button
                    type="button"
                    className="btn btn-primary my-3 mx-2"
                    onClick={() => {
                      this.props.createOrdemServico({
                        osType: propertyConstantes.OS
                      });
                      this.props.history.push('/ordemServico/novo');
                      this.toggle();
                    }}
                >
                  Ordem de serviço
                </button>
              </div>
              <div className="text-center mt-2">
                <button
                    type="button"
                    className="btn btn-secondary btn-sm mb-2"
                    onClick={() => {
                      this.props.createOrdemServico({
                        osType: propertyConstantes.PA
                      });
                      this.props.history.push('/ordemServico/novo');
                      this.toggle();
                    }}
                >
                  {' '}
                  Pronto atendimento
                </button>
              </div>
            </ModalBody>
          </Modal>
          <div>
            <div className="topoCard d-flex flex-column flex-sm-row">
              <h3 className="flex-grow-1">
                ordens de serviço (
                {this.props.showNewOS
                    ? this.state.ordens.length + 1
                    : this.state.ordens.length}
                )
              </h3>

              {_self.getHeader()}
            </div>
            <div className="conteudoCard flex-fill">
              {this.props.showNewOS && (
                  <div className="d-flex flex-row itemListaOS align-items-center text-left  osSelecionada">
                    <div className="col-4 tipo">
                      {this.props.ordemServico.osType} --
                    </div>
                    <div className="col-3 item">--</div>
                    <div className="col-4 text-right data">Em criação</div>
                    <div className="col-1">
                      <div className="farol farol-azul" />
                    </div>
                  </div>
              )}
              {this.state.ordens.map(function(os, i) {
                return (
                    <OrdemServicoItem
                        itemOS={os}
                        key={os.id}
                        onSelectOS={_self.handleSelectOS}
                    />
                );
              })}
            </div>
            <FooterTablePagination
                pages={this.state.pages}
                sizePerPage={this.state.options.pageSize}
                dataTotalSize={this.state.dataTotalSize}
                onPageChange={this.handlePageChange}
            />
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  const { ordemServico } = state.ordemServico;
  const { user, permissions } = state.authentication;
  return {
    ordemServico,
    user,
    permissions
  };
}

const mapDispatch = ({
                       ordemServico: { setOrdemServico, createOrdemServico },
                       alert: { error },
                       load: { loading }
                     }) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  createOrdemServico: osType => createOrdemServico(osType),
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load })
});

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(CardClienteOrdemServico));
