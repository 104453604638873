//@flow
import React, { PureComponent } from 'react';
import { FormRow } from '../../components/FormRow';
import CnpjInput from '../../components/Inputs/CnpjInput';
import FormInput from '../../components/Inputs/FormInput';
import PhoneInput from '../../components/Inputs/PhoneInput';
import SelectInput from '../../components/Inputs/SelectInput';
import ZipCodeInput from '../../components/Inputs/ZipCodeInput';
import { LineVert } from '../../components/Utils/LineVert';
import { enderecoService } from '../../services/endereco.service';
type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  handleChange: any
};

export default class RegisterEmpresaFormFull extends PureComponent<
  Props,
  State
> {
  render() {
    const { entity, validateObject } = this.props;
    return (
      <React.Fragment>
        <FormRow>
          <CnpjInput
            ref={input => {
              this._cnpj = input;
            }}
            name="cnpj"
            label="CNPJ"
            placeholder="CNPJ"
            messageValidate={validateObject.cnpj}
            value={entity.cnpj}
            onChange={this.props.handleChange}
            required={true}
            style={['col-8 col-lg-3']}
            id={'cnpj'}
          />
          <FormInput
            type="text"
            name="razaoSocial"
            label="Razão Social"
            placeholder="Razão social"
            messageValidate={validateObject.razaoSocial}
            required={true}
            style={['col-12 col-lg']}
            id={'razaoSocial'}
            ref={input => {
              this._razaoSocial = input;
            }}
            onChange={this.props.handleChange}
            value={entity.razaoSocial}
          />
          <FormInput
            type="text"
            name="nomeFantasia"
            label="Nome fantasia"
            placeholder="Nome fantasia"
            required={true}
            messageValidate={validateObject.nomeFantasia}
            style={['col-12 col-lg']}
            id={'nomeFantasia'}
            ref={input => {
              this._nomeFantasia = input;
            }}
            onChange={this.props.handleChange}
            value={entity.nomeFantasia}
          />
        </FormRow>
        <FormRow>
          <FormInput
            type="number"
            name="inscricaoEstadual"
            label="Inscrição estadual"
            placeholder="Somente números"
            required={false}
            style={['col-6 col-lg-3']}
            size={3}
            id={'inscricaoEstadual'}
            ref={input => {
              this._inscricaoEstadual = input;
            }}
            onChange={this.props.handleChange}
            value={entity.inscricaoEstadual}
          />
          <FormInput
            type="number"
            name="inscricaoMunicipal"
            label="Inscrição municipal"
            placeholder="Somente números"
            messageValidate={validateObject.inscricaoMunicipal}
            style={['col-6 col-lg-3']}
            size={3}
            id={'inscricaoMunicipal'}
            ref={input => {
              this._inscMunicipal = input;
            }}
            onChange={this.props.handleChange}
            value={entity.inscricaoMunicipal}
          />
          <FormInput
            type="text"
            name="emailComercial"
            label="E-mail Comercial"
            placeholder="E-mail Comercial"
            messageValidate={validateObject.emailComercial}
            required={true}
            style={['col-12 col-lg-4']}
            id={'emailComercial'}
            ref={input => {
              this._emailComercial = input;
            }}
            onChange={this.props.handleChange}
            value={entity.emailComercial}
          />
          <PhoneInput
            type="number"
            name="telefoneComercial"
            label="Telefone comercial"
            placeholder="Somente números"
            messageValidate={validateObject.telefoneComercial}
            required={true}
            style={['col-6 col-lg-2']}
            size={3}
            id={'telefoneComercial'}
            ref={input => {
              this._telefoneComercial = input;
            }}
            onChange={this.props.handleChange}
            value={entity.telefoneComercial}
          />
          <FormInput
            type="text"
            name="horaFuncionamento"
            label="Horário de Funcionamento"
            placeholder="Horário de Funcionamento"
            erroMensagem={validateObject.horaFuncionamento}
            required={false}
            style={['col-12 col-lg-12']}
            id={'horaFuncionamento'}
            ref={input => {
              this._horaFuncionamento = input;
            }}
            onChange={this.props.handleChange}
            value={entity.horaFuncionamento}
          />
        </FormRow>
        <LineVert />
        <FormRow>
          <h3>Endereço</h3>
        </FormRow>
        <FormRow>
          <ZipCodeInput
            type="number"
            name="cep"
            label="CEP"
            placeholder="CEP"
            messageValidate={validateObject.cep}
            required={true}
            style={['col-6 col-lg-2']}
            size={2}
            id={'cep'}
            ref={input => {
              this._cep = input;
            }}
            onChange={this.props.handleZipCode}
            value={entity.cep}
          />
          <FormInput
            type="text"
            name="rua"
            label="Logradouro"
            placeholder="Av, Rua, Alameda"
            messageValidate={validateObject.rua}
            required={true}
            style={['col-12 col-lg-4']}
            id={'rua'}
            ref={input => {
              this._logradouro = input;
            }}
            onChange={this.props.handleChange}
            value={entity.rua}
          />
          <FormInput
            type="text"
            name="numero"
            label="Nº"
            placeholder="Nº"
            messageValidate={validateObject.numero}
            required={true}
            style={['col-4 col-lg-1']}
            size={4}
            id={'numero'}
            ref={input => {
              this._numero = input;
            }}
            onChange={this.props.handleChange}
            value={entity.numero}
          />
          <FormInput
            type="text"
            name="complemento"
            label="Complemento"
            placeholder="Complemento"
            messageValidate={validateObject.complemento}
            required={false}
            style={['col-8 col-lg-2']}
            size={8}
            id={'complemento'}
            ref={input => {
              this._complemento = input;
            }}
            onChange={this.props.handleChange}
            value={entity.complemento}
          />
          <FormInput
            type="text"
            name="bairro"
            label="Bairro"
            placeholder="Bairro"
            messageValidate={validateObject.bairro}
            required={true}
            style={['col-12 col-lg-3']}
            id={'bairro'}
            ref={input => {
              this._bairro = input;
            }}
            onChange={this.props.handleChange}
            value={entity.bairro}
          />
          <SelectInput
            type="text"
            name="pais"
            label="País"
            placeholder="País"
            onFetchData={enderecoService.findPais}
            valueKey="id"
            labelKey="nome"
            required={true}
            style={['col-6 col-lg-2']}
            size={6}
            returnFullObject={true}
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'pais'}
            onChange={this.props.handleSelectChange}
            value={entity.pais}
          />
          <SelectInput
            type="text"
            name="estado"
            label="Estado"
            placeholder="Estado"
            messageValidate={validateObject.estado}
            required={true}
            style={['col-6 col-lg-3']}
            size={6}
            parent={entity.pais}
            onFetchData={enderecoService.findEstado}
            valueKey="id"
            labelKey="nome"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'estado'}
            onChange={this.props.handleSelectChange}
            value={entity.estado}
          />
          <SelectInput
            type="text"
            name="cidade"
            label="Cidade"
            placeholder="Cidade"
            parent={entity.estado}
            required={true}
            style={['col-12 col-lg-4']}
            onFetchData={enderecoService.findCidade}
            valueKey="id"
            labelKey="nome"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'cidade'}
            onChange={this.props.handleSelectChange}
            value={entity.cidade}
          />
        </FormRow>
        <div className="row rodapeFixed">
          <div className="col-12">
            <h5 className="legenda">(*) Campos obrigatórios</h5>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
