import * as React from 'react';
import SelectInput from '../../components/Inputs/SelectInput';
import { dataService } from '../../services/data.service';
import { marcaService } from '../../services/marca.service';
import { Can } from '@casl/react';
import { objectsConstants } from '../../constants/objects.constants';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import FormInput from '../../components/Inputs/FormInput';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { dateHelper } from '../../helpers/date.helper';
import MonthPickerInput from '../../components/Inputs/MonthPickerInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class FinanceiroListFilter extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Número OS / CP / PA"
          placeholder={'Número OS / CP / PA'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.id}
        />
        <SelectInput
          type="text"
          name="marca"
          label="Marca"
          placeholder="Marca"
          parent={entity.marca}
          style={['col-6 col-sm-3']}
          onFetchData={marcaService.find}
          valueKey="id"
          labelKey="nome"
          returnFullObject={true}
          searchable={true}
          //messageValidate={validate.portadorDeficiencia}
          id={'marca'}
          onChange={this.props.onChangeSelect}
          value={entity.marca}
        />
        <SelectInput
          id={'garantiaInput'}
          name="garantia"
          label="Garantia"
          placeholder="Todos"
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
          style={['col-6 col-sm-1']}
          returnFullObject={true}
          valueKey={'value'}
          name={'garantia'}
          noSize={true}
          value={entity.garantia}
          onChange={this.props.onChangeSelect}
        />

        <MonthPickerInput
          placeholder={'Inicio Mês/Ano'}
          label="Mês/Ano"
          month={
            moment()
              .subtract(1, 'months')
              .month() + 1
          }
          year={moment()
            .subtract(1, 'months')
            .year()}
          onChange={this.props.onChangeMonthYear}
          size={6}
          style={['col-6 col-sm-2']}
          id={'inicioAoMes'}
        />

        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={this.props.permissions}
        >
          <SelectInput
            type="text"
            name="posto"
            label="Posto Autorizado"
            placeholder="Posto Autorizado"
            required={true}
            style={['col-6 col-sm-3']}
            onFetchData={postoAutorizadoService.find}
            valueKey="id"
            labelKey="nomeFantasia"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'posto'}
            onChange={this.props.onChangeSelect}
            value={entity.posto}
          />
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  return {
    permissions,
  };
}

export default connect(
  mapStateToProps,
  null
)(FinanceiroListFilter);
