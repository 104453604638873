//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import { Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import { urlsConstants } from '../../constants/url.constant';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { grupoMarcaService } from '../../services/grupoMarca.service';
import GrupoMarcaListFilter from './GrupoMarcaListFilter';
import {
  objectsConstants,
  constHelper
} from '../../constants/objects.constants';
const columns = [
  {
    path: 'nome',
    title: 'Nome',
    isKey: true,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'valor',
    title: 'Valor',
    isKey: false,
    dataSort: false,
    isMoney: true
  },
  {
    path: 'status',
    title: 'Status',
    isKey: false,
    dataSort: false
  }
];

const endPoint = '/GrupoMarca/edit/';
const endPointView = '/GrupoMarca/view/';
class GrupoMarcaList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Novo Grupo Marca'
      }
    });
  };

  getAvatar(entity: any) {
    if (entity.foto) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
    }
  }
  renderCustom(row: any, entity: any, column: any) {
    if (column.path == 'status') {
      if (entity.status) {
        return <div className={`txtGreen`}>ATIVO</div>;
      } else {
        return <div className={`txtRed`}>INATIVO</div>;
      }
    } else {
      return tableHelper.renderCustom(row, entity, column);
    }
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension
    };
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      fields
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Grupo de marca"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`Grupo de marca`}
          newButtonActionClick={this.handleNewButton}
          delete={() => {}}
          service={grupoMarcaService}
          labelNewButton="Novo Grupo de marca"
          defaultOrder={'id'}
          defaultDirection={'desc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={GrupoMarcaListFilter}
          controller="grupoMarca"
          roleNewButton={objectsConstants.ABILITY_BTN_GRUPO_MARCA}
          fields={this.state.fields}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(GrupoMarcaList);
