//@flow
import * as React from 'react';

import { Route, Switch } from 'react-router-dom';
import ColaboradorCrudList from './ColaboradorCrudList';
import ColaboradorCrudView from './ColaboradorCrudView';
import ColaboradorCrudEdit from './ColaboradorCrudEdit';

export default class ColaboradorCrud extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/colaboradorcrud"
            exact
            component={ColaboradorCrudList}
          />
          <Route
            path="/colaboradorcrud/view/:id"
            component={ColaboradorCrudView}
          />
          <Route path="/colaboradorcrud/edit" component={ColaboradorCrudEdit} />
        </Switch>
      </React.Fragment>
    );
  }
}
