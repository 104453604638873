//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import GrupoFilialList from "./GrupoFilialList";
import GrupoFilialEdit from "./GrupoFilialEdit";
import GrupoFilialView from "./GrupoFilialView";

export default class GrupoFilial extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/grupofilial" exact component={GrupoFilialList} />
          <Route path="/grupofilial/new" component={GrupoFilialEdit} />
          <Route path="/grupofilial/edit" component={GrupoFilialEdit} />
          <Route path="/grupofilial/view/:id" component={GrupoFilialView} />
        </Switch>
      </React.Fragment>
    );
  }
}
