//@flow
import * as React from 'react';
import { enderecoService } from '../../services/endereco.service';
import SelectInput from './SelectInput';

export default class EstadoInput extends React.Component<{
  handleSelectChange: Function<any>,
  value: string,
  name: string,
  style?: string,
  size?: number,
  parentValue: any,
  required: boolean
}> {
  render() {
    return (
      <SelectInput
        type="text"
        name={this.props.name}
        label={this.props.label ? this.props.label : 'Estado'}
        placeholder="Estado"
        onFetchData={enderecoService.findEstado}
        valueKey="id"
        labelKey="uf"
        required={this.props.required}
        style={this.props.style}
        size={this.props.size}
        returnFullObject={true}
        searchable={true}
        onChange={this.props.handleSelectChange}
        value={this.props.value}
        parent={this.props.parentValue}
        className={`${this.props.className}`}
      />
    );
  }
}
