//@flow
import * as React from 'react';
import CidadeInput from '../../components/Inputs/CidadeInput';
import EstadoInput from '../../components/Inputs/EstadoInput';
import FormInput from '../../components/Inputs/FormInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import SelectInput from '../../components/Inputs/SelectInput';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class PostoAutorizadoFilter extends React.PureComponent<Props> {
  getListStatus() {
    return [
      { id: 'true', nome: 'Sim' },
      { id: 'false', nome: 'Não' },
      { id: 'null', nome: 'Todos' },
    ];
  }

  getStatusCadastro() {
    return [
      { id: 'HABILITADO', nome: 'Habilitado' },
      { id: 'CREDENCIADO', nome: 'Credenciado' },
      { id: 'TODOS', nome: 'Todos' },
    ];
  }

  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Registro"
          placeholder={'Registro'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.id}
        />
        <EstadoInput
          name="estado"
          value={entity.estado}
          style={'col-2 col-sm-1'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          label="UF"
        />
        <CidadeInput
          name="cidade"
          value={entity.cidade}
          style={'col-4 col-sm-2'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          parentValue={entity.estado}
        />

        <StatusSelectInput
          label="Aprovado"
          placeholder={'Aprovado'}
          id={'aprovado'}
          name="aprovado"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={6}
          style={'col-sm-1'}
          value={entity.aprovado}
          service={this.getListStatus}
          returnFullObject={false}
          clearable={true}
        />

        <StatusSelectInput
          label="Cadastro"
          placeholder={'Aprovado'}
          id={'paStatus'}
          name="paStatus"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.paStatus}
          service={this.getStatusCadastro}
          returnFullObject={false}
          clearable={true}
        />

        <SelectInput
          type="text"
          name="postoAutorizado"
          label="Autorizada"
          placeholder="-Selecione-"
          style={['col-12 col-sm-4']}
          onFetchData={postoAutorizadoService.find}
          valueKey="id"
          labelKey="nomeFantasia"
          returnFullObject={true}
          searchable={true}
          //messageValidate={validate.portadorDeficiencia}
          id={'postoAutorizado'}
          onChange={this.props.onChangeSelect}
          value={entity.postoAutorizado}
        />
      </React.Fragment>
    );
  }
}
