//@flow
import { propertyConstantes } from '../constants/property.constantes';

export const colaboradoresHelper = {
  getListStatus
};

function getListStatus() {
  return [
    { id: 'true', nome: 'Ativo' },
    { id: 'false', nome: 'Inativo' },
    { id: 'null', nome: 'Todos' }
  ];
}
