//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import type { OrdemServicoType } from '../types/ordemServico.type';
import axios from 'axios';

export const relogioService = {
  find,
  findByFiltro,
  findMarcaTerceiros,
  findCadastroManual,
  getById,
  doSave,
findByGarantia,
};

function findByGarantia (codigoRelogio){
  console.log(`${urlsConstants.FIND_RELOGIO_GARANTIA}${codigoRelogio}`)
return axios.get(`${urlsConstants.FIND_RELOGIO_GARANTIA}${codigoRelogio}`);


}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.RELOGIO, entity);
}

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.RELOGIO}list`,
    options,
    filter
  );
}


function getById(id) {
  return defaultService.doGet(`${urlsConstants.RELOGIO}${id}`);
}

function find(marca, codigoRelogio) {
  if (marca && codigoRelogio) {
    return defaultService.doGet(
      `${urlsConstants.FIND_RELOGIO}${marca.id}/${codigoRelogio}`
    );
  } else {
    return defaultService.doGet(`${urlsConstants.FIND_RELOGIO}${marca}`);
  }
}
function findMarcaTerceiros(marca, codigoRelogio) {
  if (marca && codigoRelogio) {
    return defaultService.doGet(
      `${urlsConstants.FIND_RELOGIO}marcaTerceiros/${marca.id}/${codigoRelogio}`
    );
  } else {
    return defaultService.doGet(
      `${urlsConstants.FIND_RELOGIO}marcaTerceiros/${marca}`
    );
  }
}
function findCadastroManual(marca, codigoRelogio) {
  if (marca && codigoRelogio) {
    return defaultService.doGet(
      `${urlsConstants.FIND_RELOGIO}cadastroManual/${marca.id}/${codigoRelogio}`
    );
  } else {
    return defaultService.doGet(
      `${urlsConstants.FIND_RELOGIO}cadastroManual/${marca}`
    );
  }
}
