//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  disabled: boolean,
  style?: any,
  messageValidate?: string
};

class RadioInput extends PureComponent<Props, State> {
  static defaultProps = {
    notInline: false
  };
  _isMounted = false;
  constructor(props: Props) {
    super(props);
    let options = [];
    if (props.options) {
      options = props.options;
    }
    this._isMounted = true;
    this.state = { options: options, checked: false };
  }
  static defaultProps = {
    valueKey: 'id',
    labelKey: 'name',
    async: true,
    multi: false,
    label: '',
    required: false
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    if (checked) {
      this.props.onChange(value, this.props.name);
    }
  };

  toggleLine = (e, item) => {
    e.preventDefault();
    this.setState({ checked: !this.state.checked }, () => {
      this.props.onChange(item.id, this.props.name);
    });
  };

  render() {
    const { value } = this.props;
    let _this = this;
    let radios = [];
    _.forEach(this.state.options, function(item, index) {
      radios.push(
        <React.Fragment>
          <div
            className="d-flex col-6 col-sm-3 bd-highlight action"
            onClick={e => {
              _this.toggleLine(e, item);
            }}
            value={item.id}
            checked={item.id === value}
          >
            <div className="p-2 bd-highlight">
              <div className="bottom">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={item.id === value}
                    onChange={() => {}}
                    id={item.id}
                    name={item.name}
                  />
                  <i className="icon-checkbox" />
                </label>
              </div>
            </div>
            <div className="pt-2 bd-highlight">
              <div className="pt-2 serv_txt">
                <div className="miniTxt">{item.name}</div>
              </div>
            </div>
            <div className="bd-highlight" />
          </div>
        </React.Fragment>
      );
    });
    return (
      <FormGroup className={`${this.props.style}`}>
        <Label>{''}</Label>
        <div className="row">
          {radios}
          <div className="offset-sm-6" />
        </div>
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(RadioInput);
