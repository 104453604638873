//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import BtnPrimary from '../../components/Buttons/BtnPrimary';
import ImageProfile from '../../components/Inputs/ImageProfile';
import { HeaderComponent } from '../../components/RegisterComponents/HeaderComponent';
import { FormRow } from '../../components/FormRow';

type Props = { entity: any, step: number };
type State = { entity: any };

class RegisterFourthStep extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    localStorage.removeItem('tempCadastro');
    this.state = {
      entity: _.cloneDeep(this.props.entity)
    };
  }

  nextStep = () => {};

  previousStep = () => {
    this.props.step(3);
  };

  render() {
    const { entity } = this.state;
    let dataUrl = entity.foto ? entity.foto.data : undefined;
    return (
      <form action="" method="">
        <div class="tab-pane mt-4 active" id="confirmacao">
          <div class="form-row justify-content-center">
            <div class="col-10 col-lg-6 text-center">
              {dataUrl && (
                <ImageProfile
                  name={''}
                  width={120}
                  height={120}
                  dataUrl={dataUrl}
                />
              )}
            </div>
          </div>
          <div class="form-row justify-content-center">
            <div class="col-10 col-lg-6 text-center">
              <h2>
                olá <b>{entity.nome}</b>
              </h2>
              <p class="txtBlue">{entity.email}</p>
              <p>
                Foi enviado para o e-mail do responsavel um link para a confirmação do
                cadastro. Basta clique no link e logo em seguida
                acessar a conta para finalizar o cadastro.
              </p>

            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default connect()(RegisterFourthStep);
