//@flow
import React from 'react';
import type { ClienteType } from '../types/cliente.type';
import * as moment from 'moment';
import type { OrdemServicoType } from '../types/ordemServico.type';

type State = {
  cliente: ClienteType,
  ordemServico: OrdemServicoType
};

type PayloadCliente = {
  cliente: ClienteType
};

type PayloadOrdemServico = {
  osType: string
};

export default {
  state: { cliente: {}, ordemServico: {} },
  reducers: {
    clearOS: (state: State, payload: PayloadCliente) => {
      return Object.assign({}, state, {
        ordemServico: undefined
      });
    },
    setCliente: (state: State, payload: PayloadCliente) => {
      return Object.assign({}, state, {
        cliente: payload.cliente
      });
    },
    setOrdemServico: (state: State, payload: PayloadCliente) => {
      return Object.assign({}, state, {
        ordemServico: payload.ordemServico
      });
    },
    createOrdemServico: (state: State, payload: PayloadOrdemServico) => {
      return Object.assign({}, state, {
        ordemServico: {
          statusOS: { name: 'EM_CRIACAO', description: 'Em criação' },
          creationDateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
          dataStatus: moment().format('YYYY-MM-DDTHH:mm:ss'),
          garantia: { label: 'Sim', value: true },
          relogioEstoque: { label: 'Não', value: false },
          dataNotaFiscal: '',
          numeroNotaFiscal: '',
          relogio: null,
          osType: payload.osType
        }
      });
    }
  }
};
