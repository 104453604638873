//@flow

import type { Options } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const passwordRecoverService = {
  recover,
  reset,
  confirm
};

function recover(entity: any) {
  return defaultService.doPost(
    `${urlsConstants.PUBLIC_USUARIO}recoverPassword`,
    entity
  );
}
function reset(entity: any) {
  return defaultService.doPut(
    `${urlsConstants.PUBLIC_USUARIO}updateRecoverPassword`,
    entity
  );
}

function confirm(entity: any) {
  return defaultService.doPut(
    `${urlsConstants.PUBLIC_USUARIO}confirmEmail`,
    entity
  );
}
