//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const sintomaOsService = {
  findAll,
  findByPeca
};

function findByPeca(peca) {
  return defaultService.doGet(`${urlsConstants.FIND_SINTOMA_BY_PECA}/${peca}`);
}

function findAll(peca, description) {
  return defaultService.doGet(
    `${urlsConstants.FIND_ALL_SINTOMA}/${peca}/${description}`
  );
}
