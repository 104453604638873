//@flow
import * as React from 'react';
import FormInput from '../../components/Inputs/FormInput';
import { marcaService } from '../../services/marca.service';
import moment from 'moment/moment';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class CompraPecaListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Número CP"
          placeholder={'Número CP'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.id}
        />

        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataInicio"
          label="Data de"
          value={entity.dataInicio ? moment(entity.dataInicio) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-1'}
          id={'dataInicio'}
        />
        <DatePickerInput
          name="dataFim"
          label="Data até"
          placeholder={'--/--/--'}
          value={entity.dataFim ? moment(entity.dataFim) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-1'}
          id={'dataFim'}
        />

        <StatusSelectInput
          label="Status"
          placeholder={'Status'}
          id={'statusOS'}
          name="statusOS"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-3'}
          value={entity.statusOS}
          service={ordemServicoHelper.getListStatus}
        />
      </React.Fragment>
    );
  }
}
