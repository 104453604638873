export const formatterHelper = {
  cpfCnpjFormatter,
};

function cpfCnpjFormatter(values) {
  if (values) {
    let onlyNumber = values.replace(/\D/g, '');
    if (onlyNumber.length <= 11) {
      onlyNumber = onlyNumber.replace(/(\d{3})(\d)/, '$1.$2');
      onlyNumber = onlyNumber.replace(/(\d{3})(\d)/, '$1.$2');
      onlyNumber = onlyNumber.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      //Coloca ponto entre o segundo e o terceiro dígitos
      onlyNumber = onlyNumber.replace(/^(\d{2})(\d)/, '$1.$2');
      //Coloca ponto entre o quinto e o sexto dígitos
      onlyNumber = onlyNumber.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      //Coloca uma barra entre o oitavo e o nono dígitos
      onlyNumber = onlyNumber.replace(/\.(\d{3})(\d)/, '.$1/$2');
      //Coloca um hífen depois do bloco de quatro dígitos
      onlyNumber = onlyNumber.replace(/(\d{4})(\d)/, '$1-$2');
    }
    return onlyNumber;
  }
}
