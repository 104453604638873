//@flow
import React from 'react';

type State = {
  totalSintomas: number,
  avaliacaoPosto: number,
  totalTrocas: 0
};

type Payload = {
  totalSintomas: number
};

export default {
  state: { totalSintomas: 0, avaliacaoPosto: 0, totalTrocas: 0 },
  reducers: {
    setTotal: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        totalSintomas: payload.totalSintomas,
        avaliacaoPosto: payload.avaliacaoPosto
          ? payload.avaliacaoPosto
          : state.avaliacaoPosto,
        totalTrocas: payload.totalTrocas
      });
    }
  }
};
