//@flow
import * as React from 'react';
import { causaOsService } from '../../services/causa.service';
import SelectInput from './SelectInput';
import connect from 'react-redux/es/connect/connect';

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean
};

type State = {
  options: Array<any>
};
class CascadeSelectInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: [],
      noResultsText: 'Carregando'
    };
  }

  componentDidMount() {
    this.fetchData(this.props.parent);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.parent && nextProps.parent != this.props.parent) {
      this.fetchData(nextProps.parent);
    }
  }

  fetchData(parent) {
    let fetchFunction = this.props.service(parent);
    this.props.loading(true);
    fetchFunction.then(
      data => {
        let options = [];
        options = options.concat(data.data);
        let noResultsText = this.state.noResultsText;
        if (options.length < 1) {
          noResultsText = 'Nenhum resultado encontrado.';
        }
        this.props.loading(false);
        this.setState({ options: options, noResultsText: noResultsText });
      },
      error => {
        this.props.loading(false);
        console.log('Erro ao montar o combo', error);
      }
    );
  }

  render() {
    return (
      <SelectInput
        id={this.props.id}
        label={this.props.label}
        valueKey={this.props.valueKey ? this.props.valueKey : 'id'}
        labelKey={this.props.labelKey ? this.props.labelKey : 'nome'}
        name={this.props.name}
        noResultsText={this.state.noResultsText}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        multi={false}
        returnFullObject={this.props.returnFullObject}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        disabled={this.props.disabled}
        creatable={this.props.creatable}
        erroMensagem={this.props.erroMensagem}
      />
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
  ordemServico: { setCliente }
}) => ({
  loading: (load: boolean) => loading({ load }),
  setCliente: cliente => setCliente({ cliente }),
  success: msg => success(msg),
  info: msg => info(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(null, mapDispatch)(CascadeSelectInput);
