//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import SelectInput from '../../../components/Inputs/SelectInput';
import { objectsConstants } from '../../../constants/objects.constants';
import relogioBG from '../../../img/relogioBG.png';
import { marcaService } from '../../../services/marca.service';
import { midiaService } from '../../../services/midiaService';
import { relogioService } from '../../../services/relogio.service';
import FotoRelogio from '../../../components/FotoRelogio';
import MoneyInput from '../../../components/Inputs/MoneyInput';
import FormInput from "../../../components/Inputs/FormInput";
class TrocaRelogioContainerItem extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    if (!props.relogio) {
      this.state = {
        values: {},
        listPecas: [],
        pecasAdded: []
      };
    } else {
      this.state = {
        values: props.relogio,
        listPecas: [],
        pecasAdded: []
      };
    }
  }

  showError = () => {
    this.props.error({ message: objectsConstants.messageCampoObrigatorio });
    return '';
  };

  handleSelectChange = (name: any, option: any) => {
    const { values } = this.state;
    console.log(name)
    let newEntity = _.cloneDeep(values);
    _.set(newEntity, name, option);
    this.setState({ values: newEntity }, () => {
      if (this.state.values.relogio) {
        this.props.onChange(this.props.posicao, this.state.values);
      }
    });
  };

  render() {
    const { values } = this.state;
    console.log(values)
    console.log(this);
    return (
      <React.Fragment>
        <form
          noValidate
          className="row conteudoTab d-flex flex-column flex-lg-row "
        >
          <div className="flex-column col-12 col-md-3 text-center">
            <FotoRelogio
              relogio={values.relogio ? values.relogio : null}
              width={'70%'}
            />
          </div>
          <div className="flex-column col-10 col-md-6">
            <div className="form-row pt-3">
              <SelectInput
                type="text"
                id={'relogioInput'}
                name={'relogio'}
                label={`Código do relógio*`}
                placeholder="Código do relógio"
                onFetchData={relogioService.find}
                valueKey="codigoRelogio"
                labelKey="codigoRelogio"
                returnFullObject={true}
                searchable={true}
                onChange={this.handleSelectChange}
                value={values.relogio}
                style={['col-12 col-sm-12']}
                parent={values.marca}
              />
              <SelectInput
                type="text"
                name="marca"
                label="Marca"
                placeholder="Marca"
                required={true}
                style={['col-6 col-sm-6']}
                onFetchData={marcaService.find}
                valueKey="id"
                labelKey="nome"
                returnFullObject={true}
                searchable={true}
                id={'marca'}
                onChange={this.handleSelectChange}
                value={values.marca}
                value={
                  values.relogio && values.relogio.marca
                    ? values.relogio.marca
                    : values.marca
                }
              />

              <MoneyInput
                type="text"
                name="valor"
                label="Valor"
                placeholder="Valor"
                size={3}
                className={['col-6 col-sm-6']}
                id={'valorFinal'}
                ref={input => {
                  this._valor = input;
                }}
                value={values.valor}
                disabled={
                  this.props.ordemServico.garantia
                    ? this.props.ordemServico.garantia
                    : false
                }
                onChange={this.handleSelectChange}
              />
              <FormInput
                  type="text"
                  name="caixa"
                  label="Tamanho da caixa"
                  placeholder="Tamanho da caixa"
                  size={6}
                  className={['col-8 col-sm-8']}
                  id={'caixa'}
                  ref={input => {
                    this._caixa = input;
                  }}
                  value={values.caixa}
                  onChange={(e)=>{ const { name, value } = e.currentTarget;this.handleSelectChange(name,value)}}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <a
              href="#"
              onClick={() => {
                this.props.removeItem(this.props.posicao);
              }}
            >
              <img
                className="icon"
                src={require('../../../img/icon_excluir.svg')}
              />{' '}
              excluir{' '}
            </a>
          </div>
        </form>
        <hr />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente, ordemServico } = state.ordemServico;

  return {
    cliente,
    ordemServico
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, clearOS },
  alert: { success, error },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({}),
  error: msg => error(msg)
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(TrocaRelogioContainerItem);
