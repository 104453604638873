//@flow
import * as React from 'react';
import { marcaService } from '../../../services/marca.service';
import SelectInput from '../../../components/Inputs/SelectInput';
import moment from 'moment/moment';
import MonthPickerInput from '../../../components/Inputs/MonthPickerInput';
import CheckBoxInput from '../../../components/Inputs/CheckBoxInput';
import DatePickerInput from '../../../components/Inputs/DatePickerInput';
import StatusSelectInput from '../../../components/Inputs/StatusSelectInput';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';
import { objectsConstants } from '../../../constants/objects.constants';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Can } from '@casl/react';
import FormInput from '../../../components/Inputs/FormInput';
import CpfCnpjInput from '../../../components/Inputs/CpfCnpjInput';
import { dateHelper } from '../../../helpers/date.helper';
import _ from 'lodash';

class TrocaBateriaAjustePulseiraFormFilter extends React.PureComponent<
  Props,
  State
> {
  handleMesAno = (name, month, year) => {
    this.props.onChangeMonthYear(month, year);
  };

  render() {
    const { entity, permissions } = this.props;

    return (
      <React.Fragment>
        <FormInput
          label="Número OS / PA"
          placeholder={'Número OS / PA'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-1'}
          value={entity.id}
        />

        <SelectInput
          id={'garantiaInput'}
          name="garantia"
          label="Garantia"
          placeholder="Todos"
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
          style={['col-6 col-sm-1']}
          returnFullObject={true}
          valueKey={'value'}
          name={'garantia'}
          noSize={true}
          value={entity.garantia}
          onChange={this.props.onChangeSelect}
          clearable={true}
        />

        <SelectInput
          type="text"
          name="marca"
          label="Marca"
          placeholder="Marca"
          required={true}
          style={['col-12 col-sm-2']}
          onFetchData={marcaService.find}
          valueKey="id"
          labelKey="nome"
          returnFullObject={true}
          searchable={true}
          id={'marca'}
          onChange={this.props.onChangeSelect}
          value={entity.marca}
        />

        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <SelectInput
            type="text"
            name="postoAutorizado"
            label="Autorizada"
            placeholder="-Selecione-"
            style={['col-12 col-sm-3']}
            onFetchData={postoAutorizadoService.find}
            valueKey="id"
            labelKey="nomeFantasia"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'postoAutorizado'}
            onChange={this.props.onChangeSelect}
            value={entity.postoAutorizado}
          />
        </Can>
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataInicio"
          label="Data de"
          value={
            entity.dataInicio ? moment(entity.dataInicio) : moment().date(1)
          }
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-12 col-sm-2'}
          id={'dataInicio'}
        />
        <DatePickerInput
          name="dataFim"
          label="Data até"
          placeholder={'--/--/--'}
          value={entity.dataFim ? moment(entity.dataFim) : moment()}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-12 col-sm-2'}
          id={'dataFim'}
        />
        <StatusSelectInput
          label="Status"
          placeholder={'Status'}
          id={'statusOS'}
          name="statusOS"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-12 col-sm-2'}
          value={entity.statusOS}
          service={ordemServicoHelper.getListStatusFinalizada}
        />
        <FormInput
          label="Código do Relógio"
          placeholder={'Código do Relógio'}
          id={'condigoRelogio'}
          name="condigoRelogio"
          onChange={this.props.onChangeText}
          type={'text'}
          size={12}
          style={'col-12 col-sm-2'}
          value={entity.condiogoRelogio}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions,
  };
}

export default connect(mapStateToProps)(TrocaBateriaAjustePulseiraFormFilter);
