//@flow
import {urlsConstants} from '../constants/url.constant';
import {defaultService} from "./defaultService";

export const mensagemService = {
    getWsUrl,
    getWsTopic,
    updadeViewReceiverOS,
    getWsTopicMyMessage,
    findMyMessage,
    findContact,
    getWsTopicOS,
  updadeViewReceiver
};

function updadeViewReceiver(receiver){
  return defaultService.doGet(`${urlsConstants.UPDATE_VIEW_RECEIVER}${receiver}`);
}

function getWsTopicMyMessage(id) {
    return '/chat/mymessage/' + id
}

function updadeViewReceiverOS(os) {
    return defaultService.doGet(`${urlsConstants.UPDATE_VIEW_RECEIVER_OS}${os}`);
}

function getWsTopicOS(os) {
    return '/chat/os/' + os;
}

function getWsTopic(sender, receiver) {
    return '/chat/private/' + sender + "/" + receiver;
}

function getWsUrl() {
    return urlsConstants.WS_WEBSOCKET;
}

function findMyMessage() {
    return defaultService.doGet(urlsConstants.FIND_MY_MESSAGE);
}

function findContact() {
    return defaultService.doGet(urlsConstants.FIND_CONTACT);
}
