//@flow
import * as React from 'react';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import { relatorioService } from '../../../services/relatorio.service';
import RelatorioPrazoMedioAtendimentoTrocaChart from './prazoMedioAtendimentoTrocaChart';
import RelatorioPrazoMedioAtendimentoTrocaFormFilter from './prazoMedioAtendimentoTrocaFormFilter';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';

export default class RelatorioPrazoMedioAtendimentoTroca extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="Prazo médio atendimento troca "
          canBack={false}
        />
        <CardRelatorio
          methodFindChart={relatorioService.prazoMedioAtendimentoTroca}
          FormFilter={RelatorioPrazoMedioAtendimentoTrocaFormFilter}
          renderChartOnConstruct={true}
          Chart={RelatorioPrazoMedioAtendimentoTrocaChart}
        />
      </React.Fragment>
    );
  }
}
