//@flow
import * as React from 'react';
import { enderecoService } from '../../services/endereco.service';
import SelectInput from './SelectInput';

export default class PaisInput extends React.Component<{
  handleSelectChange: Function<any>,
  value: string,
  name: string,
  style?: string,
  size?: number,
  required: boolean
}> {
  render() {
    return (
      <SelectInput
        type="text"
        name={this.props.name}
        label="País"
        placeholder="País"
        onFetchData={enderecoService.findPais}
        valueKey="id"
        labelKey="nome"
        required={this.props.required}
        style={this.props.style}
        size={this.props.size}
        returnFullObject={true}
        searchable={true}
        onChange={this.props.handleSelectChange}
        className={`${this.props.className}`}
        value={this.props.value}
      />
    );
  }
}
