//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import SelectInput from '../../components/Inputs/SelectInput';
import Toast from '../../components/Toasts/Toast';
import { objectsConstants } from '../../constants/objects.constants';
import { grupoMarcaService } from '../../services/grupoMarca.service';
import { marcaService } from '../../services/marca.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
// type Props = { titlePage: any };
type State = { initialValues: any };

const NewMarca = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório'),
  tipo: Yup.string().required('Tipo é obrigatório'),
  mesesGarantia: Yup.string().required('Meses de Garantia é obrigatório')
});

class MarcaEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        name: ''
      }
    };
  }

  componentWillMount() {
    console.log(this.props);
    if (this.props.match.params.id) {
      marcaService.getById(this.props.match.params.id).then(result => {
        let initialValues = result.data.data;
        this.setState({ initialValues });
      });
    }
  }

  showError = messageError => {
    this.props.error({ message: messageError });
    return '';
  };

  clearError = () => {
    this.props.clear();
    return '';
  };

  render() {
    return (
      <Formik
        validationSchema={NewMarca}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={this.state.initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.props.loading(true);
          marcaService
            .doSave(values)
            .then(response => {
              actions.setSubmitting(false);
              this.props.success({ message: 'Marca salva com sucesso.' });
              this.props.loading(false);
              this.props.history.push('/marca');
            })
            .catch(() => {
              actions.setSubmitting(false);
              this.props.loading(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="flex-column">
              <Toast />
              <TopoTitleComponente
                mainTitle={'Marca'}
                subTitle={
                  values.id != undefined ? ' Editar dados' : 'Novo colaborador'
                }
                canBack={true}
              />
              {!_.isEmpty(errors) &&
                this.showError(objectsConstants.messageCampoObrigatorio)}
              {values.id && this.clearError()}
              <div className="cardSeculus d-flex flex-column mb-0">
                <div className="topoCard d-flex flex-column flex-sm-row">
                  <h3 className="flex-grow-1">Marca</h3>
                </div>

                <div className="form-row">
                  <div className="form-group col-12 col-lg-12">
                    <label>Nome*</label>
                    <input
                      type="text"
                      className={`form-control ${errors.name &&
                        touched.name &&
                        'error'}`}
                      name="nome"
                      placeholder="Digite o nome completo"
                      value={values.nome}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-6 col-lg-6">
                    <label>Meses de garantia*</label>
                    <input
                      type="number"
                      className={`form-control ${errors.mesesGarantia &&
                        touched.mesesGarantia &&
                        'error'}`}
                      name="mesesGarantia"
                      placeholder="Digite o nome completo"
                      value={values.mesesGarantia}
                      onChange={handleChange}
                    />
                  </div>

                  <SelectInput
                    type="text"
                    name="tipo"
                    label="Tipo"
                    placeholder=""
                    style={`col-6 col-sm-6`}
                    options={[
                      { label: 'Nacional', value: 'NACIONAL' },
                      { label: 'Importada', value: 'IMPORTADA' }
                    ]}
                    returnFullObject={false}
                    searchable={true}
                    id={'tipo'}
                    ref={input => {
                      this._garantia = input;
                    }}
                    onChange={(name, value) => setFieldValue(name, value)}
                    value={values.tipo}
                    required={true}
                  />

                  <FormSelectInput
                    name="grupoMarca"
                    label="Grupo de Marcas"
                    placeholder=""
                    size={12}
                    className={'col-12 col-sm-12'}
                    returnFullObject={true}
                    searchable={true}
                    id={'grupoMarca'}
                    ref={input => {
                      this._servico = input;
                    }}
                    onChange={(name, value) => setFieldValue(name, value)}
                    value={values.grupoMarca}
                    service={grupoMarcaService.findAll}
                    required={false}
                  />

                  <SelectInput
                    type="text"
                    name="destinoEPR"
                    label="Destino EPR"
                    placeholder=""
                    style={`col-6 col-sm-6`}
                    options={[
                      { label: 'Manaus', value: false },
                      { label: 'São Paulo', value: true }
                    ]}
                    returnFullObject={true}
                    searchable={true}
                    ref={input => {
                      this._garantia = input;
                    }}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    value={values.destinoEPR}
                    required={true}
                  />
                </div>

                <div className="d-flex flex-row flex-sm-row ">
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="button"
                      className="btn btn-secondary btn30 white"
                      disabled={isSubmitting}
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="submit"
                      className="btn btn-secondary btn30 green"
                      disabled={isSubmitting}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch
)(MarcaEdit);
