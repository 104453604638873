//@flow
import * as React from 'react';
import { Line } from 'react-chartjs-2';

const options = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: 10
    }
  }
};

export default class AnaliseAtendimentoEprChart extends React.PureComponent<
  Props,
  State
> {
  render() {
    const { data, entity } = this.props;
    let dataLine;
    if(data) {
      let labels = [];
      let qtPecasNaoAtendidas = {
        label: "Qtd de peças não atendidas",
        data: [],
        borderColor: "red",
        backgroundColor: "red",
        fill: false,
        lineTension: 0.1
      };
      let qtPecasAtendidas = {
        label: "Qtd de peças atendidas",
        data: [],
        borderColor: "blue",
        backgroundColor: "blue",
        fill: false,
        lineTension: 0.1
      };
      for (var i = 0; i < data[0].listAtendimentoEprChartVO.length; i++) {
        labels.push(this.props.data[0].listAtendimentoEprChartVO[i].id);
        qtPecasNaoAtendidas.data.push(this.props.data[0].listAtendimentoEprChartVO[i].countNaoAtendidas);
        qtPecasAtendidas.data.push(this.props.data[0].listAtendimentoEprChartVO[i].countAtendidas);
      }
      let datasets = [];
      datasets.push(qtPecasNaoAtendidas);
      datasets.push(qtPecasAtendidas);
      dataLine = { labels, datasets };
    }
    return (
      <div className="pt-3">
        {dataLine && (
          <Line data={dataLine}  />
        )}
      </div>
    );
  }
}
