import * as React from 'react';
import { connect } from 'react-redux';
import { defaultService } from '../../services/defaultService';
import { taskService } from '../../services/taskService';
import { urlsConstants } from './../../constants/url.constant';
import _ from 'lodash';
let download = require('downloadjs');

let fileInput;
class ArrayFileInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dataFile: null,
      files: []
    };
    this.loadFile = this.loadFile.bind(this);
  }

  componentDidMount() {
    if (this.props.fileItem) {
      this.loadFile(this.props.fileItem);
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.fileItem) {
      this.loadFile(nextProps.fileItem);
    }
  }
  loadFile = fileItem => {
    let _this = this;
    console.log(fileItem)
    if (fileItem.id) {
      let url = `${
        _this.props.url ? _this.props.url : urlsConstants.MIDIA_FILE
      }${fileItem.id}`;
      defaultService.doGet(url).then(result => {
        _this.setState({ dataFile: result.data.data });
      });
    }
  };

  onChange = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;

      for (let i = 0; i < files.length; i++) {
        let item = files[i];
        resize(item, maxWidth, maxHeight, function(midiaFile) {
          let { files } = self.state;
          let newArrayFiles = _.cloneDeep(files);
          newArrayFiles.push(midiaFile);
          self.setState({ files: newArrayFiles }, () => {
            self.props.onChange(newArrayFiles, self.props.name);
          });
        });
      }
    }
  };

  removeItem = (e, image) => {
    let { files } = this.state;
    let newArrayFiles = _.cloneDeep(files);
    _.remove(newArrayFiles, function(item) {
      return (image.id && image.id === item.id) || image.data === item.data;
    });
    this.setState({ files: newArrayFiles }, () => {
      this.props.onChange(newArrayFiles, this.props.name);
    });
  };

  openInputFile = (dataFile: any, readOnly) => {
    if (this.props.readOnly || readOnly) {
      if (dataFile) {
        let uri = `data:${dataFile.contentType};base64,${dataFile.data}`;
        download(uri, dataFile.filename, dataFile.contentType);
      }
      return false;
    }
    this.inputFile.click();
  };

  getFile = (dataFile, readOnly, canRemove, index) => {
    let dataUrl;
    if (dataFile.id) {
      dataUrl = `${urlsConstants.MIDIA_IMAGE}${dataFile.id}`;
    } else {
      dataUrl = dataFile.data;
    }
    if (dataUrl) {
      return (
        <div className="align-middle pl-3">
          <a
            role="button"
            href="#"
            onClick={() => {
              this.openInputFile(dataFile, readOnly);
            }}
            id={this.props.id}
            className={this.props.className}
          >
            {dataFile && !canRemove && <i className="icon-anexo" />}

            {dataFile &&
              (this.props.forceLabel
                ? this.props.forceLabel
                : dataFile.filename)}
            {!dataFile && this.props.emptyLabel}
          </a>
          {dataFile && canRemove && (
            <img
              src={require('../../img/icon_excluir.svg')}
              className="icon_archive"
              alt="Ícone de um arquivo"
              onClick={e => {
                this.removeItem(index, dataFile);
              }}
            />
          )}
        </div>
      );
    }
  };

  render() {
    var file;
    var dataFile = this.state.dataFile;
    
    file = (
      <a
        role="button"
        href="#"
        onClick={this.openInputFile}
        id={this.props.id}
        className={this.props.className}
      >
        {this.props.children}
        {dataFile && !this.props.children && (
          <img
            src={require('../../img/icon_archive.svg')}
            className="icon_archive"
            alt="Ícone de um arquivo"
          />
        )}
        {dataFile &&
          (this.props.forceLabel ? this.props.forceLabel : dataFile.filename)}
        {!dataFile && this.props.emptyLabel}
      </a>
    );

    return (
      <React.Fragment>
        <input
          type="file"
          accept={this.props.accept ? this.props.accept : '*'}
          onChange={this.onChange}
          className="d-none img-profile-input"
          maxLength="1024"
          multiple={!this.props.singleFile}
          ref={input => {
            this.inputFile = input;
          }}
        />
        {file}
        <div className="row col-12 pt-3">
          {this.state.files.map((file, index) =>
            this.getFile(file, true, true, index)
          )}
        </div>
        {/* {this.props.imagens &&
          this.props.imagens.map((image, index) =>
            this.getImage(image, true, false, image.id)
          )} */}
      </React.Fragment>
    );
  }
}

function resize(file, maxWidth, maxHeight, fn) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function(event) {
    var dataUrl = event.target.result;

    var midiaVO = {
      filename: file.name,
      length: file.size,
      contentType: file.type
    };
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (validImageTypes.includes(file.type)) {
      var dataUrl = event.target.result;
      var image = new Image();
      image.src = dataUrl;
      image.onload = function() {
        var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
        midiaVO.data = resizedDataUrl;
        fn(midiaVO);
      };
    } else {
      midiaVO.data = dataUrl;
      fn(midiaVO);
    }
  };
}

function resizeImage(image, maxWidth, maxHeight, quality) {
  var canvas = document.createElement('canvas');

  var width = image.width;
  var height = image.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL('image/jpeg', quality);
}

export default connect(
  null,
  null,
  null,
  { withRef: true }
)(ArrayFileInput);
