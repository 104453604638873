//@flow
import { oauthHeader } from '../helpers/oauth-header';
import { urlsConstants } from '../constants/url.constant';
import { objectsConstants } from '../constants/objects.constants';
import { defaultService } from './defaultService';
import axios from 'axios/index';

export const userService = {
  me,
  getCurrentUser,
  isAdminManaus,
  updateFoto,
  isAdmin,
  canViewPostos,
  canViewEstoque,
  canViewPostoHabilitado
};

function isAdminManaus() {
  let user = getCurrentUser();
  if (
    user.role === objectsConstants.ROLE_ADMIN ||
    user.role === objectsConstants.ROLE_MANAUS
  ) {
    return true;
  }
}

function canViewPostos() {
  let user = getCurrentUser();
  if (user.role === objectsConstants.ABILITY_VISUALIZAR_POSTOS_OS) {
    return true;
  }
}
function canViewEstoque() {
  let user = getCurrentUser();
  if (user.role === objectsConstants.ABILITY_VISUALIZAR_ESTOQUE_OS) {
    return true;
  }
}

function canViewPostoHabilitado() {
  let user = getCurrentUser();
  if (
    user.role ===
    objectsConstants.ABILITY_ORDEM_SERVICO_VISUALIZAR_POSTO_HABILITADO
  ) {
    return true;
  }
}

function isAdmin() {
  let user = getCurrentUser();
  if (user.extension) {
    return false;
  } else {
    return true;
  }
}

function me() {
  const requestOptions = {
    method: 'GET',
    headers: oauthHeader()
  };
  return defaultService.doGet(urlsConstants.USER_ME).then(response => {
    console.log(response);
    if (response.data.error === 'invalid_token') {
      return Promise.reject('invalid_token');
    }
    localStorage.setItem('user', JSON.stringify(response.data));
    return response;
  });
}

function updateFoto(image) {
  return defaultService.doPost(`${urlsConstants.USER}updateFoto`, image);
}

function getCurrentUser() {
  let token = localStorage.getItem('user');
  if (!token) {
    return null;
  }
  let user = JSON.parse(token);
  return user;
}
