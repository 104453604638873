//@flow
import { Can } from '@casl/react';
import _ from 'lodash';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
// import type { ClienteType } from '../../types/cliente.type';
import { withRouter } from 'react-router-dom';
import {
  constHelper,
  objectsConstants
} from '../../constants/objects.constants';
import { dateHelper } from '../../helpers/date.helper';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import { grupoMarcaService } from '../../services/grupoMarca.service';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';
import { UserAvatar } from '../menu/UserAvatar';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';

const endPoint = '/grupoMarca/edit/';
class GrupoMarca extends React.Component<Props> {
  disableEnabledUser = () => {
    let { grupoMarca } = this.props;
    this.props.loading(true);
    _.set(grupoMarca, 'status', !grupoMarca.status);
    grupoMarcaService
      .doSave(grupoMarca)
      .then(response => {
        this.props.history.push('/grupoMarca');
        this.props.success({ message: 'Alterado com sucesso.' });
        this.props.loading(false);
      })
      .catch(() => {
        this.props.success('Não foi possível inativar o grupo.');
        this.props.loading(false);
      });
  };

  getBtnActiveInative = () => {
    const { grupoMarca } = this.props;
    let textAction = grupoMarca.status ? 'INATIVAR' : 'ATIVAR';
    return (
      <button
        type="button"
        className={`btn btn-primary btn-sm ${
          grupoMarca.status ? 'red' : 'green'
        }`}
        data-toggle="modal"
        data-target="#editarDados"
        onClick={() => {
          ConfirmAcationService.confirmMsg(
            this.disableEnabledUser,
            textAction + ' GRUPO DE MARCA',
            'Você confirma que deseja ' + textAction + ' o grupo:',
            '' + grupoMarca.nome + ''
          );
        }}
      >
        {textAction}
      </button>
    );
  };

  render() {
    const { grupoMarca, permissions, titlePageEdit } = this.props;
    return (
      <div className="card cardSeculus miniCard d-flex flex-column ">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">Grupo de marcas</h3>
          <div className="boxButon float-right d-sm-flex">
            <Can
              I={objectsConstants.VIEW}
              a={objectsConstants.EDIT_PROFILE}
              ability={permissions}
            >
              {this.getBtnActiveInative()}
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  this.props.history.push({
                    pathname: endPoint,
                    state: {
                      grupoMarca: this.props.grupoMarca,
                      titlePage: titlePageEdit
                    }
                  });
                }}
              >
                editar dados
              </button>
            </Can>
          </div>
        </div>

        <div className="conteudoCard mt-2">
          <TopoTitleComponente
            mainTitle={this.props.titlePageEdit}
            subTitle={'Grupode marca - ' + grupoMarca.nome}
            canBack={true}
          />

          <div className="row ">
            <div className="col-12">
              <label className="miniLabel">Nome:</label>
              <div className="mb-2">
                <b>{grupoMarca.nome}</b>
              </div>
            </div>

            <div className="col-12">
              <label className="miniLabel">Descrição:</label>
              <div className="mb-2">{grupoMarca.descricao}</div>
            </div>
            <div className="col-6">
              <label className="miniLabel">Valor:</label>
              <div className="mb-2">
                {ordemServicoHelper.formatMoney(
                  parseFloat(grupoMarca.valor ? grupoMarca.valor : 0),
                  2,
                  ',',
                  '.'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;
  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(GrupoMarca));
