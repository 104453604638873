import React, { Component } from "react";
import CadastroGarantiaUsuario from "./CadastroGarantiaUsuario";
import logo_mondaine from "../../img/logo_mondaine_2016.jpg";
import logo_guess from "../../img/LOGOTIPO_GUESS.jpg";
import logo_seculus from "../../img/LOGOTIPO_SECULUS.jpg";
import logo_speedo from "../../img/LOGOTIPO_SPEEDO.jpg";
import logo_adidas from "../../img/adiddas.jpeg";
import logo_timex from "../../img/timex.png";
import "../../css/CadastroGarantia.css";
import GarantiaDadosRelogio from "./GarantiaDadosRelogio";
import WizardBtn from "../../components/Buttons/WizardBtn";
import CadastroNotaFiscal from "./CadastroNotaFiscal";
import CadastroGarantiaConcluido from "./CadastroGarantiaConcluido";
import StepProgress from "../../components/Inputs/StepProgress";
import { Formik, yupToFormErrors } from "formik";
import moment from 'moment';
import * as Yup from "yup";
import { garantiaClienteService } from "../../services/garantiaCliente.service";
export default class CadastroGarantia extends Component {
  constructor(props) {
    super(props);
    this.state = { etapaAtual: 1 };
  }

  proximaEtapa = () => {
    console.log(this.state.etapaAtual);
    this.setState({ etapaAtual: this.state.etapaAtual + 1 });
  };
  voltarEtapa = () => {
    console.log(this.state.etapaAtual);
    this.setState({ etapaAtual: this.state.etapaAtual - 1 });
  };

  getValidationSchema = () => {
    if (this.state.etapaAtual === 1) {
      return Yup.object().shape({cliente:Yup.object().shape({
          nome:Yup.string().required('O campo é obrigatorio'),
          documento:Yup.string().required('O campo é obrigatorio').min(14,'Cpf inválido'),
          email:Yup.string().required('O campo é obrigatorio').email('E-mail inválido'),
          endereco:Yup.object().shape({
            cep:Yup.string().required('O campo é obrigatorio').min(5,'CEP inválido'),
            rua:Yup.string().required('O campo é obrigatorio'),
            bairro:Yup.string().required('O campo é obrigatorio'),
            numero:Yup.string().required('O campo é obrigatorio'),
            cidade:Yup.object().shape({nome:Yup.string().required('O campo é obrigatorio'),
              estado:Yup.object().shape({uf:Yup.string().required('O campo é obrigatorio')})

            })
          })

        })})
    }else if(this.state.etapaAtual===2){
      return Yup.object().shape({numeroNF:Yup.string().required('Obrigatorio').min(1) ,
        relogio:Yup.string().required('o codigo é obrigatório').min(1 ,'obrigatório' ) ,
        dataNF: Yup.date()
            .required("data obrigatório")
            .max(new Date(),'A Data da compra deve precisa der menor que hoje'),
      })
    }else if(this.state.etapaAtual===3){
      return Yup.object().shape({notaFiscal:Yup.string().required('Obrigatorio')
      })
    }
  };
  render() {
    console.log(this.state.etapaAtual);
    let validationSchema = this.getValidationSchema();
    console.log(validationSchema);
    return (
        <div className="container-fluid container cadastroGarantia text-center mt-5 pb-5">
          <Formik
              validationSchema={() =>
                  Yup.lazy((values) => {
                    return validationSchema;
                  })
              }
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{cliente:{endereco:{cidade:{estado:{}}}},telefones:[{}]}}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                if(this.state.etapaAtual<3){
                  this.proximaEtapa();
                }else{
                  console.log(values);
                  values.notaFiscal = values.notaFiscal[0];
                  garantiaClienteService.save(values).then((r)=>{
                    this.proximaEtapa();
                  }).catch((error)=>{
                    console.log(error);
                  })
                }
              }}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <div className="row">
                  {console.log(errors)}

                  <div className="col-12">
                    <img
                        className="logoCadastroGarantia"
                        src={logo_seculus}
                        alt={""}
                    />
                    <img
                        className="logoCadastroGarantia"
                        src={logo_mondaine}
                        alt={""}
                    />
                    <img
                        className="logoCadastroGarantia"
                        src={logo_speedo}
                        alt={""}
                    />
                    <img
                        className="logoCadastroGarantia"
                        src={logo_guess}
                        alt={""}
                    />
                      <img
                          className="logoCadastroGarantia logo-p-0"
                          src={logo_timex}
                          alt={""}
                      />
                    <img
                        className="logoCadastroGarantia logo-p-0"
                        src={logo_adidas}
                        alt={""}
                    />
                    <h3>REGISTRO DE GARANTIA</h3>
                  </div>

                  <div className="col-md-12">
                    <StepProgress currentStep={this.state.etapaAtual - 1} />
                  </div>

                  <div className="col-12 px-5">
                    {this.state.etapaAtual === 1 && (
                        <CadastroGarantiaUsuario
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            handleChange={handleChange}
                        />
                    )}

                    {this.state.etapaAtual === 2 && (
                        <GarantiaDadosRelogio
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        />
                    )}

                    {this.state.etapaAtual === 3 && <CadastroNotaFiscal  values={values} setFieldValue={setFieldValue} errors={errors}/>}

                    {this.state.etapaAtual === 4 && <CadastroGarantiaConcluido />}
                  </div>

                  <div className="boxButton row text-letf col-12 my-5 py-3 px-5 ">
                    {this.state.etapaAtual >= 2 && this.state.etapaAtual !== 4 && (
                        <WizardBtn
                            title="Voltar"
                            id="buttonGarantia"
                            className=" btn-custom col-12 justify-content-start"
                            type="button"
                            onSubmit={this.voltarEtapa}
                        ></WizardBtn>
                    )}


                    {this.state.etapaAtual <= 2 && (
                        <WizardBtn
                            title="Avançar"
                            id="buttonGarantia"
                            className=" btn-custom col-12 justify-content-end my-3"
                            type="submit"
                            onSubmit={handleSubmit}
                        ></WizardBtn>
                    )}


                    {this.state.etapaAtual === 3 && (
                        <WizardBtn
                            title="Enviar e Concluir"
                            id="buttonGarantia justify-content-end my-3"
                            className=" btn-custom col-12 "

                            type="submit"
                            onSubmit={handleSubmit}
                        ></WizardBtn>
                    )}

                  </div>
                </div>
            )}
          </Formik>
        </div>
    );
  }
}
