import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label } from 'reactstrap';
import { translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';

type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange: any,
  onBlur: any,
  children?: any,
  required: boolean,
  messageValidate?: string,
  placeholder: string,
  disabled: boolean,
  maxLength: number,
  style: any,
};
class CpfInput extends PureComponent<Props> {
  static defaultProps = {
    size: 12,
  };

  handleBorderColor = (messageValidate) => {
    let borderColor;
    if (messageValidate) {
      borderColor = '#dc3545';
    }
    return {
      borderColor,
    };
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    if (this.props.onChange) {
      this.props.onChange(name, value);
    }
  };

  render() {
    const { messages, name } = this.props;

    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }

    const value = this.props.value || '';
    console.log(value);
    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    return (
      <FormGroup
        className={`${this.props.noSize ? '' : colSize} ${this.props.style}`}
      >
        {this.props.label && (
          <Label for={this.props.name}>
            {this.props.label}
            {this.props.required && '*'}
          </Label>
        )}
        <InputMask
          mask="999.999.999-99"
          maskChar=""
          id={this.props.id}
          name={this.props.name}
          value={value}
          style={this.handleBorderColor(messageValidate)}
          onChange={this.handleChange}
          required={this.props.required}
          placeholder={this.props.placeholder}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled ? this.props.disabled : false}
          className={`form-control ${messageValidate ? 'is-invalid' : ''}`}
        >
          {this.props.children}
        </InputMask>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert,
  };
}

export default connect(mapStateToProps)(CpfInput);
