//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { urlsConstants } from '../../constants/url.constant';
import { ordemServicoService } from '../../services/ordemServico.service';

import type { PostoAutorizadoType } from '../../types/postoAutorizado.type';

type State = {
  entity: PostoAutorizadoType,
  modal: boolean
};

class OrdemServicoTask extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    console.log('sd');
    this.props.loading(true);
    let urlOS = urlsConstants.ORDEM_SERVICO + this.props.match.params.id;
    ordemServicoService.doGet(urlOS).then(response => {
      let os = response.data.data;
      this.props.setOrdemServico(os);
      this.props.setCliente(os.cliente);
      this.props.history.push(
        '/ordemServico/view/' + this.props.match.params.id
      );
      this.props.loading(false);
    });
  }

  render() {
    return <React.Fragment />;
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;
  const { permissions } = state.authentication;
  return {
    subTitle,
    permissions
  };
}

const mapDispatch = ({
  ordemServico: { setCliente, clearOS, setOrdemServico },
  load: { loading }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({})
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(OrdemServicoTask));
