//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const relatorioService = {
  represamentoPreReparo,
  prazoMedioAtendimentoReparo,
  volumeTroca,
  prazoMedioEntregaPosReparo,
  consumoPecas,
  volumesPorStatus,
  represamentoPreReparoTable,
  disponibilidadePecasEPR,
  represamentoPreReparoSemanal
};

function represamentoPreReparoSemanal(filter){
  return defaultService.doPost(
      `${urlsConstants.RELATORIO_REPRESAMENTO_PRE_REPARO_SEMANAL}`,
      filter
  );
}


function volumesPorStatus(filter){
  return defaultService.doPost(
      `${urlsConstants.RELATORIO_VOLUME_POR_STATUS}`,
      filter
  );
}

function disponibilidadePecasEPR(filter){
  return defaultService.doPost(
    `${urlsConstants.RELATORIO_DISPONIBILIDADE_PECAS_EPR}`,
    filter
  );
}

function prazoMedioEntregaPosReparo(filter){
    return defaultService.doPost(
        `${urlsConstants.RELATORIO_PRAZO_MEDIO_ENTREGA_POS_REPARO}`,
        filter
    );
}

function represamentoPreReparo(filter) {
  return defaultService.doPost(
    `${urlsConstants.RELATORIO_REPRESAMENTO_PRE_REPARO}`,
    filter
  );
}

function represamentoPreReparoTable(filter){

}

function prazoMedioAtendimentoReparo(filter) {
  return defaultService.doPost(
    `${urlsConstants.RELATORIO_PRAZO_MEDIO_ATENDIMENTO_REPARO}`,
    filter
  );
}

function volumeTroca(filter) {
  return defaultService.doPost(`${urlsConstants.RELATORIO}volumeTroca`, filter);
}

function consumoPecas(filter) {
  return defaultService.doPost(
    `${urlsConstants.RELATORIO}consumoPecas`,
    filter
  );
}
