import React, { PureComponent } from 'react';
import '../../../css/boxPostoAutorizadoOS.css';

type Props = {
  children?: any,
  onChange: any
};
type State = {
  entity: any
};

export class EmptyOS extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity
    };
  }
  handleAction = () => {};
  render() {
    return (
      <div className="card cardSeculus emptyOS d-flex flex-column a1 h-50">
        <div className="conteudoCard d-flex flex-column flex-sm-column">
          <div className="col-sm-12 d-flex flex-column align-items-center pr-3 pt-5">
            <div className="justify-content-center pl-3">
              <span className="icon-os_x icon-lg">
                <span className="path1 " />
                <span className="path2 " />
                <span className="path3 " />
                <span className="path4 " />
                <span className="path5 " />
                <span className="path6 " />
                <span className="path7 " />
              </span>
            </div>
            <div className="justify-content-center pl-3 ">
              <p className="p-msg pb-5">
                Selecione uma ordem de serviço para obter mais detalhes.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
