import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import AlterarStatusOsEdit from './AlterarStatusOSEdit';

export default class AlterarStatusOS extends PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/alterarstatusos"
            exact
            component={AlterarStatusOsEdit}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
