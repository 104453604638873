//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import { pesquisaSatisfacaoService } from '../../services/pesquisaSatisfacaoService';

const columns = [
  { path: 'postoAutorizado.id', title: '#', isKey: true, dataSort: false },
  {
    path: 'postoAutorizado.nomeFantasia',
    title: 'Nome',
    isKey: false,
    dataSort: true
  },
  { path: 'endereco', title: 'Cidade', isKey: false, dataSort: true },
  {
    path: 'mediaAvaliacao',
    title: 'Avaliação',
    isKey: false,
    dataSort: true,
    isDecimal: true
  }
];

type State = {
  modal: boolean
};

class CardListaPostoAvaliacao extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      fields
    };
  }

  updateTable = () => {
    this._listagemPage.getWrappedInstance().updateTable();
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <ListagemPage
          title={`POSTOS AUTORIZADOS(${this.props.subTitle})`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={pesquisaSatisfacaoService}
          labelNewButton={''}
          classSignal="temSinal"
          defaultOrder={'creationDateTime'}
          defaultDirection={'desc'}
          defaultFilter={this.props.filter}
          onRowClick={this.handleRowClick}
          showNewButton={false}
          controller="relatorio/postoAutorizado"
          fields={this.state.fields}
          forceUpdate={this.props.update}
          notUpdateOnChangeFilter={true}
          ref={listagemPage => (this._listagemPage = listagemPage)}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle, mainTitle } = state.title;

  return {
    subTitle,
    mainTitle
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(CardListaPostoAvaliacao);
