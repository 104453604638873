//@flow
import { Can } from '@casl/react';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import MonthPickerInput from '../../../components/Inputs/MonthPickerInput';
import SelectInput from '../../../components/Inputs/SelectInput';
import StatusSelectInput from '../../../components/Inputs/StatusSelectInput';
import { objectsConstants } from '../../../constants/objects.constants';
import { marcaService } from '../../../services/marca.service';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';

class RelatorioVolumesPorStatusFormFilter extends React.PureComponent<{}> {
  handleMesAno = (name, month, year) => {
    this.props.onChangeMonthYear(month, year);
  };
  render() {
    const { entity, permissions } = this.props;
    return (
      <React.Fragment>
        <SelectInput
          type="text"
          name="marca"
          label="Marca:"
          placeholder="-Selecione-"
          required={true}
          style={['col-12 col-sm-3']}
          onFetchData={marcaService.find}
          valueKey="id"
          labelKey="nome"
          returnFullObject={true}
          searchable={true}
          //messageValidate={validate.portadorDeficiencia}
          id={'marca'}
          onChange={this.props.onChangeSelect}
          value={entity.marca}
        />
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <SelectInput
            type="text"
            name="postoAutorizado"
            label="Autorizada"
            placeholder="-Selecione-"
            style={['col-12 col-sm-3']}
            onFetchData={postoAutorizadoService.find}
            valueKey="id"
            labelKey="nomeFantasia"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'postoAutorizado'}
            onChange={this.props.onChangeSelect}
            value={entity.postoAutorizado}
          />
        </Can>
        <MonthPickerInput
          placeholder={'Mês/Ano'}
          name="anoMes"
          label="Mês/Ano"
          onChange={this.handleMesAno}
          size={6}
          style={['col-6 col-sm-2']}
          id={'anoMes'}
          defaultValue={true}
        />
        <StatusSelectInput
          label="Status"
          placeholder={'Status'}
          id={'statusOS'}
          name="statusOS"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-2'}
          value={entity.statusOS}
          service={ordemServicoHelper.getListStatusFinalizada}
        />
        <SelectInput
          type="text"
          name="tipo"
          label="Tipo"
          placeholder="Tipo"
          style={['col-12 col-sm-2']}
          options={objectsConstants.TIPO_OS}
          valueKey="value"
          labelKey="label"
          returnFullObject={false}
          searchable={true}
          id={'tipo'}
          onChange={this.props.onChangeSelect}
          value={entity.tipo}
        />
        <SelectInput
          type="text"
          name="tipoRecebimento"
          label="Tipo de Recebimento"
          placeholder="Tipo"
          style={['col-12 col-sm-2']}
          options={[
            { label: 'Correio', value: 'CORREIO' },
            { label: 'Recepção', value: 'RECEPCAO' },
            { label: 'Todos', value: 'TODOS' }
          ]}
          valueKey="value"
          labelKey="label"
          returnFullObject={false}
          id={'tipoRecebimento'}
          onChange={this.props.onChangeSelect}
          value={entity.tipoRecebimento}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps)(RelatorioVolumesPorStatusFormFilter);
