//@flow
import _ from 'lodash';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../constants/objects.constants';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { compraPecaService } from '../../services/compraPeca.service';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import { defaultService } from '../../services/defaultService';
import RelogioContainerItem from '../../views/compraPeca/RelogioContainerItem';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';
import Toast from '../Toasts/Toast';
import type { OrdemServicoType } from '../../types/ordemServico.type';
import { userService } from '../../services/user.service';
import { translate } from '../../helpers/message.helper';

type Props = { ordemServico: OrdemServicoType };

const EMPTY_VALUE = '--,--';
class CardCriarCP extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      relogios: [{ posicao: 1, relogio: { pecas: [] } }]
    };
  }
  handleConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui success">
            <h3>
              <span className="txtGreen">Solicitar peças</span>-
              <span className="txtDarkGray">Confirmar</span>
            </h3>
            <p className="p-msg">
              A solicitação destas peças está no
              <br /> valor de <b>{this.getValorCompra()}</b>.
            </p>
            <p>
              <b>Deseja realmente realizar esta solicitação?</b>
            </p>
            <button
              className="btn btn-primary my-4 mx-lg-3"
              onClick={() => {
                this.handleSubmiteSolicitacao();
                onClose();
              }}
            >
              Solicitar peças
            </button>
            <button
              className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
              onClick={() => {
                onClose();
              }}
            >
              Não, obrigado
            </button>
          </div>
        );
      }
    });
  };

  handleVoltar = () => {
    this.props.clearOS();
    this.props.history.push('/compraPeca');
  };

  handleSubmiteSolicitacao = async () => {
    this.props.loading(true);
    const { relogios } = this.state;

    let compraPecaVO;

    if (this.props.posto.id) {
      compraPecaVO = {
        postoAutorizado: this.props.posto,
        relogios: await ordemServicoHelper.getObjectsInArray(
          relogios,
          'relogio'
        )
      };
    } else {
      compraPecaVO = {
        relogios: await ordemServicoHelper.getObjectsInArray(
          relogios,
          'relogio'
        )
      };
    }

    compraPecaService.doSave(compraPecaVO).then(
      response => {
        let id = defaultService.getIdFromUrl(response.headers.location);
        this.props.clear();
        compraPecaService.getById(id).then(response => {
          this.props.success({
            message: 'Solicitação realizada com sucesso.'
          });
          this.props.setOrdemServico(response.data.data);
          this.props.loading(false);
          if (userService.isAdmin()) {
            this.props.history.push('/compraPeca/view', {
              entity: { posto: this.props.posto }
            });
          } else {
            this.props.history.push('/compraPeca/view');
          }
        });
      },
      error => {
        this.props.loading(false);
        this.props.error({
          message: translate(error.response.data.messages[0].message.code)
        });
        console.log(error);
      }
    );
  };

  showError = () => {
    this.props.error({ message: objectsConstants.messageCampoObrigatorio });
    return '';
  };

  addRow = () => {
    let relogios = this.state.relogios;
    relogios.push({
      posicao: relogios[relogios.length - 1].posicao + 1,
      relogio: { pecas: [] }
    });
    this.setState(relogios);
  };

  handleChangeItem = async (posicao: any, newItem: any) => {
    const { relogios } = this.state;
    let newEntity = _.cloneDeep(relogios);
    //add item in object
    if (newItem) {
      newEntity = _.map(relogios, function(item) {
        return item.posicao === posicao ? { posicao, relogio: newItem } : item;
      });
    }

    this.setState({ relogios: newEntity });
  };

  getValorCompra = () => {
    let valorTotal = 0;

    if (
      this.state.relogios &&
      this.state.relogios.length === 1 &&
      this.state.relogios[0].relogio.pecas.length === 0
    ) {
      return EMPTY_VALUE;
    }
    _.map(this.state.relogios, function(item) {
      _.map(item.relogio.pecas, function(peca) {
        valorTotal += parseInt(peca.quantidade) * peca.valorCompra;
      });
    });

    return ordemServicoHelper.formatMoney(parseFloat(valorTotal), 2, ',', '.');
  };

  render() {
    const { ordemServico } = this.props;
    let dias = '-- Dias';
    return (
      <div
        className="tab-pane fade show active"
        id="relogio"
        role="tabpanel"
        aria-labelledby="relogio-tab"
      >
        <Toast />
        <TopoTitleComponente
          mainTitle="Compra de peças"
          subTitle={'Nova solicitação'}
          canBack={true}
        />
        <div className="row topoTab">
          <div className="col-3 px-1 px-sm-2 px-md-3">
            <h4>Solicitação aberta</h4>
            <h1>
              <b>{dias}</b>
            </h1>
          </div>
          <div className="col-4 px-1 px-sm-2 px-md-3 text-center">
            <h4>valor Total da solicitação</h4>
            <h1>
              <b>{this.getValorCompra()}</b>
            </h1>
          </div>
          <div className="col-5 text-right px-1 px-sm-2 px-md-3">
            <div className="col-12 d-flex justify-content-between pt-4">
              <button
                type="button"
                className="btn btn-primary justify-content-start white btn-sm"
                onClick={() =>
                  ConfirmAcationService.confirmMsg(
                    this.handleVoltar,
                    'Cancelar',
                    'Deseja cancelar Solicitação?',
                    'Ao confirmar os dados digitados serão perdidos.'
                  )
                }
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  console.log('props', this.props);
                  this.handleConfirm();
                }}
                disabled={this.getValorCompra() === EMPTY_VALUE}
              >
                Solicitar peças
              </button>
            </div>
          </div>
        </div>
        <hr />

        {this.state.relogios.map((relogio, index) => (
          <RelogioContainerItem
            onChange={this.handleChangeItem}
            key={relogio.posicao}
            posicao={relogio.posicao}
          />
        ))}

        <div className="text-right py-2 pt-2" onClick={this.addRow}>
          <span className="icon-badge">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </span>{' '}
          <a>Relógios </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;

  return {
    cliente
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, clearOS },
  alert: { success, error, clear },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({}),
  clear: () => clear(),
  error: msg => error(msg)
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(withRouter(CardCriarCP));
