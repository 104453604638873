//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const tecnicoService = {
  findByPosto,
  doSave
};

function findByPosto(posto) {
  console.log(posto);
  return defaultService.doGet(`${urlsConstants.TECNICO}${posto.id}`);
}

function doSave(value, posto) {
  return defaultService.doGet(`${urlsConstants.TECNICO}${posto.id}/${value}`);
}
