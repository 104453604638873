import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const financeiroService = {
  findByFiltro,
  findDetailsFinanceiroByFilter
};

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.FINANCEIRO}list`,
    options,
    filter
  );
}

function findDetailsFinanceiroByFilter(

  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.doPost(`${urlsConstants.FINANCEIRO}details`, {
    filter
  });
}
