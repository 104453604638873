//@flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import CardSelectPosto from '../../components/Cards/CardSelectPosto';
import CardListaPosto from '../../components/Cards/CardListaPosto';
import { CardItem } from '../../components/templates/CardItem';
import PostoAutorizadoFormViewView from '../../views/postoAutorizado/PostoAutorizadoFormViewView';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';
import _ from 'lodash';
import {
  constHelper,
  objectsConstants
} from '../../constants/objects.constants';
import { Can } from '@casl/react';

type State = { colaborador: any, edit: boolean, entity: any };

class ConfiguracoesValoresGarantia extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { colaborador: {}, edit: false, entity: {} };
  }

  onChangeSelect = (name: string, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity });
  };

  handleSelectPosto = postoAutorizado => {
    this.props.history.push(
      '/configuracoes/valoresGarantia/posto/' + postoAutorizado.id
    );
  };

  render() {
    const { permissions } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Configurações"
          subTitle="Valores de atendimento na garantia "
          canBack={true}
        />
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <div className="flex-column">
            <CardSelectPosto
              onChangeSelect={this.onChangeSelect}
              entity={this.state.entity}
              onSelectPosto={this.handleSelectPosto}
            >
              <div className="conteudoCard d-flex flex-column flex-sm-column">
                <div className="cardErroCpf cardSeculus col-sm-12 d-flex flex-column align-items-center pr-3  mb-0">
                  <div className="justify-content-center">
                    <p className="p-erro mb-0 text-center">
                      <b>Selecione um posto autorizado. </b>
                      <br /> para maior detalhes dos valores de atendimento.
                    </p>
                  </div>
                </div>
              </div>
            </CardSelectPosto>
          </div>
          <div className="flex-column">
            <CardListaPosto filter={this.state.entity} />
          </div>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}
export default connect(
  mapStateToProps,
  null
)(withRouter(ConfiguracoesValoresGarantia));
