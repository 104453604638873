//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import type { Options } from './defaultService';

const taskType = {
  ENVIO_FOTO_FACHADA: { url: '/home', field: 'faixada', withParam: false },
  ENVIO_CONTRATO_SOCIAL: {
    url: '/home',
    field: 'contratoSocial',
    withParam: false
  },
  ENVIO_FOTO_INTERNA: { url: '/home', field: 'fotoInterna', withParam: false },
  APROVAR_POSTO_AUTORIZADO: { url: '/postoAutorizado/', withParam: true },
  TROCAR_MODELO_RELOGIO: { url: '/ordemServico/task/', withParam: true },
  TROCAR_ESCOLHA_MODELO_POSTO_AUTORIZADO: {
    url: '/ordemServico/task/',
    withParam: true
  },
  TROCAR_ENVIAR_MODELO_TROCA_MANAUS: {
    url: '/trocaModelo/view/',
    withParam: true
  },
  PECAS_ENVIADAS_ORDEM_SERVICO: {
    url: '/ordemServico/task/',
    withParam: true
  },
  PECAS_NAO_ENVIADAS_ORDEM_SERVICO: {
    url: '/ordemServico/task/',
    withParam: true
  },
  PECAS_REJEITADAS_ORDEM_SERVICO: {
    url: '/ordemServico/task/',
    withParam: true
  },
  TROCA_NAO_AUTORIZADA: {
    url: '/ordemServico/task/',
    withParam: true
  },
  TROCA_REVERTIDA: {
    url: '/ordemServico/task/',
    withParam: true
  }
};

export const taskService = {
  getTaskType,
  findLastTask,
  getWsUrl,
  getWsTopic,
  findLastTaskFilter
};

function getTaskType(taskName: string) {
  return taskType[taskName];
}

function findLastTask(user) {
  return findLastTaskFilter(user, null, { pageSize: 5, page: 1 });
}

function findLastTaskFilter(user, filter, options) {
  let url = '';
  if (user.extension) {
    url = urlsConstants.TASK_FIND_LAST + user.role.id + '/' + user.extension;
  } else {
    url = urlsConstants.TASK_FIND_LAST + user.role.name;
  }
  return defaultService.findByFiltro(url, options, filter);
}

function getWsTopic(user) {
  if (user.extension) {
    return '/task/' + user.role + '/' + user.extension;
  } else {
    return '/task/' + user.role.name;
  }
}

function getWsUrl() {
  return urlsConstants.WS_WEBSOCKET;
}
