//@flow
import * as React from 'react';
import { Pie } from 'react-chartjs-2';
import { urlsConstants } from '../../../constants/url.constant';
import { ordemServicoService } from '../../../services/ordemServico.service';
import Datatable from '../../../components/table/Datatable';
import { TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';
import { tableHelper } from '../../../helpers/tableHelper';
import { relatorioVolumePorStatusService } from '../../../services/relatorioVolumePorStatus.service';
import { defaultService } from '../../../services/defaultService';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';

let download = require('downloadjs');
const columns = [
  {
    path: 'numeroOS',
    title: 'Número da OS',
    isKey: true,
    dataSort: true,
    className: 'colunaDestaque'
  },
  {
    path: 'relogio.codigoRelogio',
    title: 'Código do relógio',
    isKey: false,
    dataSort: true
  },
  {
    path: 'relogio.descricao',
    title: 'Produto',
    isKey: false,
    dataSort: true
  },
  {
    path: 'cliente',
    title: 'Cliente',
    isKey: false,
    dataSort: true
  },
  {
    path: 'dataFim',
    title: 'Data de Reparo',
    isDate: true,
    isKey: false,
    dataSort: true
  },
  {
    path: 'garantia',
    title: 'Garantia',
    yesNo: true,
    dataSort: true
  },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase'
  }
];
class RelatorioVolumesPorStatusChart extends React.PureComponent<{}> {
  onClickLegendItem = index => {
    const filter = _.cloneDeep(this.state.filter);
    console.log(index)
    _.set(filter, 'index', index);
    if (this.refs.dataTable) {
      this.refs.dataTable.getWrappedInstance().resetTable(filter);
    }
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    defaultService
      .exportToExcel('relatorio/volumePorStatus', filter, fields)
      .then(response => {
        download(
          response.data,
          'relatorio/volumePorStatus' + '.csv',
          'text/html'
        );
      });
    this.setState({ filter });
  };

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.entity) {
      this.setState({ filter: nextProps.entity });
    }
  }
  constructor(props) {
    super(props);
    this.state = { filter: {} };
  }

  handleRowClick = (row: e) => {
    this.props.loading(true);
    let urlOS = urlsConstants.ORDEM_SERVICO + row.id;
    ordemServicoService.doGet(urlOS).then(response => {
      this.props.loading(false);
      let os = response.data.data;
      this.props.setOrdemServico(os);
      this.props.setCliente(os.cliente);
      this.props.history.push('/ordemServico/view/' + row.id);
    });
  };

  render() {
    let dataTotal = null;
    let dataDetalhado = null;
    let dataTotalReparadoFinalizado = null;
    if (this.props.data) {
      dataTotal = {
        labels: ['Garantia', 'Fora Garantia'],
        datasets: [
          {
            data: [
              this.props.data.totalGarantia,
              this.props.data.totalForaGarantia
            ],
            backgroundColor: ['blue', 'red'],
            hoverBackgroundColor: ['blue', 'red']
          }
        ]
      };

      dataTotalReparadoFinalizado = {
        labels: ['Reparadas', 'Finalizada'],
        datasets: [
          {
            data: [
              this.props.data.totalReparada,
              this.props.data.totalFinalizada
            ],
            backgroundColor: ['green', 'blue'],
            hoverBackgroundColor: ['green', 'blue']
          }
        ]
      };

      dataDetalhado = {
        labels: [
          'Garantia',
          'Pronto Atendimento',
          'Fora da Garantia',
          'PA fora da garantia'
        ],
        datasets: [
          {
            data: [
              this.props.data.ordemServicoGarantia,
              this.props.data.prontoAtendimentoGarantia,
              this.props.data.ordemServicoForaGarantia,
              this.props.data.prontoAtendimentoForaGarantia
            ],
            backgroundColor: ['blue', 'red', 'yellow', 'green'],
            hoverBackgroundColor: ['blue', 'red', 'yellow', 'green']
          }
        ]
      };
    }
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <div className={'col-12 col-sm-4 d-inline-block action'}>
          {dataTotal && (
            <Pie
              data={dataTotal}
              options={{
                responsive: true,
                legend: {
                  display: true,
                  position: 'left',
                  onClick: (e, legendItem) => {
                    console.log(e)
                    this.onClickLegendItem(legendItem.index);
                  },
                  onHover: this.setClass,
                  labels: {
                    usePointStyle: true
                  }
                },
                tooltips: {
                  enabled: true
                }
              }}
            />
          )}
        </div>
        <div className={'col-12 col-sm-4 d-inline-block action'}>
          {dataDetalhado && (
            <Pie
              data={dataDetalhado}
              options={{
                responsive: true,
                legend: {
                  display: true,
                  position: 'left',
                  onClick: (e, legendItem) => {
                    this.onClickLegendItem(legendItem.index + 2);
                  },
                  onHover: this.setClass,
                  labels: {
                    usePointStyle: true
                  }
                },
                tooltips: {
                  enabled: true
                }
              }}
            />
          )}
        </div>
        <div className={'col-12 col-sm-4 d-inline-block action'}>
          {dataTotalReparadoFinalizado && (
            <Pie
              data={dataTotalReparadoFinalizado}
              options={{
                responsive: true,
                legend: {
                  display: true,
                  position: 'left',
                  onClick: (e, legendItem) => {
                    console.log(123)
                    this.onClickLegendItem(legendItem.index + 6);
                  },
                  onHover: this.setClass,
                  labels: {
                    usePointStyle: true
                  }
                },
                tooltips: {
                  enabled: true
                }
              }}
            />
          )}
        </div>
        {this.state.filter && this.state.filter.index != null && (
          <React.Fragment>
            <div className="row separator mt-5" />
            <Datatable
              defaultOrder={this.props.defaultOrder}
              defaultDirection={this.props.defaultDirection}
              service={relatorioVolumePorStatusService}
              filter={this.state.filter}
              onRowClick={this.handleRowClick}
              submitFilter={this.state.submitFilter}
              ref="dataTable"
              onDataUpdate={this.handleRefreshData}
            >
              {tableColumn}
            </Datatable>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico },
  load: { loading }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load })
});
export default connect(
  null,
  mapDispatch
)(withRouter(RelatorioVolumesPorStatusChart));
