//@flow
import 'moment/locale/pt-br';
import moment from 'moment';

export function validateInput(value?: string, input: inputType) {
  let valid = true;
  if (input.required) {
    valid = valid && validateRequired(value);
  }

  if (input.type === 'email') {
    valid = valid && validateEmail(value);
  }

  if (input.type === 'date') {
    valid = valid && validateDate(value);
  }

  if (input.type === 'cnpj') {
    valid = valid && validateCnpj(value);
  }

  if (input.type === 'cpf') {
    valid = valid && validateCpf(value);
  }

  return valid;
}

function validateEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );
}

export function validateRequired(value?: string) {
  return !(value === undefined || value === null || value.length === 0);
}

function validateDate(value?: string) {
  return moment(value).isValid();
}

function validateCnpj(value?: string) {
  if (value.length === 18) {
    return true;
  }
  return false;
}

function validateCpf(value?: string) {
  if (value.length === 14) {
    return true;
  }
  return false;
}
