//@flow
import * as React from 'react';
import '../../css/doc_manaus.css';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import {docService} from '../../services/doc.service';
import {connect} from 'react-redux';
import _ from 'lodash';
import {ConfirmAcationService} from '../../services/ConfirmAcationService';
import {objectsConstants} from '../../constants/objects.constants';
import HeaderList from '../../components/templates/HeaderList';
import {defaultService} from '../../services/defaultService';
import * as Yup from 'yup';
import Toast from '../../components/Toasts/Toast';
import {osAguardandoImportacaoService} from "../../services/osAguardandoImportacao.service";
import OsAguardandoImportacaoDetailsItem from "./OsAguardandoImportacaoDetailsItem";

type Props = { onChangeText: any, entity: any, onChangeValue: any };
const validateTrca = Yup.object().shape({
  codigoPecaTroca: Yup.string().required('Peça é obrigatório'),
  quantidadeAdptada: Yup.string().required('Quantidade é obrigatório')
});
class OsAguardandoImportacaoView extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      docDetail: {},
      entity: {},
      canConcluir: false,
      indexPecaSelect: null,
      idPeca: null,
      pecas: [],
      modal: false,
      modalDetalhesPeca: false,
      fields: [
        'marca.nome',
        'statusOS',
        'numeroOS',
        'codigoRelogio',
        'codigoPeca',
        'codigoPecaGenerica',
        'qtdSolicitada'
      ],
      labels: [
        'Marca',
        'Status',
        'Número OS',
        'Relogio',
        'Código da peça ',
        'Código da peça generica',
        'Quantidade'
      ]
    };
  }

  toggle = idPeca => {
    const { pecas } = this.state;
    var indexPeca = null;
    pecas.find(function(peca, index) {
      if (peca.idPeca == idPeca) {
        indexPeca = index;
      }
    });
    if (indexPeca == null) {
      indexPeca = _.cloneDeep(pecas.length);
    }

    this.setState({
      indexPecaSelect: indexPeca,
      idPeca: idPeca,
      modal: !this.state.modal
    });
  };

  componentDidMount() {
    this.reloadDoc();
  }

  reloadDoc = () => {
    let marca = this.props.match.params.marca;
    let status = this.props.match.params.status;
    this.FindOsInDoc(marca,status);
  };

  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newEntity = _.cloneDeep(this.state.entity);
    _.set(newEntity, name, value);
    this.setState({ entity: newEntity });
  };
  handleSelectChange = (name: any, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity });
  };

  handleSetPeca = (newItem: any) => {
    const { pecas } = this.state;
    let newEntity = _.cloneDeep(pecas);
    var existenteItem = pecas.find(function(peca) {
      return peca.idPeca === newItem.idPeca;
    });
    //update object in array se já exitir
    if (existenteItem) {
      newEntity = _.map(pecas, function(item) {
        return item.idPeca === newItem.idPeca
          ? {
              idPeca: item.idPeca,
              quantidadeEnviada: newItem.quantidadeEnviada,
              quantidadeSolicitada: item.quantidadeSolicitada,
              codigoPecaTroca: newItem.codigoPecaTroca,
              fotoPecaTroca: newItem.fotoPecaTroca,
              quantidadeAdptada: newItem.quantidadeAdptada,
              naoAtendida: newItem.naoAtendida
            }
          : item;
      });
    } else {
      newEntity.push(newItem);
    }
    var haPecas = true;

    if (
      (newItem.quantidadeEnviada === '' ||
        newItem.quantidadeEnviada === undefined) &&
      !newItem.naoAtendida
    ) {
      haPecas = false;
    }

    this.setState({
      pecas: newEntity,
      canConcluir: haPecas,
      changePeca: true
    });
  };
  canEnviarDoc = () => {
    let canConcluir = true;

    console.log(this.state.pecas);

    this.state.pecas.map(pecaItem => {
      if (
        parseInt(pecaItem.quantidadeEnviada) >= 1 &&
        !this.state.entity.tipoTransporte
      ) {
        canConcluir = false;
      }
    });
    return canConcluir;
  };
  handleConcluirDOC = () => {
      let atenderDocVO = _.cloneDeep(this.state.entity);
      atenderDocVO.pecas = this.state.pecas;
      atenderDocVO.idDoc = this.state.docDetail.id;

      this.props.loading(true);
      osAguardandoImportacaoService.savePedido(this.props.match.params.marca).then(
        result => {
          this.FindOsInDoc(this.props.match.params.marca,objectsConstants.SOLICITAR_INVOICE);
          this.props.success({
            message: 'Invoice solicitado com sucesso.'
          });
          this.props.loading(false);
          //HARDCODE porque não estava limpando memoria
         // _.unset(atenderDocVO, 'codigoRastreio');
         /* atenderDocVO = {};
          this.setState({ entity: atenderDocVO, canConcluir: false });*/
        },
        erro => {
          console.log('Error:', erro);
          this.props.error(objectsConstants.messageError);
          this.props.loading(false);
        }
      );

  };

  disableConcluir = () => {
    if (this.state.canConcluir) {
      return false;
    } else {
      return true;
    }
  };

  FindOsInDoc = (marca,status) => {
    this.props.loading(true);
    osAguardandoImportacaoService.getOs(marca,status).then(
      result => {
        this.setState({ docDetail: result.data.data });
        this.props.loading(false);
      },
      () => {
        this.props.loading(false);
      }
    );
  };
  cancelarTroca = () => {
    this.setState({
      modal: false,
      modalDetalhesPeca: false
    });
  };
  toggleDetalhesPeca = () => {
    this.setState({
      modal: !this.state.modal,
      modalDetalhesPeca: !this.state.modalDetalhesPeca
    });
  };

  TitleComponent = () => {
    const { docDetail } = this.state;
    return (
      <h3 className="flex-grow-1">
        {'Importação de peças'}
        <span
          /*className={
          }*/
        >
        {/*  {docDetail.statusDoc.description}*/}
        </span>
      </h3>
    );
  };

  changeStatus = idPeca => {
    const { docDetail } = this.state;
    let newEntity = _.cloneDeep(docDetail);

    newEntity.listOrdemServico.find(function(registroTrabalho, indexRt) {
      registroTrabalho.listPecaOrdemServico.find(function(peca, indexPeca) {
        if (peca.id === idPeca) {
          peca.docDetailsView = {
            status: { description: 'Substituição em Andamento' }
          };
        }
      });
    });
    this.setState({ docDetail: newEntity });
  };

  render() {
    const { docDetail } = this.state;
    return (
      <React.Fragment>
        <Toast />
        {docDetail.postoAutorizado && (
          <div className="card cardSeculus cardFull d-flex flex-column">
            <TopoTitleComponente
              mainTitle="Importação de Peças"
              subTitle={docDetail.status.description}
              canBack={true}
            />
            <HeaderList
              TitleComponente={this.TitleComponent}
              showNewButton={false}
              filter={{ marca: {id:this.props.match.params.marca},statusOS:this.props.match.params.status }}
              controller={'osAguardandoImportacao'}
              fields={this.state.fields}
              labels={this.state.labels}

            />
            <div className="conteudoCard">
              <div className="row">
                {/*<div className="col-6 col-lg-3">
                  <h2 className="marca">
                    <b>{docDetail.postoAutorizado.nomeFantasia}</b>
                  </h2>
                </div>
                <div className="col-6 col-lg-4">
                  <div>
                    {docDetail.postoAutorizado.endereco.rua},{' '}
                    {docDetail.postoAutorizado.endereco.numero}
                    {docDetail.postoAutorizado.endereco.complemento
                      ? ',' + docDetail.postoAutorizado.endereco.complemento
                      : ''}{' '}
                    - {docDetail.postoAutorizado.endereco.bairro}
                  </div>
                  <div>
                    {docDetail.postoAutorizado.cidade.nome}-
                    {docDetail.postoAutorizado.estado.nome}
                  </div>
                  <div>CEP {docDetail.postoAutorizado.endereco.cep}</div>
                </div>*/}
                <div className="form-row col-12 col-lg-5">
                 {/* <FormSelectInput
                    type="text"
                    name="tipoTransporte"
                    label="Tipo transporte"
                    placeholder=""
                    size={4}
                    style={['col-4 col-lg-4']}
                    options={this.state.tipoTransporte}
                    returnFullObject={true}
                    searchable={true}
                    id={'tipoTransporte'}
                    ref={input => {
                      this._sintoma = input;
                    }}
                    onChange={this.handleSelectChange}
                    value={this.state.entity.tipoTransporte}
                    service={tipoTransporteService.findAll}
                    required={true}
                    disabled={!this.state.canConcluir}
                  />
                  <div className="col-4 col-lg-4">
                    <label className="d-none d-sm-inline">
                      {' '}
                      Código de rastreio
                    </label>
                    <label className="d-inline d-sm-none"> Cód. rastreio</label>
                    <FormInput
                      type="text"
                      name="codigoRastreio"
                      placeholder="Cód. Rastreio"
                      required={true}
                      size={12}
                      id={'codigoRastreio'}
                      onChange={this._handleChangeText}
                      value={
                        this.state.entity.codigoRastreio
                          ? this.state.entity.codigoRastreio
                          : ''
                      }
                      disabled={!this.state.canConcluir}
                      noSize={true}
                    />
                  </div>*/}
                  {docDetail.status.name!==objectsConstants.SOLICITAR_INVOICE&&(
                  <div className="col-4 col-lg-4 form-group">
                    <button
                      type="button"
                      className="btn btn-primary mt-lbl"
                      /*disabled={this.disableConcluir()}*/
                      onClick={() =>
                        ConfirmAcationService.confirmMsg(
                          this.handleConcluirDOC,
                          'Solicitar Invoice',
                          'Deseja solicitar Invoice?',
                          ''
                        )
                      }
                    >
                      Solicitar Invoice
                    </button>
                  </div>
                  )}
                </div>
              </div>

              <div className="react-bs-table-container">
                {docDetail.listOrdemServico.map((ordemServico, index) => (
                  <OsAguardandoImportacaoDetailsItem
                    onTrocaPeca={this.toggle}
                    key={ordemServico.id}
                    entity={ordemServico}
                    onSetPeca={this.handleSetPeca}
                    reloadDoc={this.reloadDoc}
                  />
                ))}
              </div>
            </div>
          </div>


        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ alert: { error, success }, load: { loading } }) => ({
  error: (message, code) => error({ message, code }),
  success: message => success(message),
  loading: (load: boolean) => loading({ load })
});

export default connect(null, mapDispatch, null, { withRef: true })(OsAguardandoImportacaoView);
