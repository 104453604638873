//@flow
import * as React from 'react';
import FormInput from '../../components/Inputs/FormInput';
import SelectInput from '../../components/Inputs/SelectInput';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import { marcaService } from '../../services/marca.service';
import moment from 'moment/moment';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import EstadoInput from '../../components/Inputs/EstadoInput';
import CidadeInput from '../../components/Inputs/CidadeInput';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { relogioService } from '../../services/relogio.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class OrdemServicoConsultaFilter extends React.Component<Props> {
  render() {
    const { entity, erros } = this.props;
    return (
      <React.Fragment>
        <CpfCnpjInput
          label="CPF/CNPJ"
          placeholder={'CPF/CNPJ'}
          id={'documento'}
          name="documento"
          value={entity.documento}
          onChange={this.props.onChangeText}
          type={'text'}
          style={'col-12 col-sm-3'}
          erroMensagem={erros && erros.documento}
        />
        <FormInput
          label="Número OS"
          placeholder={'Número OS'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          erroMensagem={erros && erros.id}
          value={entity.id}
        />

        <EstadoInput
          name="estado"
          value={entity.estado}
          style={'col-2 col-sm-1'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          label="UF"
        />
        <CidadeInput
          name="cidade"
          id="cidade"
          value={entity.cidade}
          style={'col-4 col-sm-2'}
          handleSelectChange={this.props.onChangeSelect}
          parentValue={entity.estado}
          erroMensagem={erros && erros.cidade}
        />
      </React.Fragment>
    );
  }
}
