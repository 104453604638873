//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

type Props = {
  toPage: string
};

class RedirectCrud extends PureComponent<Props> {
  handleRedirect = () => {
    const { toPage, filter } = this.props;
    if (filter) {
      this.props.history.push({
        pathname: toPage,
        state: {
          filter
        }
      });
    } else {
      this.props.history.push(toPage);
    }
  };
  render() {
    return (
      <div className="rodapeCard action" onClick={this.handleRedirect}>
        <i className="icon-acessar float-right" />
      </div>
    );
  }
}

export default connect()(withRouter(RedirectCrud));
