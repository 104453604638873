//@flow

export const importacaoHelper = {
  getListStatusImportacao,
};

function getListStatusImportacao() {
  return [
    { id: 'AGUARDANDO', nome: 'Aguardando processamento' },
    { id: 'FALHA', nome: 'Falha' },
    { id: 'SUCESSO', nome: 'Sucesso' },
    { id: 'PARCIAL', nome: 'Parcial' },
    { id: 'TODOS', nome: 'Todos' },
  ];
}
