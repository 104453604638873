//exemplo de chamada do modal
// type State = {
//   isModalVisible: boolean
// };
// this.state = {
//   isModalVisible: false
// };
// <NovaOsModal
//   isModalVisible={this.state.isModalVisible}
//   togglemodal={this._togglemodal}
// />;
// _togglemodal = () => {
//   this.setState({ isModalVisible: !this.state.isModalVisible });
// };
import React, { PureComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import BtnPrimary from '../Buttons/BtnPrimary';
import CnpjInput from '../../components/Inputs/CnpjInput';
import FormInput from '../../components/Inputs/FormInput';
import { FormRow } from '../../components/FormRow';
import iconX_os from '../../img/iconX_os.svg';
import icon_config from '../../img/icon_config.svg';
import { clienteService } from '../../services/cliente.service';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import { withRouter } from 'react-router';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col
} from 'reactstrap';

type State = {
  isModalVisible: any
};

const NewClienteInit = Yup.object().shape({
  documentoModal: Yup.string()
    .min(14, 'CPF ou CNPJ inválido!')
    .max(18, 'CPF ou CNPJ inválido!')
    .required('CPF ou CNPJ é obrigatório')
});

class NovaOsModal extends PureComponent<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ isModalVisible: nextProps.isModalVisible });
  }

  toggleModal = () => {
    this.props.toggleModal();
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    const { entity } = this.state;

    return (
      <Modal isOpen={this.state.isModalVisible} toggle={this.toggleModal}>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column">
                <h3>
                  <span className="txtGreen"> nova os </span>- cliente
                </h3>
                <Formik
                  initialValues={{ documentoModal: '' }}
                  validationSchema={NewClienteInit}
                  onSubmit={(values, actions) => {
                    clienteService
                      .getByDocumento(values.documentoModal.replace(/\D/g, ''))
                      .then(
                        cliente => {

                          this.props.clearOS();
                          this.props.setCliente(cliente.data.data);
                          if(cliente.data.data.possuiOS){
                            this.props.history.push(`/ordemServico/novo/${true}`);
                          }else {
                            this.props.history.push('/ordemServico/inicio');
                          }
                        },
                        error => {
                          if (error.response.status === 404) {
                            console.log(values.documentoModal)
                            this.props.history.push('/cliente/novo', {
                              entity: { documento: values.documentoModal }
                            });
                          } else {
                            actions.setSubmitting(false);
                          }
                        }
                      )
                      .catch(() => {
                        actions.setSubmitting(false);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="conteudo">
                        <p>
                          Identifique se seu cliente já possuí o cadastro no
                          sistema. Se ainda não estiver cadastrado, é preciso
                          cadastrá-lo para abrir uma nova OS.
                        </p>
                        <div className="form-group">
                          <label>CPF ou CNPJ</label>
                          <CpfCnpjInput
                            type="text"
                            className={`form-control ${errors.documentoModal &&
                              touched.documentoModal &&
                              'error'}`}
                            id={'documentoModal'}
                            name="documentoModal"
                            size={100}
                            placeholder="Digite o CPF ou CNPJ"
                            value={values.documentoModal}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mt-auto"
                        disabled={isSubmitting}
                      >
                        identificar cliente
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </ModalBody>

        {/* <ModalBody>
          <Container>
            <Row onClick={this.onItemClick}>
              <Col xs="6" style={{ borderRight: '2px solid black' }}>
                <div style={{ marginBottom: 15, clear: 'both' }}>
                  <h3 style={{ color: '#85CD63', float: 'left' }}>
                    NOVA OS&ensp;{' '}
                  </h3>
                  <h3 style={{ color: '#AAAAAA', float: 'righ' }}>
                    -&ensp;PEÇAS AVULSAS
                  </h3>
                </div>
                <div style={{ marginBottom: 30 }}>
                  <p>
                    Para criar uma OS solicitando peças avulsas, clique no botão
                    abaixo
                  </p>
                </div>
                <Row style={{ marginBottom: 30 }}>
                  <Col sm="12" md={{ size: 8, offset: 3 }}>
                    <img src={iconX_os} alt="logo" />
                    <img
                      style={{ verticalAlign: 'bottom', marginLeft: 5 }}
                      src={icon_config}
                      alt="logo"
                      width={30}
                      height={30}
                    />
                    <img
                      style={{ verticalAlign: 'bottom', marginLeft: 5 }}
                      src={icon_config}
                      alt="logo"
                      width={15}
                      height={15}
                    />
                  </Col>
                </Row>
                <BtnPrimary
                  type={'button'}
                  className={'justify-content-start voltar my-4 mx-2'}
                  title={'SOLICITAR PEÇAS AVULSAS'}
                  onSubmit={this.props.onBack}
                />
              </Col>
              <Col xs="6" style={{ textAlign: 'left' }}>
                <div style={{ marginBottom: 15, clear: 'both' }}>
                  <h3 style={{ color: '#85CD63', float: 'left' }}>
                    NOVA OS&ensp;{' '}
                  </h3>
                  <h3 style={{ color: '#AAAAAA', float: 'righ' }}>
                    -&ensp;CLIENTE
                  </h3>
                </div>
                <p>
                  Identifique se seu cliente já possuí o cadastro no sistema. Se
                  ainda não estiver cadastrado, é preciso cadastrá-lo para abrir
                  uma nova OS
                </p>
                <div style={{ marginBottom: 30 }}>
                  <FormRow>
                    <FormInput
                      type="text"
                      name="razaoSocial"
                      label="CPF ou CNPJ"
                      placeholder="Digite o CPF ou CNPJ"
                      required={true}
                      style={['col-lg-12']}
                      id={'razaoSocial'}
                      ref={input => {
                        this._razaoSocial = input;
                      }}
                      onChange={this.handleChangebranchVO}
                      value={entity}
                    />
                  </FormRow>
                </div>
                <BtnPrimary
                  type={'button'}
                  title={'IDENTIFICAR CLIENTE'}
                  onSubmit={this.props.onBack}
                  className="mt-5"
                />
              </Col>
            </Row>
          </Container>
        </ModalBody> */}
      </Modal>
    );
  }
}

const mapDispatch = ({  ordemServico: { setCliente, clearOS}}) => ({
  setCliente: cliente => setCliente({ cliente }),
  clearOS: () => clearOS({})
});

export default connect(
  null,
  mapDispatch
)(withRouter(NovaOsModal));
