//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ConfirmAcationService } from '../../../services/ConfirmAcationService';
import { objectsConstants } from '../../../constants/objects.constants';
import { Can } from '@casl/react';

type Props = {
  onConfirm: any,
  onCancel: any
};

class OsReparadaBtn extends PureComponent<Props> {
  render() {
    const { permissions } = this.props;
    return (
      <React.Fragment>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_REPARAR_OS}
          ability={permissions}
        >
          <div className="d-flex flex-row-reverse bd-highlight btn-bold">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                ConfirmAcationService.confirmMsg(
                  this.props.onConfirm,
                  'Confirmar reparo',
                  'Confirma o reparo da OS?',
                  'Ao clicar em confirmar a OS será reparada.'
                );
              }}
            >
              REPARAR RELÓGIO
            </button>
          </div>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(mapStateToProps)(OsReparadaBtn);
