//@flow
import * as React from 'react';
import FormInput from '../../components/Inputs/FormInput';
import SelectInput from '../../components/Inputs/SelectInput';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import { marcaService } from '../../services/marca.service';
import moment from 'moment/moment';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { relogioService } from '../../services/relogio.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class OrdemServicoListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Número OS"
          placeholder={'Número OS'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.id}
        />
        <SelectInput
          type="text"
          name="marca"
          label="Marca"
          placeholder="Marca"
          style={['col-6 col-sm-2']}
          onFetchData={marcaService.find}
          valueKey="id"
          labelKey="nome"
          returnFullObject={true}
          searchable={true}
          //messageValidate={validate.portadorDeficiencia}
          id={'marca'}
          onChange={this.props.onChangeSelect}
          value={entity.marca}
        />
        <SelectInput
          type="text"
          id={'relogioInput'}
          name={'relogio'}
          label={`Código do relógio*`}
          placeholder="Código do relógio"
          onFetchData={relogioService.find}
          valueKey="codigoRelogio"
          labelKey="codigoRelogio"
          returnFullObject={true}
          searchable={true}
          onChange={this.props.onChangeSelect}
          value={entity.relogio}
          style={['col-6 col-sm-2']}
          parent={entity.marca}
        />
        <FormInput
          label="Cliente"
          placeholder={'Nome do cliente'}
          id={'cliente'}
          name="cliente"
          onChange={this.props.onChangeText}
          type={'text'}
          size={12}
          style={'col-sm-3'}
          value={entity.cliente}
        />
        <CpfCnpjInput
          label="CPF/CNPJ"
          placeholder={'CPF/CNPJ'}
          id={'documento'}
          name="documento"
          value={entity.documento}
          onChange={this.props.onChangeText}
          type={'text'}
          style={'col-12 col-sm-3'}
        />
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataInicio"
          label="Data de entrada do relogio. De"
          value={entity.dataInicio ? moment(entity.dataInicio) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-2'}
          id={'dataInicio'}
        />
        <DatePickerInput
          name="dataFim"
          label="Data de entrada do relogio.Até"
          placeholder={'--/--/--'}
          value={entity.dataFim ? moment(entity.dataFim) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-2'}
          id={'dataFim'}
        />

        <StatusSelectInput
          label="Status"
          placeholder={'Status'}
          id={'statusOS'}
          name="statusOS"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-3'}
          value={entity.statusOS}
          service={ordemServicoHelper.getListStatus}
        />
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataUltimoStatusInicio"
          label="Data do ultimo status. De"
          value={
            entity.dataUltimoStatusInicio
              ? moment(entity.dataUltimoStatusInicio)
              : null
          }
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-2'}
          id={'dataUltimoStatusInicio'}
        />
        <DatePickerInput
          name="dataUltimoStatusFim"
          label="Data do ultimo status. Até"
          placeholder={'--/--/--'}
          value={
            entity.dataUltimoStatusFim
              ? moment(entity.dataUltimoStatusFim)
              : null
          }
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-2'}
          id={'dataUltimoStatusFim'}
        />
        <SelectInput
          label="Garantia"
          id={'garantia'}
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
          returnFullObject={true}
          valueKey={'value'}
          name={'filtroGarantia'}
          noSize={true}
          value={entity.filtroGarantia}
          onChange={this.props.onChangeSelect}
          size={12}
          style={'col-sm-3'}
        />
      </React.Fragment>
    );
  }
}
