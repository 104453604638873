import * as React from 'react';
import { financeiroService } from '../../services/financeiroService';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class FinanceiroDetalheList extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = { details: {} };
  }

  componentDidMount() {
    if (this.props.loadOnInit === true) {
      this.findDetails(this.props);
    }
  }

  componentWillReceiveProps(nextProps: any) {
    console.log(nextProps);
    if (nextProps.submitFilter) {
      this.findDetails(nextProps);
    }
  }

  findDetails = nextProps => {
    this.props.loading(true);
    financeiroService.findDetailsFinanceiroByFilter(nextProps.filter).then(
      response => {
        const details = response.data;
        console.log(details);
        this.setState({ details }, () => {
          this.props.loading(false);
        });
      },
      () => {
        this.props.loading(false);
        this.setState({ details: {} });
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="col-12 mb-3" />
        <div className="col-12 col-md-9 offset-md-3 col-xl-8 offset-xl-4 d-inline-flex pt-3 pt-sm-0 pt-3">
          <div className="col flex-fill text-center">
            <div className="miniTxt">Quantidade de OS(Relógios Nacionais)</div>
            <p className={'destaqueFinanceiro'}>
              {this.state.details.qtdGarantiaNacional}
            </p>
          </div>
          <div className="sinal">x</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Valor por reparo (Relógio Nacional)</div>
            <p className={'destaqueFinanceiro'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(
                  this.state.details.valorGarantiaNacional /
                    this.state.details.qtdGarantiaNacional
                ),
                2,
                ',',
                '.'
              )}
            </p>
          </div>
          <div className="sinal">=</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">
              Receita relógios nacionais na garantia
            </div>
            <p className={'destaqueFinanceiro'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(this.state.details.valorGarantiaNacional),
                2,
                ',',
                '.'
              )}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-9 offset-md-3 col-xl-8 offset-xl-4 d-inline-flex pt-3 pt-sm-0">
          <div className="col flex-fill text-center">
            <div className="miniTxt">
              Quantidade de OS(Relógios Internacionais)
            </div>
            <p className={'destaqueFinanceiro'}>
              {this.state.details.qtdGarantiaInternacional}
            </p>
          </div>
          <div className="sinal">x</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">
              Valor por reparo (Relógio Internacional)
            </div>
            <p className={'destaqueFinanceiro'}>
              {this.state.details.valorGarantiaInternacional &&
                ordemServicoHelper.formatMoney(
                  parseFloat(
                    this.state.details.valorGarantiaInternacional /
                      this.state.details.qtdGarantiaInternacional
                  ),
                  2,
                  ',',
                  '.'
                )}
            </p>
          </div>
          <div className="sinal">=</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">
              Receita relógios internacionais na garantia
            </div>
            <p className={'destaqueFinanceiro'}>
              {this.state.details.valorGarantiaInternacional &&
                ordemServicoHelper.formatMoney(
                  parseFloat(this.state.details.valorGarantiaInternacional),
                  2,
                  ',',
                  '.'
                )}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-9 offset-md-3 col-xl-8 offset-xl-4 d-inline-flex pt-3 pt-sm-0">
          <div className="col flex-fill text-center">
            <div className="miniTxt">Quantidade de OS</div>
            <p className={'destaqueFinanceiro'}>
              {this.state.details.qtdGarantiaInternacional &&
                this.state.details.qtdGarantiaInternacional +
                  this.state.details.qtdGarantiaNacional}
            </p>
          </div>
          <div className="sinal">x</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Valor por reparo </div>
            <p className={'destaqueFinanceiro'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(
                  (this.state.details.valorGarantiaInternacional +
                    this.state.details.valorGarantiaNacional) /
                    (this.state.details.qtdGarantiaInternacional +
                      this.state.details.qtdGarantiaNacional)
                ),
                2,
                ',',
                '.'
              )}
            </p>
          </div>
          <div className="sinal">=</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Receita relógios na garantia</div>
            <p className={'destaqueFinanceiro'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(
                  this.state.details.valorGarantiaInternacional +
                    this.state.details.valorGarantiaNacional
                ),
                2,
                ',',
                '.'
              )}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-9 offset-md-3 col-xl-8 offset-xl-4">
          <hr />
        </div>

        <div className="col-12 col-md-9 offset-md-3 col-xl-8 offset-xl-4 d-inline-flex pt-3 pt-sm-0">
          <div className="col flex-fill text-center">
            <div className="miniTxt">Receita relógios na garantia</div>
            <p className={'destaqueFinanceiro'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(
                  this.state.details.valorGarantiaInternacional +
                    this.state.details.valorGarantiaNacional
                ),
                2,
                ',',
                '.'
              )}
            </p>
          </div>
          <div className="sinal">+</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Receitas com peças e serviços</div>
            <p className={'destaqueFinanceiro'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(this.state.details.valorForaGarantia),
                2,
                ',',
                '.'
              )}
            </p>
          </div>
          <div className="sinal">=</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Receita total</div>
            <p className={'destaqueFinanceiro'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(
                  this.state.details.valorGarantiaInternacional +
                    this.state.details.valorGarantiaNacional +
                    this.state.details.valorForaGarantia
                ),
                2,
                ',',
                '.'
              )}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});
export default connect(null, mapDispatch)(withRouter(FinanceiroDetalheList));
