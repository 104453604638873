import React, { Component } from "react";
import "../../css/CadastroGarantia.css";
import _ from 'lodash'
import Pessoal from '../../img/icon_pessoal.svg'
import Nota from '../../img/icon_nota.svg'
import Finalizado from '../../img/icon_finalizado.svg'
import Check from '../../img/icon_check.svg'
import Foto from '../../img/icon_camera.svg'


export default class StepProgress extends Component {
  constructor(props) {
    super(props);
    this.state = { stepProgress: {name:('Dados Pessoais' , 'Nota Fiscal', 'Finalizando'), posicao:('1' , '2' , '3')}}
  }


  handleChangeInput = (name, value) => {
    let novaPosicao = _.cloneDeep(this.state.stepProgress)
    novaPosicao[name] = value
    this.setState({ stepProgress: novaPosicao })

  }


  render() {
    return (
        <form action="row">

          <div className="step col-12 imagen-fluid">
            <ul className={"step-progress"}>
              <li  className={`slidepage ${this.props.currentStep===0?'check-current':''} ${this.props.currentStep>0?'check-pass':''}`}>
                <img src={Pessoal} alt={''} /><br/>
                <img src={Check} alt={''} className={`check ${this.props.currentStep===0?'check-current':''} ${this.props.currentStep>0?'check-pass':''}`} />
                <p>Dados Pessoal</p>
              </li>
              <li className={`${this.props.currentStep===1?'check-current':''} ${this.props.currentStep>1?'check-pass':''}`}>
                <img src={Nota} alt={''} /><br/>
                <img src={Check} alt={''} className={`check ${this.props.currentStep===1?'check-current':''} ${this.props.currentStep>1?'check-pass':''}`}/>
                <p>Dados da Nota</p>
              </li>
              <li  className={`${this.props.currentStep===2?'check-current':''} ${this.props.currentStep>2?'check-pass':''}`}>
                <img src={Foto} alt={''}  /><br/>
                <img src={Check} alt={''} className={`check ${this.props.currentStep===2?'check-current':''} ${this.props.currentStep>2?'check-pass':''}`} />
                <p>Imagem</p>
              </li>
              <li  className={`${this.props.currentStep===3?'check-pass':''} `}>
                <img src={Finalizado} alt={''}  /><br/>
                <img src={Check} alt={''} className={`check ${this.props.currentStep===3?'check-pass':''} `} />
                <p>Finalizado</p>
              </li>
            </ul>

          </div>



        </form>
    );
  }
}

