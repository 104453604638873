//@flow
import {Formik} from 'formik';
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';

import Toast from '../../components/Toasts/Toast';

import {objectsConstants} from '../../constants/objects.constants';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import SelectInput from "../../components/Inputs/SelectInput";
import FormInput from "../../components/Inputs/FormInput";
import {GrupoFilialService} from "../../services/grupoFilial.service";
import {clienteService} from "../../services/cliente.service";

// type Props = { titlePage: any };
type State = { initialValues: any };

const NewGrupoFilial = Yup.object().shape({
  filialDocumento: Yup.string().required(objectsConstants.CAMPO_OBRIGATORIO),
  tempoGarantia: Yup.string().required(objectsConstants.CAMPO_OBRIGATORIO),

});

class GrupoFilialCrudEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
      },
      grupoFilial: {}
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.grupoFilial) {
      let grupoFilial = this.props.location.state.grupoFilial;

      this.setState({initialValues: grupoFilial});
    }

  }

  showError = messageError => {
    this.props.error({message: messageError});
    return '';
  };

  clearError = () => {
    this.props.clear();
    return '';
  };

  render() {
    const {grupoFilial} = this.state;
    let titlePage;
    if (this.props.location.state) {
      titlePage = this.props.location.state.titlePage;
    }

    return (
      <Formik
        validationSchema={NewGrupoFilial}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={this.state.initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.props.loading(true);

          GrupoFilialService
            .doSave(values)
            .then(response => {
              actions.setSubmitting(false);
              this.props.success({
                message: 'grupo salvo com sucesso.'
              });
              this.props.loading(false);
              this.props.history.push('/grupofilial');
            })
            .catch(() => {
              actions.setSubmitting(false);
              this.props.loading(false);
            });
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="flex-column">
              <Toast />
              <TopoTitleComponente
                mainTitle={this.props.location.state.titlePage}
                subTitle={
                  values.id != undefined
                    ? 'Editar dados'
                    : 'Novo grupo'
                }
                canBack={true}
              />
              {!_.isEmpty(errors) &&
              this.showError(objectsConstants.MESSAGECAMPOOBRIGATORIO)}
              {values.id && this.clearError()}
              <div className="cardSeculus d-flex flex-column mb-0">
                <div className="topoCard d-flex flex-column flex-sm-row">
                  <h3 className="flex-grow-1">Grupo Filial</h3>
                </div>


                <div className="form-group col-12 col-sm-12">
                  <label>Clientes do grupo:</label>
                  <SelectInput
                    id={`clienteLista`}
                    onFetchData={clienteService.find}
                    valueKey="id"
                    labelKey = "nomeDocumento"
                    multi= {true}
                    name={`clienteLista`}
                    placeholder="Selecione os clientes do grupo"
                    value={_.get(values, `clienteLista`)}
                    returnFullObject = {true}
                    onChange={(name, value) => {
                        setFieldValue(name, value)
                      }
                    }
                  />
                </div>

                <div className="form-group col-6 col-sm-6">
                  <label>Documento Grupo Filial*</label>
                  <FormInput
                    id="filialDocumento"
                    type="text"
                    name="filialDocumento"
                    placeholder="Digite o documento do grupo"
                    value={values.filialDocumento}
                    onChange={handleChange}
                    className={` ${errors.nome &&
                    touched.nome &&
                    'error'}`}
                    erroMensagem={errors.nome}
                  />
                </div>

                <div className="form-group col-6 col-sm-6">
                  <label>Tempo Garantia*</label>
                  <FormInput
                    id="tempoGarantia"
                    name="tempoGarantia"
                    placeholder="Digite o tempo de garantia"
                    value={values.tempoGarantia}
                    onChange={handleChange}
                    rows="4"
                    required={true}
                    className={` ${errors.registroAns &&
                    touched.registroAns &&
                    'error'}`}
                    erroMensagem={errors.registroAns}
                  />
                </div>


                <div className="d-flex flex-row flex-sm-row ">
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="button"
                      className="btn btn-secondary btn30 white"
                      disabled={isSubmitting}
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>

                <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                  <button
                    type="submit"
                    className="btn btn-secondary btn30 green"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </button>
                </div>

              </div>

            </div>


          </form>
        )}
      </Formik>
    );
  }
}

const mapDispatch = ({
                       alert: {success, error, clear},
                       load: {loading}
                     }) => ({
  loading: (load: boolean) => loading({load}),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(null, mapDispatch)(GrupoFilialCrudEdit);
