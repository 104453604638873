import React, { PureComponent } from 'react';
import { BodyWhite } from '../../components/templates/BodyWhite';
import Toast from '../../components/Toasts/Toast';
import { connect } from 'react-redux';

class SectionLoginBase extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    let login = { email: '' };
    this.state = login;
  }

  componentWillMount() {
    this.props.clear();
    localStorage.removeItem('token');

    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px'
    });
  }

  render() {
    return (
      <React.Fragment>
        <BodyWhite />
        <section id="sectionLogin">
          <Toast />
          <div className="container-fluid">
            <div className="row">
              {/* <!-- logo --> */}
              {/* <div className="col-4 d-flex justify-content-center">
                <div className="index_logoSeculus" />
              </div> */}
              {/* <!-- border --> */}
              {/* <div className="col colBorder">
                <div className="index_border" />
              </div> */}
              {/* <!-- container form --> */}
              <div
                className="col d-flex flex-column align-items-center"
                id="indexLogin"
                style={{ height: this.state.height }}
              >
                {this.props.children}

                {/* <!-- bt_cadastro --> */}
                <div className="col index_colRight_02" />
                <h5 className="mt-auto rodape">
                  <img
                    className="iconRodape"
                    src={require('../../img/icon.svg')}
                  />
                  © Seculus da Aamazônia
                </h5>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { error, clear },
  authentication: { login },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  loading: (load: boolean) => loading({ load }),
  login: (user, password) => login({ user, password })
});
export default connect(
  null,
  mapDispatch
)(SectionLoginBase);
