import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CardItem } from '../../components/templates/CardItem';
import Toast from '../../components/Toasts/Toast';
import PostoAutorizadoFormView from '../postoAutorizado/PostoAutorizadoFormView';
import RegisterFifthStep from './RegisterFifthStep';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
type State = {
  entity: any
};
class RegisterFinalize extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: {},
      step: 5
    };
  }

  handleEntity = () => {};
  render() {
    const { step } = this.state;
    const { entity } = this.props;
    return (
      <React.Fragment>
        <div className="flex-column">
          <Toast />
          <RegisterFifthStep entity={entity} step={step} />
        </div>
        <div className="flex-column">
          <CardItem height={'a2'} width={''}>
            <PostoAutorizadoFormView entity={entity} />
          </CardItem>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, company } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    user,
    alert,
    messages,
    entity: company
  };
}

const mapDispatch = ({ authentication: { loadCompany } }) => ({
  loadCompany: company => loadCompany(company)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(RegisterFinalize);
