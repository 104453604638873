//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import '../../css/boxOS_Selecionada.css';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import type { ClienteType } from '../../types/cliente.type';
import { propertyConstantes } from '../../constants/property.constantes';

type Props = { cliente: ClienteType, showNewOS: boolean };

const defautOptions = {
  pageSize: 10,
  page: 0,
  order: 'farol',
  direction: 'desc'
};
class OrdemServicoItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    let options = defautOptions;
  }

  getFarol = (ordemServico: any) => {
    let classFarol = ordemServicoHelper.getFarol(ordemServico);
    return <div className={`farol ${classFarol}`} />;
  };

  getRelogioName = itemOS => {
    if (
      itemOS.osType === propertyConstantes.PA ||
      itemOS.osType === propertyConstantes.OS ||
      itemOS.osType === propertyConstantes.RE
    ) {
      return itemOS.marca;
    } else {
      //is compra peca
      if (itemOS.relogios.length) {
        return itemOS.relogios.length + 1 + ' Relógios diferentes';
      }
      return itemOS.relogio;
    }
  };

  render() {
    let _self = this;
    const { itemOS } = this.props;
    return (
      <div
        className={`d-flex flex-row itemListaOS align-items-center action  flex-wrap ${
          _self.props.ordemServico && _self.props.ordemServico.id === itemOS.id
            ? 'osSelecionada'
            : ''
        }`}
        onClick={() => _self.props.onSelectOS(itemOS.id)}
      >
        <div className="col-6 tipo pt-2">{itemOS.numOs}</div>
        <div className="col-6 text-right data pt-2">
          {/* se for ordems serviço selecionada pega o status dele, se algum componente mudar status vai esta atualizado                     */}
          {_self.props.ordemServico && _self.props.ordemServico.id === itemOS.id
            ? _self.props.ordemServico.statusOS.description
            : itemOS.statusOS.description}
        </div>
        <div className="col-12 pb-2 d-flex flex-column item">
          {this.getRelogioName(itemOS)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { ordemServico } = state.ordemServico;
  const { user, permissions } = state.authentication;
  return {
    ordemServico,
    user,
    permissions
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, createOrdemServico },
  alert: { error },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  createOrdemServico: osType => createOrdemServico(osType),
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load })
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(OrdemServicoItem));
