//@flow
import * as React from 'react';
import { NewButton } from '../Buttons/NewButton';
import ExportExcelButton from '../Buttons/ExportExcelButton';
import { defaultService } from '../../services/defaultService';
import { Can } from '@casl/react';
import { objectsConstants } from '../../constants/objects.constants';
import { connect } from 'react-redux';
let download = require('downloadjs');

type Props = {
  title: ?string,
  labelNewButton: ?string,
  showNewButton: boolean,
  actionClick: Function | string
};

class HeaderList extends React.Component<Props> {
  handleClick = () => {
    this.props.loading(true);
    console.log();
    defaultService
      .exportToExcel(
        this.props.controller,
        this.props.filter,
        this.props.fields,
        this.props.labels
      )
      .then(
        response => {
          download(
            response.data,
            (this.props.downloadExcelName
              ? this.props.downloadExcelName
              : this.props.controller) + '.csv',
            'text/html'
          );
          this.props.loading(false);
        },
        () => {
          this.props.loading(false);
          this.props.error('Não foi possível exportar arquivo.');
        }
      );
  };
  getBtn = () => {
    const { permissions } = this.props;
    if (this.props.showNewButton && this.props.roleNewButton) {
      return (
        <Can
          I={objectsConstants.VIEW}
          a={this.props.roleNewButton}
          ability={permissions}
        >
          <NewButton
            handleClick={this.props.actionClick}
            label={this.props.labelNewButton}
          />
        </Can>
      );
    } else if (this.props.showNewButton) {
      return (
        <NewButton
          handleClick={this.props.actionClick}
          label={this.props.labelNewButton}
        />
      );
    }
  };
  render() {
    const { TitleComponente, hideExport } = this.props;
    return (
      <div className="topoCard d-flex flex-column flex-sm-row">
        {!TitleComponente && (
          <h3 className="flex-grow-1">{this.props.title}</h3>
        )}
        {TitleComponente && <TitleComponente />}
        <div className="boxButon float-right d-sm-flex">
          {!hideExport && <ExportExcelButton onClick={this.handleClick} />}
          {this.getBtn()}
        </div>
      </div>
    );
  }
}

const mapDispatch = ({ alert: { error }, load: { loading } }) => ({
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load })
});

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  return {
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(HeaderList);
