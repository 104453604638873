//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrdemServicoEdit from './../OrdemServicoEdit';
import OrdemServicoListAdmin from './OrdemServicoListAdmin';

export default class OrdemServicoAdminManaus extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/ordemServicoAdmin/view/:id"
            component={OrdemServicoEdit}
          />
          <Route
            path="/ordemServicoAdmin"
            exact
            component={OrdemServicoListAdmin}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
