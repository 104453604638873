import * as React from 'react';
import avatarDefault from '../../img/avatarDefault.jpg';
import './ImageInput.css';
import { connect } from 'react-redux';
import avatar from '../../img/avatarDefault.jpg';

export class ImageProfile extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dataUrl: props.dataUrl ? props.dataUrl : avatar,
      name: props.name
    };
  }
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.dataUrl) {
      this.setState({ dataUrl: nextProps.dataUrl });
    } else {
      this.setState({
        dataUrl: avatarDefault
      });
    }
  }
  render() {
    const { dataUrl } = this.state;
    return (
      <React.Fragment>
        <div className="avatarContainer mb-2">
          <img
            src={dataUrl}
            alt="avatar"
            className={`img-fluid rounded-circle m-auto ${
              this.props.className
            }`}
            width={this.props.width}
            height={this.props.height}
          />
          {/* <h5 className="ml-2 bold">{this.props.name}</h5> */}
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(ImageProfile);
