//@flow
import { defaultService } from './defaultService';
import { urlsConstants } from '../constants/url.constant';

export const GrupoFilialService = {
  findByFiltro,
  getById,
  doSave,
  findAll
};

function getById(id:number) {
  return defaultService.doGet( `${urlsConstants.GRUPO_FILIAL}${id}`)
}

function doSave(value) {
  console.log("Inside do save")
  return defaultService.doSave(`${urlsConstants.GRUPO_FILIAL}`, value);
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.GRUPO_FILIAL}findAll`);
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.GRUPO_FILIAL}list`,
    options,
    filter
  );
}
