//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import CidadeInput from '../../components/Inputs/CidadeInput';
import EstadoInput from '../../components/Inputs/EstadoInput';
import PaisInput from '../../components/Inputs/PaisInput';
import PhoneInput from '../../components/Inputs/PhoneInput';
import TipoTelefoneInput from '../../components/Inputs/TipoTelefoneInput';
import ZipCodeInput from '../../components/Inputs/ZipCodeInput';
import { clienteService } from '../../services/cliente.service';
import type { ClienteType } from '../../types/cliente.type';
import { defaultService } from '../../services/defaultService';
import Toast from '../../components/Toasts/Toast';
import { objectsConstants } from '../../constants/objects.constants';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import { enderecoService } from '../../services/endereco.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { formatterHelper } from '../../helpers/formatter.helper';
type Props = {};
type State = { initialValues: ClienteType };

const NewCliente = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório'),

  endereco: Yup.object().shape({
    cep: Yup.string().required('CEP é obrigatório'),
    rua: Yup.string().required('Rua é obrigatório'),
    numero: Yup.string().required('Número é obrigatório'),
    bairro: Yup.string().required('Bairro é obrigatório'),
    cidade: Yup.string().required('Cidade é obrigatório'),
    estado: Yup.string().required('Estado é obrigatório'),
    pais: Yup.string().required('Pais é obrigatório')
  }),
  telefone: Yup.string().test(
    'min-telefone',
    'Telefone é obrigatório',
    function(value) {
      const { parent, createError } = this;
      if (
        parent.telefones[0].numero === '' ||
        parent.telefones[0].numero === undefined
      ) {
        return createError();
      } else {
        return true;
      }
    }
  )
});

class ClienteEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let documento = '';
    if (!this.props.match.params.id) {
      documento = formatterHelper.cpfCnpjFormatter(
        this.props.location.state.entity.documento
      );
    }
    this.state = {
      initialValues: {
        documento,
        nome: '',
        email: '',
        endereco: {
          cep: '',
          rua: '',
          numero: '',
          complemento: '',
          bairro: '',
          cidade: null,
          estado: null,
          pais: null
        },
        telefones: [{ numero: '', tipo: 'CELULAR' }]
      }
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      clienteService.getById(this.props.match.params.id).then(cliente => {
        let data = cliente.data.data;
        data.endereco.estado = data.endereco.cidade.estado;
        if(data.endereco.cidade.estado) {
          data.endereco.pais = data.endereco.cidade.estado.pais;
        }
        if (!data.telefones.length) {
          data.telefones = [{ numero: '', tipo: 'CELULAR' }];
        }
        this.setState({ initialValues: data });
        this.props.loading(false);
      });
    } else {
      this.showInfo(
        'Não encontramos nenhum cadastro com esse CPF.Por favor, termine de inserir os campos abaixo para cadastrar este novo cliente.'
      );
    }
  }

  handleZipCode = (location, zipCode, entity, setFieldValue) => {
    console.log(location);
    if (location.erro) {
      _.set(entity, 'cep', zipCode);
      setFieldValue('endereco', entity);
      if (!location.notComplete) {
        this.showInfo('CEP não encontrado.');
      }
      if (location.timeout) {
        this.showInfo(
          'Não foi possivel conectar no servidor de CEP, Preencha os dados manualmente.'
        );
      }
      this.setState({ entity });
    } else {
      this.props.loading(true);
      _.set(entity, 'cep', zipCode);
      _.set(entity, 'pais', objectsConstants.PAIS_DEFAULT);
      enderecoService
        .findEstadoExact(location.uf, objectsConstants.PAIS_DEFAULT)
        .then(estado => {
          _.set(entity, 'estado', estado.data);
          enderecoService
            .findCidadeExact(location.localidade, estado.data)
            .then(cidade => {
              _.set(entity, 'cidade', cidade.data);
              _.set(entity, 'bairro', location.bairro);
              _.set(entity, 'rua', location.logradouro);
              setFieldValue('endereco', entity);
              this.props.loading(false);
            });
        });
    }
  };
  addTelefone = (setFieldValue, telefones) => {
    let newArray = _.concat(telefones, { numero: '', tipo: 'CELULAR' });
    setFieldValue('telefones', newArray);
  };

  removeItem = (setFieldValue, telefones, index) => {
    telefones.splice(index, 1);
    setFieldValue('telefones', telefones);
  };

  showError = messageError => {
    this.props.error({ message: messageError });
    return '';
  };

  showInfo = messageInfo => {
    this.props.info({ message: messageInfo });
    return '';
  };

  clearError = () => {
    this.props.clear();
    return '';
  };

  render() {
    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Cliente" subTitle=" " canBack={true} />
        <Formik
          validationSchema={NewCliente}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={this.state.initialValues}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            let insertable = values.id === null || values.id === undefined;
            let clientEntity = values;
            _.unset(clientEntity, 'type');
            clienteService
              .save(clientEntity)
              .then(response => {
                let id = defaultService.getIdFromUrl(response.headers.location);
                values.id = id;
                this.props.setCliente(values);
                if (insertable) {
                  this.props.history.push('/ordemServico/inicio');
                } else {
                  this.props.history.push('/cliente/view/' + id);
                }
                actions.setSubmitting(false);
                this.clearError();
                this.props.success({ message: 'Cliente salvo com sucesso.' });
              })
              .catch(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="flex-column">
                <Toast />

                {!_.isEmpty(errors) &&
                  this.showError(objectsConstants.messageCampoObrigatorio)}

                {values.id && this.clearError()}
                <div className="cardSeculus d-flex flex-column mb-0">
                  <div className="topoCard d-flex flex-column flex-sm-row">
                    <h3 className="flex-grow-1">cliente</h3>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-12 col-lg-12">
                      <label>Nome*</label>
                      <input
                        type="text"
                        className={`form-control ${errors.nome &&
                          touched.nome &&
                          'error'}`}
                        name="nome"
                        placeholder="Digite o nome completo ou nome fantásia"
                        value={values.nome}
                        onChange={e => {
                          const { name, value } = e.currentTarget;
                          if (value) {
                            setFieldValue(name, value.toProperCase());
                          } else {
                            setFieldValue(name, value);
                          }
                        }}
                      />
                    </div>

                    <div className="form-group col-12 col-lg-12">
                      <h5 className="title">CPF ou CNPJ:</h5>
                      <p role="button">
                        {!values.id && <b>{values.documento}</b>}
                        {values.id && (
                          <CpfCnpjInput
                            placeholder="Digite o CPF ou CNPJ"
                            erroMensagem={errors.documento}
                            id={'documento'}
                            name="documento"
                            value={values.documento}
                            onChange={handleChange}
                            noSize={true}
                          />
                        )}
                      </p>
                    </div>

                    <div className="form-group col-12 col-lg-12 ">
                      <label>E-mail </label>
                      <input
                        type="text"
                        className={`form-control ${errors.email &&
                          touched.email &&
                          'error'}`}
                        name="email"
                        placeholder="Digite o e-mail"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group col-6 col-lg-4 ">
                      <label>CEP*</label>
                      <ZipCodeInput
                        type="number"
                        id={'cep'}
                        className={`${errors.endereco &&
                          errors.endereco.cep &&
                          touched.endereco &&
                          touched.endereco.cep &&
                          'error'}`}
                        name="endereco.cep"
                        size={100}
                        placeholder="Somente números"
                        value={values.endereco.cep}
                        onChange={(location, zipCode) => {
                          this.handleZipCode(
                            location,
                            zipCode,
                            values.endereco,
                            setFieldValue
                          );
                        }}
                      />
                    </div>

                    <PaisInput
                      handleSelectChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      name="endereco.pais"
                      value={values.endereco.pais}
                      style={'col-6 col-lg-4'}
                      className={`${errors.endereco &&
                        errors.endereco.pais &&
                        touched.endereco &&
                        touched.endereco.pais &&
                        'error'}`}
                      required={true}
                    />

                    <EstadoInput
                      handleSelectChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      name="endereco.estado"
                      value={values.endereco.estado}
                      style={'col-6 col-lg-4'}
                      parentValue={values.endereco.pais}
                      className={`${errors.endereco &&
                        errors.endereco.estado &&
                        touched.endereco &&
                        touched.endereco.estado &&
                        'error'}`}
                      required={true}
                    />

                    <div className="form-group col-12 col-lg-8 ">
                      <label>Rua*</label>
                      <input
                        type="text"
                        className={`form-control ${errors.endereco &&
                          errors.endereco.rua &&
                          touched.endereco &&
                          touched.endereco.rua &&
                          'error'}`}
                        name="endereco.rua"
                        placeholder="Rua"
                        value={values.endereco.rua}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group col-4 col-lg-4 ">
                      <label>Número*</label>
                      <input
                        type="text"
                        className={`form-control ${errors.endereco &&
                          errors.endereco.numero &&
                          touched.endereco &&
                          touched.endereco.numero &&
                          'error'}`}
                        name="endereco.numero"
                        placeholder="Número"
                        value={values.endereco.numero}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group col-8 col-lg-4 ">
                      <label>Bairro*</label>
                      <input
                        type="text"
                        className={`form-control ${errors.endereco &&
                          errors.endereco.bairro &&
                          touched.endereco &&
                          touched.endereco.bairro &&
                          'error'}`}
                        name="endereco.bairro"
                        placeholder="Bairro"
                        value={values.endereco.bairro}
                        onChange={handleChange}
                      />
                    </div>

                    <CidadeInput
                      handleSelectChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      name="endereco.cidade"
                      value={values.endereco.cidade}
                      style={'col-6 col-lg-4'}
                      parentValue={values.endereco.estado}
                      className={`${errors.endereco &&
                        errors.endereco.cidade &&
                        touched.endereco &&
                        touched.endereco.cidade &&
                        'error'}`}
                      required={true}
                    />

                    <div className="form-group col-6 col-lg-4 ">
                      <label>Complemento</label>
                      <input
                        type="text"
                        className="form-control"
                        name="endereco.complemento"
                        placeholder="Complemento"
                        value={values.endereco.complemento}
                        onChange={handleChange}
                      />
                    </div>

                    {values.telefones.map((value, index) => (
                      <React.Fragment key={index}>
                        <TipoTelefoneInput
                          handleChange={handleChange}
                          name={`telefones[${index}].tipo`}
                          value={value.tipo}
                        />

                        <div className="form-group col-5 col-lg-4">
                          <label>Número Telefone *</label>
                          <PhoneInput
                            type="number"
                            id={`Phone${index}`}
                            className="form-control"
                            size={100}
                            name={`telefones[${index}].numero`}
                            placeholder="Somente números"
                            value={value.numero}
                            onChange={handleChange}
                            className={`${errors.telefone && 'error'}`}
                          />
                        </div>

                        <div className="form-group col-3 col-lg-4 d-flex align-items-end justify-content-center">
                          {/* //se não for ultimo pode excluir */}
                          {values.telefones.length - 1 !== index && (
                            <div className="text-right pb-2">
                              {' '}
                              <a
                                href="#"
                                onClick={() => {
                                  this.removeItem(
                                    setFieldValue,
                                    values.telefones,
                                    index
                                  );
                                }}
                              >
                                <img
                                  className="icon"
                                  src={require('../../img/icon_excluir.svg')}
                                />
                                Telefone{' '}
                              </a>
                            </div>
                          )}
                          {values.telefones.length - 1 === index && (
                            <div
                              className="text-right pb-2"
                              onClick={() =>
                                this.addTelefone(
                                  setFieldValue,
                                  values.telefones
                                )
                              }
                            >
                              <span className="icon-badge">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                              </span>{' '}
                              <a>Telefone </a>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>

                  <div className="d-flex flex-row flex-sm-row ">
                    <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                      <button
                        type="button"
                        className="btn btn-secondary btn30 white"
                        disabled={isSubmitting}
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancelar
                      </button>
                    </div>
                    <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                      <button
                        type="submit"
                        className="btn btn-secondary btn30 green"
                        disabled={isSubmitting}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
  ordemServico: { setCliente }
}) => ({
  loading: (load: boolean) => loading({ load }),
  setCliente: cliente => setCliente({ cliente }),
  success: msg => success(msg),
  info: msg => info(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(null, mapDispatch)(ClienteEdit);
