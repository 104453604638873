export const messages = {
  UNEXPECTED_ERROR: 'Erro inesperado, contacte o administrador do sistema',
  USER_NOT_FOUND: 'Usuário não encontrado',
  NOT_FOUND: 'Não encontrado',
  CAST_ERROR: 'Erro ao converter',
  RECEIVE_NOT_FOUND: 'Destinatário não encontrado',
  SENDER_NOT_FOUND: 'Remetente não encontrado',
  EMPTY_REQUIRED_FIELD: 'Campo obrigatório',
  CNPJ_INVALID: 'CNPJ inválido',
  PHONE_INVALID: 'Telefone inválido',
  EMAIL_INVALID: 'E-mail inválido',
  INVALID_REQUEST: 'Requisição inválida',
  CPF_INVALID: 'CPF inválido',
  POSTO_NOT_FOUND: 'Posto Autorizado não encontrado',
  ERROR_CONVERT_FILE: 'Erro ao converter arquivo',
  NOT_PERMITED: 'Acesso não permitido',
  INVALID_CONFIRM_PASSWORD: 'As senhas informadas não correspondem',
  INVALID_CONFIRM_EMAIL: 'Os e-mails informados não correspondem',
  CNPJ_AREADY_REGISTERED: 'CNPJ já cadastrado',
  DOCUMENT_INVALID: 'Documento inválido',
  INVALID_INSCRICAO_ESTADUAL: 'Inscrição estadual inválida',
  CEP_INVALID: 'CEP inválido',
  CPF_AREADY_REGISTERED: 'Cpf já cadastrado',
  ConfirmEqualsConstraint: 'As senhas informadas não correspondem',
  EMAIL_AREADY_REGISTERED: 'E-mail já cadastrado',
  DT_NASCIMENTO_MENOR_18: 'Idade menor que 18 anos',
  REINCIDENCIA_IDENTIFICADA:
    'Foi identificado Reincidência de reparo realizado para este cliente. Aguarde a autorização da Seculus da Amazonia para dar continuidade no processo.',
  SAME_CODE: 'Código de relógio já cadastrado.',
  COMPRA_DE_PECA_MINIMA:
    'O valor total da solicitação de compra de peças deve ser igual ou superior a R$100,00.'
};

export function translate(key) {
  return messages[key];
}
