//@flow
import { Can } from '@casl/react';
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import CardCliente from '../../components/Cards/CardCliente';
import CardClienteOrdemServico from '../../components/Cards/CardClienteOrdemServico';
import CardCriarOs from '../../components/Cards/CardCriarOs';
import { CardPecaComponente } from '../../components/Cards/CardPecaComponente';
import CardReincidencia from '../../components/Cards/CardReincidencia';
import { CardServicosComponente } from '../../components/Cards/CardServicosComponente';
import CardTrocaModeloRelogio from '../../components/Cards/CardTrocaModeloRelogio';
import FotoRelogio from '../../components/FotoRelogio';
import EditableTextField from '../../components/Inputs/EditableTextField';
import FileInput from '../../components/Inputs/FileInput';
import MuliplesImageLoad from '../../components/Inputs/MuliplesImageLoad';
import TecnicoInput from '../../components/Inputs/TecnicoInput';
import Toast from '../../components/Toasts/Toast';
import { objectsConstants } from '../../constants/objects.constants';
import { propertyConstantes } from '../../constants/property.constantes';
import { urlsConstants } from '../../constants/url.constant';
import { dateHelper } from '../../helpers/date.helper';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { ordemServicoService } from '../../services/ordemServico.service';
import BoxOrdemServico from '../boxOrdemServico/BoxOrdemServico';
import ChatOs from '../chat/ChatOs';
import AprovarOrcamentoBtn from '../cliente/Componentes/AprovarOrcamentoBtn';
import ConfirmItensOrcamentoModal from '../cliente/Componentes/ConfirmItensOrcamentoModal';
import ExpirarOSBtn from '../cliente/Componentes/ExpirarOSBtn';
import OsReparadaBtn from '../cliente/Componentes/OsReparadaBtn';
import RelogioEntregueBtn from '../cliente/Componentes/RelogioEntregueBtn';
import RelogioTrocaMaisModeloBtn from '../cliente/Componentes/RelogioTrocaMaisModeloBtn';
import ReopenOSBtn from '../cliente/Componentes/ReopenOSBtn';
import OrdemServicoHistorico from '../ordemServico/OrdemServicoHistorico';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import CancelarOSBtn from './Componentes/CancelarOSBtn';
import CancelarREBtn from './Componentes/CancelarREBtn';
import { EmptyOS } from './Componentes/EmptyOS';
import { PrintOrdemServicoBtn } from './Componentes/PrintOrdemServicoBtn';
import ReprovarTrocaBtn from './Componentes/ReprovarTrocaBtn';
import SolicitarATSPBtn from './Componentes/SolicitarATSPBtn';
import SolicitarHpBtn from './Componentes/SolicitarHpBtn';
import SolicitarTrocaBtn from './Componentes/SolicitarTrocaBtn';
import type { ClienteType } from '../../types/cliente.type';
import {importacaoOrdemCompraClientePjService} from "../../services/importacaoOrdemCompraClientePjService";
import {translate} from "../../helpers/message.helper";
type Props = { cliente: ClienteType, ordemServico: OrdemServicoType };

const ERRO_MSG = 'Não foi possível salvar requisição.';
const ERRO_REQUEST = 'Não foi possível salvar requisição.';

class ClienteView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {},
      pecaOrdemServico: [],
      servicoOrdemServico: [],
      fotosRelgoio: [],
      showReproveModal: false,
      showReopenOSModal: false,
      clearImagens: false,
      totalMensagens: {},
      listOsRelacionada: [],
      submit: false
    };

    if (
      props.ordemServico !== undefined &&
      props.ordemServico.osType === propertyConstantes.RE
    ) {
      this.getListReincidencia(props.ordemServico);
    }
  }
  componentWillReceiveProps(nexProps) {
    if (nexProps.ordemServico && !nexProps.ordemServico.id) {
      this.setState({
        pecaOrdemServico: [],
        servicoOrdemServico: [],
      });
    }

    if (
      nexProps.ordemServico &&
      nexProps.ordemServico.osType === propertyConstantes.RE
    ) {
      this.getListReincidencia(nexProps.ordemServico);
    }
  }

  componentDidUpdate = () => {
    const { ordemServico } = this.props;
    if (
      ordemServico &&
      ordemServico.statusOS &&
      (ordemServico.statusOS.name === propertyConstantes.PECA_NAO_ATENDIDA ||
        ordemServico.statusOS.name === propertyConstantes.CENTRO_TECNICO ||
        ordemServico.statusOS.name ===
          propertyConstantes.AGUARDANDO_RECEBIMENTO)
    ) {
      document.getElementById('manutencao-tab').click();
    }
  };

  handleOsClick = ordemServico => {
    let urlOS = urlsConstants.ORDEM_SERVICO + ordemServico.id;
    this.props.loading(true);
    ordemServicoService.doGet(urlOS).then(
      response => {
        this.props.loading(false);
        let os = response.data.data;
        this.props.setOrdemServico(os);
      },
      () => {
        this.props.loading(false);
      }
    );
  };

  expirarOs = () => {
    const { ordemServico } = this.props;
    ordemServicoService.expirarOS(ordemServico.id).then((response) => {
      console.log(response);
      this.props.setOrdemServico(response.data.data);
      this.props.success({
        message: 'Ordem expirada com sucesso.',
      });
    });
  };

  cancelarOs = () => {
    const { ordemServico } = this.props;
    ordemServicoService.cancelarOs(ordemServico.id).then((response) => {
      console.log(response);
      this.props.setOrdemServico(response.data.data);
      this.props.success({
        message: 'Ordem cancelada com sucesso.',
      });
    });
  };

  cancelarRE = () => {
    const { ordemServico } = this.props;
    ordemServicoService.cancelarRE(ordemServico.id).then(response => {
      console.log(response);
      this.props.setOrdemServico(response.data.data);
      this.props.succes({
        message: 'Reincidencia desfeita com sucesso.'
      });
    });
  };

  reativarOS = () => {
    const { ordemServico } = this.props;
    ordemServicoService.reativarOS(ordemServico.id).then((response) => {
      console.log(response);
      this.props.setOrdemServico(response.data.data);
      this.props.success({
        message: 'Ordem reativada com sucesso.',
      });
    });
  };

  handleReverterTroca = () => {
    const { ordemServico } = this.props;
    ordemServicoService.reverterTroca(ordemServico.id).then((response) => {
      console.log(response);
      this.props.setOrdemServico(response.data.data);
      this.props.success({
        message: 'Troca revertida com sucesso.',
      });
    });
  };

  reprovarTrocaModelo = (observacaoReprovarTroca) => {
    //vai nos servidor salva reprovação de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService
      .reprovarTrocaModelo(ordemServico.id, observacaoReprovarTroca)
      .then(
        (r) => {
          this.props.setOrdemServico(r.data.data);
          this.setState({ showReopenOSModal: false });
          this.props.success({
            message: 'Troca não autorizada com sucesso.',
          });
          this.props.loading(false);
        },
        (error) => {
          this.props.loading(false);
          this.props.error(ERRO_REQUEST);
          console.log(error);
        }
      );
  };

  getValorTotal = (ordemServico) => {
    const {
      listPecaOrdemServico,
      listServicoOrdemServico,
      statusOS,
    } = ordemServico;
    const { pecaOrdemServico, servicoOrdemServico } = this.state;
    let pecas;
    let servicos;
    if (statusOS.name === propertyConstantes.CENTRO_TECNICO) {
      pecas = pecaOrdemServico;
      servicos = servicoOrdemServico;
    } else {
      pecas = _.map(listPecaOrdemServico, function(peca, index) {
        return { posicao: index, peca: peca };
      });
      servicos = _.map(listServicoOrdemServico, function(servico, index) {
        return { posicao: index, servico: servico };
      });
    }
    let totalPecas = 0;
    let totalServicos = 0;
    if (
      statusOS.name === propertyConstantes.EM_CRIACAO || //se os estiver em criação
      ((!servicoOrdemServico || servicoOrdemServico.length === 0) && //não tem serviço no servidor
      (!pecaOrdemServico || pecaOrdemServico.length === 0) && //não tem peça salvar no servidor
      (!listPecaOrdemServico || listPecaOrdemServico.length === 0) && //nem peças adicionadas ao orçamento
        (!listServicoOrdemServico || listServicoOrdemServico.length === 0)) //nme serviços adicionados ao orçamento
    ) {
      return '--,--';
    }
    //valor total da ordem de serço definido pelo soma das peças e serviços que não estão na garantia
    _.map(pecas, function(i) {
      let value = _.get(i, 'peca.valorFinal');
      totalPecas += isNaN(value) ? 0 : value;
    });

    //setar valor dos servicoes
    _.map(servicos, function(i) {
      let value = _.get(i, 'servico.valorFinal');
      totalServicos += isNaN(value) ? 0 : value;
    });
    let totalOrcamento = totalPecas + totalServicos;
    if (
      ordemServico &&
      ordemServico.relogioTrocaOrdemServico &&
      ordemServico.relogioTrocaOrdemServico.valor
    ) {
      return ordemServicoHelper.formatMoney(
        parseFloat(ordemServico.relogioTrocaOrdemServico.valor),
        2,
        ',',
        '.'
      );
    }
    return ordemServicoHelper.formatMoney(
      parseFloat(totalOrcamento),
      2,
      ',',
      '.'
    );
  };

  handleAddPecas = (arrayPecas) => {
    this.setState({ pecaOrdemServico: arrayPecas });
  };

  handleAddServico = (arrayServicos) => {
    this.setState({ servicoOrdemServico: arrayServicos });
  };

  handleChangeImages = (arrayImagens) => {
    this.setState({ fotosRelgoio: arrayImagens, clearImagens: false });
  };

  handleSelectChange = (name: string, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);

    newEntity[name] = option;
    this.setState({ entity: newEntity });
  };

  getImagensFromOS = (listFotoOrdemServico) => {
    if (!listFotoOrdemServico || listFotoOrdemServico.length === 0) {
      return [];
    } else {
      let arrayImagens = _.map(listFotoOrdemServico, function(item) {
        return { id: item.foto.id };
      });
      return arrayImagens;
    }
  };
  validateOrcamento = () => {
    const { pecaOrdemServico, servicoOrdemServico, fotosRelgoio } = this.state;
    const { ordemServico } = this.props;
    console.log(ordemServico, ' OS VALIDATE ORCAMENTO');
    let valid = true;
    _.map(pecaOrdemServico, function(item) {
      const peca = item.peca;
      if (!peca) {
        valid = false;
      }
      if (peca && !peca.peca) {
        valid = false;
      }
      if (peca && !peca.sintoma) {
        valid = false;
      }
      if (ordemServico!=null&&ordemServico.relogio!=null&&ordemServico.relogio.marca!=null&&ordemServico.relogio.marca.tipo===objectsConstants.TIPO_MARCA_IMPORTADA) {

        if((peca['codigoPeca'] === undefined || peca['codigoPeca'] === null)){
          valid = false;
        }

      }
      console.log(peca);
      if (peca && !peca.garantia && peca.garantia != false) {
        console.log(4);
        valid = false;
      }


      if (peca && !peca.solicitarPeca && !peca.solicitarPeca!=false) {
        console.log(5);
        valid = false;
      }
      /* if (ordemServico.relogio.marca.tipo === 'IMPORTADA') {
        if (peca && !peca.codigoPeca) {
          console.log(6);
          valid = false;
        }
      }*/
    });
    _.map(servicoOrdemServico, function(item) {
      const servico = item.servico;
      if (!servico == null) {
        console.log(8);
        valid = false;
      }
      if (servico && !servico.servico) {
        valid = false;
      }

      if (
        servico &&
        servico.servico &&
        servico.servico.tipo &&
        servico.servico.tipo === 'TROCA_BATERIA' &&
        !servico.quantidade
      ) {
        valid = false;
      }
      if (
        servico &&
        servico.servico &&
        servico.servico.tipo &&
        servico.servico.tipo === 'TROCA_BATERIA' &&
        !servico.calibre
      ) {
        valid = false;
      }
    });
    return valid;
  };

  handleOrcamentoFilalize = async () => {
    this.props.loading(true);
    console.log(this.validateOrcamento());
    if (this.validateOrcamento()) {
      let ordemServico;
      if (
        this.props.ordemServico.listReincidencia &&
        this.props.ordemServico.listReincidencia.length > 0
      ) {
        ordemServico = this.props.ordemServico.listReincidencia[0];
      } else {
        ordemServico = this.props.ordemServico;
      }

      const {
        pecaOrdemServico,
        servicoOrdemServico,
        fotosRelgoio,
      } = this.state;

      let postoAutorizadoVO = {
        fotos: fotosRelgoio,
        tecnico: this.state.tecnico,
        pecas: await ordemServicoHelper.getObjectsInArray(
          pecaOrdemServico,
          'peca'
        ),
        servicos: await ordemServicoHelper.getObjectsInArray(
          servicoOrdemServico,
          'servico'
        ),
      };
      ordemServicoService
        .saveOrcamento(ordemServico.id, postoAutorizadoVO)
        .then(
          (r) => {
            this.setState(
              {
                fotosRelgoio: [],
                clearImagens: true,
                refreshOsCliente: true,
                submit: false,
              },
              () => {
                this.props.setOrdemServico(r.data.data);
                if (
                  this.props.success &&
                  r.data.data.listReincidencia !== undefined
                ) {
                  this.props.success({
                    message: 'Orçamento concluído, aguardando aprovação.',
                  });

                  this.props.success({
                    message: 'Atenção: Reincidencia identificada!',
                  });
                } else {
                  this.props.success({
                    message: 'Orçamento concluído, aguardando aprovação.',
                  });
                }
                document.getElementById('relogio-tab').click();
                this.props.loading(false);
              }
            );
          },
          (error) => {
            this.props.loading(false);
            this.props.error('Não foi possível aprovar orçamento.');
            console.log(error);
          }
        );
    } else {
      this.props.loading(false);
      this.setState({ submit: true });
    }
  };

  setOSToEmAvaliacao = (ordemServico) => {
    this.props.loading(true);
    ordemServicoService.reabrirOrcamento(ordemServico.id).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível aprovar orçamento.',
        });
        console.log(error);
      }
    );
  };

  getTopoManutenção = (ordemServico) => {
    console.log(ordemServico);
    return (
      <div className="row topoTab">
        <div className="col-8 d-flex px-1 px-sm-2 px-md-3">
          <div className="flex-column pr-1 containerImg">
            <FotoRelogio
              relogio={ordemServico.relogio ? ordemServico.relogio : null}
              height={'100%'}
              width={'100%'}
            />
          </div>
          <div className="flex-column pl-1">
            <div className="miniTxt">Garantia</div>
            <p
              className={`bold ${ordemServico.garantia ? 'txtBlue' : 'txtRed'}`}
            >
              {ordemServico.garantia ? 'Sim' : 'Não'}
            </p>
            <div className="miniTxt pt-3">Questionamento do cliente</div>
            <p>{ordemServico.questionamentoCliente}</p>
          </div>
        </div>
        <div className="col-4 text-right px-1 px-sm-2 px-md-3">
          <h4>Orçamento</h4>
          <h1>
            <b>{this.getValorTotal(ordemServico)}</b>
          </h1>
          {this.getBotaoConcluir(ordemServico)}
          {this.getStateOrButtonReparada(ordemServico)}
          {this.getTrocaBtn(ordemServico)}
          {this.getHbBtn(ordemServico)}
          {this.getAtSPBtn(ordemServico)}
        </div>
      </div>
    );
  };

  savedOS = () => {
    this.setState({ refreshOsCliente: true });
  };

  getBotaoConcluir = (ordemServico) => {
    const { pecaOrdemServico, servicoOrdemServico } = this.state;
    let canAprovar;
    canAprovar = pecaOrdemServico.length > 0 || servicoOrdemServico.length > 0;

    if (ordemServico.statusOS.name === propertyConstantes.CENTRO_TECNICO) {
      return (
        <button
          type="button"
          disabled={!canAprovar}
          className={`btn ${
            canAprovar ? 'btn-primary' : 'btn-secondary'
          } btn-sm mt-1`}
          data-toggle="modal"
          data-target="#novaOS"
          onClick={this.handleOrcamentoFilalize}
        >
          concluir orçamento
        </button>
      );
    }
  };

  onChangeTecnico = (value) => {
    this.setState({ tecnico: value });
  };

  render() {
    const { cliente, ordemServico, permissions } = this.props;
    const {
      pecaOrdemServico,
      servicoOrdemServico,
      submit,
      listOsRelacionada
    } = this.state;
    const {
      handleAddPecas,
      handleAddServico,
      setOSToEmAvaliacao,
      getTopoManutenção,
    } = this;
    let canAprovar =
      pecaOrdemServico.length > 0 || servicoOrdemServico.length > 0;
    let showNewOs =
      ordemServico &&
      ordemServico.statusOS.name === propertyConstantes.EM_CRIACAO;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Cliente"
          subTitle={cliente.name}
          canBack={true}
        />
        <Toast />
        <div className="flex-column">
          <CardCliente cliente={cliente} />

          <BoxOrdemServico hideReparadas={true} />
          <CardClienteOrdemServico
            cliente={cliente}
            showNewOS={showNewOs}
            refreshOs={this.state.refreshOsCliente}
            showCardNovaOS={this.props.showCardNovaOS}
          />
        </div>
        {ordemServico && (
          <div className="flex-column">
            <div className="card cardSeculus cardTab d-flex flex-column w2">
              <h4 className=" text-center d-inline d-lg-none">
                {ordemServico.osType} {ordemServico.id ? ordemServico.id : '--'}
              </h4>
              <div>
                <ul className="nav" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="relogio-tab"
                      data-toggle="tab"
                      data-target="#relogio"
                      role="tab"
                      aria-controls="relogio"
                      aria-selected="true"
                    >
                      <i className="icon-relogio" />{' '}
                      <span className="d-none d-md-inline">relógio</span>
                    </a>
                  </li>
                  {ordemServico.id && (
                    <React.Fragment>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="manutencao-tab"
                          data-toggle="tab"
                          data-target="#manutencao"
                          role="tab"
                          aria-controls="manutencao-tab"
                          aria-selected="false"
                        >
                          <i className="icon-manutencao" />{' '}
                          <span className="d-none d-md-inline">manutenção</span>
                        </a>
                      </li>
                      {ordemServico.osType === propertyConstantes.OS && (
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="mensagens-tab"
                            data-toggle="tab"
                            data-target="#mensagens"
                            role="tab"
                            aria-controls="mensagens"
                            aria-selected="false"
                          >
                            <i className="icon-mensagem_x" />{' '}
                            <span className="d-none d-md-inline">
                              Mensagens
                            </span>
                          </a>
                          <span className="badge badge-success">
                            {this.props.totalMessages}
                          </span>
                        </li>
                      )}
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="historico-tab"
                          data-toggle="tab"
                          data-target="#historico"
                          role="tab"
                          aria-controls="historico"
                          aria-selected="false"
                        >
                          <i className="icon-historico" />{' '}
                          <span className="d-none d-md-inline">histórico</span>
                        </a>
                      </li>
                    </React.Fragment>
                  )}
                  <li className="nOs float-right d-none d-lg-inline">
                    {ordemServico.numOs
                      ? ordemServico.numOs
                      : ordemServico.osType + '--'}
                  </li>
                </ul>

                <div className="tab-content">
                  {!ordemServico.id && (
                    <CardCriarOs
                      ordemServico={ordemServico}
                      onSaved={this.savedOS}
                    />
                  )}

                  {ordemServico.id && this.viewOS(ordemServico, cliente)}

                  {ordemServico.id && (
                    <React.Fragment>
                      <div
                        className="tab-pane fade "
                        id="manutencao"
                        role="tabpanel "
                        aria-labelledby="manutencao-tab "
                      >
                        {this.getTopoManutenção(ordemServico)}
                        <div className="row conteudoTab d-flex flex-column flex-lg-row ">
                          {ordemServico &&
                            ordemServico.id &&
                            ordemServico.listReincidencia &&
                            ordemServico.listReincidencia.length > 0 && (
                              <h3 className="flex-grow-1">
                                {ordemServico.numOs}
                              </h3>
                            )}
                          <div className={'col-6'}>
                            <TecnicoInput
                              postoAutorizado={ordemServico.postoAutorizado}
                              statusOS={ordemServico.statusOS.name}
                              tecnico={ordemServico.tecnico}
                              onChange={this.onChangeTecnico}
                            />
                          </div>
                          {ordemServico && ordemServico.numDoc && (
                            <div className={'col-6'}>
                              <div className="form-group col-12 col-sm-12">
                                <label className="miniLabel">DOC</label>
                                <Can
                                  I={objectsConstants.VIEW}
                                  a={objectsConstants.ABILITY_DOC}
                                  ability={permissions}
                                >
                                  <p>
                                    <NavLink
                                      className="noDecoration"
                                      to={
                                        '/doc/view/' +
                                        ordemServico.numDoc.replace(
                                          /[^\d]+/g,
                                          ''
                                        )
                                      }
                                    >
                                      {' '}
                                      {ordemServico.numDoc}
                                    </NavLink>
                                  </p>
                                </Can>
                                <Can
                                  I={objectsConstants.VIEW}
                                  a={objectsConstants.ABILITY_DOC}
                                  ability={permissions}
                                  not
                                >
                                  <p>{ordemServico.numDoc}</p>
                                </Can>
                              </div>
                            </div>
                          )}

                          <CardPecaComponente
                            onAddPeca={this.handleAddPecas}
                            listPecaOrdemServico={
                              ordemServico.listPecaOrdemServico
                            }
                            submit={submit}
                            ordemServico={ordemServico}
                            statusOS={ordemServico.statusOS.name}
                            onConfirmChangeStatus={() => {
                              setOSToEmAvaliacao(ordemServico);
                            }}
                            osType={ordemServico.osType}
                          />

                          <CardServicosComponente
                            onAddServico={this.handleAddServico}
                            listServicoOrdemServico={
                              ordemServico.listServicoOrdemServico
                            }
                            submit={submit}
                            ordemServico={ordemServico}
                            statusOS={ordemServico.statusOS.name}
                            onConfirmChangeStatus={() => {
                              setOSToEmAvaliacao(ordemServico);
                            }}
                          />
                          <div className="flex-column col-12 pt-2">
                            <div className="miniTxt mb-1">
                              Foto do relogio do cliente
                            </div>
                            <div className="form-row">
                              <MuliplesImageLoad
                                maxWidth={90}
                                maxHeight={90}
                                onChange={this.handleChangeImages}
                                readOnly={
                                  ordemServico.statusOS.name ===
                                  propertyConstantes.CENTRO_TECNICO
                                    ? false
                                    : true
                                }
                                clearState={this.state.clearImagens}
                                imagens={this.getImagensFromOS(
                                  ordemServico.listFotoOrdemServico
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade "
                        id="mensagens"
                        role="tabpanel "
                        aria-labelledby="mensagens-tab "
                      >
                        <ChatOs os={ordemServico.id} />
                      </div>
                      <div
                        className="tab-pane fade "
                        id="historico"
                        role="tabpanel "
                        aria-labelledby="historico-tab "
                      >
                        <OrdemServicoHistorico id={ordemServico.id} />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {ordemServico === undefined && <EmptyOS />}
      </React.Fragment>
    );
  }

  aproveOrcamento = (_ordemServico) => {
    //vai nos servidor salva orçamento
    this.props.loading(true);
    let ordemServico;
    if (_ordemServico) {
      ordemServico = _ordemServico;
    } else {
      ordemServico = this.props.ordemServico;
    }
    ordemServicoService.aproveOrcamento(ordemServico.id).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.props.success({
          message: 'Orçamento aprovado com sucesso.',
        });
        this.setState({ refreshOsCliente: true });
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        this.props.error(ERRO_MSG);
        console.log(error);
      }
    );
  };

  reproveOrcamento = (itensOrcamentoVO) => {
    //vai nos servidor salva reprovação de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService
      .reproveOrcamento(ordemServico.id, itensOrcamentoVO)
      .then(
        (r) => {
          this.props.setOrdemServico(r.data.data);
          this.setState({ showReproveModal: false, refreshOsCliente: true });
          this.props.success({
            message: 'Orçamento salvo com sucesso.',
          });
          this.props.loading(false);
        },
        (error) => {
          this.props.loading(false);
          this.props.error('Não foi possível salvar requisição.');
          console.log(error);
        }
      );
  };

  reopenOS = (itensOrcamentoVO) => {
    //vai nos servidor salva reprovação de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService.reabrirOs(ordemServico.id, itensOrcamentoVO).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.setState({ showReopenOSModal: false });
        this.props.success({
          message: 'Peças solicitadas.',
        });
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        this.props.error(ERRO_REQUEST);
        console.log(error);
      }
    );
  };

  entregaRelogio = (dtEntrega) => {
    //vai nos servidor salva reprovação de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService.entregaRelogio(ordemServico.id, dtEntrega).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.setState({ showReopenOSModal: false });
        this.props.success({
          message: 'OS finalizada com sucesso.',
        });
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        this.props.error(ERRO_REQUEST);
        console.log(error);
      }
    );
  };

  trocaMaisModelos = () => {
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService.trocaMaisModelos(ordemServico.id).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.setState({ showReopenOSModal: false });
        this.props.success({
          message: 'Status alterado com sucesso.',
        });
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        this.props.error(ERRO_REQUEST);
        console.log(error);
      }
    );
  };

  solicitarTroca = (files) => {
    //vai nos servidor salva reprovação de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService.solicitarTroca(ordemServico.id, files).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.setState({ showReopenOSModal: false });
        this.props.success({
          message: 'Solicitação realizada com sucesso.',
        });
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        this.props.error(ERRO_REQUEST);
        console.log(error);
      }
    );
  };

  solicitarHP = () => {
    //vai nos servidor salva reprovação de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService.solicitarHP(ordemServico.id).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.setState({ showReopenOSModal: false });
        this.props.success({
          message: 'Solicitação realizada com sucesso.',
        });
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        if(error.response.data.messages){
          const messages = error.response.data.messages;
          for(let i=0;i<messages.length ; i++){
            this.props.error(messages[i].message.code);
          }
        }
        this.props.error(ERRO_REQUEST);
        console.log(error);
      }
    );
  };

  solicitarATSP = () => {
    //vai nos servidor salva reprovação de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService.solicitarATSP(ordemServico.id).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.setState({ showReopenOSModal: false });
        this.props.success({
          message: 'Solicitação realizada com sucesso.',
        });
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        this.props.error(ERRO_REQUEST);
        console.log(error);
      }
    );
  };

  OsReparada = () => {
    //vai nos servidor salva os reparada de parte ou todo orçamento
    this.props.loading(true);
    const { ordemServico } = this.props;
    ordemServicoService.OsReparada(ordemServico.id).then(
      (r) => {
        this.props.setOrdemServico(r.data.data);
        this.props.success({
          message: 'Relógio reparado.',
        });
        this.props.loading(false);
      },
      (error) => {
        this.props.loading(false);
        this.props.error(ERRO_REQUEST);
        console.log(error);
      }
    );
  };

  handleModalReprove = () => {
    //show modal itens
    this.setState({ showReproveModal: true });
  };

  togglemodalReprove = () => {
    this.setState({ showReproveModal: !this.state.showReproveModal });
  };

  handleModalReopen = () => {
    //show modal itens para escolher
    this.setState({ showReopenOSModal: true });
  };

  togglemodalReopen = () => {
    this.setState({ showReopenOSModal: !this.state.showReopenOSModal });
  };

  getStateOrButton = (ordemServico) => {
    const { user } = this.props;
    if (user.role === objectsConstants.ROLE_MANAUS) {
      return ordemServico.statusOS.description;
    }
    let orcamentoReprovado =
      ordemServico.statusOS.name === propertyConstantes.ORCAMENTO_REPROVADO
        ? true
        : false;
    let reparada =
      ordemServico.statusOS.name === propertyConstantes.REPARADA ? true : false;

    let trocaAguardandoAprovacao =
      ordemServico.statusOS.name === propertyConstantes.ESCOLHA_DE_MODELO
        ? true
        : false;
    if (trocaAguardandoAprovacao) {
      return <RelogioTrocaMaisModeloBtn onConfirm={this.trocaMaisModelos} />;
    }

    if (orcamentoReprovado || reparada) {
      return (
        <RelogioEntregueBtn
          ordemServico={ordemServico}
          onConfirm={this.entregaRelogio}
        />
      );
    }

    return ordemServico.statusOS.description;
  };

  getStateOrButtonReparada = (ordemServico) => {
    const { user, permissions } = this.props;

    if (permissions.can(objectsConstants.VIEW, objectsConstants.CONSULTA_OS)) {
      return (
        <h4 className={ordemServico.statusOS.className}>
          {ordemServico.statusOS.description}
        </h4>
      );
    }

    if (
      ordemServico.statusOS.name === propertyConstantes.AGUARDANDO_PECAS ||
      ordemServico.statusOS.name ===
        propertyConstantes.AGUARDANDO_RECEBIMENTO ||
      ordemServico.statusOS.name === propertyConstantes.PECAS_NAO_ATENDIDAS ||
      ordemServico.statusOS.name ===
        propertyConstantes.AGUARDANDO_RELOGIO_DE_TROCA ||
      ordemServico.statusOS.name === propertyConstantes.AGUARDANDO_REPARO ||
      ordemServico.statusOS.name === propertyConstantes.TROCA_NAO_AUTORIZADA ||
      ordemServico.statusOS.name === propertyConstantes.TROCA_REVERTIDA ||
      ordemServico.statusOS.name === propertyConstantes.PECA_REJEITADA ||
      ordemServico.statusOS.name === propertyConstantes.AGUARDANDO_IMPORTACAO ||
      ordemServico.statusOS.name === propertyConstantes.TRANSFERIDA
    ) {
      return (
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_REPARAR_OS}
          ability={permissions}
        >
          <OsReparadaBtn onConfirm={this.OsReparada} />
        </Can>
      );
    }

    return (
      <h4 className={ordemServico.statusOS.className}>
        {ordemServico.statusOS.description}
      </h4>
    );
  };

  _onSaveObservacoes = (value) => {
    const { ordemServico } = this.props;

    this.props.loading(true);
    ordemServicoService
      .doSave({ observacoes: value, id: ordemServico.id })
      .then((response) => {
        let urlOS = urlsConstants.ORDEM_SERVICO + ordemServico.id;
        this.props.clear();
        ordemServicoService.doGet(urlOS).then((response) => {
          this.props.success({
            message: 'Ordem alterada com sucesso.',
          });
          if (this.props.onSaved) {
            this.props.onSaved();
          }

          this.props.setOrdemServico(response.data.data);
          this.props.loading(false);
        });
      });
  };

  getTrocaBtn = (ordemServico) => {
    const { permissions } = this.props;
    const { listOsRelacionada } = this.state;
    if (ordemServico.cliente.documento.length > 14 && ordemServico.relogioEstoque ) {
      importacaoOrdemCompraClientePjService.findByClienteAndRelogio(ordemServico.cliente, ordemServico.relogio).then(response => {

        if (response.data) {
          if (
              (ordemServico.osType === propertyConstantes.RE &&
                  listOsRelacionada.length >= 3 &&
                  ordemServico.statusOS.name !== propertyConstantes.TROCA_MODELO) ||
              ordemServico.statusOS.name === propertyConstantes.ATENDIDA_PARCIALMENTE ||
              ordemServico.statusOS.name === propertyConstantes.PECAS_NAO_ATENDIDAS ||
              (ordemServico.statusOS.name ===
                  propertyConstantes.AGUARDANDO_RECEBIMENTO &&
                  ordemServico.naoAtendida)
          ) {
            return (
                <Can
                    I={objectsConstants.VIEW}
                    a={objectsConstants.ABILITY_SOLICITAR_TROCA_MODELO}
                    ability={permissions}
                >
                  <SolicitarTrocaBtn onConfirm={this.solicitarTroca}/>
                </Can>
            );
          }
        }else{
          return "Não é possivel solicitar a troca do modelo. Relogio fora da garantia";
        }


      });
    }else
if (
(ordemServico.osType === propertyConstantes.RE &&
listOsRelacionada.length >= 3 &&
ordemServico.statusOS.name !== propertyConstantes.TROCA_MODELO) ||
ordemServico.statusOS.name === propertyConstantes.ATENDIDA_PARCIALMENTE ||
ordemServico.statusOS.name === propertyConstantes.PECAS_NAO_ATENDIDAS ||
(ordemServico.statusOS.name ===
propertyConstantes.AGUARDANDO_RECEBIMENTO &&
ordemServico.naoAtendida)
) {
return (
<Can
I={objectsConstants.VIEW}
a={objectsConstants.ABILITY_SOLICITAR_TROCA_MODELO}
ability={permissions}
>
<SolicitarTrocaBtn onConfirm={this.solicitarTroca} />
</Can>
);
}
};

getHbBtn = (ordemServico) => {
const { permissions } = this.props;
console.log(ordemServico.statusOS.name === propertyConstantes.REPARADA);
console.log( ordemServico.garantia);
console.log(ordemServico.tipoRecebimento != null);
console.log(ordemServico.tipoRecebimento === 'CORREIO');
console.log(!ordemServico.solicitarCodigoPostagem);
if (
ordemServico.statusOS.name === propertyConstantes.REPARADA &&
ordemServico.garantia &&
ordemServico.tipoRecebimento != null &&
ordemServico.tipoRecebimento === 'CORREIO' &&
!ordemServico.solicitarCodigoPostagem
) {
console.log(123)
return <SolicitarHpBtn onConfirm={this.solicitarHP} />;
}
};

getAtSPBtn = (ordemServico) => {
if (
//ordemServico.statusOS.name === propertyConstantes.REPARADA &&
ordemServico.relogio.marca.tipo === 'IMPORTADA' &&
!ordemServico.solicitarCodigoAtSp
) {
return <SolicitarATSPBtn onConfirm={this.solicitarATSP} />;
}
};

getListReincidencia = ordemServico => {
ordemServicoService.findListReincidencia(ordemServico.id).then(response => {
if (response.data.data) {
this.setState({ listOsRelacionada: response.data.data });
} else {
this.setState({ listOsRelacionada: [] });
}
});
};

viewOS = (ordemServico, cliente) => {
const { _onSaveObservacoes } = this;

const { permissions } = this.props;
const { aproveOrcamento, handleModalReprove, getValorTotal } = this;
const { listOsRelacionada } = this.state;
const _this = this;
let dias =
dateHelper.diff(ordemServico.creationDateTime, ordemServico.dataReparo) +
' dias';
let orcamentoReprovado =
ordemServico.statusOS.name === propertyConstantes.ORCAMENTO_REPROVADO
? true
: false;
return (
<React.Fragment>
<div
className="tab-pane fade show active"
id="relogio"
role="tabpanel"
aria-labelledby="relogio-tab"
>
<TopoTitleComponente
mainTitle="Ordens de Serviço"
subTitle={`${ordemServico.numOs}`}
canBack={true}
/>
{ordemServico && (
<ConfirmItensOrcamentoModal
ordemServico={ordemServico}
isModalVisible={this.state.showReproveModal}
toggleModal={this.togglemodalReprove}
handleErro={this.props.error}
ConfirmSelected={this.reproveOrcamento}
type={'REPROVE'}
/>
)}

{ordemServico && (
<ConfirmItensOrcamentoModal
ordemServico={ordemServico}
isModalVisible={this.state.showReopenOSModal}
toggleModal={this.togglemodalReopen}
handleErro={this.props.error}
ConfirmSelected={this.reopenOS}
type={'REOPEN'}
/>
)}
<div className="row topoTab">
<div className="col-4 px-1 px-sm-2 px-md-3">
{
<>
{
(console.log(ordemServico.statusOS),
ordemServico.statusOS.name !==
propertyConstantes.FINALIZADA &&
ordemServico.statusOS.name !==
propertyConstantes.CANCELADA &&
ordemServico.statusOS.name !==
propertyConstantes.EXPIRADA &&
ordemServico.statusOS.name !==
propertyConstantes.REPARADA && (
<>
 <h4>Prazo de reparo</h4>
 <h1>
   <b>{dias}</b>
 </h1>
</>
))
}
</>
}
<div className="miniLabel">Data de abertura</div>
<p>{dateHelper.format(ordemServico.creationDateTime)}</p>
<React.Fragment>
<div className="miniLabel">Data da entrega do relógio</div>
<p>
{ordemServico.dataEntragaRelogio
? dateHelper.format(ordemServico.dataEntragaRelogio)
: '--/--/----'}
</p>
</React.Fragment>
</div>
<div className="col-4 px-1 px-sm-2 px-md-3 text-center">
<h4 className={`${orcamentoReprovado ? 'txtRed' : ''}`}>
Orçamento {`${orcamentoReprovado ? 'Reprovado' : ''}`}
</h4>
<h1 className={`${orcamentoReprovado ? 'txtRed' : ''}`}>
<b>{this.getValorTotal(ordemServico)}</b>
</h1>
{ordemServico.statusOS.name ===
propertyConstantes.APROVAR_ORCAMENTO && (
<Can
I={objectsConstants.VIEW}
a={objectsConstants.ABILITY_APROVAR_ORCAMENTO_OS}
ability={permissions}
>
<AprovarOrcamentoBtn
getValorOrcamento={this.getValorTotal}
ordemServico={ordemServico}
onAprove={this.aproveOrcamento}
onReprove={this.handleModalReprove}
/>
</Can>
)}

<React.Fragment>
<div className="miniLabel">Previsão entrega</div>
<p>
{ordemServico.previsaoEntrega
? dateHelper.format(ordemServico.previsaoEntrega)
: '--/--/----'}
</p>
</React.Fragment>
</div>
<div className="col-4 text-right px-1 px-sm-2 px-md-3">
<h4>Status</h4>
<h4 className={`sts ${ordemServico.statusOS.className}`}>
{this.getStateOrButton(ordemServico)}
{(ordemServico.statusOS.name ===
propertyConstantes.APROVAR_ORCAMENTO ||
ordemServico.statusOS.name ===
propertyConstantes.TROCA_MODELO_EM_ANDAMENTO) && (
<Can
I={objectsConstants.VIEW}
a={objectsConstants.ABILITY_EXPIRAR_OS}
ability={permissions}
>
<ExpirarOSBtn
onConfirm={this.expirarOs}
ordemServico={ordemServico}
/>
</Can>
)}

<Can
I={objectsConstants.VIEW}
a={objectsConstants.ABILITY_CANCELAR_OS}
ability={permissions}
>
<CancelarOSBtn
onConfirm={this.cancelarOs}
ordemServico={ordemServico}
/>
</Can>

{ordemServico.osType === propertyConstantes.RE && (
<Can
I={objectsConstants.VIEW}
a={objectsConstants.ABILITY_CANCELAR_RE}
ability={permissions}
>
<CancelarREBtn
onConfirm={this.cancelarRE}
ordemServico={ordemServico}
/>
</Can>
)}
{ordemServico.statusOS.name === propertyConstantes.EXPIRADA && (
<Can
I={objectsConstants.VIEW}
a={objectsConstants.ABILITY_REATIVAR_OS}
ability={permissions}
>
<ReopenOSBtn
onConfirm={this.reativarOS}
ordemServico={ordemServico}
/>
</Can>
)}
{ordemServico &&
ordemServico.id &&
(ordemServico.statusOS.name ===
propertyConstantes.ESCOLHA_DE_MODELO ||
ordemServico.statusOS.name ===
propertyConstantes.TROCA_MODELO ||
ordemServico.statusOS.name ===
propertyConstantes.TROCA_MAIS_MODELO ||
ordemServico.relogioTrocaOrdemServico ||
ordemServico.statusOS.name ===
propertyConstantes.TROCA_MODELO_EM_ANDAMENTO) && (
<Can
I={objectsConstants.VIEW}
a={objectsConstants.ABILITY_REPROVAR_TROCA_MODELO}
ability={permissions}
>
<ReprovarTrocaBtn
onConfirm={this.reprovarTrocaModelo}
ordemServico={ordemServico}
/>
</Can>
)}
</h4>

{ordemServico.osType === propertyConstantes.RE &&
listOsRelacionada.length > 0 && (
<>
<div className="miniLabel">
Ordens de Serviço Relacionadas{' '}
</div>
{listOsRelacionada.length > 0 && (
<>
{_.map(listOsRelacionada, function(item, index) {
 return (
   <a
     key={index}
     className="d-flex flex-row-reverse pt-2"
     href="#"
     onClick={() => {
       _this.handleOsClick(item);
     }}
   >
     {item.numOs}
   </a>
 );
})}
</>
)}
</>
)}

{ordemServico.osType === propertyConstantes.OS &&
ordemServico.listReincidencia &&
ordemServico.listReincidencia.length > 0 && (
<>
<div className="miniLabel">Reincidencias Relacionadas </div>

{ordemServico.listReincidencia.length > 0 && (
<>
{_.map(ordemServico.listReincidencia, function(
 item,
 index
) {
 return (
   <a
     key={index}
     className="d-flex flex-row-reverse pt-2"
     href="#"
     onClick={() => {
       _this.handleOsClick(item);
     }}
   >
     {item.numOs}
   </a>
 );
})}
</>
)}
</>
)}
<div className="miniLabel pt-3">Data do Status</div>
<p>
{dateHelper.format(ordemServico.dataStatusAtual, {
pattern: 'DD/MM/YYYY',
})}
</p>

{ordemServico.observacaoReprovarTroca && (
<React.Fragment>
<div className="miniLabel">Observações de troca</div>
<p>{ordemServico.observacaoReprovarTroca}</p>
</React.Fragment>
)}
</div>
</div>

{ordemServico &&
ordemServico.id &&
(ordemServico.statusOS.name ===
propertyConstantes.ESCOLHA_DE_MODELO ||
ordemServico.statusOS.name === propertyConstantes.TROCA_MODELO ||
ordemServico.statusOS.name ===
propertyConstantes.TROCA_MAIS_MODELO ||
ordemServico.relogioTrocaOrdemServico /*||
(ordemServico.statusOS.name === propertyConstantes.EXPIRADA &&
ordemServico.listModeloRelogioTrocaOrdemServico &&
ordemServico.listModeloRelogioTrocaOrdemServico.length > 0 &&
!ordemServico.relogioTrocaOrdemServico)*/) && (
              <CardTrocaModeloRelogio
                ordemServico={ordemServico}
                handleReverterTroca={this.handleReverterTroca}
              />
            )}

          <div className="row conteudoTab d-flex flex-column flex-lg-row ">
            <div className="flex-column col-12 col-md-6">
              <div className="form-row">
                <div className="form-group col-12">
                  <label className="miniLabel">Código do relógio</label>
                  <p>{ordemServico.relogio.codigoRelogio}</p>
                </div>
                <div className="form-group col-12 col-sm-12">
                  <label className="miniLabel">Marca</label>
                  <p>
                    {ordemServico.relogio.marca
                      ? ordemServico.relogio.marca.nome
                      : ''}
                  </p>
                </div>
                <div className="form-group col-12 col-sm-12">
                  <label className="miniLabel">Descrição</label>
                  <p>
                    {ordemServico.relogio.descricao
                      ? ordemServico.relogio.descricao
                      : ''}
                  </p>
                </div>

                <div className="form-group col-12">
                  <label className="miniLabel">Questionamento do cliente</label>
                  <p>{ordemServico.questionamentoCliente}</p>
                </div>
                <div className="form-group col-12">
                  <label className="miniLabel">Observações</label>
                  <EditableTextField
                    name="observacoes"
                    onSave={_onSaveObservacoes}
                    rows={8}
                    value={ordemServico.observacoes}
                    placeholder="Please input your username"
                  />
                </div>
              </div>
            </div>
            <div className="flex-column col-12 col-md-4 offset-md-2 text-right">
              <FotoRelogio
                relogio={
                  // ordemServico.relogio
                  //   ? ordemServico.relogio.codigoRelogio
                  //   : null
                  ordemServico.relogio
                }
              />

              <div className="form-row">
                <div className="form-group col-12">
                  <label className="miniLabel">Relogio estoque</label>
                  <p
                    className={`bold ${
                      ordemServico.relogioEstoque ? 'txtBlue' : 'txtRed'
                    }`}
                  >
                    {ordemServico.relogioEstoque ? 'Sim' : 'Não'}
                  </p>
                </div>{' '}
                {ordemServico.tipoRecebimento && (
                  <div className="form-group col-12">
                    <label className="miniLabel">Tipo de recebimento</label>

                    <p className={`bold`}>
                      {ordemServico.tipoRecebimento === 'CORREIO'
                        ? 'Correio'
                        : 'Recepção'}
                    </p>
                  </div>
                )}
                <div className="form-group col-12 col-sm-5">
                  <label className="miniLabel">Garantia</label>
                  <p
                    className={`bold ${
                      ordemServico.garantia ? 'txtBlue' : 'txtRed'
                    }`}
                  >
                    {ordemServico.garantia ? 'Sim' : 'Não'}
                  </p>
                </div>
                <div className="form-group col-12 col-sm-7">
                  <label className="miniLabel"> </label>
                  <p>
                    {ordemServico.dataNotaFiscal
                      ? dateHelper.format(ordemServico.dataNotaFiscal, {
                          pattern: 'DD/MM/YYYY',
                        })
                      : '--'}
                  </p>
                </div>
                <div className="form-group col-12">
                  <label className="miniLabel">Número da NF</label>
                  <p>
                    {ordemServico.numeroNotaFiscal
                      ? ordemServico.numeroNotaFiscal
                      : '--'}
                  </p>
                </div>
                {ordemServico.notaFiscal &&
                  ordemServico.notaFiscal.map(function(item) {
                    return (
                      <FileInput
                        name="notaFiscal"
                        entity={ordemServico}
                        fileItem={item}
                        className="small nfinput"
                        accept="image/*"
                        readOnly={true}
                        url={urlsConstants.MIDIA_IMAGE_JSON}
                      >
                        <i className="icon-foto" />
                      </FileInput>
                    );
                  })}
              </div>
            </div>

            {ordemServico.listAnexoTrocaModelo && (
              <div className="form-row col-12">
                <React.Fragment>
                  <div className="flex-column col-12 pt-2">
                    <div className="miniTxt mb-1">
                      Anexos da solicitação de troca
                    </div>
                    <div className="form-row">
                      {ordemServico.listAnexoTrocaModelo.map(function(
                        anexo,
                        index
                      ) {
                        return (
                          <FileInput
                            name="anexoItem"
                            fileItem={anexo.midiaFile}
                            readOnly={true}
                            key={anexo.id}
                            className={'col-6'}
                          />
                        );
                      })}
                    </div>
                  </div>
                </React.Fragment>
              </div>
            )}

            <div className="col-12 text-right pt-3">
              <PrintOrdemServicoBtn
                ordemServico={ordemServico}
                cliente={cliente}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

function mapStateToProps(state) {
  const { cliente, ordemServico } = state.ordemServico;
  const { totalMessages } = state.chat;
  const { user, permissions } = state.authentication;
  return {
    cliente,
    ordemServico,
    user,
    permissions,
    totalMessages,
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico },
  alert: { error, clear, success },
  load: { loading },
}) => ({
  setCliente: (cliente) => setCliente({ cliente }),
  setOrdemServico: (ordemServico) => setOrdemServico({ ordemServico }),
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
});

export default connect(
  mapStateToProps,
  mapDispatch
)(ClienteView);
