//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './taskInput.css';
type Props = {
  onAprove: any,
  onWait: any
};

class AprovarAguardarCardBtn extends PureComponent<Props> {
  render() {
    return (
      <React.Fragment>
        <div className="boxButon float-right flex-shrink-1 d-sm-flex">
          {/* <button
            type="button"
            className="btn btn-secondary btn-sm white"
            onClick={this.props.onWait}
          >
          <b>Aguardar</b>
            
          </button> */}
          <button
            type="button"
            className="btn btn-secondary btn-sm green"
            onClick={this.props.onAprove}
          >
            Aprovar
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(AprovarAguardarCardBtn);
