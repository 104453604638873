import $ from 'jquery';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { passwordRecoverService } from '../../services/passwordRecoverService';
import RecoverPasswordSucess from './RecoverPasswordSucess';
import SectionLoginBase from './SectionLoginBase';

type Props = {
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  alert: any,
  fieldMessage: any
};

class RecoverPassword extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    let login = { email: '' };
    this.state = login;
  }

  handleRecoverPassword = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    const { email } = this.state;

    this.props.clear();

    if (email) {
      this.props.loading(true);

      let entity = { email: email };

      return passwordRecoverService.recover(entity).then(
        response => {
          let json = response.data;
          if ('ERROR' === json.type) {
            this.setState({ errors: json.messages, saved: false });
          } else {
            this.setState({
              sended: true
            });
          }
          this.props.loading(false);
        },
        error => {
          this.setState({ errors: error.response.data.messages, saved: false });

          console.log(error);
          let errorMsg = 'Não foi possível redefinir senha';
          this.props.error(errorMsg);
          this.props.loading(false);
        }
      );
    } else {
      this.props.clear();
      this.props.error('Informe o E-mail');
    }
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  componentWillMount() {
    this.props.clear();
    localStorage.removeItem('token');

    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px'
    });
  }

  clickEvent(event) {
    if (event.target.hash !== '') {
      event.preventDefault();
      let hash = event.target.hash;
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top
        },
        500,
        function() {
          window.location.hash = hash;
        }
      );
    }
  }

  render() {
    const { sended, email } = this.state;

    if (sended) {
      return <RecoverPasswordSucess email={email} />;
    }

    return (
      <SectionLoginBase>
        <div className="index_btCadastro">
          <NavLink className="btn btn-secondary" to="/cadastro">
            Cadastro
          </NavLink>
        </div>
        <h1 className="mb-2 text-center slide-down">Redefinir Senha</h1>
        <p className="maxWidth mb-4 text-center slide-down">
          Informe seu e-mail e enviaremos <br />
          um link para cadastrar uma nova senha de acesso
        </p>

        <form
          className="index_form slide-up"
          onSubmit={this.handleRecoverPassword}
        >
          {/* <!--toast error--> */}
          <div className="form-group">
            <label>E-mail</label>
            <input
              type="text"
              className="form-control index_input"
              id="email"
              name="email"
              placeholder="Digite seu e-mail"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </div>
          <div className="mb-5">
            <NavLink
              to={{
                pathname: `/index`,
                state: { goToLogin: true }
              }}
            >
              Voltar
            </NavLink>
          </div>
          <button type="submit" className="btn btn-primary mb-4">
            Enviar
          </button>
        </form>
      </SectionLoginBase>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    loggedIn,
    alert,
    messages
  };
}

const mapDispatch = ({
  alert: { error, clear },
  authentication: { login },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  loading: (load: boolean) => loading({ load }),
  login: (user, password) => login({ user, password })
});
export default connect(
  mapStateToProps,
  mapDispatch
)(RecoverPassword);
