//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import CardFormEditView from '../../components/templates/CardFormEditView';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import PostoAutorizadoForm from './PostoAutorizadoForm';
import PostoAutorizadoFormViewView from './PostoAutorizadoFormViewView';

type Props = { entity: any };
type State = {
  entity: any,
  edit: boolean
};

class PostoAutorizadoFormView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getForm = this.getForm.bind(this);
    this.state = {
      entity: _.cloneDeep(this.props.entity),
      edit: false
    };
  }

  getForm() {
    return this._form;
  }
  componentWillReceiveProps(nextProps: any) {
    this.setState({ entity: _.cloneDeep(nextProps.entity) });
  }
  handleSaved = entity => {
    this.setState({ entity: _.cloneDeep(entity) });
  };

  setEditMode = (edit: boolean) => {
    this.setState({ edit });
  };

  getFormContent = () => {
    const { entity } = this.state;

    if (this.state.edit === true) {
      return (
        <PostoAutorizadoForm
          entity={entity}
          ref={forms => {
            this._form = forms;
          }}
        />
      );
    } else {
      return (
        <PostoAutorizadoFormViewView
          entity={entity}
          readOnly={this.props.readOnly}
        />
      );
    }
  };

  render() {
    return (
      <CardFormEditView
        setEditMode={this.setEditMode}
        title="Posto Autorizado"
        onSaved={this.handleSaved}
        getForm={this.getForm}
        onSave={postoAutorizadoService.doSave}
      >
        {this.getFormContent()}
      </CardFormEditView>
    );
  }
}

export default connect()(PostoAutorizadoFormView);
