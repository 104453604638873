import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

type Props = {
  title: string,
  className: string,
  type: string,
  onSubmit: any,
  disabled: boolean,
  child?: any
};

class WizardBtn extends PureComponent<Props> {
  render() {
    const { title, className, type, onSubmit, disabled } = this.props;

    let defaultClass = 'btn';
    if (this.props.className) {
      defaultClass += ' ' + className;
    }

    return (
    
        <button
          onClick={onSubmit}
          className={defaultClass}
          type={type}
          disabled={disabled}
        >
          {title}
          {this.props.child}
          <i className="icon_arrow" />
        </button>
      
    );
  }
}

export default connect()(WizardBtn);