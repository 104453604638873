//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { dateHelper } from '../../../helpers/date.helper';
import { objectsConstants } from '../../../constants/objects.constants';
import { Can } from '@casl/react';
import { propertyConstantes } from '../../../constants/property.constantes';
import { ordemServicoService } from '../../../services/ordemServico.service';

type Props = {
  onReprove: any,
  onAprove: any
};

class ExpirarOSBtn extends PureComponent<Props> {
  handleConfirm = () => {
    let ordemServico = this.props.ordemServico;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui success">
            <h3>Expirar Ordem de Serviço</h3>
            <p className="p-msg">Deseja expirar a Ordem de Serviço ?</p>
            <button
              className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
              onClick={() => {
                onClose();
              }}
            >
              CANCELAR
            </button>
            <button
              className="btn btn-primary my-4 mx-lg-3"
              onClick={() => {
                this.props.onConfirm();
                onClose();
              }}
            >
              SIM
            </button>
          </div>
        );
      }
    });
  };

  render() {
    const { permissions, ordemServico } = this.props;
    console.log(ordemServico.statusOS.name);
    return (
      <React.Fragment>
        {ordemServico &&
          ordemServico.statusOS.name !== propertyConstantes.EXPIRADA &&
          ordemServico &&
          ordemServico.statusOS.name !== propertyConstantes.FINALIZADA &&
          ordemServico.statusOS.name !== propertyConstantes.REPARADA && (
            <div className="justify-content-between btn-bold ">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={this.handleConfirm}
              >
                Expirar OS
              </button>
            </div>
          )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(mapStateToProps)(ExpirarOSBtn);
