//@flow
import * as React from 'react';

import { relatorioService } from '../../../services/relatorio.service';
import AnaliseFalhasChart from './AnaliseFalhasChart';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import { TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';
import moment from 'moment';
import { consumoBaterias } from '../../../services/consumoBaterias.service';
import connect from 'react-redux/es/connect/connect';
import AnaliseFalhasFormFilter from './AnaliseFalhasFormFilter';
import { analiseFalhasService } from '../../../services/analiseFalhas.service';
import { tableHelper } from '../../../helpers/tableHelper';
import AnaliseFalhasDetails from './AnaliseFalhasDetails';
//Nº
const columns = [
  {
    path: 'numOS',
    title: 'Nº OS',
    isKey: true,
    dataSort: true
  },
  {
    path: 'relogio.codigoRelogio',
    title: 'Código do relógio',
    isKey: false,
    dataSort: true
  },
  {
    path: 'marca.nome',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'peca.nome',
    title: 'Parte',
    isKey: false,
    dataSort: true
  },
  {
    path: 'sintoma.nome',
    title: 'Sintoma',
    isKey: false,
    dataSort: true
  },
  {
    path: 'causa.nome',
    title: 'Causa',
    isKey: false,
    dataSort: true
  },
  {
    path: 'garantia',
    title: 'Garantia',
    isKey: false,
    dataSort: true,
    yesNo: true
  },
  {
    path: 'questionamentoCliente',
    title: 'Questionamento do cliente',
    isKey: false,
    dataSort: false,
    yesNo: false
  }
];

class AnaliseFalhas extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      fields,
      defaultFilter: {
        mes,
        ano,
        sintetico: false
      },
      filter: {}
    };
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="ANÁLISE DE FALHAS  "
          canBack={false}
        />
        <CardRelatorio
          title={`ANÁLISE DE FALHAS  (${this.props.subTitle})`}
          FormFilter={AnaliseFalhasFormFilter}
         /* Chart={AnaliseFalhasChart}*/
          showTable={true}
          tableService={analiseFalhasService}
          controller={'relatorio/analiseFalhas'}
          fields={this.state.fields}
          defaultFilter={this.state.defaultFilter}
          DetalhePage={AnaliseFalhasDetails}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(AnaliseFalhas);
