//@flow
import React, { PureComponent } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ConfirmAcationService } from '../../../services/ConfirmAcationService';

type Props = {
  onConfirm: any,
  onCancel: any
};

export default class ConfirmarPecasBtn extends PureComponent<Props> {
  render() {
    return (
      <React.Fragment>
        <div className="d-flex flex-row-reverse bd-highlight btn-bold">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              ConfirmAcationService.confirmMsg(
                this.props.onConfirm,
                'CONFIRMAR RECEBIMENTO',
                `Você confirma o recebimento da sua solicitação ${
                  this.props.numOs
                }?`
              );
            }}
          >
            CONFIRMAR RECEBIMENTO
          </button>
        </div>
      </React.Fragment>
    );
  }
}
