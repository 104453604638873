import React, { PureComponent } from 'react';
import BtnPrimary from '../Buttons/BtnPrimary';

type Props = {
  onBack: any,
  onForward: any,
  disableBackBtn: boolean,
  disableForwardBtn: boolean,
  step: number,
  hideBtns: boolean,
  btnStyle: string
};
export class HeaderComponent extends PureComponent<Props> {
  getStateStep(step: number) {
    if (this.props.step === step) {
      return 'active';
    } else if (this.props.step > step) {
      return 'checked';
    }
  }

  getPercent() {
    if (this.props.step === 1) {
      return 10;
    } else if (this.props.step === 3) {
      return 50;
    } else if (this.props.step === 4) {
      return 70;
    } else if (this.props.step === 5) {
      return 90;
    } else {
      return this.props.step * 16;
    }
  }
  componentWillMount() {
    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px'
    });
  }

  render() {
    const statebarWidht = `calc(${this.getPercent()}% - 15px)`;
    let txtCadastro = window.innerWidth <= 1024 ? 'd-none' : 'slide-down';

    return (
      <div className="topoFixo">
        <div className="row justify-content-between align-items-center mx-3 mb-3">
          <BtnPrimary
            type={'button'}
            className={`btn btn-primary justify-content-start white my-4 mx-lg-3 ${
              this.props.btnStyle
            }`}
            style={this.props.btnStyle}
            title={'Voltar'}
            onSubmit={this.props.onBack}
            disabled={this.props.disableBackBtn}
          >
            <i className="icon_arrow" />
          </BtnPrimary>
          <BtnPrimary
            type={'button'}
            className={`my-4 mx-lg-3${this.props.btnStyle}`}
            style={this.props.btnStyle}
            title={'Avançar'}
            onSubmit={this.props.onForward}
            disabled={this.props.disableForwardBtn}
          />
        </div>

        <div className="align-items-center my-2 mx-4">
          <h2 className={`text-center ${txtCadastro}`}>
            Cadastro de posto autorizado <b>Seculus</b>
          </h2>
          <p className="text-center slide-up">
            Preencha os campos abaixo para se cadastrar
          </p>
        </div>
        <div className="wizardNav align-items-center">
          <div className="wizard-navigation">
            <div className="progress-with-circle">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="1"
                aria-valuemin="1"
                aria-valuemax="3"
                style={{ width: statebarWidht }}
              />
            </div>
            <ul className="nav nav-pills">
              <li className={`w-20 ${this.getStateStep(1)}`}>
                <a className="linkWizard" href="#empresa" data-toggle="tab">
                  <div className="tit">Empresa</div>
                  <div className="icone-circle ">
                    <div className="num"> 1 </div>
                  </div>
                </a>
              </li>
              <li className={`w-20 ${this.getStateStep(2)}`}>
                <a className="linkWizard" href="#responsavel" data-toggle="tab">
                  <div className="tit">Responsável</div>
                  <div className="icone-circle">
                    <div className="num"> 2 </div>
                  </div>
                </a>
              </li>
              <li className={`w-20 ${this.getStateStep(3)}`}>
                <a className="linkWizard" href="#termo" data-toggle="tab">
                  <div className="tit">Termo de Uso</div>
                  <div className="icone-circle">
                    <div className="num"> 3 </div>
                  </div>
                </a>
              </li>
              <li className={`w-20 ${this.getStateStep(4)}`}>
                <a className="linkWizard" href="#confirmacao" data-toggle="tab">
                  <div className="tit">Confirmação</div>
                  <div className="icone-circle">
                    <div className="num"> 4 </div>
                  </div>
                </a>
              </li>
              <li className={`w-20 ${this.getStateStep(5)}`}>
                <a className="linkWizard" href="#conclusao" data-toggle="tab">
                  <div className="tit">Conclusão</div>
                  <div className="icone-circle">
                    <div className="num"> 5 </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
