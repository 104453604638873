import * as React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import ImageProfile from './ImageProfile';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import {
  constHelper,
  objectsConstants
} from '../../constants/objects.constants';
import _ from 'lodash';
import { urlsConstants } from '../../constants/url.constant';
import { withRouter } from 'react-router-dom';
import { Can } from '@casl/react';
import RedirectCrud from '../Buttons/RedirectCrud';

type Props = {};
type State = {
  colaboradores: any
};
const endPointView = '/ColaboradorCrud/view/';
const endPoint = '/ColaboradorCrud/edit/';
class ColaboradorCarousel extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      colaboradores: this.props.data ? this.props.data : []
    };
  }

  getAvatar(entity: any) {
    if (entity.foto) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
    }
  }

  handleRowClick = (entity: e) => {
    this.props.history.push(endPointView + entity.id);
  };

  handleNewColaborador = (row: e) => {
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Novo Colaborador'
      }
    });
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true
    };
    let mySelf = this;
    const { permissions } = this.props;
    return (
      <div>
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">
            Colaboradores ({this.state.colaboradores.length})
          </h3>
          <div className="float-right  d-sm-flex">
            {/**Nome posto */}
            <h5>{''}</h5>
          </div>
        </div>
        <div className="conteudoCard">
          <Slider {...settings}>
            <Can
              I={objectsConstants.VIEW}
              a={objectsConstants.ABILITY_EDITAR_COLABORADOR}
              ability={permissions}
            >
              <div
                className="align-content-center text-center action novoColaborador"
                onClick={this.handleNewColaborador}
                tabIndex="-1"
              >
                <div className="avatarContainer">
                  <img
                    src={require('../../img/addColaborador.svg')}
                    className="img-fluid rounded-circle m-auto "
                    width="70"
                    height="70"
                  />
                </div>
                <h4 className="txtGray mt-2 nomeColaborador">NOVO</h4>
              </div>
            </Can>
            {this.state.colaboradores.map((colaborador, index) => (
              <div
                className="align-content-center text-center action"
                key={index}
                onClick={() => {
                  mySelf.handleRowClick(colaborador);
                }}
              >
                <ImageProfile
                  name={''}
                  width={70}
                  height={70}
                  dataUrl={this.getAvatar(colaborador)}
                />
                <h4 className="mb-1 nomeColaborador">
                  <b>{colaborador.name}</b>
                </h4>
                <h5 className="txtDarkGray subtitle">
                  {_.get(constHelper, colaborador.role)}
                </h5>
              </div>
            ))}
          </Slider>
        </div>
        <RedirectCrud toPage="/ColaboradorCrud" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;
  return {
    permissions
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(ColaboradorCarousel));
