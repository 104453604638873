//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import { defaultService } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';

export const ordemServicoService = {
  findByFiltro,
  saveObservacoes,
  getTotalOSMes,
  find,
  doSave,
  findByCliente,
  doGet,
  saveOrcamento,
  reproveOrcamento,
  aproveOrcamento,
  confirmChangeStatus,
  reabrirOrcamento,
  reabrirTrocaModelo,
  reabrirOs,
  entregaRelogio,
  OsReparada,
  findHistoricoByOS,
  getUsers,
  getById,
  rejeitarPeca,
  doSaveRelogioTrocaOrdemServico,
  solicitarTroca,
  saveModelosTrocaRelogio,
  getPostoOS,
  getTotalFinanceiroMes,
  trocaMaisModelos,
  expirarOS,
  aguardandoImportacao,
  reprovarTrocaModelo,
  reverterTroca,
  reativarOS,
  cancelarOs,
  solicitarHP,
  solicitarATSP,
  trocaStatusOS,
  findOS,
  findListReincidencia,
  cancelarRE
};

function confirmChangeStatus(onConfirm) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="confirm-ui success">
          <h3>
            Mudar status da OS - <span className="txtDarkGray">Confirmar?</span>
          </h3>
          <p className="p-msg">
            Ao clicar no botão Confirmar o status da OS será alterada.
          </p>
          <p>
            <b>Deseja realmente mudar status da OS?</b>
          </p>
          <button
            className="btn btn-primary my-4 mx-lg-3"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Confirmar
          </button>
          <button
            className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
            onClick={() => {
              onClose();
            }}
          >
            Não, obrigado
          </button>
        </div>
      );
    }
  });
}

function doGet(url) {
  return defaultService.doGet(url);
}
function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.ORDEM_SERVICO, entity);
}
function doSaveRelogioTrocaOrdemServico(idRelogio, idOrdemServico) {
  return defaultService.doGet(
    `${urlsConstants.ORDEM_SERVICO_SAVE_TROCA_RELOGIO}${idOrdemServico}/${idRelogio}`
  );
}

function saveModelosTrocaRelogio(relogios, idOrdemServico) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_SAVE_MODELOS_TROCA_RELOGIO}${idOrdemServico}`,
    relogios
  );
}

function findByCliente(cliente: any, filter: any) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_FIND_BY_CLIENTE}${cliente.id}`,
    filter
  );
}
function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.ORDEM_SERVICO}list`,
    options,
    filter
  );
}

function find(input) {
  return defaultService.doGet(`${urlsConstants.FIND_OS}${input}`);
}

function getTotalOSMes(entity) {
  return defaultService.doPost(`${urlsConstants.OS_TOTAL_MES}`, entity);
}

function saveOrcamento(OSid, entity: any) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_ORCAMENTO}${OSid}`,
    entity
  );
}

function reproveOrcamento(OSid, entity: any) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_REPROVAR_ORCAMENTO}${OSid}`,
    entity
  );
}

function aproveOrcamento(OSid) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_APROVAR_ORCAMENTO}${OSid}`,
    {}
  );
}

function reabrirOrcamento(OSid) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_COLOCAR_EM_AVALIACAO}${OSid}`,
    {}
  );
}

function reabrirOs(OSid, entity: any) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_REABRIR_OS}${OSid}`,
    entity
  );
}
function trocaMaisModelos(OSid) {
  return defaultService.doGet(`${urlsConstants.TROCA_MAIS_MODELOS}${OSid}`, {});
}
function entregaRelogio(OSid, dtEntrega) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_ENTREGA_RELOGIO_OS}${OSid}`,
    { dtEntrega }
  );
}

function OsReparada(OSid) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_ENTREGA_OS_REPARADA}${OSid}`,
    {}
  );
}

function getUsers(OSid) {
  return defaultService.doGet(`${urlsConstants.OS_GET_USERS}${OSid}`);
}

function findHistoricoByOS(id) {
  return defaultService.doGet(`${urlsConstants.HISTORICO_OS}${id}`);
}

function getById(id: number) {
  return defaultService.doGet(`${urlsConstants.ORDEM_SERVICO}${id}`);
}

function rejeitarPeca(OSid, pecaRegistroTrabalhoDocVO) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO}rejeitarPeca/${OSid}`,
    pecaRegistroTrabalhoDocVO
  );
}

function solicitarTroca(OSid, files) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO}solicitarTroca/${OSid}`,
    files
  );
}

function solicitarHP(OSid) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO}solicitarHP/${OSid}`,
    {}
  );
}
function solicitarATSP(OSid) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO}solicitarATSP/${OSid}`,
    {}
  );
}

function reabrirTrocaModelo(OSid) {
  return defaultService.doGet(
    `${urlsConstants.ORDEM_SERVICO_COLOCAR_TROCA_MODELO}${OSid}`,
    {}
  );
}

function getPostoOS(OSid) {
  return defaultService.doGet(`${urlsConstants.GET_POSTO_OS}${OSid}`, {});
}

function getTotalFinanceiroMes(month, year) {
  return defaultService.doGet(
    `${urlsConstants.GET_TOTAL_FINANCEIRO}${year}/${month}`
  );
}

function expirarOS(id) {
  return defaultService.doGet(`${urlsConstants.ORDEM_SERVICO}expirar/${id}`);
}
function cancelarOs(id) {
  return defaultService.doGet(`${urlsConstants.ORDEM_SERVICO}cancelar/${id}`);
}

function cancelarRE(id) {
  return defaultService.doGet(`${urlsConstants.ORDEM_SERVICO}cancelarRE/${id}`);
}

function reativarOS(id) {
  return defaultService.doGet(`${urlsConstants.ORDEM_SERVICO}reativar/${id}`);
}
function aguardandoImportacao(id) {
  return defaultService.doGet(
    `${urlsConstants.ORDEM_SERVICO}aguardandoImportacao/${id}`
  );
}

function saveObservacoes(ordemServico) {
  return defaultService.doPut(`${urlsConstants.ORDEM_SERVICO}`, ordemServico);
}
function reprovarTrocaModelo(OSid, observacaoReprovarTroca) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO}trocaNaoAutorizada/${OSid}`,
    { observacaoReprovarTroca }
  );
}

function reverterTroca(id) {
  return defaultService.doGet(
    `${urlsConstants.ORDEM_SERVICO}reverterTroca/${id}`
  );
}

function trocaStatusOS(listOs) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO}trocarStatusOS/`,
    listOs
  );
}

function findOS(input) {
  return defaultService.doGet(`${urlsConstants.FIND_OS_BY_ID}${input}`);
}

function findListReincidencia(id) {
  return defaultService.doGet(
    `${urlsConstants.ORDEM_SERVICO}findListReincidencia/${id}`
  );
}
