import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { propertyConstantes } from '../../constants/property.constantes';
import RejeitarPecasBtn from '../../views/compraPeca/Components/RejeitarPecasBtn';
import FileInput from '../Inputs/FileInput';
import { urlsConstants } from '../../constants/url.constant';
import { ordemServicoService } from '../../services/ordemServico.service';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import {objectsConstants} from "../../constants/objects.constants";

type Props = { entity: any };
type State = {
  pecas: any,
  entity: any
};

export class CardPecaItemView extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: { quantidade: 1 }
    };
  }

  getBtnRejectOrgetImagenPeca = () => {
    const peca = this.props.entity.peca;
    if (
      peca.lastPecaRegistroTrabalhoDocVO &&
      peca.lastPecaRegistroTrabalhoDocVO.status.name !==
        propertyConstantes.REJEITADA &&
        this.props.ordemServico.statusOS.name !== propertyConstantes.REPARADA &&
        this.props.ordemServico.statusOS.name !== propertyConstantes.FINALIZADA
    ) {
      return (
        <RejeitarPecasBtn
          peca={peca.peca}
          pecaRegistroTrabalhoDocVO={peca.lastPecaRegistroTrabalhoDocVO}
          serviceSave={ordemServicoService.rejeitarPeca}
          serviceGet={ordemServicoService.getById}
        />
      );
    } else if (
      peca.lastPecaRegistroTrabalhoDocVO &&
      peca.lastPecaRegistroTrabalhoDocVO.status.name ===
        propertyConstantes.REJEITADA
    ) {
      return (
        <FileInput
          emptyLabel="Enviar foto NF"
          name="fotoPecaRejeitada"
          entity={peca.lastPecaRegistroTrabalhoDocVO}
          fileItem={peca.lastPecaRegistroTrabalhoDocVO.fotoPecaRejeitada}
          className="small txtBlue"
          accept="image/"
          readOnly={true}
          url={urlsConstants.MIDIA_IMAGE_JSON}
          forceLabel="Peça rejeitada"
        >
          <i className="icon-foto" />
        </FileInput>
      );
    }
  };

  render() {
    const { entity } = this.props;
    let garantia =
      typeof entity.peca.garantia == 'boolean'
        ? entity.peca.garantia
          ? 'true'
          : 'false'
        : entity.peca.garantia;
    return (
      <React.Fragment>
        <div className="form-row borderForm formView">
          <div className="form-group col-12 col-sm-4">
            <label className="miniLabel">Peça/Código</label>
            <p>{entity.peca.peca.nome + ' / ' + entity.peca.peca.codigoPeca}</p>
          </div>
          <div className="form-group col-12 col-sm-4">
            <label className="miniLabel">Sintoma</label>
            <p>{entity.peca.sintoma.nome}</p>
          </div>
          <div className="form-group col-12 col-sm-4">
            <label className="miniLabel">Causa</label>
            <p>{entity.peca.causa.nome}</p>
          </div>

          <div className="form-group borderTop col-6 col-sm-4">
            <label className="miniLabel">Qtd</label>
            <p>{entity.peca.quantidade}</p>
          </div>
          {!entity.peca.garantia && (
            <React.Fragment>
              <div className="form-group borderTop col-6 col-sm-4">
                <label className="miniLabel">Valor sugerido</label>
                <p className="txtDarkGray">
                  {ordemServicoHelper.formatMoney(
                    parseFloat(entity.peca.valorSugerido),
                    2,
                    ',',
                    '.'
                  )}
                </p>
              </div>
            </React.Fragment>
          )}
          <div className="form-group borderTop col-12 col-sm-4">
            <label className="miniLabel">Valor Final</label>
            <p>
              {entity.peca.valorFinal
                ? ordemServicoHelper.formatMoney(
                    parseFloat(entity.peca.valorFinal),
                    2,
                    ',',
                    '.'
                  )
                : 'R$ 0,00'}
            </p>
          </div>
          {/*Investigar a causa de garantia vim como string*/}
          <div className="form-group borderTop col-6 col-sm-4">
            <label className="miniLabel">Peça na garantia?</label>
            <p className={`bold ${garantia === 'true' ? 'txtBlue' : 'txtRed'}`}>
              {garantia == 'true' ? 'Sim' : 'Não'}
            </p>
          </div>
          <div className="form-group borderTop col-6 col-sm-4">
            <label className="miniLabel">Solicitar peça</label>
            <p className={`${entity.peca.solicitarPeca ? 'txtBlue bold' : ''}`}>
              {entity.peca.solicitarPeca ? 'Sim' : 'Não'}
            </p>
          </div>
          {entity.peca.codigoPeca && (
            <div className="form-group borderTop col-6 col-sm-4">
              <label className="miniLabel">Código peça 2</label>
              <p className={`bold`}>{entity.peca.codigoPeca}</p>
            </div>
          )}
          {entity.peca.lastPecaRegistroTrabalhoDocVO && (
            <div className="form-row col-12">
              <div className="form-group col-4 col-sm-4 align-left">
                {entity.peca.lastPecaRegistroTrabalhoDocVO.codigoRastreio && (
                  <span className={'txtBlue bold small'}>
                    RASTREIO{' '}
                    {entity.peca.lastPecaRegistroTrabalhoDocVO.codigoRastreio}
                  </span>
                )}
                {entity.peca.lastPecaRegistroTrabalhoDocVO &&
                  entity.peca.lastPecaRegistroTrabalhoDocVO.status.name ===
                    propertyConstantes.REJEITADA && (
                    <React.Fragment>
                      <label className="miniLabel">Motivo da rejeição: </label>
                      <span className={'txtBlue bold small'}>
                        {
                          entity.peca.lastPecaRegistroTrabalhoDocVO
                            .motivoRejeicao.descricao
                        }
                      </span>
                    </React.Fragment>
                  )}
              </div>
              <div className="form-group col-4 col-sm-4 text-right">
                {this.getBtnRejectOrgetImagenPeca()}
              </div>
              <div className="form-group col-4 col-sm-4 text-right">
                <span
                  className={`${entity.peca.lastPecaRegistroTrabalhoDocVO.status.className} bold small`}
                >
                  {entity.peca.lastPecaRegistroTrabalhoDocVO.status.description}
                </span>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(CardPecaItemView);
