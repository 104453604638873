//@flow
import React from 'react';
import { dateHelper } from './date.helper';
import { propertyConstantes } from '../constants/property.constantes';
import _ from 'lodash';
import { objectsConstants } from '../constants/objects.constants';

export const ordemServicoHelper = {
  getStatus,
  getListStatus,
  getListStatusTrocaAdmin,
  getFarol,
  getObjectsInArray,
  formatMoney,
  getClientesTipo,
  getListStatusFinalizada
};

function formatMoney(n, c, d, t) {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? '.' : d,
    t = t == undefined ? ',' : t,
    s = n < 0 ? '-' : '',
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    'R$ ' +
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
}

async function getObjectsInArray(arrayItens, name) {
  if (arrayItens.length) {
    let newArrayItens = await Promise.all(
      _.map(arrayItens, async function(item) {
        return await _.get(item, name);
      })
    );
    return _.without(newArrayItens, undefined);
  } else {
    return [];
  }
}

// - Branco - Os, reparada entregue para cliente
// - Branco - Os, reparada entregue para cliente
// - AZUL - até 7 dias;
// - VERDE - 8 a 17 dias;
// - AMARELO -18 a 25;
// - VERMELHO - acima de 25;

function getFarol(ordemServico) {
  let dias = ordemServico.diasCorridos
    ? ordemServico.diasCorridos
    : dateHelper.diff(ordemServico.creationDateTime, ordemServico.dataReparo);
  if (
    (ordemServico.diasCorridos && ordemServico.diasCorridos == -1) ||
    ordemServico.dataReparo ||
    ordemServico.dataEntragaRelogio ||
    ordemServico.statusOS.name === propertyConstantes.ORCAMENTO_REPROVADO ||
    ordemServico.statusOS.name === propertyConstantes.ATENDIDA
  ) {
    return 'farol-branco';
  }

  if (dias <= 7) {
    return 'farol-azul';
  } else if (dias > 7 && dias <= 17) {
    return 'farol-verde';
  } else if (dias > 18 && dias <= 25) {
    return 'farol-amarelo';
  } else {
    return 'farol-vermelho';
  }
}
function getListStatus() {
  return [
    {
      id: propertyConstantes.AGUARDANDO_IMPORTACAO,
      nome: 'Aguardando importação'
    },
    { id: propertyConstantes.AGUARDANDO_PECAS, nome: 'Aguardando peças' },
    {
      id: propertyConstantes.AGUARDANDO_RECEBIMENTO,
      nome: 'Aguardando recebimento'
    },
    { id: propertyConstantes.AGUARDANDO_REPARO, nome: 'Aguardando reparo' },
    {
      id: propertyConstantes.AGUARDANDO_ENVIO_RELOGIO,
      nome: 'Aguardando relógio'
    },
    { id: propertyConstantes.APROVAR_ORCAMENTO, nome: 'Aprovar Orçamento' },
    { id: propertyConstantes.CANCELADA, nome: 'Cancelada' },
    { id: propertyConstantes.CENTRO_TECNICO, nome: 'Centro técnico' },
    { id: propertyConstantes.EXPIRADA, nome: 'Expirada' },
    { id: propertyConstantes.FINALIZADA, nome: 'Finalizada' },
    { id: propertyConstantes.ORCAMENTO_REPROVADO, nome: 'Orçamento reprovado' },
    {
      id: propertyConstantes.ATENDIDA_PARCIALMENTE,
      nome: 'Parcialmente atendida'
    },
    { id: propertyConstantes.PECA_NAO_ATENDIDA, nome: 'Peças não atendidas' },
    { id: propertyConstantes.APOIO_COMERCIAL, nome: 'Peças não atendidas Estoque' },
    { id: propertyConstantes.PECA_REJEITADA, nome: 'Peças rejeitada' },
    { id: propertyConstantes.REPARADA, nome: 'Reparada' },
    {
      id: propertyConstantes.TRANSFERIDA,
      nome: 'OS transferida'
    },
    {
      id: propertyConstantes.AGUARDANDO_RELOGIO_DE_TROCA,
      nome: 'Troca Atendida'
    },
    {
      id: propertyConstantes.ESCOLHA_DE_MODELO,
      nome: 'Troca aguardando aprovação'
    },
    { id: propertyConstantes.TROCA_MODELO, nome: 'Troca de modelo' },
    {
      id: propertyConstantes.TROCA_MODELO_EM_ANDAMENTO,
      nome: 'Troca do modelo em andamento'
    },
    { id: propertyConstantes.TROCA_MAIS_MODELO, nome: 'Troca mais modelos' },
    {
      id: propertyConstantes.TROCA_NAO_AUTORIZADA,
      nome: 'Troca não autorizada'
    },
    { id: propertyConstantes.TROCA_REVERTIDA, nome: 'Troca revertida' },
    { id: 'TODOS', nome: 'Todos' }
  ];
}
function getListStatusTrocaAdmin() {
  return [
    {
      id: propertyConstantes.ESCOLHA_DE_MODELO,
      nome: 'Troca aguardando aprovação'
    },
    { id: propertyConstantes.TROCA_MODELO, nome: 'Troca de modelo' },
    { id: propertyConstantes.TROCA_MAIS_MODELO, nome: 'Troca mais modelos' },
    { id: 'TODOS', nome: 'Todos' }
  ];
}
function getStatus(statusEnum: string): string {
  if ('EM_CRIACAO' === statusEnum) {
    return 'Em criação';
  }
  return statusEnum;
}

function getClientesTipo() {
  return objectsConstants.TIPO_PESSOA_ARRAY;
}
function getListStatusFinalizada() {
  return [
    { id: propertyConstantes.FINALIZADA, nome: 'Finalizada' },
    { id: propertyConstantes.REPARADA, nome: 'Reparada' },
    { id: 'TODOS', nome: 'Todos' }
  ];
}
