//@flow
import moment from 'moment';
import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/datePicker.css';

type Props = {
  label: string,
  id: string,
  name: string,
  size?: number,
  onChange: any,
  required: boolean,
  messageValidate?: string,
  value?: any,
  placeholder: string,
  style: any,
  disabled: boolean
};

export default class DateTimePicker extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }
  handleBorderColor = messageValidate => {
    let borderColor;
    if (messageValidate) {
      borderColor = '#dc3545';
    }
    return {
      borderColor
    };
  };

  handleChange = (date: any) => {
    if (this.props.onChange) {
      this.setState({ value: date }, function() {
        this.props.onChange(this.props.name, date);
      });
    } else {
      this.setState({ value: date });
    }
  };

  render() {
    const { messages, name } = this.props;

    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }

    let selectedValue = this.state.value; //? moment(this.state.value) : '';
    return (
      <DatePicker
        disabled={this.props.disabled}
        id={this.props.id}
        name={this.props.name}
        className={`form-control `}
        selected={selectedValue}
        onChange={this.handleChange}
        required={this.props.required}
        locale="pt-BR"
        style={{ borderColor: 'red' }}
        placeholderText={this.props.placeholder}
        onBlur={this.props.handleOnBlur}
        //customInput={<InputMask mask="99/99/9999" value={this.props.value} />}
        popperPlacement={this.props.popperPlacement}
        maxDate={this.props.maxDate ? moment(this.props.maxDate) : null}
        minDate={this.props.minDate ? moment(this.props.minDate) : null}
        showTimeSelect={this.props.showTimeSelect}
        timeFormat="HH:mm"
        timeCaption="Horas"
        dateFormat="DD/MM/YYYY HH:mm"
      />
    );
  }
}
