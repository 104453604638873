//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import { postoValorAtendimentoGarantiaService } from '../../services/postoValorAtendimentoGarantiaService';
import FormValoresGarantia from './FormValoresGarantia';
import { withRouter } from 'react-router-dom';

const columns = [
  { path: 'postoAutorizado.id', title: '#', isKey: true, dataSort: false },
  {
    path: 'postoAutorizado.nomeFantasia',
    title: 'Posto',
    isKey: false,
    dataSort: true
  },
  {
    path: 'grupoMarca.nome',
    title: 'Grupo Marca',
    isKey: false,
    dataSort: true,
    isMoney: false
  },
  {
    path: 'valor',
    title: 'Valor',
    isKey: false,
    dataSort: true,
    isMoney: true
  }
];

type State = {
  modal: boolean
};

class ValoresGarantiaList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      fields,
      entity: {}
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(
      '/configuracoes/valoresGarantia/posto/' + row.postoAutorizado.id
    );
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <ListagemPage
          title={`Postos autorizados(${this.props.subTitle})`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={postoValorAtendimentoGarantiaService}
          labelNewButton={''}
          classSignal="temSinal"
          defaultOrder={'creationDateTime'}
          defaultDirection={'desc'}
          defaultFilter={this.props.filter}
          onRowClick={this.handleRowClick}
          showNewButton={false}
          controller="postoValorAtendimentoGarantia"
          fields={this.state.fields}
          FormCardListagem={FormValoresGarantia}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle, mainTitle } = state.title;

  return {
    subTitle,
    mainTitle
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ValoresGarantiaList));
