//@flow

export default {
  state: { extension: null },
  reducers: {
    setExtension: (state, payload) => {
      return Object.assign({}, state, {
        extension: payload
      });
    }
  }
};
