//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import CpfInput from '../../components/Inputs/CpfInput';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import FormInput from '../../components/Inputs/FormInput';
import RoleSelectInput from '../../components/Inputs/RoleSelectInput';
import Toast from '../../components/Toasts/Toast';
import UploadPictureRegister from '../../components/UploadPictureRegister';
import { objectsConstants } from '../../constants/objects.constants';
import { colaboradorService } from '../../services/colaborador.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import PhoneInput from '../../components/Inputs/PhoneInput';
import { marcaService } from '../../services/marca.service';
import SelectInput from '../../components/Inputs/SelectInput';
import { relogioService } from '../../services/relogio.service';
import ImageInput from '../../components/Inputs/ImageInput';
import { midiaService } from '../../services/midiaService';
import relogioBG from '../../img/relogioBG.png';
import MoneyInput from '../../components/Inputs/MoneyInput';
import FormTextArea from '../../components/Inputs/FormTextArea';
import { garantiaClienteService } from '../../services/garantiaCliente.service';
import { urlsConstants } from '../../constants/url.constant';
// type Props = { titlePage: any };
type State = { initialValues: any };

const NewGarantia = Yup.object().shape({
  codigoRelogio: Yup.string().required('Código do Relógio é obrigatório'),
  marca: Yup.string().required('Marca é obrigatório')
});

class GarantiaEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        cliente: {},
        relogio: {},
        notaFiscal:{}
      }
    };
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      garantiaClienteService.getById(this.props.match.params.id).then(result => {
        let initialValues = result.data.data;
        this.setState({ initialValues });
      });
    }
  }

  showError = messageError => {
    this.props.error({ message: messageError });
    return '';
  };

  clearError = () => {
    this.props.clear();
    return '';
  };

  dataUrl = values => {
    if (values.foto && values.foto.id) {
      return garantiaClienteService.get(values.notaFiscal.id);
    } else {
      if (values.foto) {
        return values.foto.data;
      }
    }
  };

  render() {
    return (
        <Formik
            validationSchema={{}}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={this.state.initialValues}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              this.props.loading(true);
              garantiaClienteService
                  .doDelete(values.id)
                  .then(response => {
                    actions.setSubmitting(false);
                    this.props.success({ message: 'Garantia deletada com sucesso.' });
                    this.props.loading(false);
                    this.props.history.push('/cadastroGarantia');
                  })
                  .catch(() => {
                    actions.setSubmitting(false);
                    this.props.loading(false);
                  });
            }}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="flex-column">
                  <Toast />
                  <TopoTitleComponente
                      mainTitle={'Cadastro'}
                      subTitle={
                        values.id != undefined ? ' Editar dados' : 'Novo cadastro'
                      }
                      canBack={true}
                  />
                  {!_.isEmpty(errors) &&
                  this.showError(objectsConstants.messageCampoObrigatorio)}
                  {values.id && this.clearError()}
                  <div className="cardSeculus d-flex flex-column mb-0">
                    <div className="topoCard d-flex flex-column flex-sm-row">
                      <h3 className="flex-grow-1">Cadastro</h3>
                    </div>
                    <div className="form-row">

                      <img src={midiaService.get(values.notaFiscal.id)} className="img-fluid" />

                      <CpfInput
                          placeholder="Digite o CPF"
                          label="CPF"
                          name="cliente.cpf"
                          id="cpf"
                          value={values.cliente.documento}
                          onChange={(name, value) => setFieldValue(name, value)}
                          style={['col-6 col-sm-12']}
                      />

                      <FormInput
                          type="text"
                          id="numeroNF"
                          name="numeroNF"
                          value={values.numeroNF}
                          label="Número da Nota Fiscal"
                          placeholder=""
                          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                            const { numeroNF, value } = e.currentTarget;
                            let newValue = value.trim();
                            setFieldValue(numeroNF, newValue);
                          }}
                          style={['col-6 col-sm-12']}
                      />

                      <FormInput
                          type="text"
                          id="codigoGarantia"
                          name="relogio.codigoRelogio"
                          label={'Codigo do relogio'}
                          placeholder=""
                          style={['col-6 col-sm-12']}
                          value={values.relogio.codigoRelogio}
                          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                            const { codigoGarantia, value } = e.currentTarget;
                            let newValue = value.trim();
                            setFieldValue(codigoGarantia, newValue);
                          }}
                          noSize={true}
                          erroMensagem={errors.codigoRelogio}
                      />



                      <div className="d-flex flex-row flex-sm-row ">

                        <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                          <button
                              type="submit"
                              className="btn btn-primary btn30 red"
                              disabled={isSubmitting}
                          >
                            Deletar Registro
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
          )}
        </Formik>
    );
  }
}

const mapDispatch = ({
                       alert: { success, error, clear },
                       load: { loading }
                     }) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
    null,
    mapDispatch
)(GarantiaEdit);
