import React, { Component } from 'react';
import InputTrigger from 'react-input-trigger';
import { ImageProfile } from '../../components/Inputs/ImageProfile';
import { ordemServicoService } from '../../services/ordemServico.service';
import _ from 'lodash';
import { constHelper } from '../../constants/objects.constants';
import { urlsConstants } from '../../constants/url.constant';
import { MentionInputUser } from './MentionInputUser';

type Props = {
  children?: any,
  onChange: any,
  onAddMetion: any,
  onRemoveMention: any
};
type State = {
  entity: any
};

export class MentionInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      top: null,
      left: null,
      showSuggestor: false,
      startPosition: null,
      os: props.os,
      users: [],

      text: null,
      currentSelection: 0
    };

    this.toggleSuggestor = this.toggleSuggestor.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleTextareaInput = this.handleTextareaInput.bind(this);
  }

  componentDidMount() {
    console.log('1');
    ordemServicoService.getUsers(this.state.os).then(r => {
      let users = r.data.data.map(user => {
        return user;
      });
      this.setState({ users });
    });
  }

  toggleSuggestor(metaInformation) {
    console.log(metaInformation);
    const { hookType, cursor } = metaInformation;
    if (hookType === 'start') {
      this.setState({
        showSuggestor: true,
        left: cursor.left,
        top: cursor.top + cursor.height, // we need to add the cursor height so that the dropdown doesn't overlap with the `@`.
        startPosition: cursor.selectionStart
      });
    }

    if (hookType === 'cancel') {
      // reset the state

      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null
      });
    }
  }

  handleInput(metaInformation) {
    this.setState({
      text: metaInformation.text
    });
  }

  componentWillReceiveProps(nexProps) {
    if (nexProps.value === '') {
      this.setState({ textareaValue: '' });
    }
    if (this.props.os != this.state.props) {
      ordemServicoService.getUsers(this.props.os).then(r => {
        let users = r.data.data.map(user => {
          return user;
        });
        this.setState({ users, os: this.props.os });
      });
    }
  }

  handleKeyDown(event) {
    const { which, shiftKey } = event;
    console.log(which);
    const { currentSelection, users, textareaValue } = this.state;
    console.log(shiftKey);
    if (
      which === 13 &&
      !this.state.showSuggestor &&
      textareaValue &&
      textareaValue !== '' &&
      !shiftKey
    ) {
      this.props.sendMessage();
      this.setState({ textareaValue: '' });
    }
    if (which === 8) {
      console.log(textareaValue)
      const start = this.props.onRemoveMention(textareaValue);
      console.log(start)
      if (start != null) {
        const newText = `${textareaValue.slice(0, start)}`;
        this.setState({ textareaValue: newText });
      }
    }
    if (which === 40 && this.state.text != null && this.state.text.length > 0) {
      // 40 is the character code of the down arrow
      event.preventDefault();
      console.log(this.state.text);
      this.setState({
        currentSelection:
          (currentSelection + 1) %
          users.filter(user => (user.name.toUpperCase().indexOf(this.state.text?this.state.text.toUpperCase():this.state.text) !== -1 || !this.state.text || this.state.text.length==0)).length
      });
    }
    if (which === 38 && this.state.text != null && this.state.text.length > 0) {
      // 40 is the character code of the down arrow
      event.preventDefault();
      const length = users.filter(user => (user.name.toUpperCase().indexOf(this.state.text?this.state.text.toUpperCase():this.state.text) !== -1 || !this.state.text || this.state.text.length==0)).length;

      if (currentSelection !== 0) {
        this.setState({
          currentSelection: (currentSelection - 1) % length
        });
      } else {
        this.setState({
          currentSelection: length - 1
        });
      }
    }

    if (which === 13 && this.state.text != null && this.state.text.length > 0) {
      // 13 is the character code for enter
      this.selectMention(event, this.state.currentSelection);
    }
  }

  selectMention = (event, currentSelection) => {
    event.preventDefault();

    const {
      users,

      startPosition,
      textareaValue
    } = this.state;

    const user = users
        .filter(user => (user.name.toUpperCase().indexOf(this.state.text?this.state.text.toUpperCase():this.state.text) !== -1 || !this.state.text || this.state.text.length==0))
    [currentSelection];

    const newText = `${textareaValue.slice(0, startPosition)}${
      user.name
    }${textareaValue.slice(
      startPosition + user.name.length,
      textareaValue.length
    )}`;

    // reset the state and set new text
    this.props.onAddMetion({
      user: user,
      start: startPosition,
      finish: startPosition - 1 + user.name.length
    });
    this.setState({
      showSuggestor: false,
      left: null,
      top: null,
      text: null,
      startPosition: null,
      textareaValue: newText
    });

    this.endHandler();
  };

  handleTextareaInput = event => {
    console.log('6');
    const { which, shiftKey } = event;
    const { value } = event.target;
    console.log(value !== '\n');
    if (value !== '\n') {
      this.setState(
        {
          textareaValue: value
        },
        () => {
          this.props.onChange(value);
        }
      );
    }
  };

  getAvatar(entity: any) {
    if (entity.foto) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
    }
  }

  render() {
    return (
      <div className="itemSearch" onKeyDown={this.handleKeyDown}>
        <InputTrigger
          trigger={{
            keyCode: 50,
            shiftKey: true
          }}
          onStart={metaData => {
            this.toggleSuggestor(metaData);
          }}
          onCancel={metaData => {
            this.toggleSuggestor(metaData);
          }}
          onType={metaData => {
            this.handleInput(metaData);
          }}
          endTrigger={endHandler => {
            this.endHandler = endHandler;
          }}
          className="classItem"
        >
          <textarea
            className="form-control chat"
            placeholder="@ Digite sua mensagem"
            onChange={this.handleTextareaInput}
            value={this.state.textareaValue}
          />
        </InputTrigger>

        <ul
          id="dropdown"
          className="mention"
          style={{
            display: this.state.showSuggestor ? 'block' : 'none',
            top: this.state.top,
            left: this.state.left
          }}
        >
          {this.state.users
            .filter(user => (user.name.toUpperCase().indexOf(this.state.text?this.state.text.toUpperCase():this.state.text) !== -1 || !this.state.text || this.state.text.length==0))
            .map((user, index) => (
              <li
                className="content"
                style={{
                  color:
                    index === this.state.currentSelection ? '#fff' : '#000',
                  background:
                    index === this.state.currentSelection ? '#85cd63' : ''
                }}
                key={user.id}
                onClick={e => {
                  this.selectMention(e, index);
                }}
              >
                <MentionInputUser user={user} />
              </li>
            ))}
        </ul>
      </div>
    );
  }
}
