//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import ColaboradorCarousel from '../../components/Inputs/ColaboradorCarousel';
import { CardItem } from '../../components/templates/CardItem';
import { colaboradorService } from '../../services/colaborador.service';
import type { PostoAutorizadoType } from '../../types/postoAutorizado.type';

type State = {
  entity: PostoAutorizadoType
};
type Props = {
  extension: number
};

class ColaboradorView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      entity: {}
    };
  }
  componentDidMount() {
    //TODO carregar os dados do servidor.
    if (this.props.user) {
      let filter = {
        extension: this.props.extension
          ? this.props.extension
          : this.props.user.extension
      };
      this.requestData(
        {
          pageSize: 10,
          page: 1,
          order: 'name',
          direction: 'asc'
        },
        filter
      );
    }
  }

  requestData = (options: Options, filter?: any) => {
    colaboradorService
      .findByFiltro(
        {
          pageSize: options.pageSize,
          page: options.page,
          order: options.order,
          direction: options.direction
        },
        filter ? filter : this.props.filter
      )
      .then(response => {
        let data = response.data;
        this.setState({
          data: data.data,
          dataTotalSize: data.dataTotalSize,
          currentPage: options.page,
          order: options.order,
          direction: options.direction
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { data } = this.state;
    return <CardItem>{data && <ColaboradorCarousel data={data} />}</CardItem>;
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ColaboradorView);
