//@flow
import * as React from 'react';
import TaskBox from '../../components/Inputs/TaskBox';
import { taskService } from '../../services/taskService';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FooterTablePagination } from '../../components/table/FooterTablePagination';
import _ from 'lodash';

const defautOptions = {
  pageSize: 10,
  page: 1,
  order: 'id',
  direction: 'desc'
};
let timoutVar;
class Task extends React.PureComponent<{}> {
  constructor(props) {
    super(props);

    let options = defautOptions;
    if (props.pageSize != null) {
      options.pageSize = props.pageSize;
    } else {
      options.pageSize = 10;
    }
    options.filter = { description: null };
    this.state = { taskList: [], options };
  }

  handlePageChange = (page: number) => {
    const newOption = _.cloneDeep(this.state.options);
    newOption.page = page - 1;
    this.setState(
      {
        options: newOption
      },
      () => {
        this.requestData(this.state.options, this.state.filter);
      }
    );
  };

  filterOS = (name, value) => {
    const newFilter = _.cloneDeep(this.state.options);
    _.set(newFilter.filter, name, value);
    this.setState({ options: newFilter }, () => {
      this.clearThisTimout();
      this.updateTable();
    });
  };

  clearThisTimout = () => {
    clearTimeout(timoutVar);
  };
  updateTable = () => {
    timoutVar = setTimeout(() => this.requestData(this.state.options), 1000);
  };

  componentDidMount() {
    this.requestData(this.state.options);
  }

  requestData = (options: any) => {
    this.props.loading(true);
    console.log(this.state.options.filter);
    taskService
      .findLastTaskFilter(this.props.user, this.state.options.filter, options)
      .then(response => {
        let data = response.data.data;
        this.setState({
          taskList: data.tarefas,
          dataTotalSize: data.size,
          currentPage: options.page,
          order: options.order,
          direction: options.direction
        });
        this.props.loading(false);
      })
      .catch(error => {
        this.props.loading(false);
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <TaskBox taskList={this.state.taskList} onChange={this.filterOS}>
          <FooterTablePagination
            pages={this.state.pages}
            sizePerPage={this.state.options.pageSize}
            dataTotalSize={this.state.dataTotalSize}
            onPageChange={this.handlePageChange}
          />
        </TaskBox>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;

  return {
    user
  };
}

const mapDispatch = ({ alert: { error }, load: { loading } }) => ({
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load })
});
const connectedTaskComponente = withRouter(Task);

export default connect(
  mapStateToProps,
  mapDispatch
)(connectedTaskComponente);
