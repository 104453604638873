//@flow
import { defaultService } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import type { ClienteType } from '../types/cliente.type';
import axios from 'axios';
export const clienteService = {
  getByDocumento,
  getByGrupoFilial,
  save,
  find,
  getById,
  findClienteGarantiaPorCpf
};
function findClienteGarantiaPorCpf(cpf){
  return axios.get(`${urlsConstants.CLIENTE_GARANTIA}${cpf.replace(/\D/g, '')}`,);
}


function getByDocumento(documento: string) {
  return defaultService.doGet(
    `${urlsConstants.CLIENTE_BY_DOCUMENTO}${documento}`
  );
}

function getByGrupoFilial(grupoId) {
  console.log('get by grupo filial')
  console.log(this.state)
  console.log(this.props)
  return defaultService.doGet(
    `${urlsConstants.CLIENTE_BY_GRUPO_FILIAL}${grupoId}`
  );
}

function find(input) {
  return defaultService.doGet(`${urlsConstants.CLIENT_BASE}find/${input}`);
}

function save(entity: ClienteType) {
  return defaultService.doPost(urlsConstants.CLIENT_BASE, entity);
}

function getById(id: number) {
  return defaultService.doGet(`${urlsConstants.CLIENT_BASE}${id}`);
}
