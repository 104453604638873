//@flow
import type { Options } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const pesquisaSatisfacaoService = {
  findByFiltro,
  getMediaPostoAutorizado
};

function getMediaPostoAutorizado(filter) {
  return defaultService.doPost(
    `${urlsConstants.RELATORIO}getAvaliacaoPosto`,
    filter
  );
}

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.RELATORIO}postoAutorizado`,
    options,
    filter
  );
}
