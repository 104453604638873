//@flow
import * as React from 'react';
import { marcaService } from '../../services/marca.service';
import { relogioService } from '../../services/relogio.service';
import SelectInput from '../../components/Inputs/SelectInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class RelogioCrudListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    console.log(entity);
    return (
      <React.Fragment>
        <SelectInput
          type="text"
          name="marca"
          label="Marca"
          placeholder="Marca"
          style={['col-6 col-sm-2']}
          onFetchData={marcaService.findCadastroManual}
          valueKey="id"
          labelKey="nome"
          returnFullObject={true}
          searchable={true}
          //messageValidate={validate.portadorDeficiencia}
          id={'marca'}
          onChange={this.props.onChangeSelect}
          value={entity.marca}
        />
        <SelectInput
          type="text"
          id={'relogioInput'}
          name={'codigoRelogio'}
          label={`Código do relógio*`}
          placeholder="Código do relógio"
          onFetchData={relogioService.findCadastroManual}
          valueKey="codigoRelogio"
          labelKey="codigoRelogio"
          returnFullObject={false}
          searchable={true}
          onChange={this.props.onChangeSelect}
          value={entity}
          style={['col-6 col-sm-2']}
          parent={entity.marca}
        />
      </React.Fragment>
    );
  }
}
