//@flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { objectsConstants } from '../../constants/objects.constants';
import { propertyConstantes } from '../../constants/property.constantes';
import { Can } from '@casl/react';

type Props = {};

const endPointValGarantia = '/configuracoes/valoresGarantia';
const endPointSintomasCausas = '/configuracoes/sintomasCausas';
const endPointCadastroPosto = '/postoHabilitado/novo/';
const endPointAlterarStatusOS = '/alterarstatusos';

class CardConfigGerais extends React.Component<Props> {
  render() {
    const { permissions } = this.props;

    return (
      <div className="card cardSeculus miniCard d-flex flex-column ">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">configurações gerais</h3>
          <div className="boxButon float-right d-sm-flex" />
        </div>

        <div className="conteudoCard mt-2">
          <ul className="list-group list-group-flush">
            <li className="list-group-item ">
              <a
                onClick={() => this.props.history.push(endPointValGarantia)}
                className="d-flex justify-content-between align-items-center"
              >
                VALORES DE ATENDIMENTO NA GARANTIA
                <i className="icon-arrowR" />
              </a>
            </li>
            <li className="list-group-item">
              <a
                onClick={() => this.props.history.push(endPointCadastroPosto)}
                className="d-flex justify-content-between align-items-center"
              >
                CADASTRO DE POSTO HABILITADO
                <i className="icon-arrowR" />
              </a>
            </li>
            <Can
              I={objectsConstants.VIEW}
              a={objectsConstants.ABILITY_ALTERAR_STATUS_OS}
              ability={permissions}
            >
              <li className="list-group-item">
                <a
                  onClick={() =>
                    this.props.history.push(endPointAlterarStatusOS)
                  }
                  className="d-flex justify-content-between align-items-center"
                >
                  ALTERAR STATUS DE OS
                  <i className="icon-arrowR" />
                </a>
              </li>
            </Can>
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, null)(withRouter(CardConfigGerais));
