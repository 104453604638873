//@flow
import { Can } from '@casl/react';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import BtnSecondary from '../../../components/Buttons/BtnSecondary';
import { CardItem } from '../../../components/templates/CardItem';
import { objectsConstants } from '../../../constants/objects.constants';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';
import TopoTitleComponente from '../../../views/Topo/TopoTitleComponente';
import PostoAutorizadoFormViewView from '../../postoAutorizado/PostoAutorizadoFormViewView';
import PostoAutorizadoClientList from './PostoAutorizadoClientList';
import PostoAvaliacaoAtendimento from './PostoAvaliacaoAtendimento';

type State = { colaborador: any, entity: any };

class PesquisaSatisfacaoPosto extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      posto: {},
      totalAvaliacoes: 0,
      entity: { id: this.props.match.params.id }
    };
  }

  componentDidMount() {
    postoAutorizadoService.doGet(this.props.match.params.id).then(response => {
      let posto = response.data.data;
      this.setState({ posto });
    });
  }

  changePosto = () => {
    this.props.history.push('/report/pesquisaSatisfacao');
  };

  getBtns = () => {
    return <BtnSecondary title={'Alterar posto'} onSubmit={this.changePosto} />;
  };

  render() {
    const { permissions } = this.props;
    return (
      <React.Fragment>
        {this.state.posto.id && (
          <TopoTitleComponente
            mainTitle="RELATÓRIO"
            subTitle={` Pesquisa de satisfação - ${
              this.state.posto.nomeFantasia
            }`}
            canBack={true}
          />
        )}
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <div className="flex-column">
            {this.state.posto.id && (
              <CardItem title="POSTO AUTORIZADO" getBtns={this.getBtns}>
                <PostoAutorizadoFormViewView
                  entity={this.state.posto}
                  readOnly={true}
                  noLoadAvaliacao={true}
                />
              </CardItem>
            )}
          </div>
          <div className="flex-column">
            {this.state.posto.id && (
              <PostoAvaliacaoAtendimento entity={this.state.posto} />
            )}
            <PostoAutorizadoClientList filter={this.state.entity} />
          </div>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}
export default connect(
  mapStateToProps,
  null
)(withRouter(PesquisaSatisfacaoPosto));
