//@flow
import type { Options } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const postoAutorizadoService = {
  doGet,
  doSave,
  doDelete,
  validStepOne,
  validStepTwo,
  saveFaixada,
  saveFotoInterna,
  saveContrato,
  doRegister,
  autorizar,
  findResponsavelLegal,
  find,
  findAll,
  findByFiltro,
  findByCidade,
  getTotal,
  inativarPosto,
  getByDocumento,findMarcaBloqueada,
  saveMarcas
};

function getTotal() {
  return defaultService.doGet(`${urlsConstants.POSTO_AUTORIZADO}getTotal`);
}

function find(input) {
  return defaultService.doGet(`${urlsConstants.POSTO_AUTORIZADO}find/${input}`);
}

function findByCidade(cidade: any, input: any) {
  return defaultService.doPost(
    `${urlsConstants.POSTO_AUTORIZADO}findByCidade/${cidade.id}`,
    { description: input }
  );
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.POSTO_AUTORIZADO}`);
}

function saveFotoInterna(id: number, midiaVO: any) {
  return defaultService.doPost(
    urlsConstants.POSTO_AUTORIZADO_INTERNO + id,
    midiaVO
  );
}

function saveFaixada(id: number, midiaVO: any) {
  return defaultService.doPost(
    urlsConstants.POSTO_AUTORIZADO_FAIXADA + id,
    midiaVO
  );
}

function saveContrato(id: number, midiaVO: any) {
  return defaultService.doPost(
    urlsConstants.POSTO_AUTORIZADO_CONTRATO_SOCIAL + id,
    midiaVO
  );
}

function doGet(id: number) {
  return defaultService.doGet(`${urlsConstants.POSTO_AUTORIZADO}${id}`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.POSTO_AUTORIZADO, entity);
}

function doRegister(entity: any) {
  return defaultService.doSave(urlsConstants.POSTO_AUTORIZADO_REGISTER, entity);
}

function validStepOne(entity) {
  return defaultService
    .doPost(urlsConstants.POSTO_AUTORIZADO_VALID_STEP_ONE, entity)
    .then(response => {
      return response;
    });
}

function validStepTwo(entity) {
  return defaultService
    .doPost(urlsConstants.POSTO_AUTORIZADO_VALID_STEP_TWO, entity)
    .then(response => {
      return response;
    });
}

function autorizar(id: number) {
  return defaultService.doPost(
    urlsConstants.POSTO_AUTORIZADO_AUTORIZAR + id,
    {}
  );
}
function findResponsavelLegal(id: number) {
  return defaultService.doGet(urlsConstants.GET_RESPONSAVEL_LEGAL + id);
}
function findMarcaBloqueada(id: number) {
  return defaultService.doGet(`${urlsConstants.POSTO_AUTORIZADO}findMarcaBloqueada/${id}`);
}
function saveMarcas(marcas,id: number) {
  return defaultService.doPost(`${urlsConstants.POSTO_AUTORIZADO}saveMarcasBloqueada/${id}`,marcas);
}
function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.POSTO_AUTORIZADO}list`,
    options,
    filter
  );
}

function doDelete(id: number) {
  return defaultService.doDelete(`${urlsConstants.POSTO_AUTORIZADO}${id}`);
}

function inativarPosto(id: number) {
  return defaultService.doGet(`${urlsConstants.INATIVAR_POSTO}${id}`);
}

function getByDocumento(documento: string) {
  return defaultService.doGet(
    `${urlsConstants.POSTO_BY_DOCUMENTO}${documento}`
  );
}
