//@flow
import moment from 'moment/moment';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import { importacaoHelper } from '../../helpers/importacao.helper';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class ImportacaoValoresPecasListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;

    const { permissions } = this.props;
    return (
      <React.Fragment>
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataCriacao"
          label="Data inicio"
          value={entity.dataCriacao ? moment(entity.dataCriacao) : null}
          onChange={this.props.onChangeDate}
          size={12}
          style={'col-sm-1'}
          id={'dataCriacao'}
        />

        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataFim"
          label="Data fim"
          value={entity.dataFim ? moment(entity.dataFim) : null}
          onChange={this.props.onChangeDate}
          size={12}
          style={'col-sm-1'}
          id={'dataFim'}
        />

        <StatusSelectInput
          label="Status Importação"
          placeholder={'Status'}
          id={'status'}
          name="status"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-2'}
          value={entity.status}
          service={importacaoHelper.getListStatusImportacao}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions,
  };
}

const connectedMenu = withRouter(
  connect(
    mapStateToProps,
    null
  )(ImportacaoValoresPecasListFilter)
);
export { connectedMenu as ImportacaoValoresPecasListFilter };
