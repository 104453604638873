//@flow
import type { Options } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const osExpiradaService = {

  findByFiltro
};


function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.RELATORIO_OS_EXPIRADA}`,
    options,
    filter
  );
}
