//@flow
import { propertyConstantes } from '../constants/property.constantes';

export const docHelper = {
  getListStatus,
  getListStatusTroca
};

function getListStatus() {
  return [
    { id: propertyConstantes.NOVO, nome: 'Novo' },
    { id: propertyConstantes.ATENDIDO, nome: 'Atendido' },
    { id: propertyConstantes.PENDENTE, nome: 'Pendente' },
    { id: 'TODOS', nome: 'Todos' }
  ];
}

function getListStatusTroca() {
  return [
    {
      id: propertyConstantes.AGUARDANDO_ENVIO_RELOGIO,
      nome: 'Aguardando relógio'
    },
    {
      id: propertyConstantes.AGUARDANDO_RELOGIO_DE_TROCA,
      nome: 'Troca Atendida'
    },
    {
      id: propertyConstantes.TROCA_MODELO_EM_ANDAMENTO,
      nome: 'Troca do modelo em andamento'
    }
  ];
}
