//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import connect from "react-redux/es/connect/connect";
import { alertActions } from '../../actions/alert.action';
import { FormRow } from '../../components/FormRow';
import IconErrorToast from '../../components/ToastIcons/IconErrorToast';
import { responsibleConstant } from '../../constants/objects.constants';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import RegisterSecondStepForm from './RegisterSecondStepForm';

type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  emailValidation: any,
  passwordValidation: any,
  validateObject: any
};

const objectValidateResponsibleVO = [
  responsibleConstant.nomeValidate,
  responsibleConstant.dataNascimentoValidate,
  responsibleConstant.cpfValidate,
  responsibleConstant.emailValidate,
  responsibleConstant.confirmarEmailValidate,
  responsibleConstant.celularValidate,
  responsibleConstant.senhaValidate,
  responsibleConstant.confirmarSenhaValidate
];

class RegisterSecondStep extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: _.cloneDeep(this.props.entity)
    };
  }

  nextStep = () => {
    const toastrOptionsSuccess = { icon: <IconErrorToast /> };
    this.props.loading(true);
    //TODO modificar maneira que foi feita
    postoAutorizadoService
      .validStepTwo(this._form.getEntity())
      .then(response => {
        this.props.loading(false);
        alertActions.clear();
        this.props.handleEntity(this._form.getEntity(), 3);
      });
  };

  render() {
    const { entity } = this.state;
    return (
      <div className="tab-pane active" id="responsavel">
        <FormRow>
          <h3>Responsável pela empresa</h3>
        </FormRow>

        <RegisterSecondStepForm
          entity={entity}
          ref={forms => {
            this._form = forms;
          }}
        />
      </div>
    );
  }
}


const mapDispatch = ({
                       alert: { success, error, clear, info },
                       load: { loading },
                     }) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  info: msg => info(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(RegisterSecondStep);


