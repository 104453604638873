//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ImportacaoPecasListagem from './ImportacaoPecasListagem';

export default class ImportacaoPecas extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/importacaoPecas"
            exact
            component={ImportacaoPecasListagem}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
