//@flow
import * as React from 'react';
import FormInput from '../../components/Inputs/FormInput';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class GrupoFilialListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Documento Filial"
          placeholder={'Documento Filial'}
          id={'filialDocumento'}
          name="filialDocumento"
          onChange={this.props.onChangeText}
          type={'text'}
          size={12}
          style={'col-sm-4'}
          value={entity.filialDocumento}
        />

        <FormInput
          label="Tempo Garantia"
          placeholder={'Tempo Garantia'}
          id={'tempo_garantia'}
          name="tempo_garantia"
          onChange={this.props.onChangeText}
          type={'text'}
          size={12}
          style={'col-sm-4'}
          value={entity.tempo_garantia}
        />
      </React.Fragment>
    );
  }
}
