//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import { Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import { urlsConstants } from '../../constants/url.constant';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { colaboradorService } from '../../services/colaborador.service';
import ColaboradorCrudListFilter from './ColaboradorCrudListFilter';
import {
  objectsConstants,
  constHelper
} from '../../constants/objects.constants';
const columns = [
  {
    path: 'avatar',
    title: '',
    isKey: false,
    dataSort: false
  },
  {
    path: 'name',
    title: 'Nome Colaborador',
    isKey: false,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'role.description',
    title: 'Perfil',
    isKey: false,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'email',
    title: 'E-mail',
    isKey: false,
    dataSort: false
  },
  {
    path: 'cpf',
    title: 'CPF',
    isKey: true,
    dataSort: false
  },
  {
    path: 'status',
    title: 'Status',
    isKey: false,
    dataSort: false
  }
];

const endPoint = '/ColaboradorCrud/edit/';
const endPointView = '/ColaboradorCrud/view/';
class ColaboradorCrudList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Novo Colaborador'
      }
    });
  };

  getAvatar(entity: any) {
    if (entity.foto) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
    }
  }
  renderCustom(row: any, entity: any, column: any) {
    var avatarUrl = require('../../img/avatarDefault.jpg');
    if (column.path == 'avatar') {
      if (entity.foto) {
        avatarUrl = `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
      }
      return (
        <img
          src={avatarUrl}
          alt="avatar"
          className={`rounded-circle m-auto `}
          width="50"
          height="50"
        />
      );
    } else if (column.path == 'status') {
      if (entity.status) {
        return <div className={`txtGreen`}>ATIVO</div>;
      } else {
        return <div className={`txtRed`}>INATIVO</div>;
      }
    } else if (column.path == 'role') {
      return (
        <React.Fragment> {_.get(constHelper, entity.role)}</React.Fragment>
      );
    } else {
      return tableHelper.renderCustom(row, entity, column);
    }
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Colaboradores"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`COLABORADORES`}
          newButtonActionClick={this.handleNewButton}
          delete={() => {}}
          service={colaboradorService}
          labelNewButton="Novo Colaborador"
          defaultOrder={'id'}
          defaultDirection={'desc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={ColaboradorCrudListFilter}
          controller="colaborador"
          roleNewButton={objectsConstants.ABILITY_COLABORADORES}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ColaboradorCrudList);
