//@flow
import React, { PureComponent } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { propertyConstantes } from '../../../constants/property.constantes';

type Props = {
  onReprove: any,
  onAprove: any
};

class CancelarREBtn extends PureComponent<Props> {
  handleConfirm = () => {
    let ordemServico = this.props.ordemServico;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui success">
            <h3>Desfazer Reincidencia</h3>
            <p className="p-msg">Deseja desfazer a Reincidencia ?</p>
            <button
              className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
              onClick={() => {
                onClose();
              }}
            >
              CANCELAR
            </button>
            <button
              className="btn btn-primary my-4 mx-lg-3"
              onClick={() => {
                this.props.onConfirm();
                onClose();
              }}
            >
              SIM
            </button>
          </div>
        );
      }
    });
  };

  render() {
    const { ordemServico } = this.props;
    return (
      <React.Fragment>
        {ordemServico && (
          <div className="justify-content-between btn-bold ">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.handleConfirm}
            >
              Desfazer RE
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(mapStateToProps)(CancelarREBtn);
