//@flow
import * as React from 'react';
import SelectInput from './SelectInput';
import { objectsConstants } from '../../constants/objects.constants';
import { connect } from 'react-redux';
import { perfilService } from '../../services/perfil.service';

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service: any
};

type State = {
  options: Array<any>
};
class RoleSelectInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    perfilService.findAll().then(
      data => {
        let options = [];
        options = options.concat(data.data);
        this.setState({ options: options });
      },
      error => {
        console.log('Erro ao montar o combo', error);
      }
    );
  }

  render() {
    return (
      <SelectInput
        label={this.props.label}
        valueKey={'id'}
        labelKey={'description'}
        name={this.props.name}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        multi={false}
        returnFullObject={true}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        noSize={this.props.noSize}
        clearable={true}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(RoleSelectInput);
