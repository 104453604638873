import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CardServicosItem } from './CardServicosItem';
import { CardServicoItemView } from './CardServicoItemView';
import { propertyConstantes } from '../../constants/property.constantes';
import { ordemServicoService } from '../../services/ordemServico.service';

type Props = { entity: any };
type State = {
  servicos: any,
  entity: any
};

export class CardServicosComponente extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const servicos = _.map(props.listServicoOrdemServico, function(
      servico,
      index
    ) {
      return { posicao: index, servico: _.clone(servico) };
    });
    if (
      props.listServicoOrdemServico &&
      props.listServicoOrdemServico.length > 0
    ) {
      this.state = {
        servicos: servicos,
        idOs: props.ordemServico.id
      };
      this.props.onAddServico(servicos);
    } else {
      this.state = {
        servicos: [{ posicao: 0, servico: { valorFinal: 0 } }],
        idOs: props.ordemServico.id
      };
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.listServicoOrdemServico &&
      nextProps.listServicoOrdemServico.length > 0
    ) {
      if (nextProps.ordemServico.id != this.state.idOs) {
        this.setState(
          {
            servicos: _.map(nextProps.listServicoOrdemServico, function(
              servico,
              index
            ) {
              return { posicao: index, servico: _.clone(servico) };
            }),
            idOs: nextProps.ordemServico.id
          },
          () => {
            nextProps.onAddServico(this.state.servicos);
          }
        );
      }
    } else {
      if (nextProps.ordemServico.id != this.state.idOs) {
        this.setState(
          {
            servicos: [{ posicao: 0, servico: {} }],
            idOs: nextProps.ordemServico.id
          },
          () => {
            nextProps.onAddServico(this.state.servicos);
          }
        );
      }
    }
  }

  handleChangeItem = (posicao: any, newItem: any) => {
    const { servicos } = this.state;
    let newEntity = _.cloneDeep(servicos);
    //add item in object
    if (newItem) {
      newEntity = _.map(servicos, function(item, index) {
        return index === posicao ? newItem : item;
      });
    } //remove item from array
    else {
      if (newEntity.length > 1) {
        _.remove(newEntity, function(currentObject, index) {
          return index === posicao;
        });
      } else {
        //alerta de erro
      }
    }
    this.setState({ servicos: newEntity }, () => {
      this.props.onAddServico(this.state.servicos);
    });
  };
  removeServico = (index, id) => {
    let servicos = _.map(this.state.servicos, _.clone);
    servicos.splice(index, 1);
    this.setState({ servicos: servicos }, () => {
      this.props.onAddServico(this.state.servicos);
    });
  };
  itemRow = (servico, posicao) => {
    console.log(this.props);
    return (
      <CardServicosItem
        key={posicao}
        submit={this.props.submit}
        posicao={posicao}
        removeServico={this.removeServico.bind(this)}
        id={posicao}
        entity={servico}
        onChange={this.handleChangeItem}
      />
    );
  };

  addRow = () => {
    this.addItem();
  };

  editOs = () => {
    ordemServicoService.confirmChangeStatus(this.props.onConfirmChangeStatus);
  };

  addItem = () => {
    let servicos = _.map(this.state.servicos, _.clone);
    servicos.push({
      posicao: this.state.servicos.length,
      servico: {}
    });
    this.setState({ servicos: servicos });
  };

  viewServico = servico => {
    return <CardServicoItemView key={servico.posicao} entity={servico} />;
  };
  getBotaoAddPeca = () => {
    if (
      this.props.statusOS === propertyConstantes.CENTRO_TECNICO ||
      this.props.statusOS === propertyConstantes.APROVAR_ORCAMENTO
    ) {
      if (this.props.statusOS === propertyConstantes.APROVAR_ORCAMENTO) {
        return (
          <div className="text-right py-2" onClick={this.editOs}>
            <span className="icon-badge">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </span>{' '}
            <a>Editar </a>
          </div>
        );
      } else {
        return (
          <div className="text-right py-2" onClick={this.addRow}>
            <span className="icon-badge">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </span>{' '}
            <a>Serviços </a>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="flex-column col-12 pt-2">
          <div className="miniTxt mb-1">Serviços adicionados</div>

          {this.state.servicos.map((servico, index) => {
            if (this.props.statusOS === propertyConstantes.CENTRO_TECNICO) {
              return this.itemRow(servico, servico.posicao);
            } else {
              if (servico.servico && servico.servico.servico) {
                return this.viewServico(servico, servico.posicao);
              }
            }
          })}
          {this.getBotaoAddPeca()}
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(CardServicosComponente);
