//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import { tableHelper } from '../../helpers/tableHelper';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import PostoAutorizadoFilter from './PostoAutorizadoFilter';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import ListagemPage from '../../components/templates/ListagemPage';
import { objectsConstants } from '../../constants/objects.constants';
const columns = [
  {
    path: 'numPA',
    title: 'Registro',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  {
    path: 'nomeFantasia',
    title: 'Posto autorizado',
    isKey: false,
    dataSort: true
  },
  {
    path: 'documentoModal',
    title: 'CPF/CNPJ',
    isKey: false,
    dataSort: false
  },
  { path: 'estadoCidade', title: 'Cidade', isKey: false, dataSort: false },
  {
    path: 'paStatus',
    title: 'Cadastro',
    isKey: false,
    dataSort: false
  },
  {
    isKey: false,
    isFarol: true,
    path: 'aprovado'
  }
];

type State = {
  modal: boolean
};

const endPoint = '/postoAutorizado/';
const endPointHabilitado = '/postoHabilitado/novo/';
class PostoAutorizadoPageList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let modal = false;

    if (this.props.location.state && this.props.location.state.showModal) {
      modal = this.props.location.state.showModal;
    }

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      modal,
      fields
    };
  }

  handleRowClick = (row: e) => {
    // let urlOS = urlsConstants.ORDEM_SERVICO + row.id;
    // postoAutorizadoService.doGet(urlOS).then(response => {
    //   let os = response.data.data;
    //   this.props.setOrdemServico(os);
    //   this.props.setCliente(os.cliente);
    // });

    this.props.history.push('/postoAutorizado/' + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push({
      pathname: endPointHabilitado,
      state: {
        titlePage: 'Posto Habilitado'
      }
    });
  };

  renderCustom(row: any, postoAutorizado: any, column: any) {
    if (column.path === 'aprovado' && postoAutorizado.aprovado) {
      return <div className={`farol farol-verde`} />;
    } else if (column.path === 'aprovado' && !postoAutorizado.aprovado) {
      return <div className={`farol farol-vermelho`} />;
    } else {
      return tableHelper.renderCustom(row, postoAutorizado, column);
    }
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Postos Autorizados"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`POSTOS AUTORIZADOS(${this.props.subTitle})`}
          delete={() => {}}
          service={postoAutorizadoService}
          defaultOrder={'nomeFantasia'}
          defaultDirection={'asc'}
          defaultFilter={{ endereco: {} }}
          onRowClick={this.handleRowClick}
          FormFilter={PostoAutorizadoFilter}
          newButtonActionClick={this.handleNewButton}
          labelNewButton="Novo Posto Habilitado"
          roleNewButton={objectsConstants.ABILITY_CADASTRAR_POSTO_HABILITADO}
          showNewButton={true}
          controller="postoAutorizado"
          fields={this.state.fields}
          classSignal={'temSinal'}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(mapStateToProps, mapDispatch)(PostoAutorizadoPageList);
