//@flow
import * as React from 'react';
import CardGrupoMarca from '../../components/Cards/CardGrupoMarca';
import CardHistorico from '../../components/Cards/CardHistorico';
import connect from 'react-redux/es/connect/connect';
import { grupoMarcaService } from '../../services/grupoMarca.service';
import { propertyConstantes } from '../../constants/property.constantes';
import BoxOrdemServico from '../boxOrdemServico/BoxOrdemServico';
import Toast from '../../components/Toasts/Toast';
import TopoTitleComponente from '../Topo/TopoTitleComponente';

type State = { grupoMarca: any };

class GrupoMarcaView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { grupoMarca: {} };
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      grupoMarcaService.getById(this.props.match.params.id).then(result => {
        let grupoMarca = result.data.data;
        this.setState({ grupoMarca });
      });
    }
  }

  render() {
    const { grupoMarca } = this.state;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Grupos de marcas"
          subTitle=" "
          canBack={true}
        />
        <div className="flex-column">
          <CardGrupoMarca
            grupoMarca={grupoMarca}
            titlePageEdit="Grupos de marcas"
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;

  return {
    cliente
  };
}

const mapDispatch = ({ ordemServico: { createOrdemServico } }) => ({
  createOrdemServico: osType => createOrdemServico(osType)
});

export default connect()(GrupoMarcaView);
