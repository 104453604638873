//@flow
import * as React from 'react';
import Datatable from '../table/Datatable';
import { connect } from 'react-redux';
import _ from 'lodash';
import { objectsConstants } from './../../constants/objects.constants';
import axios from 'axios';
import CardSeculusFull from '../panels/CardSeculusFull';
import HeaderList from './HeaderList';

type Props = {
  title?: string,
  children: any,
  FormFilter?: any,
  DetalhePage: any,
  newButtonActionClick: Function | string,
  showNewButton: boolean,
  defaultOrder: string,
  defaultDirection: string,
  service: any,
  deleteSuccess: string,
  labelNewButton: string,
  defaultFilter: any,
  onRowClick: any,
  noFilter?: boolean
};

type State = { filter: any, filterForm: any, submitFilter: boolean };

class ListagemDetalhePage extends React.Component<Props, State> {
  static defaultProps = {
    defaultOrder: 'id',
    showNewButton: true,
    deleteSuccess: 'Registro excluído com sucesso.',
    forceUpdate: false
  };

  _datatable: any;
  _source: any;

  constructor(props: any) {
    super(props);
    this._source = axios.CancelToken.source();
    let filter = this.props.defaultFilter
      ? this.props.defaultFilter
      : objectsConstants.defaultFilter;
    this.state = { filter, filterForm: {}, submitFilter: false };
  }

  handleValue = (value: any, name: string) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeData = (name, value) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    const newFilter = _.cloneDeep(this.state.filter);
    _.set(newFilter, name, type === 'checkbox' ? checked : value);
    this.setState({ filter: newFilter, submitFilter: false });
  };
  _handleChangeMonthYear = ( name: string,month: number, year: number) => {
    const filter = _.cloneDeep(this.state.filter);
    if (!name) {
      _.set(filter, 'mes', month);
      _.set(filter, 'ano', year);
    } else {
      _.set(filter, `${name}Mes`, month);
      _.set(filter, `${name}Ano`, year);
    }
    this.setState({ filter, submitFilter: false });
  };
  handleSelectChange = (name: string, option: any) => {
    const { filter } = this.state;
    let newfilter = _.cloneDeep(filter);
    _.set(newfilter, name, option);
    this.setState({ filter: newfilter, submitFilter: false });
  };
  handleFilter = (e: SyntheticEvent<>) => {
    if (e) {
      e.preventDefault();
    }

    this.setState(
      {
        filterForm: Object.assign({}, this.state.filter),
        submitFilter: true
      },
      () => {
        this._datatable.getWrappedInstance().resetTable(this.state.filter);
      }
    );
  };

  componentWillUnmount() {
    this._source.cancel('Operation canceled due component being unmounted.');
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.forceUpdate) {
      this._datatable.getWrappedInstance().updateTable();
    }
    this.setState({ submitFilter: false });
  }

  render() {
    const {
      newButtonActionClick,
      showNewButton,
      labelNewButton,
      title,
      FormFilter,
      DetalhePage
    } = this.props;
    return (
      <CardSeculusFull>
        <HeaderList
          title={title}
          labelNewButton={labelNewButton}
          actionClick={newButtonActionClick}
          showNewButton={showNewButton}
          filter={this.state.filter}
          controller={this.props.controller}
          fields={this.props.fields}
          labels={this.props.labels}
        />

        <div className="conteudoCard">
          {FormFilter && (
            <div className="form-row searchBox">
              <div className="col-12 mb-3">
                <div className="miniTxt">Pesquisa</div>
              </div>
              <FormFilter
                onChangeSelect={this.handleSelectChange}
                onChangeText={this._handleChangeText}
                onChangeValue={this.handleValue}
                onChangeDate={this._handleChangeData}
                onChangeMonthYear={this._handleChangeMonthYear}
                entity={this.state.filter}
              />
              <div className="form-group col-12 col-sm-12 ">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm float-right"
                  onClick={this.handleFilter}
                >
                  buscar
                </button>
              </div>
            </div>
          )}
          <div className="row separator" />
          <DetalhePage
            filter={this.state.filter}
            submitFilter={this.state.submitFilter}
            loadOnInit={this.props.loadOnInit}
          />
          <Datatable
            ref={datatable => (this._datatable = datatable)}
            defaultOrder={this.props.defaultOrder}
            defaultDirection={this.props.defaultDirection}
            service={this.props.service}
            filter={this.state.filter}
            onRowClick={this.props.onRowClick}
            submitFilter={this.state.submitFilter}
            loadOnInit={this.props.loadOnInit}
          >
            {this.props.children}
          </Datatable>
        </div>
      </CardSeculusFull>
    );
  }
}

const mapDispatch = ({ alert: { error, success } }) => ({
  error: message => error({ message }),
  success: message => success({ message })
});
export default connect(null, mapDispatch, null, { withRef: true })(
  ListagemDetalhePage
);
