//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { objectsConstants } from '../../constants/objects.constants';
import { validateInput } from '../../helpers/validateInputs.helper';
import { enderecoService } from '../../services/endereco.service';
import connect from 'react-redux/es/connect/connect';

type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  country: Array<any>,
  states: Array<any>,
  cities: Array<any>,
  validateObject: any,
  onBack: any,
  step: number,
  children?: any,
  FormContent: any
};

const objectValidateBranchVO = [
  objectsConstants.cnpjValidate,
  objectsConstants.razaoSocialValidate,
  objectsConstants.nomeFantasiaValidate,
  objectsConstants.telefoneComercialValidate,
  objectsConstants.emailComercialValidate,
  objectsConstants.horaFuncionamentoValidate
];

const objectValidateAddressVO = [
  objectsConstants.cepValidate,
  objectsConstants.ruaValidate,
  objectsConstants.numeroValidate,
  objectsConstants.bairroValidate,
  objectsConstants.paisValidate,
  objectsConstants.estadoValidate,
  objectsConstants.cidadeValidate
];

class RegisterFirstStepForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      entity: _.cloneDeep(this.props.entity),
      validateObject: {}
    };
  }

  handleChangebranchVO = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    let entity = _.cloneDeep(this.state.entity);

    _.set(entity, name, type === 'checkbox' ? checked : value);
    this.setState({ entity });
  };
  handleZipCode = (location, zipCode) => {
    console.log(location);
    this.props.loading(true);
    let entity = _.cloneDeep(this.state.entity);
    if (location.erro) {
      _.set(entity, 'cep', zipCode);
      this.setState({ entity });
      this.props.loading(false);
    } else {
      this.props.loading(true);
      _.set(entity, 'cep', zipCode);
      _.set(entity, 'pais', objectsConstants.PAIS_DEFAULT);
      enderecoService
        .findEstadoExact(location.uf, objectsConstants.PAIS_DEFAULT)
        .then(estado => {
          _.set(entity, 'estado', estado.data);
          enderecoService
            .findCidadeExact(location.localidade, estado.data)
            .then(cidade => {
              _.set(entity, 'cidade', cidade.data);
              _.set(entity, 'bairro', location.bairro);
              _.set(entity, 'rua', location.logradouro);
              this.props.loading(false);
              this.setState({ entity });
            });
        });
    }
  };

  handleSelectChange = (name: string, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);

    newEntity[name] = option;
    this.setState({ entity: newEntity });
  };
  getEntity = () => {
    return this.state.entity;
  };
  validateForms = () => {
    const { entity } = this.state;
    let isValid;
    let validate = {};

    objectValidateBranchVO.forEach(o => {
      const value = entity[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });
    objectValidateAddressVO.forEach(o => {
      const value = entity[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });

    this.setState({ validateObject: validate });

    if (_.isEmpty(validate)) {
      isValid = true;
    }

    return isValid;
  };

  render() {
    const { entity, validateObject } = this.state;
    const { FormContent } = this.props;
    return (
      <form>
        <FormContent
          entity={entity}
          validateObject={validateObject}
          handleChange={this.handleChangebranchVO}
          handleSelectChange={this.handleSelectChange}
          handleZipCode={this.handleZipCode}
        />
      </form>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading }
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  info: msg => info(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(RegisterFirstStepForm);
