//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import '../../css/doc_manaus.css';
import { dateHelper } from '../../helpers/date.helper';
import { pecaService } from '../../services/peca.service';
import { propertyConstantes } from '../../constants/property.constantes';
import _ from 'lodash';
import { Input } from 'reactstrap';
import { objectsConstants } from '../../constants/objects.constants';
import { Can } from '@casl/react';

type Props = {
  onChangeText: any,
  entity: any,
  onChangeValue: any,
  onTrocaPeca: any,
};

class AguardandoImportacaoPecaDetailsItemPeca extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    if (props.peca.docDetailsView && props.peca.docDetailsView.naoAtendida) {
      this.state = {
        estoque: 0,
        entity: { naoAtendida: props.peca.docDetailsView.naoAtendida },
      };
    } else {
      this.state = { estoque: 0, entity: {} };
    }
  }

  componentDidMount() {
    this.getEstoque(this.props.peca.peca.id);
  }

  // getFarolItem = () => {
  //   const { entity } = this.props;
  //   let classFarol = ordemServicoHelper.getFarol(entity);
  //   return <div className={`farol ${classFarol} ml-0 mr-2`} />;
  // };

  getEstoque(idPeca) {
    const { peca } = this.props;
    if (peca.dataAtendida === undefined) {
      pecaService.getEstoque(idPeca).then((result) => {
        this.setState({ estoque: result.data });
      });
    } else {
      this.setState({ estoque: '--' });
    }
  }

  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { peca, entity, lasItem, index } = this.props;
    const { name, value } = e.currentTarget;
    const newEntity = _.cloneDeep(this.state.entity);
    let valueFiltered = number(value);
    if (
      valueFiltered >
      (peca.docDetailsView != null
        ? peca.docDetailsView.quantidadePendente
        : peca.quantidade)
    ) {
      valueFiltered =
        peca.docDetailsView != null
          ? peca.docDetailsView.quantidadePendente
          : peca.quantidade;
      this.props.error(
        'Ops, a quantidade enviada não pode ser maior que a solicitada.'
      );
    }
    if (value === '') {
      this.props.error('A quantidade enviada não pode estar zerada.');
    }
    _.set(newEntity, name, valueFiltered);
    this.setState({ entity: newEntity }, () => {
      this.props.onSetPeca({
        idPeca: this.props.peca.id,
        quantidadeEnviada: this.state.entity.quantidadeEnviada,
        quantidadeSolicitada: this.props.peca.quantidade,
        idOs: this.props.entity.id,
      });
    });
  };

  naoAtenderPeca = () => {
    const { peca, entity, lasItem, index } = this.props;
    const newEntity = _.cloneDeep(this.state.entity);
    _.set(newEntity, 'naoAtendida', true);
    this.setState({ entity: newEntity }, () => {
      this.props.onSetPeca({
        idPeca: this.props.peca.id,
        quantidadeEnviada: this.state.entity.quantidadeEnviada,
        quantidadeSolicitada: this.props.peca.quantidade,
        idOs: this.props.entity.id,
        naoAtendida: newEntity.naoAtendida,
      });
    });
  };
  atenderPeca = () => {
    const { peca, entity, lasItem, index } = this.props;
    console.log(2);
    const newEntity = _.cloneDeep(this.state.entity);
    _.set(newEntity, 'naoAtendida', false);
    this.setState({ entity: newEntity }, () => {
      this.props.onSetPeca({
        idPeca: this.props.peca.id,
        quantidadeEnviada: this.state.entity.quantidadeEnviada,
        quantidadeSolicitada: this.props.peca.quantidade,
        idOs: this.props.entity.id,
        naoAtendida: newEntity.naoAtendida,
      });
    });
  };

  getTrsPecasAdptadas = (peca) => {
    let trs = [];
    for (let i = 0; i < peca.docDetailsView.quantidadeAdptada; i++) {
      let lastItem = peca.docDetailsView.quantidadeAdptada.length - 1 == i;
      trs.push(this.getTrsPecasAdptadasContent(peca, lastItem));
    }
    return trs;
  };

  getTrsPecasAdptadasContent = (peca, lastItem) => {
    return (
      <React.Fragment>
        <tr
          className={`itemListagemOS ${
            lastItem ? '' : 'trocaLast'
          } align-items-center text-left`}
        >
          <td className="marca">{''}</td>

          <td className="pecas">{''}</td>
          <td className="pecas">{peca.codigoPeca ? peca.codigoPeca : '--'}</td>

          <td className="codigo">{peca.docDetailsView.codigoPecaTroca}</td>
          <td className={`faturar ${peca.garantia ? '' : 'text-primary '}`}>
            <b>{''}</b>
          </td>
          <td className="quantidade solcitada">{peca.quantidade} </td>
          <td className="quantidade">
            {peca.docDetailsView.quantidadeAdptada}
          </td>
          <td
            className={`estoque ${
              this.state.estoque == 0 ? 'text-danger' : ''
            }`}
          >
            {''}
          </td>
          <td className="quantidade-enviada">
            {peca.docDetailsView.quantidadeAdptada}
          </td>

          <td className="Atendida  txtBlue">
            {peca.docDetailsView.status.description}
          </td>

          {(peca.docDetailsView != null
            ? peca.docDetailsView.quantidadePendente
            : peca.quantidade) <= this.state.estoque &&
          !peca.naoAtendida &&
          !this.state.entity.naoAtendida &&
          (peca.docDetailsView == null ||
            (peca.docDetailsView.status.description != 'Atendida' &&
              peca.docDetailsView.status.description !=
                'Substituição em Andamento')) ? (
            <td className="Atendida  txtRed">
              <b>Pendente</b>
            </td>
          ) : (
            ''
          )}
          <td className="Atendida  txtBlue">{}</td>

          <td className="codRastreio">
            {peca.docDetailsView != null
              ? peca.docDetailsView.codigoRastreio
              : ''}
          </td>

          <td className="data">
            {peca.docDetailsView != null &&
              peca.docDetailsView.dataAtendida != null &&
              dateHelper.format(peca.docDetailsView.dataAtendida, {
                pattern: 'DD/MM/YYYY',
              })}
          </td>
        </tr>
      </React.Fragment>
    );
  };

  render() {
    const { peca, entity, lasItem, index } = this.props;
    let MaquinaOuModulo =
      peca.peca.parte.nome === propertyConstantes.MAQUINA ||
      peca.peca.parte.nome === propertyConstantes.MODULO;
    const { permissions } = this.props;
    return (
      <React.Fragment>
        <tr
          className={`itemListagemOS ${
            peca.docDetailsView != null &&
            peca.docDetailsView.quantidadeAdptada != null
              ? 'trocaMain'
              : ''
          } align-items-center text-left`}
        >
          <td className="marca">
            {index === 0 &&
              (entity.relogio
                ? entity.relogio.marca.nome
                : peca.peca.relogio
                ? peca.peca.relogio.codigoRelogio
                : '')}
          </td>
          <td className="pecas">{peca.peca.nome}</td>
          <td className="pecas">{peca.codigoPeca ? peca.codigoPeca : '--'}</td>

          <td className="codigo">{peca.peca.codigoPeca}</td>

          <td className="quantidade solcitada">{peca.quantidade} </td>
        </tr>

      </React.Fragment>
    );
  }
}

function number(v) {
  //Remove tudo o que não é dígito
  v = v.replace(/\D/g, '');
  return v;
}

const mapDispatch = ({ alert: { error, success } }) => ({
  error: (message) => error({ message }),
  success: (message) => success({ message }),
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(AguardandoImportacaoPecaDetailsItemPeca);
