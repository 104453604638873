//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import ValoresGarantiaList from '../../views/configuracoes/ValoresGarantiaList';
import _ from 'lodash';

type Props = { entity: any };

class CardListaPosto extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { update: false };
  }
  componentWillReceiveProps(nexProps) {
    if (nexProps.filter && !_.isEqual(nexProps.filter, this.props.filter)) {
      this.setState({ update: true });
    }
  }

  render() {
    const { filter } = this.props;
    return <ValoresGarantiaList filter={filter} update={this.state.update} />;
  }
}

export default connect(
  null,
  null
)(withRouter(CardListaPosto));
