//@flow
import { Can } from '@casl/react';
import _ from 'lodash';
import moment from 'moment/moment';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import CardListaPostoAvaliacao from '../../../components/Cards/CardListaPostoAvaliacao';
import RadioInput from '../../../components/Inputs/RadioInput';
import DatePickerInput from '../../../components/Inputs/DatePickerInput';
import SelectInput from '../../../components/Inputs/SelectInput';
import { CardItem } from '../../../components/templates/CardItem';
import { objectsConstants } from '../../../constants/objects.constants';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';
import TopoTitleComponente from '../../../views/Topo/TopoTitleComponente';
import PostoAutorizadoClientList from './PostoAutorizadoClientList';
import PostoAvaliacaoAtendimento from './PostoAvaliacaoAtendimento';

type State = { colaborador: any, entity: any };

class PesquisaSatisfacao extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    let month = moment().month();
    let year = moment().year();

    let dataInicio = moment()
      .month(month)
      .year(year)
      .date(1)
      .hour(0)
      .minute(0);

    let dataFim = moment()
      .month(month)
      .year(year)
      .hour(23)
      .minute(59);

    dataFim = dataFim.date(dataFim.endOf('month').format('DD'));

    let filter = {
      dataInicio: dataInicio.format('YYYY-MM-DDTHH:mm:ss'),
      dataFim: dataFim.format('YYYY-MM-DDTHH:mm:ss'),
      postoAutorizado: {},
      typeFilter: 'sintetico'
    };
    this.state = { filter };
  }

  handleSelectPosto = postoAutorizado => {
    this.props.history.push(
      '/report/pesquisaSatisfacao/details/' + postoAutorizado.id
    );
  };

  handleRowClick = () => {
    const { entity } = this.props;
    this.onSelectPosto(entity.postoAutorizado);
  };

  handleChangeData = (name, value) => {
    const filter = _.cloneDeep(this.state.filter);

    if (name === 'postoAutorizado') {
      _.set(filter, 'id', value.id);
    }
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  handleValue = (value: any, name: string) => {
    const filter = _.cloneDeep(this.state.filter);

    if (name === 'typeFilter' && value === 'analitico') {
      _.set(filter, 'analitico', true);
    } else if (name === 'typeFilter') {
      _.set(filter, 'analitico', false);
    }
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  handleFilter = (e: SyntheticEvent<>) => {
    if (e) {
      e.preventDefault();
    }

    const { filter } = this.state;

    this._postoAvaliacao.getWrappedInstance().updateTable();

    if (!filter.analitico) {
      this._cardListPostoAvaliacao.getWrappedInstance().updateTable();
    } else {
      this._cardListPostoDetailsAvaliacao.getWrappedInstance().updateTable();
    }
  };

  render() {
    let options = [
      { id: 'sintetico', name: 'Sintético' },
      { id: 'analitico', name: 'Analítico' }
    ];
    const { permissions } = this.props;
    const { filter } = this.state;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle=" Pesquisa de satisfação "
          canBack={true}
        />

        <div className="d-flex  flex-column">
          <div className="d-flex flex-row flex-wrap justify-content-center">
            <CardItem title={`PESQUISA DE SATISFAÇÃO `} width="col-12 col-sm-5">
              <div className="form-row">
                <Can
                  I={objectsConstants.VIEW}
                  a={objectsConstants.ABILITY_FILTRO_POSTO}
                  ability={permissions}
                >
                  <SelectInput
                    type="text"
                    name="postoAutorizado"
                    label="Posto autorizado"
                    placeholder="Posto autorizado"
                    style={['col-12 col-sm-6']}
                    onFetchData={postoAutorizadoService.find}
                    valueKey="id"
                    labelKey="nomeFantasia"
                    returnFullObject={true}
                    searchable={true}
                    id={'postoAutorizado'}
                    onChange={this.handleChangeData}
                    value={filter.postoAutorizado}
                  />
                </Can>
                <DatePickerInput
                  placeholder={'--/--/--'}
                  name="dataInicio"
                  label="Data de "
                  value={filter.dataInicio ? moment(filter.dataInicio) : null}
                  onChange={this.handleChangeData}
                  size={6}
                  style={['col-6 col-sm-3']}
                  id={'dataInicio'}
                />
                <DatePickerInput
                  name="dataFim"
                  label="Data até "
                  placeholder={'--/--/--'}
                  value={filter.dataFim ? moment(filter.dataFim) : null}
                  onChange={this.handleChangeData}
                  size={6}
                  style={['col-6 col-sm-3']}
                  id={'dataFim'}
                />
                <RadioInput
                  label="Sintético"
                  name="typeFilter"
                  id={'typeFilter'}
                  onChange={this.handleValue}
                  style={['col']}
                  options={options}
                  value={filter.typeFilter}
                />

                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-secondary float-right"
                    disabled={filter.postoAutorizado ? false : true}
                    onClick={this.handleFilter}
                  >
                    {' '}
                    Pesquisar{' '}
                  </button>
                </div>
              </div>
            </CardItem>

            <PostoAvaliacaoAtendimento
              ref={postoAvaliacao => (this._postoAvaliacao = postoAvaliacao)}
              entity={filter}
              width="col-12 col-sm-6"
            />
          </div>

          <div className="d-flex flex-row">
            <Can
              I={objectsConstants.VIEW}
              a={objectsConstants.ABILITY_FILTRO_POSTO}
              ability={permissions}
            >
              {!filter.analitico && (
                <CardListaPostoAvaliacao
                  ref={cardListPostoAvaliacao =>
                    (this._cardListPostoAvaliacao = cardListPostoAvaliacao)
                  }
                  filter={filter}
                />
              )}
            </Can>
            {filter.analitico && (
              <PostoAutorizadoClientList
                ref={cardListPostoDetailsAvaliacao =>
                  (this._cardListPostoDetailsAvaliacao = cardListPostoDetailsAvaliacao)
                }
                filter={filter}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}
export default connect(
  mapStateToProps,
  null
)(withRouter(PesquisaSatisfacao));
