import React, { PureComponent } from 'react';
import _ from 'lodash';
import { midiaService } from '../services/midiaService';
import relogioBG from '../img/relogioBG.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { urlsConstants } from '../constants/url.constant';

type Props = {
  children?: any,
  onChange: any
};
type State = {
  entity: any
};

class FotoRelogio extends PureComponent<Props, State> {
  _isMouted = false;
  constructor(props) {
    super(props);
    this.state = {
      foto: null
    };
  }
  componentDidMount() {
    this._isMouted = true;
    const { relogio } = this.props;
    this.loadRelogio(relogio);
  }
  componentWillReceiveProps(nexProps) {
    if (_.get(nexProps, 'relogio.id') != _.get(this.props, 'relogio.id')) {
      this.loadRelogio(nexProps.relogio);
    }
  }

  componentWillUnmount() {
    this._isMouted = false;
  }

  dataUrl = values => {
    if (values && values.foto && values.foto.id) {
      return `${urlsConstants.MIDIA_IMAGE}${values.foto.id}`;
    } else {
      return null;
    }
  };

  loadRelogio = relogio => {
    if (relogio && !relogio.foto) {
      this.props.loading(true);
      midiaService.getFotoRelogio(relogio.codigoRelogio).then(
        response => {
          this.props.loading(false);
          if (this._isMouted) {
            this.setState({ foto: response.data });
          }
        },
        () => {
          this.props.loading(false);
        }
      );
    } else {
      if (this._isMouted) {
        this.setState({ foto: this.dataUrl(relogio) });
      }
    }
  };
  render() {
    const { foto } = this.state;
    return (
      <img
        src={foto ? foto : relogioBG}
        className={`${this.props.noMargin ? '' : 'mb-4'} fotoRelogio`}
        width={this.props.width ? this.props.width : '100%'}
        height={this.props.height}
      />
    );
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});

export default connect(null, mapDispatch)(withRouter(FotoRelogio));
