//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ImportacaoOrdemCompraClientePjListagem from './ImportacaoOrdemCompraClientePjListagem';

export default class ImportacaoOrdemCompraClientePj extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/importacaoOrdemCompraClientePj"
            exact
            component={ImportacaoOrdemCompraClientePjListagem}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
