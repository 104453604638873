//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import { Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import { urlsConstants } from '../../constants/url.constant';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { colaboradorService } from '../../services/colaborador.service';
import MarcaCrudListFilter from './MarcaCrudListFilter';
import {
  objectsConstants,
  constHelper
} from '../../constants/objects.constants';
import { marcaService } from '../../services/marca.service';
const columns = [
  {
    path: 'id',
    title: 'Código',
    isKey: true,
    dataSort: false
  },
  {
    path: 'nome',
    title: 'Nome',
    isKey: false,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'grupoMarca.nome',
    title: 'Grupo',
    isKey: false,
    dataSort: false
  },
  {
    path: 'tipo',
    title: 'tipo',
    isKey: false,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'mesesGarantia',
    title: 'Meses de garantia',
    isKey: false,
    dataSort: false
  }
];

const endPoint = '/marca/novo/';
const endPointView = '/marca/edit/';
class MarcaCrudList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Nova Marca'
      }
    });
  };
  render() {
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Marca" subTitle=" " canBack={false} />
        <ListagemPage
          title={`MARCA`}
          newButtonActionClick={this.handleNewButton}
          delete={() => {}}
          service={marcaService}
          labelNewButton="Nova Marca"
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={{}}
          onRowClick={this.handleRowClick}
          FormFilter={MarcaCrudListFilter}
          controller="marca"
          fields={fields}
          roleNewButton={objectsConstants.ABILITY_BTN_CADASTRO_MARCA}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(MarcaCrudList);
