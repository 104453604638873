import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { analiseAtendimentoEprService } from '../../../services/analiseAtendimentoEprService.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class AnaliseAtendimentoEprDetails extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      countAtendidasForaGarantia: 0,
      countAtendidasGarantia: 0,
      countNaoAtendidasForaGarantia: 0,
      countNaoAtendidasGarantia: 0
    };
  }

  updateItem = filter => {
    analiseAtendimentoEprService.findChartResult({}, filter).then(response => {
      let data = response.data;
      let countAtendidasForaGarantia = 0;
      let countAtendidasGarantia = 0;
      let countNaoAtendidasForaGarantia = 0;
      let countNaoAtendidasGarantia = 0;
      data.map((item, i) => {
        countAtendidasForaGarantia += item.countAtendidasForaGarantia;
        countAtendidasGarantia += item.countAtendidasGarantia;
        countNaoAtendidasForaGarantia += item.countNaoAtendidasForaGarantia;
        countNaoAtendidasGarantia += item.countNaoAtendidasGarantia;
      });
      this.setState({
        countAtendidasForaGarantia,
        countAtendidasGarantia,
        countNaoAtendidasForaGarantia,
        countNaoAtendidasGarantia
      });
    });
  };
  componentDidMount() {
    this.updateItem(this.props.filter);
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-12 col-md-6 col-xl-6 d-inline-flex pt-5 detailsChart">
          <div className="col-8 flex-fill text-center">
            <div className="miniTxt">Volume de peças atendidas na GARANTIA</div>
            <p>{this.state.countAtendidasGarantia}</p>
          </div>
          <div className="sinal">+</div>
          <div className="col-2 flex-fill text-center">
            <div className="miniTxt">Fora da garantia</div>
            <p>{this.state.countAtendidasForaGarantia}</p>
          </div>
          <div className="sinal">=</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Total</div>
            <p className="txtDarkGray">
              {this.state.countAtendidasGarantia +
                this.state.countAtendidasForaGarantia}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-6 d-inline-flex pt-5 detailsChart">
          <div className="col-8 flex-fill text-center">
            <div className="miniTxt">
              Volume de peças não atendidas na GARANTIA
            </div>
            <p>{this.state.countNaoAtendidasGarantia}</p>
          </div>
          <div className="sinal">+</div>
          <div className="col-2 flex-fill text-center">
            <div className="miniTxt">Fora da garantia</div>
            <p>{this.state.countNaoAtendidasForaGarantia}</p>
          </div>
          <div className="sinal">=</div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Total</div>
            <p className="txtDarkGray">
              {this.state.countNaoAtendidasGarantia +
                this.state.countNaoAtendidasForaGarantia}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { totalSintomas } = state.report;

  return {
    totalSintomas
  };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { withRef: true }
)(AnaliseAtendimentoEprDetails);
