//@flow
import _ from 'lodash';
import moment from 'moment/moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import FileInput from '../../components/Inputs/FileInput';
import { urlsConstants } from '../../constants/url.constant';
import { pesquisaSatisfacaoService } from '../../services/pesquisaSatisfacaoService';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import PostoImageLoad from './PostoImageLoad';
var startChequed = require('../../img/icon_star_yellow.svg');

type Props = { entity: any };
type State = {
  entity: any,
  edit: boolean
};

class PostoAutorizadoFormViewView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: _.cloneDeep(this.props.entity)
    };
  }

  componentDidMount() {
    if (this.props.entity.id && !this.props.noLoadAvaliacao) {
      let month = moment().month();
      let year = moment().year();

      let dataInicio = moment()
        .month(month)
        .year(year)
        .date(1)
        .hour(0)
        .minute(0);

      let dataFim = moment()
        .month(month)
        .year(year)
        .hour(23)
        .minute(59);

      dataFim = dataFim.date(dataFim.endOf('month').format('DD'));

      let filter = {
        dataInicio: dataInicio.format('YYYY-MM-DDTHH:mm:ss'),
        dataFim: dataFim.format('YYYY-MM-DDTHH:mm:ss'),
        postoAutorizado: this.props.entity,
        id: this.props.entity.id
      };

      this.props.loading(true);
      pesquisaSatisfacaoService
        .getMediaPostoAutorizado({ filter, id: this.props.entity.id })
        .then(response => {
          let average = 0;
          average = _.meanBy(response.data.respostas, r => r.mediaAvaliacao);
          let avaliacaoPosto = _.isNaN(average) ? 0 : average.toFixed(1);
          let payLoad = { avaliacaoPosto };
          this.props.setTotal(payLoad);
          this.props.loading(false);
        });
    }
  }

  componentWillReceiveProps(nexProps) {
    if (nexProps.entity) {
      this.setState({ entity: _.cloneDeep(nexProps.entity) });
    }
  }

  handleChangeImage = (name, value) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, value);
    this.setState({ entity: newEntity });
  };

  getFaixadaURL = () => {
    const { entity } = this.state;
    if (entity.faixada) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.faixada.id}`;
    } else {
      return require('../../img/foto_faixada.svg');
    }
  };

  getFotoInternaURL = () => {
    const { entity } = this.state;
    if (entity.fotoInterna) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.fotoInterna.id}`;
    } else {
      return require('../../img/foto_internaLoja.svg');
    }
  };

  render() {
    const NOME = 'nome';
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="conteudoCard flex-fill flex-row d-sm-flex">
          <div className="form-row">
            <div className="col-12 col-md-6 mx-0">
              <div className="row m-0">
                <div className="col-6 col-md-12 pr-1 pl-0 p-md-1">
                  <PostoImageLoad
                    name={'faixada'}
                    title={'FOTO DA FACHADA'}
                    subititle={
                      'A foto precisa mostrar toda faixada da loja, com placa e nome legível.'
                    }
                    dataUrl={this.getFaixadaURL}
                    onChange={this.handleChangeImage}
                    saveData={postoAutorizadoService.saveFaixada}
                    entity={entity}
                    readOnly={this.props.readOnly}
                  />
                </div>
                <div className="col-6 col-md-12 pl-1 pr-0 p-md-1">
                  <PostoImageLoad
                    name={'fotoInterna'}
                    title={'FOTO ITERNA DA LOJA'}
                    subititle={'A foto precisa mostrar interior da loja.'}
                    dataUrl={this.getFotoInternaURL}
                    onChange={this.handleChangeImage}
                    saveData={postoAutorizadoService.saveFotoInterna}
                    entity={entity}
                    readOnly={this.props.readOnly}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="form-row">
                <div className="col-12 col-lg-12 mt-4 mt-lg-1">
                  <h2 className="title mb-0">{entity.nomeFantasia}</h2>
                  <h4>
                    <p>
                      <b>
                        {_.get(entity.cidade, NOME)} -{' '}
                        {_.get(entity.estado, NOME)}
                      </b>
                    </p>
                  </h4>
                  {!entity.aprovado && (
                    <p className="legend">Cadastro Pendente</p>
                  )}
                  {entity.aprovado && (
                    <React.Fragment>
                      <p className="txtBlue">
                        <b>Cadastro aprovado</b>
                      </p>
                      <p>
                        Registro:<b className="txtGreen">{entity.numPA}</b>
                      </p>
                    </React.Fragment>
                  )}
                </div>
                <div className="col-12">
                  <p>
                    {entity.rua}, {entity.numero}
                    {entity.complemento ? ', ' + entity.complemento : ''}
                    <br />
                    {entity.bairro}
                    <br />
                    {entity.cep}
                  </p>
                  <p>
                    <b>{entity.horaFuncionamento ? entity.horaFuncionamento : ''}</b>
                  </p>
                  <p>{entity.telefoneComercial}</p>
                  <p>
                    {entity.paStatus === 'HABILITADO'
                      ? entity.documentoModal
                      : ''}
                  </p>
                </div>
                <div className="pl-0 col-12">
                  <NavLink
                    className="noDecoration"
                    to={'/report/pesquisaSatisfacao/details/' + entity.id}
                  >
                    {' '}
                    <img src={startChequed} className="iconRating" />{' '}
                    {this.props.avaliacaoPosto}{' '}
                  </NavLink>
                </div>

                <div className="col-12 mt-3">
                  {entity.id && (
                    <FileInput
                      emptyLabel="Anexar contrato"
                      name="contratoSocial"
                      saveData={postoAutorizadoService.saveContrato}
                      entity={entity}
                      fileItem={entity.contratoSocial}
                      onChange={this.handleChangeImage}
                      readOnly={this.props.readOnly}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rodapeCard">
          <i
            className="icon-acessar float-right"
            alt="Ícone para acessar mais detalhes"
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { currentTask } = state.task;
  const { avaliacaoPosto } = state.report;

  return {
    currentTask,
    avaliacaoPosto
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading },
  report: { setTotal }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  setTotal: totals => setTotal(totals)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(PostoAutorizadoFormViewView);
