//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import DocList from './DocList';
import DocView from './DocView';

export default class Doc extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/doc/view/:id" exact component={DocView} />
          <Route path="/doc" exact component={DocList} />
            <Route path="/doc/:responsavel" exact component={DocList} />
        </Switch>
      </React.Fragment>
    );
  }
}
