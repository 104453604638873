//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import relogioBG from '../../img/relogioBG.png';
import { midiaService } from '../../services/midiaService';
import TablePecas from './Components/TablePecas';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import _ from 'lodash';
import TablePecasView from './Components/TablePecasView';
import FotoRelogio from '../../components/FotoRelogio';
import {Can} from "@casl/react";
import {objectsConstants} from "../../constants/objects.constants";
import {NavLink} from "react-router-dom";

class RelogioContainerItemView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  getTotalPecasAtendidas = () => {
    const { pecas } = this.props.relogio;
    let quantidadeTotal = 0;

    _.map(pecas, function(peca) {
      if (peca.lastPecaRegistroTrabalhoDocVO)
        quantidadeTotal += parseInt(
          peca.lastPecaRegistroTrabalhoDocVO.quantidadeEnviada
        );
    });

    return parseInt(quantidadeTotal);
  };
  getTotalPecas = () => {
    const { pecas } = this.props.relogio;
    let quantidadeTotal = 0;

    _.map(pecas, function(peca) {
      quantidadeTotal += parseInt(peca.quantidade);
    });

    return parseInt(quantidadeTotal);
  };
  getTotalRelogio = () => {
    const { pecas } = this.props.relogio;
    let valorTotal = 0;

    _.map(pecas, function(peca) {
      valorTotal += parseInt(peca.quantidade) * peca.valorCompra;
    });

    return ordemServicoHelper.formatMoney(parseFloat(valorTotal), 2, ',', '.');
  };

  render() {
    const { relogio, pecas, data } = this.props.relogio;
    const{permissions} = this.props;
    return (
      <React.Fragment>
        <form
          noValidate
          className="row conteudoTab d-flex flex-column flex-lg-row "
        >
          <div className="flex-column col-12 col-md-3 text-center">
            <FotoRelogio relogio={relogio ? relogio : null} width={'70%'} />
          </div>
          <div className="flex-column col-12 col-md-3">
            <div className="form-row">
              <h2 className="">
                <b>{relogio.marca.nome}</b>
              </h2>
              <h2 className="">{relogio.codigoRelogio}</h2>
            </div>
          </div>

          <div className="flex-column col-12 col-md-6">
            <div className="form-row">
              <div className="form-group col-12 col-sm-3">
                <label className="miniLabel">Total</label>
                <p>{this.getTotalRelogio()}</p>
              </div>
              <div className="form-group col-12 col-sm-3">
                <label className="miniLabel">Peças</label>
                <p>{this.getTotalPecas()}</p>
              </div>

              <div className="form-group col-12 col-sm-3">
                <label className="miniLabel">Atendidas</label>
                <p>{this.getTotalPecasAtendidas()}</p>
              </div>
              {this.props.compraPeca&&this.props.compraPeca.numDoc&&(
              <div className="form-group col-12 col-sm-3">
                <label className="miniLabel">DOC</label>
                <Can
                    I={objectsConstants.VIEW}
                    a={objectsConstants.ABILITY_DOC}
                    ability={permissions}
                >
                  <p>
                    <NavLink
                        className="noDecoration"
                        to={
                          '/doc/view/' +
                          this.props.compraPeca.numDoc.replace(
                              /[^\d]+/g,
                              ''
                          )
                        }
                    >
                      {' '}
                      {this.props.compraPeca.numDoc}
                    </NavLink>
                  </p>
                </Can>
                <Can
                    I={objectsConstants.VIEW}
                    a={objectsConstants.ABILITY_DOC}
                    ability={permissions}
                    not
                >
                  <p>{this.props.compraPeca.numDoc}</p>
                </Can>
              </div>
              )}
            </div>
          </div>
        </form>

        <div className="react-bs-table-container">
          {pecas.length > 0 && (
            <TablePecasView
              listPecas={pecas}
              onRejectPeca={this.handleChangePecas}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;
  const { permissions } = state.authentication;
  return {
    cliente,
    permissions
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, clearOS },
  alert: { success, error },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({}),
  error: msg => error(msg)
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(RelogioContainerItemView);
