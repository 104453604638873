//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import NovaOsModal from '../../components/Modals/NovaOsModal';
import ListagemPage from '../../components/templates/ListagemPage';
import { objectsConstants } from '../../constants/objects.constants';
import { urlsConstants } from '../../constants/url.constant';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { tableHelper } from '../../helpers/tableHelper';
import { ordemServicoService } from '../../services/ordemServico.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import OrdemServicoListFilterAdmin from './AdminManaus/OrdemServicoListFilterAdmin';
import OrdemServicoListFilter from './OrdemServicoListFilter';

const columnsAdmin = [
  {
    path: 'numOs',
    title: 'Nº OS',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque',
  },
  {
    path: 'marca',
    title: 'Marca',
    isKey: false,
    dataSort: false,
  },
  { path: 'endereco', title: 'Cidade', isKey: false, dataSort: true },
  {
    path: 'postoAutorizado.nomeFantasia',
    title: 'Posto autorizado',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'creationDateTime',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true,
  },
  { path: 'quantidadePecas', title: 'Peças', isKey: false, dataSort: false },
  {
    path: 'estoque.description',
    title: 'Estoque',
    isKey: false,
    dataSort: false,
  },
  { path: 'garantia', title: 'Garantia', yesNo: true, dataSort: false },
  {
    path: 'dataStatusAtual',
    title: 'Data do status atual',
    isKey: false,
    dataSort: false,
    isDate: true,
  },
  { path: 'atendido', title: 'Atendido', isKey: false, dataSort: false },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase',
  },
  {
    isKey: false,
    isFarol: true,
    path: 'diasCorridos',
  },
];

const columns = [
  {
    path: 'numOs',
    title: 'Nº OS',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque',
  },
  {
    path: 'marca',
    title: 'Marca',
    isKey: false,
    dataSort: false,
  },
  { path: 'cliente.nome', title: 'Cliente', isKey: false, dataSort: true },
  {
    path: 'cliente.documento',
    title: 'CPF/CNPJ',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'creationDateTime',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true,
  },
  { path: 'quantidadePecas', title: 'Peças', isKey: false, dataSort: true },
  { path: 'garantia', title: 'Garantia', yesNo: true, dataSort: false },
  {
    path: 'dataStatusAtual',
    title: 'Data do status atual',
    isKey: false,
    dataSort: false,
    isDate: true,
  },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase',
  },
  {
    isKey: false,
    isFarol: true,
    path: 'diasCorridos',
  },
].filter((x) => x !== undefined);

const tdAttr = {
  'data-title': 'value1',
  'data-title2': 'value2',
};

type State = {
  modal: boolean,
};

// const NewClienteInit = Yup.object().shape({
//   documentoModal: Yup.string()
//     .min(14, 'CPF ou CNPJ inválido!')
//     .max(18, 'CPF ou CNPJ inválido!')
//     .required('CPF ou CNPJ é obrigatório')
// });

const endPoint = '/ordemServico/';
class OrdemServicoList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);

    // if (
    //   props.permissions.can(
    //     objectsConstants.VIEW,
    //     objectsConstants.ABILITY_VISUALIZAR_POSTOS_OS
    //   )
    // ) {
    //   columns.splice(2, 0, {
    //     path: 'postoAutorizado.nomeFantasia',
    //     title: 'Posto autorizado',
    //     isKey: false,
    //     dataSort: false
    //   });
    // }

    // if (
    //   props.permissions.can(
    //     objectsConstants.VIEW,
    //     objectsConstants.ABILITY_VISUALIZAR_ESTOQUE_OS
    //   )
    // ) {
    //   columns.splice(6, 0, {
    //     path: 'estoque.description',
    //     title: 'Estoque',
    //     isKey: false,
    //     dataSort: false
    //   });
    // }

    let modal = false;
    let fields;
    if (
      props.permissions.can(
        objectsConstants.VIEW,
        objectsConstants.ABILITY_LISTAGEM_OS_ADMIN
      )
    ) {
      fields = _.map(columnsAdmin, function(coluna) {
        return coluna.path;
      });
    } else {
      fields = _.map(columns, function(coluna) {
        return coluna.path;
      });
    }

    if (this.props.location.state && this.props.location.state.showModal) {
      modal = this.props.location.state.showModal;
    }
    this.state = {
      modal,
      fields,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleRowClick = (row: e) => {
    let urlOS = urlsConstants.ORDEM_SERVICO + row.id;
    this.props.loading(true);
    ordemServicoService.doGet(urlOS).then(
      (response) => {
        this.props.loading(false);
        let os = response.data.data;
        this.props.setOrdemServico(os);
        this.props.setCliente(os.cliente);
        this.props.history.push('/ordemServico/view/' + row.id);
      },
      () => {
        this.props.loading(false);
      }
    );
  };

  renderCustom(row: any, ordemServico: any, column: any) {
    if (column.isFarol) {
      let classFarol = ordemServicoHelper.getFarol(ordemServico);
      return <div className={`farol ${classFarol}`} />;
    } else {
      return tableHelper.renderCustom(row, ordemServico, column);
    }
  }

  render() {
    console.log('Permissoes', this.props);
    let tableColumn;
    if (
      this.props.permissions.can(
        objectsConstants.VIEW,
        objectsConstants.ABILITY_LISTAGEM_OS_ADMIN
      )
    ) {
      tableColumn = _.map(columnsAdmin, (column, index) => (
        <TableHeaderColumn
          key={index}
          dataField={column.path}
          dataFormat={this.renderCustom}
          formatExtraData={column}
          isKey={column.isKey}
          dataSort={column.dataSort}
          hidden={column.hidden}
        >
          {column.title}
        </TableHeaderColumn>
      ));
    } else {
      tableColumn = _.map(columns, (column, index) => (
        <TableHeaderColumn
          key={index}
          dataField={column.path}
          dataFormat={this.renderCustom}
          formatExtraData={column}
          isKey={column.isKey}
          dataSort={column.dataSort}
          hidden={column.hidden}
        >
          {column.title}
        </TableHeaderColumn>
      ));
    }

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Ordens de Serviço"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`ORDENS DE SERVIÇO(${this.props.subTitle})`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={ordemServicoService}
          labelNewButton="Nova OS"
          defaultOrder={'diasCorridos desc,id'}
          defaultDirection={'desc'}
          defaultFilter={{}}
          onRowClick={this.handleRowClick}
          FormFilter={
            this.props.permissions.can(
              objectsConstants.VIEW,
              objectsConstants.ABILITY_LISTAGEM_OS_ADMIN
            )
              ? OrdemServicoListFilterAdmin
              : OrdemServicoListFilter
          }
          controller="ordemServico"
          fields={this.state.fields}
          classSignal={'temSinal'}
        >
          {tableColumn}
        </ListagemPage>
        <NovaOsModal
          isModalVisible={this.state.modal}
          toggleModal={this.toggle}
        />
        {/* <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col d-flex flex-column">
                  <h3>
                    <span className="txtGreen"> nova os </span>- cliente
                  </h3>
                  <Formik
                    initialValues={{ documentoModal: '' }}
                    validationSchema={NewClienteInit}
                    onSubmit={(values, actions) => {
                      clienteService
                        .getByDocumento(
                          values.documentoModal.replace(/\D/g, '')
                        )
                        .then(
                          cliente => {
                            this.props.setCliente(cliente.data.data);

                            this.props.history.push('/ordemServico/inicio');
                          },
                          error => {
                            if (error.response.status === 404) {
                              this.props.history.push('/cliente/novo', {
                                entity: { documento: values.documentoModal }
                              });
                            } else {
                              actions.setSubmitting(false);
                            }
                          }
                        )
                        .catch(() => {
                          actions.setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="conteudo">
                          <p>
                            Identifique se seu cliente já possuí o cadastro no
                            sistema. Se ainda não estiver cadastrado, é preciso
                            cadastrá-lo para abrir uma nova OS.
                          </p>
                          <div className="form-group">
                            <label>CPF ou CNPJ</label>
                            <CpfCnpjInput
                              type="text"
                              className={`form-control ${errors.documentoModal &&
                                touched.documentoModal &&
                                'error'}`}
                              id={'documentoModal'}
                              name="documentoModal"
                              size={100}
                              placeholder="Digite o CPF ou CNPJ"
                              value={values.documentoModal}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mt-auto"
                          disabled={isSubmitting}
                        >
                          identificar cliente
                        </button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal> */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;
  const { permissions } = state.authentication;

  return {
    subTitle,
    permissions,
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico },
  load: { loading },
}) => ({
  setCliente: (cliente) => setCliente({ cliente }),
  setOrdemServico: (ordemServico) => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load }),
});

export default connect(
  mapStateToProps,
  mapDispatch
)(OrdemServicoList);
