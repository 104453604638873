import React, { PureComponent } from 'react';
import '../../css/boxPostoAutorizadoOS.css';

type Props = {
  children?: any,
  onChange: any
};
type State = {
  entity: any
};

export class BoxPostoAutorizadoOSDisabled extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity
    };
  }
  handleAction = () => {};
  render() {
    return (
      <div className="card cardSeculus d-flex flex-column l1">
        <div className="conteudoCard d-flex flex-column flex-sm-column">
          <div className="col-sm-12 d-flex flex-column align-items-center pr-3">
            <div className="justify-content-center pl-3">
              <span className="icon-os icon-lg">
                <span className="path1 " />
                <span className="path2 " />
                <span className="path3 " />
                <span className="path4 " />
                <span className="path5 " />
                <span className="path6 " />
                <span className="path7 " />
              </span>
            </div>
            <div className="justify-content-center pl-3">
              <p className="p-msg">
                A loja ainda não é um posto autorizado e por esse motivo não
                pode gerar nenhuma ordem de serviço
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
