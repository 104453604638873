//@flow
import * as React from 'react';
import { Formik } from 'formik';
import '../../css/doc_manaus.css';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { docService } from '../../services/doc.service';
import { connect } from 'react-redux';
import DocDetailsItem from './DocDetailsItem';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import _ from 'lodash';
import { tipoTransporteService } from '../../services/tipoTransporte.service';
import FormInput from '../../components/Inputs/FormInput';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import { objectsConstants } from '../../constants/objects.constants';
import HeaderList from '../../components/templates/HeaderList';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import PecaImageLoad from '../../components/Inputs/PecaImageLoad';
import { defaultService } from '../../services/defaultService';
import * as Yup from 'yup';
import Toast from '../../components/Toasts/Toast';

type Props = { onChangeText: any, entity: any, onChangeValue: any };
const validateTrca = Yup.object().shape({
  codigoPecaTroca: Yup.string().required('Peça é obrigatório'),
  quantidadeAdptada: Yup.string().required('Quantidade é obrigatório')
});
class DocView extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      docDetail: {},
      entity: {},
      canConcluir: false,
      indexPecaSelect: null,
      idPeca: null,
      pecas: [],
      modal: false,
      modalDetalhesPeca: false,
      fields: [
        'numDoc',
        'creationDateDoc',
        'postoAutorizado',
        'codigoSAP',
        'postoAutorizadoCidade',
        'codigoPeca',
        'codigoPeca2',
        'descricaoPeca',
        'codigoRelogio',
        'nomeMarca',
        'numOs',
        'dataOs',
        'garantiaValue',
        'quantidade',
        'valor',
        'valorTotal',
        'quantidadeAtendida',
        'quantidadeEstoque',
        'faturar',
         'codigoRastreio'
      ],
      labels: [
        'Número do DOC',
        'Data do DOC',
        'Posto Autorizado',
        'Código SAP',
        'Local do posto autorizado',
        'Código da peça 2',
        'Código da peça ',
        'Descrição da peça',
        'Código do Relogio',
        'Marca',
        'Código da OS',
        'Data da OS',
        'Garantia',
        'Quantidade',
        'Valor da peça',
        'Valor total',
        'Quantidade Atendida',
        'Estoque',
        'Faturar',
         'Código de Rastreio'
      ]
    };
  }

  toggle = idPeca => {
    const { pecas } = this.state;
    var indexPeca = null;
    pecas.find(function(peca, index) {
      if (peca.idPeca == idPeca) {
        indexPeca = index;
      }
    });
    if (indexPeca == null) {
      indexPeca = _.cloneDeep(pecas.length);
    }

    this.setState({
      indexPecaSelect: indexPeca,
      idPeca: idPeca,
      modal: !this.state.modal
    });
  };

  componentDidMount() {
    this.reloadDoc();
  }

  reloadDoc = () => {
    let idDoc = this.props.match.params.id;
    this.FindOsInDoc(idDoc);
  };

  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newEntity = _.cloneDeep(this.state.entity);
    _.set(newEntity, name, value);
    this.setState({ entity: newEntity });
  };
  handleSelectChange = (name: any, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity });
  };

  handleSetPeca = (newItem: any) => {
    const { pecas } = this.state;
    let newEntity = _.cloneDeep(pecas);
    var existenteItem = pecas.find(function(peca) {
      return peca.idPeca === newItem.idPeca;
    });
    //update object in array se já exitir
    if (existenteItem) {
      newEntity = _.map(pecas, function(item) {
        return item.idPeca === newItem.idPeca
          ? {
              idPeca: item.idPeca,
              quantidadeEnviada: newItem.quantidadeEnviada,
              quantidadeSolicitada: item.quantidadeSolicitada,
              codigoPecaTroca: newItem.codigoPecaTroca,
              fotoPecaTroca: newItem.fotoPecaTroca,
              quantidadeAdptada: newItem.quantidadeAdptada,
              naoAtendida: newItem.naoAtendida
            }
          : item;
      });
    } else {
      newEntity.push(newItem);
    }
    var haPecas = true;

    if (
        ((newItem.quantidadeEnviada === '' ||
        newItem.quantidadeEnviada === undefined)&&(newItem.quantidadeAdptada === '' ||
            newItem.quantidadeAdptada === undefined)) &&
      !newItem.naoAtendida
    ) {
      haPecas = false;
    }

    this.setState({
      pecas: newEntity,
      canConcluir: haPecas,
      changePeca: true
    });
  };
  canEnviarDoc = () => {
    let canConcluir = true;

    console.log(this.state.pecas);

    this.state.pecas.map(pecaItem => {
      if (
          (parseInt(pecaItem.quantidadeEnviada) >= 1 || parseInt(pecaItem.quantidadeAdptada)>=1) &&
        !this.state.entity.tipoTransporte
      ) {
        canConcluir = false;
      }
    });
    return canConcluir;
  };
  handleConcluirDOC = () => {
    if (this.canEnviarDoc()) {
      let atenderDocVO = _.cloneDeep(this.state.entity);
      atenderDocVO.pecas = this.state.pecas;
      atenderDocVO.idDoc = this.state.docDetail.id;

      this.props.loading(true);
      docService.savePedido(this.state.docDetail.id, atenderDocVO).then(
        result => {
          let id = defaultService.getIdFromUrl(result.headers.location);
          this.FindOsInDoc(id);
          this.props.success({
            message: 'DOC enviado com sucesso.'
          });
          this.props.loading(false);
          //HARDCODE porque não estava limpando memoria
          _.unset(atenderDocVO, 'codigoRastreio');
          atenderDocVO = {};
          this.setState({ entity: atenderDocVO, canConcluir: false });
        },
        erro => {
          console.log('Error:', erro);
          this.props.error(objectsConstants.messageError);
          this.props.loading(false);
        }
      );
    } else {
      this.props.error('Informe o tipo de transporte');
    }
  };

  disableConcluir = () => {
    if (this.state.canConcluir) {
      return false;
    } else {
      return true;
    }
  };

  FindOsInDoc = idDoc => {
    this.props.loading(true);
    docService.getOs(idDoc).then(
      result => {
        this.setState({ docDetail: result.data.data });
        this.props.loading(false);
      },
      () => {
        this.props.loading(false);
      }
    );
  };
  cancelarTroca = () => {
    this.setState({
      modal: false,
      modalDetalhesPeca: false
    });
  };
  toggleDetalhesPeca = () => {
    this.setState({
      modal: !this.state.modal,
      modalDetalhesPeca: !this.state.modalDetalhesPeca
    });
  };

  TitleComponent = () => {
    const { docDetail } = this.state;
    return (
      <h3 className="flex-grow-1">
        {'DOC-'}
        <span
          className={
            docDetail.statusDoc.name == 'NAO_ATENDIDO' ? 'txtRed' : 'txtBlue'
          }
        >
          {docDetail.statusDoc.description}
        </span>
      </h3>
    );
  };

  changeStatus = idPeca => {
    const { docDetail } = this.state;
    let newEntity = _.cloneDeep(docDetail);

    newEntity.listOrdemServico.find(function(registroTrabalho, indexRt) {
      registroTrabalho.listPecaOrdemServico.find(function(peca, indexPeca) {
        if (peca.id === idPeca) {
          peca.docDetailsView = {
            status: { description: 'Substituição em Andamento' }
          };
        }
      });
    });
    this.setState({ docDetail: newEntity });
  };

  render() {
    const { docDetail } = this.state;
    return (
      <React.Fragment>
        <Toast />
        {docDetail.postoAutorizado && (
          <div className="card cardSeculus cardFull d-flex flex-column">
            <TopoTitleComponente
              mainTitle="Doc"
              subTitle={docDetail.numDoc}
              canBack={true}
            />
            <HeaderList
              TitleComponente={this.TitleComponent}
              showNewButton={false}
              filter={{ id: this.state.docDetail.id }}
              controller={'docView'}
              fields={this.state.fields}
              labels={this.state.labels}
              downloadExcelName={docDetail.numDoc}
            />
            <div className="conteudoCard">
              <div className="row">
                <div className="col-6 col-lg-3">
                  <h2 className="marca">
                    <b>{docDetail.postoAutorizado.nomeFantasia}</b>
                  </h2>
                </div>
                <div className="col-6 col-lg-4">
                  <div>
                    {docDetail.postoAutorizado.endereco.rua},{' '}
                    {docDetail.postoAutorizado.endereco.numero}
                    {docDetail.postoAutorizado.endereco.complemento
                      ? ',' + docDetail.postoAutorizado.endereco.complemento
                      : ''}{' '}
                    - {docDetail.postoAutorizado.endereco.bairro}
                  </div>
                  <div>
                    {docDetail.postoAutorizado.cidade.nome}-
                    {docDetail.postoAutorizado.estado.nome}
                  </div>
                  <div>CEP {docDetail.postoAutorizado.endereco.cep}</div>
                </div>
                <div className="form-row col-12 col-lg-5">
                  <FormSelectInput
                    type="text"
                    name="tipoTransporte"
                    label="Tipo transporte"
                    placeholder=""
                    size={4}
                    style={['col-4 col-lg-4']}
                    options={this.state.tipoTransporte}
                    returnFullObject={true}
                    searchable={true}
                    id={'tipoTransporte'}
                    ref={input => {
                      this._sintoma = input;
                    }}
                    onChange={this.handleSelectChange}
                    value={this.state.entity.tipoTransporte}
                    service={tipoTransporteService.findAll}
                    required={true}
                    disabled={!this.state.canConcluir}
                  />
                  <div className="col-4 col-lg-4">
                    <label className="d-none d-sm-inline">
                      {' '}
                      Código de rastreio
                    </label>
                    <label className="d-inline d-sm-none"> Cód. rastreio</label>
                    <FormInput
                      type="text"
                      name="codigoRastreio"
                      placeholder="Cód. Rastreio"
                      required={true}
                      size={12}
                      id={'codigoRastreio'}
                      onChange={this._handleChangeText}
                      value={
                        this.state.entity.codigoRastreio
                          ? this.state.entity.codigoRastreio
                          : ''
                      }
                      disabled={!this.state.canConcluir}
                      noSize={true}
                    />
                  </div>
                  <div className="col-4 col-lg-4 form-group">
                    <button
                      type="button"
                      className="btn btn-primary mt-lbl"
                      disabled={this.disableConcluir()}
                      onClick={() =>
                        ConfirmAcationService.confirmMsg(
                          this.handleConcluirDOC,
                          'Enviar DOC',
                          'Deseja enviar este doc?',
                          'Ao enviar DOC, se houver peças não atendidas, fará com que o DOC fique pendente.'
                        )
                      }
                    >
                      Enviar DOC
                    </button>
                  </div>
                </div>
              </div>

              <div className="react-bs-table-container">
                {docDetail.listOrdemServico.map((ordemServico, index) => (
                  <DocDetailsItem
                    onTrocaPeca={this.toggle}
                    key={ordemServico.id}
                    entity={ordemServico}
                    onSetPeca={this.handleSetPeca}
                    reloadDoc={this.reloadDoc}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            <h3 className="subH3">confirmar</h3>
            <p>
              Como você não consegue atender esta peça,{' '}
              <b>
                deseja sugerir ao posto autorizado uma outra peça para
                adaptação?
              </b>
            </p>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <button
              type="button "
              className="btn btn-primary white"
              onClick={this.toggle}
            >
              não
            </button>
            <button
              type="button "
              className="btn btn-primary "
              onClick={this.toggleDetalhesPeca}
            >
              Adaptar pecas
            </button>
          </ModalFooter>
        </Modal>
        {this.state.indexPecaSelect != null && (
          <Formik
            initialValues={
              this.state.pecas[this.state.indexPecaSelect]
                ? this.state.pecas[this.state.indexPecaSelect]
                : {}
            }
            validationSchema={validateTrca}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              this.handleSetPeca({
                idPeca: this.state.idPeca,
                codigoPecaTroca: values.codigoPecaTroca,
                fotoPecaTroca: values.fotoPecaTroca,
                quantidadeAdptada: values.quantidadeAdptada
              });
              actions.setSubmitting(false);
              this.changeStatus(this.state.idPeca);
              this.cancelarTroca();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <Modal
                isOpen={this.state.modalDetalhesPeca}
                toggle={this.toggleDetalhesPeca}
              >
                <form onSubmit={handleSubmit} noValidate>
                  <ModalBody>
                    <h3 className="subH3">Adaptar peça</h3>

                    <div className="form-row">
                      <FormInput
                        type="text"
                        name="codigoPecaTroca"
                        label="Código peça de adaptação"
                        placeholder="Código peça de adaptação"
                        required={true}
                        size={12}
                        messageValidate={errors.codigoPecaTroca}
                        id={'codigoPecaTroca'}
                        onChange={handleChange}
                        value={values.codigoPecaTroca}
                      />
                      <FormInput
                        type="text"
                        name="quantidadeAdptada"
                        label="Quantidade"
                        placeholder="Quantidade de peça de adaptação"
                        required={true}
                        size={12}
                        messageValidate={errors.quantidadeAdptada}
                        id={'quantidadeAdptada'}
                        onChange={handleChange}
                        value={values.quantidadeAdptada}
                      />
                      <div className="form-group col-12">
                        <label>Enviar foto da peça de adaptação</label>
                        <PecaImageLoad
                          name={'fotoPecaTroca'}
                          title={'Foto da peça de adaptação'}
                          messageValidate={errors.fotoPecaTroca}
                          className={errors.fotoPecaTroca ? 'error' : ''}
                          dataUrl={() => {
                            return values.fotoPecaTroca
                              ? values.fotoPecaTroca.data
                              : require('../../img/fotopeca.svg');
                          }}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary white"
                      onClick={this.cancelarTroca}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary green"
                      disabled={isSubmitting}
                    >
                      Salvar
                    </button>
                  </ModalFooter>
                </form>
              </Modal>
            )}
          </Formik>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ alert: { error, success }, load: { loading } }) => ({
  error: (message, code) => error({ message, code }),
  success: message => success(message),
  loading: (load: boolean) => loading({ load })
});

export default connect(null, mapDispatch, null, { withRef: true })(DocView);
