//@flow
import * as React from 'react';
import { marcaService } from '../../../services/marca.service';
import SelectInput from '../../../components/Inputs/SelectInput';
import moment from 'moment/moment';
import MonthPickerInput from '../../../components/Inputs/MonthPickerInput';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';
import { objectsConstants } from '../../../constants/objects.constants';

import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Can } from '@casl/react';
class RelatorioPrazoAtendimentoTrocaFormFilter extends React.PureComponent<
  Props,
  State
> {
  handleSatartDate = (name, month, year) => {
    let date;
    if (name === 'dataInicio') {
      date = moment()
        .month(month - 1)
        .year(year)
        .date(1);
    } else {
      date = moment()
        .month(month - 1)
        .year(year);
      date = date.date(date.endOf('month').format('DD'));
    }
    this.props.onChangeDate(name, date);
  };

  render() {
    const { entity, permissions } = this.props;
    return (
      <React.Fragment>
        <SelectInput
          type="text"
          name="marca"
          label="Marca"
          placeholder="Marca"
          required={true}
          style={['col-12 col-sm-2']}
          onFetchData={marcaService.find}
          valueKey="id"
          labelKey="nome"
          returnFullObject={true}
          searchable={true}
          id={'marca'}
          onChange={this.props.onChangeSelect}
          value={entity.marca}
        />

        <SelectInput
          id={'garantiaInput'}
          name="garantia"
          label="Garantia"
          placeholder="Todos"
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false }
          ]}
          style={['col-6 col-sm-1']}
          returnFullObject={true}
          valueKey={'value'}
          name={'garantia'}
          noSize={true}
          value={entity.garantia}
          onChange={this.props.onChangeSelect}
          clearable={true}
        />
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <SelectInput
            type="text"
            name="postoAutorizado"
            label="Autorizada"
            placeholder="-Selecione-"
            style={['col-12 col-sm-3']}
            onFetchData={postoAutorizadoService.find}
            valueKey="id"
            labelKey="nomeFantasia"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'postoAutorizado'}
            onChange={this.props.onChangeSelect}
            value={entity.postoAutorizado}
          />
        </Can>
        <MonthPickerInput
          placeholder={'Mês/Ano'}
          name="dataInicio"
          label="Data de"
          onChange={this.handleSatartDate}
          size={6}
          style={['col-6 col-sm-2']}
          id={'dataInicio'}
        />
        <MonthPickerInput
          name="dataFim"
          label="Data até"
          placeholder={'Mês/Ano'}
          onChange={this.handleSatartDate}
          size={6}
          style={['col-6 col-sm-2']}
          id={'dataFim'}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps)(
  RelatorioPrazoAtendimentoTrocaFormFilter
);
