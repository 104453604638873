//@flow
import * as React from 'react';

import { Route, Switch } from 'react-router-dom';
import GarantiaCrudList from './GarantiaCrudList';
import GarantiaEdit from './GarantiaEdit';

export default class GarantiaCrudTest extends React.PureComponent<{}> {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/cadastroGarantia" exact component={GarantiaCrudList} />
                    <Route path="/cadastroGarantia/novo/" component={GarantiaEdit} />
                    <Route path="/cadastroGarantia/edit/:id" component={GarantiaEdit} />
                </Switch>
            </React.Fragment>
        );
    }
}
