import _ from "lodash";
import { Formik } from "formik";
import React, { Component } from "react";
import CpfInput from "../../components/Inputs/CpfInput";
import ZipCodeInput from "../../components/Inputs/ZipCodeInput";
import FormInput from "../../components/Inputs/FormInput";
import "../../css/CadastroGarantia.css";
import PhoneInput from "../../components/Inputs/PhoneInput";
import {clienteService} from "../../services/cliente.service";
import TipoTelefoneInput from "../../components/Inputs/TipoTelefoneInput";

export default class CadastroGantiaUsuario extends Component {
    constructor(props) {
        super(props);

    }
    addTelefone = (setFieldValue, telefones) => {
        let newArray = _.concat(telefones, { numero: '', tipo: 'CELULAR' });
        setFieldValue('telefones', newArray);
    };

    removeItem = (setFieldValue, telefones, index) => {
        telefones.splice(index, 1);
        setFieldValue('telefones', telefones);
    };

    render() {
        const { entity,setFieldValue,values,errors,handleChange } = this.props;
        return (

            <div className="row">
                <CpfInput
                    label="CPF"
                    placeholder={"CPF"}
                    id={"documento"}
                    name="cliente.documento"
                    value={values.cliente.documento}
                    onChange={(name,value)=>{
                        if(value&&value.length===14){
                            clienteService.findClienteGarantiaPorCpf(value).then((response)=>{
                                console.log(response);
                                setFieldValue(name,response.data.data.documento);
                                setFieldValue('cliente.nome',response.data.data.nome);
                                setFieldValue('cliente.email',response.data.data.email);
                                setFieldValue('cliente.endereco.cep',response.data.data.endereco.cep);
                                setFieldValue("cliente.endereco.rua", response.data.data.endereco.rua);
                                setFieldValue("cliente.endereco.cidade.nome",response.data.data.endereco.cidade.nome);
                                setFieldValue("cliente.endereco.cidade.estado.uf", response.data.data.endereco.cidade.estado.uf);
                                setFieldValue("cliente.endereco.bairro", response.data.data.endereco.bairro);
                                setFieldValue("cliente.endereco.numero", response.data.data.endereco.numero);
                                setFieldValue("cliente.endereco.complemento", response.data.data.endereco.complemento);
                            }).catch((error)=>{
                                setFieldValue(name,value);
                            })
                        }
                        setFieldValue(name,value);
                    }}
                    type={"text"}

                    erroMensagem={errors.cliente && errors.cliente.documento}
                />

                <FormInput
                    label="Nome Completo"
                    placeholder={"Digite aqui o nome e sobrenome"}
                    id={"nome"}
                    name="cliente.nome"
                    value={values.cliente.nome}
                    onChange={(e)=>{setFieldValue(e.target.name,e.target.value)}}
                    type={"text"}
                    size={50}
                    style={"col-lg-7 col-12"}
                    erroMensagem={errors.cliente && errors.cliente.nome}
                />
                <FormInput
                    label="E-mail"
                    placeholder={"Digite aqui seu e-mail"}
                    id={"email"}
                    name="cliente.email"
                    value={values.cliente.email}
                    onChange={(e)=>{setFieldValue(e.target.name,e.target.value)}}
                    type={"email"}
                    size={50}
                    style={"col-lg-5 col-12 "}
                    erroMensagem={errors.cliente && errors.cliente.email}
                />
                <ZipCodeInput
                    type="text"
                    id="cep"
                    name="cliente.endereco.cep"
                    label={"CEP"}
                    placeholder="Digite o CEP"
                    style={["col-lg-4 col-12"]}
                    size={["3"]}
                    value={values.cliente.endereco.cep}
                    onChange={(endereco, value) => {
                        console.log(value);
                        console.log(endereco);
                        setFieldValue("cliente.endereco.cep", value);
                        if (endereco.logradouro) {
                            setFieldValue("cliente.endereco.rua", endereco.logradouro);
                            setFieldValue("cliente.endereco.cidade.nome", endereco.localidade);

                            setFieldValue("cliente.endereco.cidade.estado.uf", endereco.uf);
                            setFieldValue("cliente.endereco.bairro", endereco.bairro);
                        }
                    }}
                    noSize={true}
                    erroMensagem={errors.cliente && errors.cliente.endereco && errors.cliente.endereco.cep}
                />
                <FormInput
                    type="text"
                    id="CidadeComunidade"
                    name="cliente.endereco.cidade.nome"
                    label={"Cidade:"}
                    placeholder="Cidade"
                    style={["col-lg-4 col-6"]}
                    value={values.cliente.endereco.cidade.nome}
                    onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                    }}
                    noSize={true}
                    erroMensagem={errors.cliente && errors.cliente.endereco && errors.cliente.endereco.cidade && errors.cliente.endereco.cidade.nome}
                />
                <FormInput
                    type="text"
                    id="ufComunidade"
                    name="cliente.endereco.cidade.estado.uf"
                    label={"UF:"}
                    placeholder="UF"
                    style={["col-lg-4 col-6"]}
                    value={values.cliente.endereco.cidade.estado.uf}
                    onChange={(e)=>{setFieldValue(e.target.name,e.target.value)}}
                    noSize={true}
                    erroMensagem={errors.cliente && errors.cliente.endereco && errors.cliente.endereco.cidade && errors.cliente.endereco.cidade.estado && errors.cliente.endereco.cidade.estado.uf}
                />
                <FormInput
                    type="text"
                    id="endereco"
                    name="cliente.endereco.rua"
                    label={"Logradouro:"}
                    placeholder="Logradouro"
                    style={["col-12"]}
                    value={values.cliente.endereco.rua}
                    onChange={(e)=>{setFieldValue(e.target.name,e.target.value)}}
                    noSize={true}
                    erroMensagem={errors.cliente && errors.cliente.endereco  && errors.cliente.endereco.rua}
                />
                <FormInput
                    type="text"
                    id="numero"
                    name="cliente.endereco.numero"
                    label={"N°:"}
                    placeholder="Digite o número"
                    style={["col-4"]}
                    value={values.cliente.endereco.numero}
                    onChange={(e)=>{setFieldValue(e.target.name,e.target.value)}}
                    noSize={true}
                    erroMensagem={errors.cliente && errors.cliente.endereco  && errors.cliente.endereco.numero}
                />
                <FormInput
                    type="text"
                    id="complemento"
                    name="cliente.endereco.complemento"
                    label={"Complemento:"}
                    placeholder="Digite o complemento"
                    style={["col-4"]}
                    value={values.cliente.endereco.complemento}
                    onChange={(e)=>{setFieldValue(e.target.name,e.target.value)}}
                    noSize={true}
                    erroMensagem={errors.cliente && errors.cliente.endereco  && errors.cliente.complemento}
                />
                <FormInput
                    type="text"
                    id="bairro"
                    name="cliente.endereco.bairro"
                    label={"Bairro:"}
                    placeholder="Bairro"
                    style={["col-4"]}
                    value={values.cliente.endereco.bairro}
                    onChange={(e)=>{setFieldValue(e.target.name,e.target.value)}}
                    noSize={true}
                    erroMensagem={errors.cliente  && errors.cliente.endereco && errors.cliente.endereco.bairro}
                />

                {values.telefones.map((value, index) => (
                    <React.Fragment key={index}>
                        <TipoTelefoneInput
                            handleChange={handleChange}
                            name={`telefones[${index}].tipo`}
                            value={value.tipo}
                        />

                        <div className="form-group col-5 col-lg-4">
                            <label>Número Telefone *</label>
                            <PhoneInput
                                type="number"
                                id={`Phone${index}`}
                                className="form-control"
                                size={100}
                                name={`telefones[${index}].numero`}
                                placeholder="Somente números"
                                value={value.numero}
                                onChange={handleChange}
                                className={`${errors.telefone && 'error'}`}
                            />
                        </div>

                        <div className="form-group col-3 col-lg-4 d-flex align-items-end justify-content-center">
                            {/* //se não for ultimo pode excluir */}
                            {values.telefones.length - 1 !== index && (
                                <div className="text-right pb-2">
                                    {' '}
                                    <a
                                        href="#"
                                        onClick={() => {
                                            this.removeItem(
                                                setFieldValue,
                                                values.telefones,
                                                index
                                            );
                                        }}
                                    >
                                        <img
                                            className="icon"
                                            src={require('../../img/icon_excluir.svg')}
                                        />
                                        Telefone{' '}
                                    </a>
                                </div>
                            )}
                            {values.telefones.length - 1 === index && (
                                <div
                                    className="text-right pb-2"
                                    onClick={() =>
                                        this.addTelefone(
                                            setFieldValue,
                                            values.telefones
                                        )
                                    }
                                >
                              <span className="icon-badge">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                              </span>{' '}
                                    <a>Telefone </a>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                ))}

            </div>

        );
    }
}
