import React, { PureComponent } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import Toast from '../../components/Toasts/Toast';
import { BodyWhite } from '../../components/templates/BodyWhite';
import { passwordRecoverService } from '../../services/passwordRecoverService';
import ApiCep from '../../services/zipCode.service';

type Props = {
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  alert: any,
  fieldMessage: any
};

class IndexContent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    let login = { email: '', senha: '' };

    if (process.env.NODE_ENV === 'development') {
      login = { email: 'sgp@seculus.com', senha: '123' };
    }
    this.state = login;
    this.goToLocaltion = this.goToLocaltion.bind(this);
  }

  handleLogin = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    const { email, senha } = this.state;

    this.props.clear();

    if (email && senha) {
      this.props.login(email, senha);
    } else {
      this.props.clear();
      this.props.error('Informe o E-mail e Senha');
    }
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  componentWillMount() {
    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px'
    });
    this.props.clear();
    if (this.props.match && this.props.match.params.id) {
      this.handleConfirmEmail(this.props.match.params.id);
    }
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.goToLogin) {
      this.goToLocaltion('#sectionLogin');
    }
  }

  handleConfirmEmail = (id: number) => {
    this.props.clear();

    if (id) {
      this.props.loading(true);

      let entity = {
        key: id
      };

      return passwordRecoverService.confirm(entity).then(
        response => {
          let json = response.data;
          this.props.success({
            message:
              'E-mail confirmado com sucesso. Efetue login para concluir seu cadastro.'
          });
          this.props.loading(false);
          this.goToLocaltion('#sectionLogin');
        },
        error => {
          if (error.response.status === 404) {
            this.props.error('E-mail já foi confirmado, efetue login.');
          } else {
            this.props.error(
              'Não foi possível confirmar E-mail, tente mais tarde.'
            );
          }
          this.goToLocaltion('#sectionLogin');
          this.props.loading(false);
        }
      );
    }
  };
  clickEvent = event => {
    if (event.target.hash !== '') {
      event.preventDefault();
      let hash = event.target.hash;
      this.goToLocaltion(hash);
    }
  };

  goToLocaltion = hash => {
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top
      },
      500,
      function() {
        window.location.hash = hash;
      }
    );
  };

  render() {
    const { loggedIn } = this.props;
    let classImgRelogio = window.innerWidth <= 1024 ? 'slide-up' : 'slide-left';

    if (loggedIn) {
      return <Redirect to={'/home'} />;
    }

    return (
      <React.Fragment>
        <BodyWhite />
        <section id="sectionCadastro">
          <div className="container-fluid">
            <div className="row">
              {/* <!-- logo --> */}
              <div className="col d-flex justify-content-center">
                <div className="index_logoSeculus" />
              </div>
              {/* <!-- border --> */}
              <div className="col colBorder">
                <div className="index_border" />
              </div>
              {/* <!-- img relógio --> */}
              <div
                className={`col index_colMid_01 ${classImgRelogio}`}
                id="imgRelogio"
                style={{ height: this.state.height }}
              />
              {/* <!-- cadastro --> */}
              <div className="col d-flex flex-column index_colRight_01">
                <div className="index_btLogin">
                  <a
                    role="button"
                    href="#sectionLogin"
                    className="btn btn-secondary"
                    onClick={this.clickEvent}
                  >
                    Minha conta
                  </a>
                </div>
                <h1 className="fontDosis">
                  Seja um posto autorizado <b>Seculus</b>
                </h1>
                <p>
                  Clique no botão abaixo e<br />
                  cadastre-se agora mesmo
                </p>
                <NavLink className="btn btn-primary mb-4" to="/cadastro">
                  Cadastro
                </NavLink>
                <h5 className="mt-auto">
                  Todos os cadastros estão sujeitos a aprovação
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="sectionLogin">
          <Toast />
          <div className="container-fluid">
            <div className="row">
              {/* <!-- login --> */}
              <div
                className="col d-flex flex-column align-items-center"
                id="indexLogin"
                style={{ height: this.state.height }}
              >
                <div className="index_btCadastro">
                  <NavLink className="btn btn-secondary" to="/cadastro">
                    Cadastro
                  </NavLink>
                </div>
                <h1 className="mb-2 text-center fontDosis">Acesse sua conta</h1>
                <p className="mb-4 text-center">
                  Insira abaixo seu e-mail e sua senha
                  <br />
                  para acessar sua conta
                </p>

                <form className="index_form" onSubmit={this.handleLogin}>
                  {/* <!--toast error--> */}
                  <div className="form-group">
                    <label>E-mail</label>
                    <input
                      type="email"
                      className="form-control index_input"
                      id="email"
                      name="email"
                      placeholder="Digite seu e-mail"
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                  </div>
                  <div className="form-group mb-5">
                    <label>Senha</label>
                    <input
                      type="password"
                      className="form-control"
                      id="senha"
                      name="senha"
                      placeholder="Digite sua senha"
                      onChange={this.handleChange}
                      value={this.state.senha}
                    />
                  </div>
                  <div className="mb-5">
                    <NavLink to="/recoverPassword">Esqueci minha senha</NavLink>
                  </div>
                  <button type="submit" className="btn btn-primary mb-4">
                    Acessar minha conta
                  </button>
                </form>
                {/* <!-- bt_cadastro --> */}
                <div className="index_colRight_02" />
                <h5 className="mt-auto rodape">
                  <img
                    className="iconRodape"
                    src={require('../../img/icon.svg')}
                  />
                  © Seculus da Aamazônia
                </h5>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    loggedIn,
    alert,
    messages
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  login: (user, password) => login({ user, password }),
  loading: (load: boolean) => loading({ load })
});
export default connect(
  mapStateToProps,
  mapDispatch
)(IndexContent);
