//@flow
import React, { PureComponent } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Modal, ModalBody, Container } from 'reactstrap';
import '../../../css/crop_reabrir_OS.css';
import _ from 'lodash';
import ItemOrcamentoOS from './ItemOrcamentoOS';

type Props = {
  onSave: any,
  type: string,
  ConfirmSelected: any
};

export default class ConfirmItensOrcamentoModal extends PureComponent<Props> {
  static defaultProps = {
    type: 'REPROVE' | 'REOPEN'
  };
  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      selectAllItem: false,
      idsPecasSelecionadas: [],
      idServicosSelecionados: []
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ isModalVisible: nextProps.isModalVisible });
  }

  selecAll = () => {
    this.setState({ selectAllItem: !this.state.selectAllItem });
  };

  verifySelectAll() {
    let {
      idsPecasSelecionadas,
      idServicosSelecionados,
      selectAllItem
    } = this.state;

    const {
      listServicoOrdemServico,
      listPecaOrdemServico
    } = this.props.ordemServico;

    if (
      listPecaOrdemServico !== undefined &&
      listServicoOrdemServico !== undefined
    ) {
      if (
        idsPecasSelecionadas.length == listPecaOrdemServico.length &&
        idServicosSelecionados.length == listServicoOrdemServico.length
      ) {
        this.setState({ selectAllItem: true }, () => {
          console.log('Verify All', this.state.selectAllItem);
        });
      }
    } else if (listPecaOrdemServico !== undefined) {
      if (idsPecasSelecionadas.length == listPecaOrdemServico.length) {
        this.setState({ selectAllItem: true }, () => {
          console.log('Verify All', this.state.selectAllItem);
        });
      }
    } else if (listServicoOrdemServico !== undefined) {
      if (idServicosSelecionados.length == listServicoOrdemServico.length) {
        this.setState({ selectAllItem: true }, () => {
          console.log('Verify All', this.state.selectAllItem);
        });
      }
    }

    //verificar se todos os items foram reprovados
  }

  toogleItemPeca = (id, checked) => {
    let { idsPecasSelecionadas, selectAllItem } = this.state;
    //if marcado add itens no array de selecionados se nao remove
    if (checked) {
      idsPecasSelecionadas.push(id);
    } else {
      idsPecasSelecionadas = _.without(idsPecasSelecionadas, id);
    }
    if (selectAllItem && !checked) {
      selectAllItem = false;
    }
    this.setState({ idsPecasSelecionadas, selectAllItem }, () => {
      this.verifySelectAll();
    });
  };

  toogleItemServico = (id, checked) => {
    let { idServicosSelecionados } = this.state;
    //if marcado add itens no array de selecionados se nao remove
    if (checked) {
      idServicosSelecionados.push(id);
    } else {
      idServicosSelecionados = _.without(idServicosSelecionados, id);
    }

    this.setState({ idServicosSelecionados }, () => {
      this.verifySelectAll();
    });
  };
  validate = () => {
    const { idServicosSelecionados, idsPecasSelecionadas } = this.state;
    if (
      this.state.selectAllItem ||
      idServicosSelecionados.length > 0 ||
      idsPecasSelecionadas.length > 0
    ) {
      return true;
    }
    return false;
  };
  handleConfirm = () => {
    const { ordemServico } = this.props;
    const { idServicosSelecionados, idsPecasSelecionadas } = this.state;
    //valida se escolheu alguma opção para reprovar
    if (this.validate()) {
      let itensOrcamentoVO = {};
      if (this.state.selectAllItem) {
        itensOrcamentoVO.selectedAll = true;
      } else {
        //if não marcou selectAll, mas marcou manualmente todos, seta selctAll para verdadeiro
        if (
          ordemServico.listServicoOrdemServico &&
          idServicosSelecionados.length ===
            ordemServico.listServicoOrdemServico.length &&
          ordemServico.listPecaOrdemServico &&
          idsPecasSelecionadas.length ===
            ordemServico.listPecaOrdemServico.length
        ) {
          itensOrcamentoVO.selectedAll = true;
        } else {
          itensOrcamentoVO.selectedAll = false;
          itensOrcamentoVO.idServicosSelecionados = idServicosSelecionados;
          itensOrcamentoVO.idsPecasSelecionadas = idsPecasSelecionadas;
        }
      }
      //chama parente avisando que tem uma entidade para reprovar
      this.props.ConfirmSelected(itensOrcamentoVO);
    } else {
      //chama parente para informar de validação
      this.props.handleErro(
        `Escolha os serviços ou peças que deseja ${
          this.props.type === 'REPROVE' ? 'reprovar' : 'realizar'
        }.`
      );
    }
  };

  getHeader = () => {
    if (this.props.type === 'REPROVE') {
      return (
        <React.Fragment>
          <h3>Reprovar Orçamento</h3>
          <p>
            O cliente reprovou todo o orçamento, ou apenas alguns serviços?{' '}
            <br />
            <b>Selecione abaixo os serviços reprovados</b>
          </p>
        </React.Fragment>
      );
    } else if (this.props.type === 'REOPEN') {
      return (
        <React.Fragment>
          <h3>Reabrir OS com todos os serviços</h3>
          <p>
            O cliente quer reabrir a OS com todos os serviços? <br />
            <b>Selecione abaixo os serviços que serão realizados</b>
          </p>
        </React.Fragment>
      );
    }
  };

  render() {
    const {
      listServicoOrdemServico,
      listPecaOrdemServico
    } = this.props.ordemServico;
    let _self = this;
    return (
      <Modal
        isOpen={this.state.isModalVisible}
        togglemodal={this.props.togglemodal}
        className="customModal"
        size="lg"
        toggle={this.props.togglemodal}
      >
        <ModalBody>
          <Container>
            {this.getHeader()}
            <div className="d-flex justify-content-flex-start mb-3">
              <a href="#" onClick={this.selecAll}>
                Selecionar todos
              </a>
            </div>

            <ul className="mb-0">
              {_.map(listPecaOrdemServico, function(i) {
                return (
                  <ItemOrcamentoOS
                    key={i.id}
                    id={i.id}
                    type="Peça"
                    label={i.peca.nome}
                    value={i.valorFinal}
                    checkAll={_self.state.selectAllItem}
                    onToogle={_self.toogleItemPeca}
                  />
                );
              })}
              {_.map(listServicoOrdemServico, function(i) {
                return (
                  <ItemOrcamentoOS
                    key={i.id}
                    id={i.id}
                    type="Serviço"
                    label={i.servico.nome}
                    value={i.valorFinal}
                    checkAll={_self.state.selectAllItem}
                    onToogle={_self.toogleItemServico}
                  />
                );
              })}
            </ul>
          </Container>
        </ModalBody>

        <div className="footer pt-5 pb-3">
          <div className="col d-flex justify-content-between align-items-center">
            <button
              type="button"
              className="btn btn-primary justify-content-start white"
              onClick={this.props.toggleModal}
            >
              <b>Cancelar</b>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleConfirm}
            >
              Concluir
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
