// import Page from './index.html';
// var htmlDoc = { __html: Page };
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import './estilo.css';
import './css/bootstrap.css';
import GoogleMapsContainer from './GoogleMapsContainer';
import axios from 'axios';
import { objectsConstants } from '../../constants/objects.constants';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import { urlsConstants } from '../../constants/url.constant';

const url = urlsConstants.URL_DOMAIN;

let infowindow;
let google;
let map;
class ConsultaAssistencia extends Component {
  static defaultProps = {
    center: [-19.9325502, -43.9275688],
    zoom: 12,
    greatPlaceCoords: { lat: -19.9325502, lng: -43.9275688 },
    greatPlaceCoords1: { lat: -9.6612129, lng: -35.740803 }
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedPlace: {},
      locais: [],
      selectedLocal: null
    };
  }

  componentDidMount() {
    this.getEstado();
    this.updateSelectCidade();
  }

  updateSelect = () => {
    let _this = this;
    var x, i, j, selElmnt, a, b, c;
    /*look for any elements with the class "select-custom":*/
    x = document.getElementsByClassName('select-custom');

    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName('select')[0];
      /*for each element, create a new DIV that will act as the selected item:*/
      a = document.createElement('DIV');
      a.setAttribute('class', 'select-selected');
      // console.log(selElmnt.options[selElmnt.selectedIndex]);
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      /*for each element, create a new DIV that will contain the option list:*/
      b = document.createElement('DIV');
      b.setAttribute('class', 'select-items select-hide');
      for (j = 1; j < selElmnt.length; j++) {
        /*for each option in the original select element,
  create a new DIV that will act as an option item:*/
        c = document.createElement('DIV');
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener('click', function(e) {
          /*when an item is clicked, update the original select box,
    and the selected item:*/
          var y, i, k, s, h;
          s = this.parentNode.parentNode.getElementsByTagName('select')[0];
          h = this.parentNode.previousSibling;
          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName('same-as-selected');
              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute('class');
              }

              this.setAttribute('class', 'same-as-selected');
              break;
            }
          }
          _this.onChangeEstado();
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);

      a.addEventListener('click', function(e) {
        /*when the select box is clicked, close any other select boxes,
  and open/close the current select box:*/
        e.stopPropagation();
        _this.closeAllSelect(this);
        this.nextSibling.classList.toggle('select-hide');
        this.classList.toggle('select-arrow-active');
      });
    }

    /*if the user clicks anywhere outside the select box,
  then close all select boxes:*/
    // document.addEventListener('click', closeAllSelect);
  };

  closeAllSelect(elmnt) {
    var x,
      y,
      i,
      arrNo = [];
    x = document.getElementsByClassName('select-items');
    y = document.getElementsByClassName('select-selected');
    for (i = 0; i < y.length; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove('select-arrow-active');
      }
    }
    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add('select-hide');
      }
    }
  }

  updateSelectCidade() {
    let _this = this;
    var x, i, j, selElmnt, a, b, c;
    /*look for any elements with the class "select-custom":*/
    x = document.getElementsByClassName('select-custom-cidade');

    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName('select')[0];
      a = document.createElement('DIV');
      a.setAttribute('class', 'select-selected-cidade');
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      b = document.createElement('DIV');
      b.setAttribute('class', 'select-items-cidade select-hide-cidade');
      for (j = 1; j < selElmnt.length; j++) {
        c = document.createElement('DIV');
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener('click', function(e) {
          var y, i, k, s, h;
          s = this.parentNode.parentNode.getElementsByTagName('select')[0];
          h = this.parentNode.previousSibling;
          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName(
                'same-as-selected-cidade'
              );
              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute('class');
              }
              this.setAttribute('class', 'same-as-selected-cidade');
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener('click', function(e) {
        e.stopPropagation();
        _this.closeAllSelectCidade(this);
        this.nextSibling.classList.toggle('select-hide-cidade');
        this.classList.toggle('select-arrow-active-cidade');
      });
    }
  }

  closeAllSelectCidade(elmnt) {
    var x,
      y,
      i,
      arrNo = [];
    x = document.getElementsByClassName('select-items-cidade');
    y = document.getElementsByClassName('select-selected-cidade');
    for (i = 0; i < y.length; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove('select-arrow-active-cidade');
      }
    }
    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add('select-hide-cidade');
      }
    }
  }

  /*if the user clicks anywhere outside the select box,
then close all select boxes:*/
  // document.addEventListener('click', closeAllSelectCidade);

  validaCampo(campo) {
    if (campo === undefined) {
      return '---';
    } else {
      return campo;
    }
  }
  atualizaTabela = locais => {
    let _this = this;
    this.setState({ locais: [], selectedLocal: null }, () => {
      for (let i = 0; i < locais.length; i++) {
        let posto = locais[i];
        let cep = _this.formatCep(posto.endereco.cep);
        let numero = posto.endereco.numero;
        let rua = posto.endereco.rua;
        _this.loadLocation(cep, numero, rua).then(cordendas => {
          posto.cordenadas = cordendas;
          this.setState({ locais: [...this.state.locais, posto] });
        });
      }
    });
  };

  loadLocation = (cep, numero, rua) => {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?key=${objectsConstants.codeGoogle}&address=${rua},${numero},${cep}`;

    return doGet(url).then(
      response => {
        var latitude = 0;
        var longitude = 0;
        if (response.data.results.length) {
          latitude = response.data.results[0].geometry.location.lat;
          longitude = response.data.results[0].geometry.location.lng;
          console.log('Latitude: ' + latitude + '\nLongitude: ' + longitude);
        }

        return { lat: latitude, lng: longitude };
      },
      erros => {
        console.log(erros);
      }
    );
  };

  formatCep(cep) {
    var num = cep.replace(/\D/g, '');
    if (num.length == 8) {
      cep = num.substr(0, 5) + '-' + num.substr(5);
    }
    if (num.length == 9) {
      cep = num.substr(0, 5) + '-' + num.substr(5);
    }
    return cep;
  }

  getCidade() {
    let _this = this;
    $('#cidade').empty();
    $('.select-items-cidade').remove();
    $('.select-selected-cidade').remove();
    var dropDown = document.getElementById('estado');
    var estadoId = dropDown.options[dropDown.selectedIndex].value;

    $.ajax({
      type: 'GET',
      url: url + '/public/postoAutorizado/list/cidade/' + estadoId,
      headers: {
        'Content-Type': 'application/json'
      },
      success: function(data) {
        $('#cidade').append('<option value="">Selecione</option>');
        $.each(data, function(i, d) {
          $('#cidade').append(
            '<option value="' + d.id + '">' + d.nome + '</option>'
          );
        });
        _this.updateSelectCidade();
      }
    });
  }

  getEstado() {
    let _this = this;
    $('#estado').empty();

    $.ajax({
      type: 'GET',
      url: url + '/public/postoAutorizado/list/estado',
      headers: {
        'Content-Type': 'application/json'
      },
      success: function(data) {
        // console.log(data)
        $('#estado').append('<option value="">Selecione</option>');
        $.each(data, function(i, d) {
          $('#estado').append(
            '<option value="' + d.id + '">' + d.nome + '</option>'
          );
        });
        _this.updateSelect();
      }
    });
  }

  onChangeEstado() {
    var dropDown = document.getElementById('estado');
    if (dropDown.selectedIndex) {
      this.getCidade();
    } else {
      // $('#cidade').empty();
      // updateSelect()
    }
  }

  filtro = () => {
    let _this = this;
    var dropDownEstado = document.getElementById('estado');
    var dropDownCidade = document.getElementById('cidade');
    var estadoId = dropDownEstado.options[dropDownEstado.selectedIndex].value;
    var cidadeId = dropDownCidade.options[dropDownCidade.selectedIndex].value;
    cidadeId = cidadeId ? cidadeId : 0;
    $.ajax({
      type: 'GET',
      url: url + '/public/postoAutorizado/ListaAT/' + estadoId + '/' + cidadeId,
      headers: {
        'Content-Type': 'application/json'
      },
      success: function(data) {
        console.log(data);
        _this.atualizaTabela(data);
      }
    });
  };

  loadPosto = local => {
    this.setState({ selectedLocal: local }, () => {
      window.scrollTo(0, 0);
    });
  };

  render() {
    let locais = this.state.locais;
    let _this = this;
    let postos = [];
    for (let i = 0; i < locais.length; i++) {
      var nome = this.validaCampo(locais[i].nome);
      var endereco =
        this.validaCampo(locais[i].endereco.rua) +
        ', ' +
        this.validaCampo(locais[i].endereco.numero) +
        ' ' +
        this.validaCampo(locais[i].endereco.complemento) +
        ' - ' +
        this.validaCampo(locais[i].endereco.bairro) +
          ' , cep: ' +
          this.validaCampo(locais[i].endereco.cep)+
        ', ' +
        this.validaCampo(locais[i].endereco.cidade.nome) +
        ' | ' +
        this.validaCampo(locais[i].endereco.cidade.estado.uf);

      var telefone = this.validaCampo(locais[i].telefoneComercial);
      var horario = this.validaCampo(locais[i].horaFuncionamento);
      var linha = (
        <tr
          key={locais[i].id}
          className={`action trLocais ${_this.state.selectedLocal &&
            locais[i].id === _this.state.selectedLocal.id &&
            'active'}`}
          onClick={() => {
            console.log('clickc');
            _this.loadPosto(locais[i]);
          }}
        >
          <td>
            <b>{nome}</b>
          </td>
          <td>{endereco}</td>
          <td>{telefone}</td>
          <td>{horario}</td>
        </tr>
      );
      postos.push(linha);
    }
    return (
      <div className="box-mapa">
        <div className="box-busca">
          <h4>Encontrar Assistência</h4>
          <div className="select-custom">
            <select id="estado">
              <option>Selecione</option>
            </select>
          </div>
          <div className="select-custom-cidade">
            <select id="cidade">
              <option>Selecione</option>
            </select>
          </div>
          <button className="button" onClick={this.filtro}>
            Buscar
          </button>
        </div>
        <div id="map" className="mapa">
          <GoogleMapsContainer
            selectedLocal={this.state.selectedLocal}
            codeGoogle
            locais={this.state.locais}
            validaCampo={this.validaCampo}
          ></GoogleMapsContainer>
        </div>
        <div className="conteudo">
          <h1 className="title">Assistência Técnica</h1>
          <div className="texto-conteudo">
            <p>
              Para restaurar o perfeito funcionamento do seu produto, basta ir
              até um dos nossos agentes oficiais com a seguinte documentação:
              nota fiscal de compra ou certificado de garantia devidamente
              preenchido, com a referência do relógio, data de compra, carimbo e
              assinatura da loja.
            </p>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="w-25">Nome</th>
                <th className="w-50 col-4">Endereço</th>
                <th className="col-4">Telefone</th>
                <th className="col-4">Horário de funcionamento</th>
              </tr>
            </thead>
            <tbody className="resultApi">{postos}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

function doGet(url: string) {
  return axios.get(url);
}

export default GoogleApiWrapper({
  apiKey: objectsConstants.codeGoogle
})(ConsultaAssistencia);
