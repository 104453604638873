//@flow
import * as React from 'react';
import { propertyConstantes } from '../../constants/property.constantes';

export default class TipoTelefoneInput extends React.Component<{
  handleChange: Function<any>,
  value: string,
  name: string
}> {
  render() {
    return (
      <div className="form-group col-4 col-lg-4 ">
        <label htmlFor="selectTipoTelefone">Tipo Telefone</label>
        <select
          className="custom-select form-control"
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.handleChange}
        >
          <option value={'CELULAR'}>{propertyConstantes.CELULAR}</option>
          <option value={'CELULAR_WHATSAPP'}>
            {propertyConstantes.CELULAR_WHATSAPP}
          </option>
          <option value={'COMERCIAL'}>{propertyConstantes.COMERCIAL}</option>
          <option value={'RESIDENCIAL'}>
            {propertyConstantes.RESIDENCIAL}
          </option>
        </select>
      </div>
    );
  }
}
