import React, { PureComponent } from 'react';
import { Tooltip } from 'reactstrap';

type Props = {
  target?: any,
  messageValidate: any
};

export class ToolTipFeedBack extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  render() {
    return (
      <Tooltip
        className="invalid-tip"
        placement="top"
        isOpen={this.state.tooltipOpen}
        target={this.props.target}
        toggle={this.toggle}
        autohide={false}
      >
        {this.props.messageValidate}
      </Tooltip>
    );
  }
}
