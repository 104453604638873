import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label } from 'reactstrap';
import SelectInput from '../../components/Inputs/SelectInput';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col
} from 'reactstrap';
import { translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange: any,
  onBlur: any,
  children?: any,
  required: boolean,
  messageValidate?: string,
  placeholder: string,
  disabled: boolean,
  maxLength: number,
  style: any
};
const telJson = [
  { value: 'Celular', label: 'Celular' },
  { value: 'Comercial', label: 'Comercial' }
];
class TelephoneInput extends PureComponent<Props> {
  static defaultProps = {
    size: 12
  };

  handleBorderColor = messageValidate => {
    let borderColor;
    if (messageValidate) {
      borderColor = '#dc3545';
    }
    return {
      borderColor
    };
  };

  render() {
    const { messages, name } = this.props;
    let colSize = 'col';

    if (this.props.size) {
      colSize += '-' + this.props.size;
    }

    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    return (
      <FormGroup className={`${colSize} ${this.props.style}`}>
        <Row className="no-gutters" onClick={this.onItemClick}>
          <Col style={{ paddingRight: 5 }} className="no-gutters" lg="6">
            <SelectInput
              type="text"
              name="tipoTelefone"
              label="Tipo telefone"
              required={true}
              style={['col-lg-12']}
              size={6}
              options={telJson}
              returnFullObject={true}
              searchable={true}
              //messageValidate={validate.portadorDeficiencia}
              id={'pais'}
              ref={input => {
                this._pais = input;
              }}
              onChange={this.handleSelectChange}
            />
          </Col>
          <Col style={{ paddingLeft: 5 }} lg="6">
            {this.props.label && (
              <Label for={this.props.name}>
                {this.props.label}
                {this.props.required && '*'}
              </Label>
            )}
            <InputMask
              mask="(99) 9999-9999"
              maskChar=" "
              id={this.props.id}
              name={this.props.name}
              value={this.props.value}
              style={{ className: 'col-lg-2' }}
              onChange={this.props.onChange}
              required={this.props.required}
              placeholder={this.props.placeholder}
              onBlur={this.props.onBlur}
              disabled={this.props.disabled ? this.props.disabled : false}
              className="form-control"
            >
              {this.props.children}
            </InputMask>
          </Col>
        </Row>

        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(TelephoneInput);
