//@flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import connect from 'react-redux/es/connect/connect';
import 'react-confirm-alert/src/react-confirm-alert.css';

import TopoTitleComponente from '../Topo/TopoTitleComponente';
import Toast from '../../components/Toasts/Toast';
import UploadPictureRegister from '../../components/UploadPictureRegister';
import { FormRow } from '../../components/FormRow';
import FormInput from '../../components/Inputs/FormInput';
import ZipCodeInput from '../../components/Inputs/ZipCodeInput';
import SelectInput from '../../components/Inputs/SelectInput';
import { enderecoService } from '../../services/endereco.service';
import { postoHabilitadoService } from '../../services/postoHabilitado.service';
import { objectsConstants } from '../../constants/objects.constants';
import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import PhoneInput from '../../components/Inputs/PhoneInput';

type State = { initialValues: any };

const newPosto = Yup.object().shape({
  razaoSocial: Yup.string().test(
    'valid-razaoSocial',
    'Razão é obrigatório',
    function() {
      const { parent, createError } = this;
      if (
        parent.documentoModal &&
        parent.documentoModal.length === 17 &&
        parent.razaoSocial === undefined
      ) {
        return createError();
      } else {
        return true;
      }
    }
  ),
  nomeFantasia: Yup.string().required('NomeFantasia é obrigatório'),
  documentoModal: Yup.string().required('CPF/CNPJ é obrigatório'),
  telefoneComercial: Yup.string().required('Telefone é obrigatório'),
  endereco: Yup.object().shape({
    cep: Yup.string().required('CEP é obrigatório'),
    rua: Yup.string().required('Rua é obrigatório'),
    numero: Yup.string().required('Número é obrigatório'),
    bairro: Yup.string().required('Bairro é obrigatório'),
    cidade: Yup.string()
      .required('Cidade é obrigatório')
      .nullable(),
    estado: Yup.string()
      .required('Estado é obrigatório')
      .nullable()
  })
});

class PostoHabilitadoEdit extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        razaoSocial: '',
        nomeFantasia: '',
        telefoneComercial: '',
        horaFuncionamento: '',
        endereco: {
          cep: '',
          rua: '',
          numero: '',
          complemento: '',
          bairro: '',
          cidade: null,
          estado: null
        }
      }
    };
  }

  handleZipCode = (location, zipCode, entity, setFieldValue) => {
    if (location.erro) {
      _.set(entity, 'cep', zipCode);
      setFieldValue('endereco', entity);
      if (!location.notComplete) {
        this.showInfo('CEP não encontrado.');
      }
      if (location.timeout) {
        this.showInfo(
          'Não foi possível conectar no servidor de CEP. Preencha os dados manualmente.'
        );
      }
      this.setState({ entity });
    } else {
      this.props.loading(true);
      _.set(entity, 'cep', zipCode);
      enderecoService
        .findEstadoExact(location.uf, objectsConstants.PAIS_DEFAULT)
        .then(estado => {
          _.set(entity, 'estado', estado.data);
          enderecoService
            .findCidadeExact(location.localidade, estado.data)
            .then(cidade => {
              _.set(entity, 'cidade', cidade.data);
              _.set(entity, 'bairro', location.bairro);
              _.set(entity, 'rua', location.logradouro);
              setFieldValue('endereco', entity);
              this.props.loading(false);
            });
        });
    }
  };

  UNSANFE_componentDidMount() {
    if (this.props.location.state && this.props.location.state.posto) {
      let posto = this.props.location.state.posto;
      this.setState({ initialValues: posto });
    }
  }

  showError = messageError => {
    this.props.error({ message: messageError });
    return '';
  };

  showInfo = messageInfo => {
    this.props.info({ message: messageInfo });
    return '';
  };

  clearError = () => {
    this.props.clear();
    return '';
  };

  getFachadaUrl = entity => {
    if (
      entity.faixada &&
      entity.faixada.id == undefined &&
      entity.faixada.data
    ) {
      return entity.faixada.data;
    } else {
      return require('../../img/foto_faixada.svg');
    }
  };

  getInternaUrl = entity => {
    if (
      entity.fotoInterna &&
      entity.fotoInterna.id == undefined &&
      entity.fotoInterna.data
    ) {
      return entity.fotoInterna.data;
    } else {
      return require('../../img/foto_internaLoja.svg');
    }
  };

  render() {
    const { posto } = this.state;
    let titlePage;
    if (this.props.location.state) {
      titlePage = this.props.location.state.titlePage;
    }

    return (
      <Formik
        validationSchema={newPosto}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={this.state.initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.props.loading(true);
          this.props.loading(false);
          this.props.loading(true);
          console.log(values);
          postoHabilitadoService
            .doSave(values)
            .then(response => {
              actions.setSubmitting(false);
              this.props.success({
                message: 'Posto salvo com sucesso.'
              });
              this.props.loading(false);
              this.props.history.goBack();
            })
            .catch(error => {
              if (error.response.status === 400) {
                this.showError('CPF/CNPJ inválido.');
                actions.setSubmitting(false);
                this.props.loading(false);
              } else {
                actions.setSubmitting(false);
                this.props.loading(false);
              }
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="flex-column">
              <Toast />
              <TopoTitleComponente
                mainTitle="POSTO HABILITADO"
                subTitle={
                  values.id != undefined
                    ? ' Editar dados'
                    : 'Novo Posto habilitado'
                }
                canBack={true}
              />
              {!_.isEmpty(errors)}
              {values.id && this.clearError()}
              <div className="cardSeculus d-flex flex-column mb-0">
                <div className="topoCard d-flex flex-column flex-sm-row">
                  <h3 className="flex-grow-1">Posto</h3>
                </div>
                <UploadPictureRegister
                  name="faixada"
                  label="FOTO DA FACHADA"
                  onChange={imageVO => setFieldValue('faixada', imageVO.image)}
                  idImage={values.faixada ? values.faixada.id : null}
                  dataUrl={this.getFachadaUrl(values)}
                  id="faixada"
                  styleFormat="fotoPerfil"
                  maxHeight={200}
                  maxWidth={200}
                  height={100}
                />

                <UploadPictureRegister
                  label="FOTO INTERNA DA LOJA"
                  name="fotoInterna"
                  onChange={imageVO =>
                    setFieldValue('fotoInterna', imageVO.image)
                  }
                  idImage={values.fotoInterna ? values.fotoInterna.id : null}
                  dataUrl={this.getInternaUrl(values)}
                  styleFormat="fotoPerfil"
                  id="fotoInterna"
                  maxHeight={200}
                  maxWidth={200}
                  height={100}
                />
                <FormRow>
                  <FormInput
                    type="text"
                    name="razaoSocial"
                    label="Razão Social"
                    placeholder="Razão social"
                    erroMensagem={errors.razaoSocial}
                    required={false}
                    style={['col-12 col-lg-12']}
                    id={'razaoSocial'}
                    ref={input => {
                      this._razaoSocial = input;
                    }}
                    onChange={handleChange}
                    value={values.razaoSocial}
                  />
                  <FormInput
                    type="text"
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    placeholder="Nome Fantasia"
                    erroMensagem={errors.nomeFantasia}
                    required={true}
                    style={['col-12 col-lg-12']}
                    id={'nomeFantasia'}
                    ref={input => {
                      this._nomeFantasia = input;
                    }}
                    onChange={handleChange}
                    value={values.nomeFantasia}
                  />
                  <FormInput
                    type="text"
                    name="horaFuncionamento"
                    label="Horário de Funcionamento"
                    placeholder="Horário de Funcionamento"
                    erroMensagem={errors.horaFuncionamento}
                    required={false}
                    style={['col-12 col-lg-12']}
                    id={'horaFuncionamento'}
                    ref={input => {
                      this._horaFuncionamento = input;
                    }}
                    onChange={handleChange}
                    value={values.horaFuncionamento}
                  />
                  <PhoneInput
                    type="number"
                    name="telefoneComercial"
                    label="Telefone comercial"
                    placeholder="Somente números"
                    erroMensagem={errors.telefoneComercial}
                    required={true}
                    style={['col-6 col-lg-4']}
                    size={3}
                    id={'telefoneComercial'}
                    ref={input => {
                      this._telefoneComercial = input;
                    }}
                    onChange={handleChange}
                    value={values.telefoneComercial}
                  />
                  <CpfCnpjInput
                    type="text"
                    style={['col-6 col-lg-4']}
                    label={'CPF ou CNPJ'}
                    errorMessage={errors.documentoModal}
                    required={true}
                    id={'documentoModal'}
                    name="documentoModal"
                    ref={input => {
                      this._documentoModal = input;
                    }}
                    size={100}
                    placeholder="Digite o CPF ou CNPJ"
                    value={values.documentoModal}
                    onChange={handleChange}
                  />
                </FormRow>
                <FormRow>
                  <h3>Endereço</h3>
                </FormRow>
                <FormRow>
                  <ZipCodeInput
                    type="number"
                    name="endereco.cep"
                    label="CEP"
                    erroMensagem={_.get(errors, 'endereco.cep')}
                    placeholder="CEP"
                    required={true}
                    style={['col-6 col-lg-4']}
                    size={2}
                    id={'cep'}
                    ref={input => {
                      this._cep = input;
                    }}
                    onChange={(location, zipCode) => {
                      this.handleZipCode(
                        location,
                        zipCode,
                        values.endereco,
                        setFieldValue
                      );
                    }}
                    value={values.endereco.cep}
                  />
                  <FormInput
                    type="text"
                    name="endereco.rua"
                    label="Logradouro"
                    placeholder="Av, Rua, Alameda"
                    erroMensagem={_.get(errors, 'endereco.rua')}
                    required={true}
                    style={['col-12 col-lg-6']}
                    id={'rua'}
                    ref={input => {
                      this._rua = input;
                    }}
                    onChange={handleChange}
                    value={values.endereco.rua}
                  />
                  <FormInput
                    type="text"
                    name="endereco.numero"
                    label="Nº"
                    placeholder="Nº"
                    erroMensagem={_.get(errors, 'endereco.numero')}
                    required={true}
                    style={['col-4 col-lg-2']}
                    size={4}
                    id={'numero'}
                    ref={input => {
                      this._numero = input;
                    }}
                    onChange={handleChange}
                    value={values.endereco.numero}
                  />
                  <FormInput
                    type="text"
                    name="endereco.complemento"
                    label="Complemento"
                    placeholder="Complemento"
                    required={false}
                    style={['col-8 col-lg-4']}
                    size={8}
                    id={'complemento'}
                    ref={input => {
                      this._complemento = input;
                    }}
                    onChange={handleChange}
                    value={values.endereco.complemento}
                  />
                  <FormInput
                    type="text"
                    name="endereco.bairro"
                    label="Bairro"
                    placeholder="Bairro"
                    erroMensagem={_.get(errors, 'endereco.bairro')}
                    required={true}
                    style={['col-12 col-lg-6']}
                    id={'bairro'}
                    ref={input => {
                      this._bairro = input;
                    }}
                    onChange={handleChange}
                    value={values.endereco.bairro}
                  />
                  <SelectInput
                    type="text"
                    name="endereco.estado"
                    label="Estado"
                    placeholder="Estado"
                    required={true}
                    style={['col-6 col-lg-6']}
                    size={6}
                    erroMensagem={_.get(errors, 'endereco.estado')}
                    onFetchData={enderecoService.findEstado}
                    valueKey="id"
                    labelKey="nome"
                    returnFullObject={true}
                    searchable={true}
                    //messageValidate={validate.portadorDeficiencia}
                    id={'estado'}
                    onChange={handleChange}
                    value={values.endereco.estado}
                  />
                  <SelectInput
                    type="text"
                    name="endereco.cidade"
                    label="Cidade"
                    placeholder="Cidade"
                    erroMensagem={_.get(errors, 'endereco.cidade')}
                    required={true}
                    style={['col-6 col-lg-6']}
                    onFetchData={enderecoService.findCidade}
                    valueKey="id"
                    labelKey="nome"
                    returnFullObject={true}
                    searchable={true}
                    //messageValidate={validate.portadorDeficiencia}
                    id={'cidade'}
                    onChange={handleChange}
                    value={values.endereco.cidade}
                  />
                </FormRow>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <div className="d-flex flex-row flex-sm-row ">
                <div className="col-6 col-lg text-center p-0 my-5 mx-2">
                  <button
                    type="button"
                    className="btn btn-secondary btn30 white"
                    disabled={isSubmitting}
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancelar
                  </button>
                </div>
                <div className="col-6 col-lg text-center p-0 my-5 mx-2">
                  <button
                    type="submit"
                    className="btn btn-secondary btn30 green"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const mapDipatch = ({
  alert: { success, error, clear, info },
  load: { loading }
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  info: msg => info(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(null, mapDipatch)(PostoHabilitadoEdit);
