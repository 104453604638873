//@flow
import * as React from 'react';

export class FormRow extends React.Component<{ children?: React.Node }, {}> {
  render() {
    return (
      <div className={`form-row ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}
