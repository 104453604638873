import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { pecaService } from "../../services/peca.service";

type Props = {
  children?: any,
  onChange: any
};
type State = {
  entity: any
};


class ChatOsRowTrocaPeca extends PureComponent<Props, State> {

  recusarPeca = () => {
    pecaService.recusarPeca(this.props.action.id).then(response => {
      this.props.onChangeAction(response, this.props.index);
    });
  };

  aceitarPeca = () => {
    pecaService.aceitarPeca(this.props.action.id).then(response => {
      this.props.onChangeAction(response, this.props.index);
    });
    ;
  };

  render() {
    return (
      <div ref={ref => (this._div = ref)}>
        {/*{this.props.action.completed && (
          <React.Fragment>
            <div className={this.props.isMeClass()} ref={ref => (this._div = ref)}>
              <div className="col-3 col-sm-2">
                <UserAvatar foto={this.props.action.foto}/>
              </div>
              <div className="msg px-0">
                <div className="boxMessage">
                  {this.props.action.messagecompletedd}
                </div>
              </div>
            </div>
          </React.Fragment>)
        }*/}
        {!this.props.action.completed && (
          <div>
            <button className={"btn-primary white"} onClick={this.recusarPeca}>NÃO ACEITAR</button>
            <button className={"btn-primary "} onClick={this.aceitarPeca}>ACEITAR TROCA</button>
          </div>

        )}
      </div>
    );
  }
}

export default connect(
  null,
  null,
  null,
  { withRef: true }
)(ChatOsRowTrocaPeca);
