//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const pecaService = {
  find,
  getEstoque,
  findByRelogio,
  recusarPeca,
  aceitarPeca,
  findByRelogioAndCodigo,findByRelogioCompraPeca
};

function aceitarPeca(idPeca) {
  return defaultService.doGet(`${urlsConstants.PECA}aceitar/${idPeca}`);
}

function recusarPeca(idPeca) {
  return defaultService.doGet(`${urlsConstants.PECA}recusar/${idPeca}`);
}

function getEstoque(idPeca) {
  return defaultService.doGet(`${urlsConstants.PECA}getEstoque/${idPeca}`);
}

function find(nomePeca) {
  return defaultService.doGet(`${urlsConstants.FIND_PECA}${nomePeca}`);
}

function findByRelogioAndCodigo(relogio, nomePeca) {
  return defaultService.doGet(
    `${urlsConstants.FIND_PECA}${relogio}/${nomePeca}`
  );
}

function findByRelogio(relogio) {
  if(relogio.id) {
    return defaultService.doGet(
        `${urlsConstants.PECA}getByRelogio/${relogio.codigoRelogio}/${relogio.id}`
    );
  }else{
    return defaultService.doGet(
        `${urlsConstants.PECA}getByRelogio/${relogio.codigoRelogio}/`);
  }
}
function findByRelogioCompraPeca(relogio) {
  return defaultService.doGet(
    `${urlsConstants.PECA}getByRelogioCompraPeca/${relogio.codigoRelogio}`
  );
}
