//@flow
import { Can } from '@casl/react';
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
import Toast from '../../components/Toasts/Toast';
import { objectsConstants } from '../../constants/objects.constants';
import { tableHelper } from '../../helpers/tableHelper';
import { perfilService } from '../../services/perfil.service';
import FormConfigurarPerfil from './FormConfigurarPerfil';
type State = { colaborador: any, entity: any };

const columns = [
  {
    path: 'select',
    title: 'Posto',
    isKey: false,
    yesNo: true,
    dataSort: true
  },
  {
    path: 'useCase.name',
    title: 'funcionalidade',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  }
];

class ConfigurarPerfilListagem extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { filter: {}, roles: [] };
  }

  handleSelectChange = (name: string, option: any) => {
    const { filter } = this.state;
    let newfilter = _.cloneDeep(filter);
    _.set(newfilter, name, option);
    this.setState({ filter: newfilter, submitFilter: false }, () => {
      this.findPerfil();
    });
  };

  renderCustom(row: any, entity: any, column: any) {
    if (column.path == 'select') {
      console.log(entity[column.path]);
      return (
        <input type={'checkbox'} checked={entity[column.path]} className={''} />
      );
    } else {
      return tableHelper.renderCustom(row, entity, column);
    }
  }

  findPerfil = () => {
    if (this.state.filter && this.state.filter.role) {
      this.props.loading(true);
      perfilService
        .findByFiltro(
          {
            pageSize: 1000,
            page: 0,
            order: 'role.useCase.name',
            direction: 'asc'
          },
          this.state.filter
        )
        .then(response => {
          let data = response.data;
          this.props.loading(false);
          this.setState({ roles: data.data });
        })
        .catch(error => {
          console.log(error);
          this.props.loading(false);
        });
    } else {
      this.setState({ roles: [] });
    }
  };

  handleChange = (checked, index) => {
    let roles = _.cloneDeep(this.state.roles);
    perfilService
      .changePermissions(roles[index].useCase, checked, this.state.filter.role)
      .then(() => {
        roles[index].select = checked;
        this.setState({ roles });
      });
  };
  render() {
    const { permissions } = this.props;

    return (
      <React.Fragment>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_CONFIGURAR_PERFIL}
          ability={permissions}
        >
          <div className="card cardSeculus d-flex flex-column a1">
            <Toast />

            <div className="conteudoCard mh-290">
              <div className="flex-column">
                <div className="form-row searchBox">
                  <div className="col-12 mb-3">
                    <div className="miniTxt">
                      Selecione o perfil para realizar a configuração de
                      permissões
                    </div>
                  </div>
                  <FormConfigurarPerfil
                    onChangeSelect={this.handleSelectChange}
                    entity={this.state.filter}
                  />
                </div>
                <div className="row">
                  {this.state.roles.map((role, index) => {
                    return (
                      <React.Fragment>
                        {/* // <div className={'row'}> */}
                        <div className="form-row col-12 b-botton pt-2">
                          <div className={'col'}>{role.useCase.name}</div>
                          <div className={'col-2 text-right'}>
                            <Switch
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onChange={checked =>
                                this.handleChange(checked, index)
                              }
                              checked={role.select}
                              onColor={'#85cd63'}
                              offColor={'#dddddd'}
                            />
                          </div>
                        </div>
                        {/* </div> */}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* <ListagemPage
                title={`Funcionalidade(${this.props.subTitle})`}
                newButtonActionClick={this.toggle}
                delete={() => {}}
                service={perfilService}
                labelNewButton={''}
                classSignal="temSinal"
                defaultOrder={'creationDateTime'}
                defaultDirection={'desc'}
                defaultFilter={{}}
                onRowClick={this.handleRowClick}
                showNewButton={false}
                controller="perfil"
                fields={this.state.fields}
                FormFilter={FormConfigurarPerfil}
            >
              {tableColumn}
            </ListagemPage>*/}
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}
const mapDispatch = ({ load: { loading }, title: { changeTitle } }) => ({
  loading: (load: boolean) => loading({ load }),
  changeTitle: (subTitle: string) => changeTitle({ subTitle })
});
export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ConfigurarPerfilListagem));
