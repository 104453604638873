import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Toast from '../../components/Toasts/Toast';
import { BodyWhite } from '../../components/templates/BodyWhite';
import { CardItem } from '../../components/templates/CardItem';
import CardSeculusFull from '../../components/panels/CardSeculusFull';
import RatingItem from '../../components/rating/RatingItem';
import { objectsConstants } from '../../constants/objects.constants';
import { avalicaoAtendimento } from '../../services/avalicaoAtendimento.service';
import { Can } from '@casl/react';

var logo = require('../../img/logo.svg');

class AvaliarAtendimento extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: { id: props.match.params.id, respostas: [] },
      jaAvaliado: false
    };
  }

  componentDidMount() {
    this.props.loading(true);
    avalicaoAtendimento.doGet(this.props.match.params.id).then(response => {
      let pesquisa = response.data.data;
      if (pesquisa.dataResposta) {
        this.setState({ jaAvaliado: true });
      }
      this.props.loading(false);
    });
  }

  handleChangeRating = (item, rating) => {
    const newEntity = _.cloneDeep(this.state.entity);

    var existenteResposta = newEntity.respostas.find(function(resposta) {
      return resposta.resposta === item.id;
    });

    //update object in array se já exitir
    if (existenteResposta) {
      //se tem quantidade atualiza item no array
      newEntity.respostas = _.map(newEntity.respostas, function(
        resposataAdded
      ) {
        return resposataAdded.resposta === item.id
          ? {
              resposta: resposataAdded.resposta,
              nota: rating
            }
          : resposataAdded;
      });
    } else {
      let newResposta = { resposta: item.id, nota: rating };
      newEntity.respostas.push(newResposta);
    }

    this.setState({ entity: newEntity });
  };

  getMedia = () => {
    const { entity } = this.state;
    let average = _.meanBy(entity.respostas, r => r.nota);
    return _.isNaN(average) ? 0 : average.toFixed(1);
  };

  handleSubmiteAvalicao = () => {
    const { entity } = this.state;
    this.props.loading(true);
    avalicaoAtendimento.doSave(entity).then(result => {
      this.props.loading(false);
      this.props.success({
        message: 'Obrigado por sua avaliação.'
      });
      this.setState({ jaAvaliado: true });
    });
  };

  isDisabledBtn = () => {
    if (this.getMedia() === 0 || this.state.jaAvaliado ||this.state.entity.respostas.length < objectsConstants.QUESTIONARIO_PESQUISA_ARRAY.length) {
      return true;
    }
    return false;
  };

  render() {
    const { permissions } = this.props;
    const { jaAvaliado } = this.state;
    return (
      <React.Fragment>
        <Toast />
        <div className="flex-column pt-1 px-1 pt-md-5 px-md-5">
          <div className="card cardSeculus cardFull">
            <div className="topoCard d-flex flex-column flex-sm-row">
              <h3 className="flex-grow-1">Pesquisa de Satisfação</h3>
            </div>
            <div className="conteudoCard ">
              <div className="col-sm-6 d-flex flex-row align-items-center pr-3 border-right">
                <div className="justify-content-center">
                  <img src={logo} alt="logo" className="logoSeculus" />
                </div>
                <div className="justify-content-center pl-3">
                  <h4 className="subDestaqueTxtCard" />
                </div>
              </div>
              {!jaAvaliado && (
                <div className="col px-4">
                  {objectsConstants.QUESTIONARIO_PESQUISA_ARRAY.map(item => {
                    return (
                      <RatingItem
                        key={item.id}
                        label={item.label}
                        item={item}
                        onChangeRating={this.handleChangeRating}
                      />
                    );
                  })}

                  <RatingItem
                    label="Nota Média"
                    classLabel="bold"
                    readonly={true}
                    rating={this.getMedia()}
                  />
                </div>
              )}
              {jaAvaliado && (
                <div className="col px-4">
                  <h1 className="mt-4 txtGreen">Obrigado por avaliar!</h1>
                  <p className="mt-0">
                    A Seculus agradece a sua participação.
                  </p>
                  <p>
                    Qualquer dúvida ou sugestões entre em contato pelos canais:
                  </p>
                  <p>
                    Central de atendimento ao Cliente
                    <br/>
                    0800 9400 977 / 4005-1505
                    <br/>
                    atendimento.cliente@seculus.com.br
                  </p>
                </div>
              )}
            </div>
            <hr />
            {!jaAvaliado && (
              <div className="form-group col-12 col-sm-12 ">
                <button
                  type="button"
                  className="btn btn-primary btn-sm float-right"
                  onClick={this.handleSubmiteAvalicao}
                  disabled={this.isDisabledBtn()}
                >
                  Enviar Avaliação
                </button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    loggedIn,
    alert,
    messages
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load })
});
export default connect(
  mapStateToProps,
  mapDispatch
)(AvaliarAtendimento);
