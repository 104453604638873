//@flow
import React from 'react';
import { toastr } from 'react-redux-toastr';
import IconErrorToast from '../components/ToastIcons/IconErrorToast';
import IconSucessToast from '../components/ToastIcons/IconSucessToast';

export default {
  state: {},
  effects: () => ({
    success: payload => {
      toastr.success(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          icon: <IconSucessToast />
        }
      );
    },
    error: payload => {
      toastr.error(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          icon: <IconErrorToast />
        }
      );
    },
    clear: () => {
      toastr.clean();
    },
    info: async payload => {
      toastr.info(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message
      );
    }
  })
};
