import React, { PureComponent } from 'react';

type Props = {
  children?: any,
  height?: string,
  width?: string
};

export class CardItemFull extends PureComponent<Props> {
  static defaultProps = {
    height: '',
    width: ''
  };
  render() {
    return (
      <div
        className={`card cardSeculus cardFull d-flex flex-column ${
          this.props.width
        } ${this.props.height}`}
      >
        {this.props.title && (
          <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">{this.props.title}</h3>
            <div className="boxButon float-right d-sm-flex">
              {this.props.getBtns && this.props.getBtns()}
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}
