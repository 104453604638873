//@flow
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import { osTipoPessoa } from '../../../services/osTipoPessoa.service';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import OsExpiradaFormFilter from './OsExpiradaFormFilter';
import { tableHelper } from '../../../helpers/tableHelper';
import { osExpiradaService } from "../../../services/osExpirada.service";

const columns = [
  {
    path: 'numOS',
    title: 'Nº OS',
    isKey: true,
    dataSort: true
  },

  {
    path: 'cliente.nome',
    title: 'Nome do cliente',
    isKey: false,
    dataSort: false
  },
  {
    path: 'creationDateTime',
    title: 'Data Inicial',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  {
    path: 'dataStatusAtual',
    title: 'Data final',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  { path: 'statusOs', title: 'Status', isKey: false, dataSort: true },
  {
    path: 'postoAutorizado.endereco.cidade.nome',
    title: 'Cidade',
    isKey: false,
    dataSort: false
  }
];

class OsExpirada extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      fields,
      defaultFilter: {},
      filter: {}
    };
  }
  _handleFilterChange = filter => {
    this.setState({ filter });
  };
  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle=" O.S. Expirada "
          canBack={false}
        />
        <CardRelatorio
          title={` O.S. Expirada (${
            this.props.subTitle
          })`}
          FormFilter={OsExpiradaFormFilter}
          showTable={true}
          tableService={osExpiradaService}
          controller={'relatorio/osExpirada'}
          fields={this.state.fields}
          defaultFilter={this.state.defaultFilter}
          onHandleFilter={this._handleFilterChange}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(OsExpirada);
