//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import _ from "lodash";
import {GrupoFilialService} from "../../services/grupoFilial.service";
import CardGrupoFilial from "../../components/Cards/CardGrupoFilial";
import {clienteService} from "../../services/cliente.service";

type State = { grupoFilial: any };

class grupoFilialView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      grupoFilial: {}
    };
  }
  //Colocar a lista de clientes aqui para ser vista, e passa ela como parametro pro card

  componentWillMount() {
    if (this.props.match.params.id) {

      GrupoFilialService.getById(this.props.match.params.id).then(result => {
        let grupoFilial = result.data.data;
        this.setState({ grupoFilial });
      });



      console.log('comp mount view group')
      console.log(this.state)
    }
  }

  onUpdate = (grupoFilial) => {
    let grupoFilialEditar = _.cloneDeep(grupoFilial);
    this.setState({ grupoFilial:grupoFilialEditar });
  }

  render() {
    console.log("Grupo Filial View")
    console.log(this.state)
    const { grupoFilial } = this.state;

    return (
      <React.Fragment>
        <div className="flex-column">
          <CardGrupoFilial
            grupoFilial={grupoFilial}
            titlePageEdit="grupoFilial"
            onUpdate={this.onUpdate}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(grupoFilialView);
